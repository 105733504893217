import { Component, OnInit, ɵConsole, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../../core/services/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { ITabItem } from '../../../app.component';
import {AuthenticationsService} from "../../../core/services/auth/authenticationsService";

@Component({
  selector: 'gmao-small-screens-menu',
  templateUrl: './small-screens-menu.component.html',
  styleUrls: ['./small-screens-menu.component.scss']
})
export class SmallScreensMenuComponent implements OnInit {
  @Input() navLinks: ITabItem[];

  isLoggedIn$: Observable<boolean>;
  selectedBuildingName: string;

  firstWord: string = "Conservatoire";

  remainingWords: string = " a rayonnement departemental";

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));

  constructor(public dialog: MatDialog, private breakpointObserver: BreakpointObserver,
              private authService: AuthenticationsService, private homeNavService: DashboardService, private router: Router) { }

  ngOnInit() {
    this.homeNavService.dashboardSelectedSiteObs.subscribe(message => {
      this.selectedBuildingName = message?.name;
      this.sliceName();
    })
  }

  sliceName() {
    this.firstWord = "";
    this.remainingWords = "";

    if (this.selectedBuildingName) {
      let words = this.selectedBuildingName.split(' ');
      this.remainingWords = "";

      for (let i = 0; i < words.length; i++) {
        if (i == 0) {
          this.firstWord = words[i] + "";
        } else {
          this.remainingWords = this.remainingWords + " " + words[i];
        }
      }
    }
  }

  navigate(path) {
    this.router.navigate([path]);
  }
}
