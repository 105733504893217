import {Component, EventEmitter, Input, Output} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {TranslatedToasterService} from '../../../services/translated-toaster/translated-toaster.service';
import {AuthenticationsService} from '../../../services/auth/authenticationsService';
import {SitesService} from '../../../services/sites/sites.service';
import {SiteZone} from '../../../models/sites/site-zone';
import {SitesManagementService} from '../../../services/sites/sites-management.service';
import {UserSpace} from "../../../models/user-access/user.enum";
import AuthedUserAttributesEnum = UserSpace.AuthedUserAttributesEnum;

/**
 *
 * @author Haytham Idrees
 * @description generic method used to view location by passing the location id
 * and using for editing element location
 */
@Component({
  selector: 'sg-location-input',
  templateUrl: './location-input.component.html'
})
/**
 * @deprecated
 */
export class LocationInputComponent {
  /**
   * emit user selected location
   * @deprecated
   */
  @Output('locationEmitter') locationEmitter = new EventEmitter<{
    location: SiteZone,
    descendantsIds: string[]
  }>();
  /**
   * show red info icon on input if the value if true
   * @deprecated
   */
  @Input('error') error: boolean;
  /**
   * @deprecated
   */
  @Input('maxAllowedRoleId') maxAllowedRoleId: number = 3;
  /**
   * used to pass classes names from parent component
   * @deprecated
   */
  @Input('classes') classes: string;
  /**
   *  use mat form field with mat input if it true, and use normal input otherwise
   * @deprecated
   */

  @Input('outline') outline: boolean = true;
  /**
   * use label if true, don't add any label other wise
   * @deprecated
   */
  @Input('showLabel') showLabel: boolean = true;
  /**
   * its used to bring only location for the selected building
   * @deprecated
   */
  @Input('buildingId') buildingId: string;
  /**
   * @deprecated
   */
  @Input('buildingsIds') buildingsIds: string[] = [];

  /**
   * prevent user editing if the value is false
   * @deprecated
   */
  @Input('canEdit') canEdit = true;
  /**
   * set the input place holder text
   * @deprecated
   */
  @Input('label') label = 'Location';
  /**
   * @deprecated
   */
  @Input('descendants') descendants = false;
  locationName: string;
  locationLoading;
  location: string;
  filteredLocations: BehaviorSubject<SiteZone[]> = new BehaviorSubject([]);
  locationId;
  timeOut;
  roleId = 3;

  constructor(
    private authService: AuthenticationsService,
    private sitesService: SitesService,
    private translatedToasterService: TranslatedToasterService,
    private sitesManagementService: SitesManagementService
  ) {
    this.roleId = +this.authService.getAuthedUserData(AuthedUserAttributesEnum.ROLE);
  }

  /**
   * bring location name by location id
   * @deprecated
   * @param locationId
   * @returns
   */
  onSelectLocationId(locationId) {
    if (this.locationId === locationId && !!this.locationName) {
      return;
    }
    this.locationName = undefined;
    this.locationId = locationId;
    if (this.sitesManagementService.validateLocationId(locationId) && this.buildingId) {
      this.locationLoading = true;
      this.sitesService.getZoneById(this.buildingId, locationId).subscribe((location: SiteZone) => {
        this.locationLoading = false;
        if (location) {
          this.emitLocation(location);
          this.locationName = location.name;
        }
      }, error => {
        this.locationLoading = false;
        this.translatedToasterService.showErrorCodeMessage('COULD_NOT_RETRIEVE_LOCATION');
      });
    }
  }

  /**
   * filter location using the user input as pattern
   * @param pattern
   * @returns
   */
  filterLocations(pattern: string) {
    if (!pattern) {
      this.filteredLocations.next([]);
      this.locationLoading = false;
      return;
    }
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }

    this.locationLoading = true;
    this.timeOut = setTimeout(() => {
      this.getLocations(pattern)
        .pipe(debounceTime(350))
        .subscribe((filteredValue: any) => {
          this.locationLoading = false;
          this.filteredLocations.next(filteredValue);
        });
    }, 350);
  }

  /**
   * emit  location and set location value
   * @param location
   */
  selectLocation(location) {
    this.locationName = location.name;
    if (this.descendants) {
      this.getLocations('')
        .subscribe((locations: SiteZone[]) => {
          const descendantsIds = locations.map(element => `${element._id}`);
          this.emitLocation(location, descendantsIds);
        });
    } else {
      this.emitLocation(location);
    }
  }

  emitLocation(location, descendantsIds?: string[]) {
    this.locationEmitter.emit({ location, descendantsIds: descendantsIds });
    this.location = location.name;
  }

  /**
   * set building id that will be used to bring location
   * @deprecated
   * @param buildingId
   */
  setBuildingId(buildingId) {
    this.buildingId = buildingId;
  }

  /**
   * get buildings if buildingsIds is exit or building location if not
   * @param pattern
   * @deprecated
   * @returns Observable<any>
   */
  getLocations(pattern): Observable<SiteZone[]> {
    return this.sitesService.getSiteZone((this.buildingId ? [this.buildingId] : this.buildingsIds), pattern);
  }
}
