<div class="w-100p h-100p f-row a-center j-between sg-bgr-primary br-5px pa-5px">

  <div class="h-100p" [ngClass]="atGridster ? 'w-100p' : 'w-72p'">
    <highcharts-chart
      [Highcharts]="highcharts"
      class="h-100p w-100p pos-relative"
      style="display: block;"
      [constructorType]="constructorType"
      [options]="chartOptions"
      (chartInstance)="loadChartInstance($event)">
    </highcharts-chart>
  </div>

  <div  *ngIf="!atGridster" class="w-25p h-95p px-10px f-row a-center j-end">
    <div class="h-100p w-82p f-col a-center j-center">
      <span class="custom-txt-22px my-5px">{{ "areaChartWidget.at" | translate}} {{settings.currentHour}} H</span>
      <span  class="txt-35px txt-bold my-5px">{{+totalData}}</span>
      <span  class="custom-txt-22px my-5px">{{Energy.EnergyUnitEnum.KWH}}</span>
      <span  class="custom-txt-18px mt-5px">{{ "areaChartWidget.consumption" | translate }}</span>

      <p  class="custom-txt-18px txt-center">{{settings.totalTitle}}</p>

    </div>
  </div>
</div>
