import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WeatherWindowComponent } from './weather-window.component';
import { WeatherWindowRoutingModule } from './weather-window-routing.module';
import { WeatherChipModule } from './weather-chip/weather-chip.module';
import { MetroCardModule } from './metro-card/metro-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { TransportationService } from 'src/app/core/services/transportation/transportation.service';
import {ActualWeatherTemperatureModule} from "./actual-weather-temperature/actual-weather-temperature.module";
import {LoadingSkeletonModule} from "../../components/loading-skeleton/loading-skeleton.module";

@NgModule({
  declarations: [
    WeatherWindowComponent
  ],

  imports: [
    CommonModule,
    TranslateModule,
    WeatherWindowRoutingModule,
    WeatherChipModule,
    MetroCardModule,
    ActualWeatherTemperatureModule,
    LoadingSkeletonModule
  ],

  exports: [
    WeatherWindowComponent
  ],
  providers: [TransportationService]
})
export class WeatherWindowModule {
}
