import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StationStop } from '../../models/transportation/station-stops.model';
import { Observable } from 'rxjs';
import { StopInfo } from '../../models/transportation/stop-info.model';

@Injectable()
export class TransportationService {

  transportationPath = `/api/transportation/`;

  stations: StationStop[] = [
    {
      name: 'Saint-Philippe du Roule',
      stops: [
        {
          name1: 'STIF:StopPoint:Q:21912:',
          name2: 'STIF:StopPoint:Q:462915:',
          icon: 'metro-9.png'
        }
      ],
      walkingDistance: '2mn'
    },
    {
      name: 'Miromesnil',
      stops: [
        {
          name1: 'STIF:StopPoint:Q:21924:',
          name2: 'STIF:StopPoint:Q:462957:',
          icon: 'metro-9.png'
        },
        {
          name1: 'STIF:StopPoint:Q:474397:',
          name2: 'STIF:StopPoint:Q:22224:',
          icon: 'metro-13.png'
        }
      ],
      walkingDistance: '5mn'
    },
    {
      name: 'Champs-Élysées - Clemenceau',
      stops: [
        {
          name1: 'STIF:StopPoint:Q:463011:',
          name2: 'STIF:StopPoint:Q:22093:',
          icon: 'metro-13.png'
        },
        {
          name1: 'STIF:StopPoint:Q:463010:',
          name2: 'STIF:StopPoint:Q:22090:',
          icon: 'metro-1.png'
        }
      ],
      walkingDistance: '7mn'
    },
    {
      name: 'Franklin D. Roosevelt',
      stops: [
        {
          name1: 'STIF:StopPoint:Q:21931:',
          name2: 'STIF:StopPoint:Q:463180:',
          icon: 'metro-9.png'
        },
        {
          name1: 'STIF:StopPoint:Q:463181:',
          name2: 'STIF:StopPoint:Q:22082:',
          icon: 'metro-1.png'
        }
      ],
      walkingDistance: '7mn'
    }
  ];

  constructor(
    private http: HttpClient
  ) {
  }

  getStopsInfo(): Observable<StopInfo> {
    const stops: string[] = [];
    this.stations.forEach((station: StationStop) => {
      for (let stop of station.stops) {
        stops.push(stop.name1);
        stops.push(stop.name2);
      }
      ;
    });

    return this.http.post<StopInfo>(this.transportationPath, stops).pipe();
  }


}
