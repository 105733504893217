<div class="dialog-container">
  <ng-container>
    <mat-dialog-content>
      <div class="content">
        <img [src]="imgPath | securePublic | async" alt="" (error)="$event.target['src']=logo">
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="actions">
      <button id="dialog_cancel_button" (click)="close()">{{'GENERAL.CANCEL' | translate |uppercase}}</button>
    </mat-dialog-actions>
  </ng-container>
</div>
