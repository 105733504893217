import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Subscription} from 'rxjs';
import {
  ToolbarSideNavService
} from 'src/app/core/deprecated/deprecated-services/deprecated-toolbar-sidenav-service/toolbar-sidenav.service';
import {ViewPageEnum, ViewPageType} from "../../../../core/view-models/view-page-enum";
import {ActivatedRoute, ActivationEnd, Router} from "@angular/router";

@Component({
  selector: 'gmao-sidebars-toolbar',
  templateUrl: './sidebars-toolbar.component.html',
  styleUrls: ['./sidebars-toolbar.component.scss']
})
export class SidebarsToolbarComponent implements OnInit, OnDestroy {
  ViewPageEnum = ViewPageEnum;
  pageViewType: ViewPageType;
  panelOpenState = false;
  saveDisabled: boolean = false;
  resetDisabled: boolean = false;

  subscription: Subscription = new Subscription();
  subscriptionRouter: Subscription = new Subscription();
  delay = 1000;

  constructor(private toolbarSideNavService: ToolbarSideNavService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.subscribeSave();
  }

  private subscribeSave() {
    this.subscription = this.toolbarSideNavService.isSavedObservable$.subscribe(
      (isSaved: boolean) => {
        this.saveDisabled = !isSaved;
      });

    this.pageViewType = this.activatedRoute.firstChild.snapshot?.data['pageViewType'];
    this.subscriptionRouter = this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.pageViewType = this.activatedRoute.firstChild.snapshot?.data['pageViewType'];
      }
    })
  }

  openDialog(type: String, edit: boolean): void {
    let message = {type: type, save: false, reset: false};
    this.toolbarSideNavService.selectToolbarItem(message);
  }

  save() {
    this.toolbarSideNavService.isItemsSaved(false);
    let message = {type: undefined, save: true, reset: false}
    this.toolbarSideNavService.selectToolbarItem(message);
  }

  /**
   * method used to reset gridster component and remove all changes
   */
  reset() {
    let message = {type: undefined, save: false, reset: true}
    this.toolbarSideNavService.selectToolbarItem(message);

    this.resetDisabled = true;
    setTimeout(() => {
      this.resetDisabled = false;
    }, this.delay);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionRouter.unsubscribe();
  }
}
