import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import {DataSourceVariable} from '../../deprecated-models/data-source-variable.model';
import {Formula} from '../../deprecated-models/formula';
import {PeriodsFormula} from '../../deprecated-models/periods-formula';
import {AuthenticationsService} from "../../../services/auth/authenticationsService";

@Injectable()
/**
 * @deprecated
 */
export class TesseractService {
  baseUrl: string;
  // dataPath = `api/tesseract/find/db/${this.authService.getClientId()}/data/`;
  // avrgDataPath = `api/tesseract/avg/db/${this.authService.getClientId()}/data/`;
  // deltaPath = `api/tesseract/delta/db/${this.authService.getClientId()}/data/`;
  // formulaPath = `api/tesseract/formula/db/${this.authService.getClientId()}/data/`;

  constructor(private http: HttpClient, private authService: AuthenticationsService) {
  }


  /**
   * @deprecated
   */
  getData(datatId, startDate: Date, stopDate: Date) {
    if (!datatId) return of([])
    const url =
      `api/tesseract/find/db/${this.authService.getClientId()}/data/` +
      datatId +
      '/?step=hours&start=' +
      startDate.getTime() +
      '&stop=' +
      stopDate.getTime();
    return this.http.get(url).pipe(
      catchError(this.handleError)
    );
  }


  /**
   * @deprecated
   */
  getDelta(datatId, startDate: Date, stopDate: Date, deltaType?: string) {
    deltaType = (deltaType) ? '&deltaType=' + deltaType : '';
    const url =
      `api/tesseract/delta/db/${this.authService.getClientId()}/data/` +
      datatId +
      '/?step=months&start=' +
      startDate.getTime() +
      '&stop=' +
      stopDate.getTime() + deltaType;
    return this.http.get(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  getFormula(
    sources: DataSourceVariable[],
    formula: Formula | PeriodsFormula,
    startDate: Date,
    stopDate: Date,
    delta: string | boolean,
    step?: string,
    sourcesTimeZone?: string
  ) {
    let now = new Date();
    if (stopDate > now) {
      stopDate = now;
    }

    if (step === undefined) {
      step = 'hours';
    }

    let params = new HttpParams();
    params = params.append("start", startDate.getTime() + '');
    params = params.append("stop", stopDate.getTime() + '');
    params = params.append("step", step);
    params = params.append("formula", encodeURIComponent(JSON.stringify(formula)));
    params = params.append("delta", delta + '');
    params = params.append("sources", JSON.stringify(sources));
    if (sourcesTimeZone) {
      params = params.append("sourcesTimeZone", sourcesTimeZone);
    }

    return this.http.get(`api/tesseract/formula/db/${this.authService.getClientId()}/data/`, {params: params}).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
