import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {FilterType} from "../sg-autocomplete-multi-select/filter-type";
import {debounceTime, distinctUntilChanged, startWith} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {
  MultiSelectAutoCompleteSkeletonComponent
} from "../../loading-skeleton/multi-select-auto-complete-skeleton/multi-select-auto-complete-skeleton.component";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'gmao-sg-autocomplete-single-select',
  templateUrl: './sg-autocomplete-single-select.component.html',
  styleUrls: ['./sg-autocomplete-single-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SgAutocompleteSingleSelectComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('searchInputRef', {static: true}) searchInputRef!: ElementRef;
  /**
   * the key to access the records for filtering the options
   */
  @Input('filterKey') filterKey: string;
  /**
   * The unique attribute filed of the ITEM object used to represent the option .
   * ex , uniqueKey = id : the output value is the id's array of the options.
   */
  @Input('uniqueKey') uniqueKey: string = 'id';
  /**
   * Mat select options observable(local filtering),
   * @see filterKey
   */
  @Input('getOptionsObs') getOptionsObs: () => Observable<any>;
  /**
   * Mat select label,
   */
  @Input('label') label: string = "";
  /**
   * Mat-select options obs retrieved from the BE side
   * @param filterType : object that hold the params needs for options filtration
   * @see filterKey
   */
  @Input('getServerOptionsObs') getServerOptionsObs: <T>(filterType?: FilterType<T>) => Observable<any>;
  /**
   * initial value for controller
   * @see dataSelectControl
   */
  @Input('initialValue') initialValue: string;
  /**
   * Set Tooltip value
   */
  @Input('setTooltip') setTooltip: (value) => string;
  /**
   * input to determine the appearance for mat-form-field
   */
  @Input('appearance') appearance: MatFormFieldAppearance = 'outline';
  /**
   * To determine min-width for mat-form-field
   */
  @Input('minWidth') minWidth: string = '270px';
  /**
   * Required selection
   */
  @Input('required') required: boolean = false;
  /**
   * This input to allow add option all
   */
  @Input('allowAllOption') allowAllOption: boolean;
  /**
   * check if the field disabled or not
   */
  @Input("isDisabled") isDisabled: boolean = false;
  /**
   * used to determine get value from labelTranslation or from filterKey
   * @see filterKey
   */
  @Input('includeLabelTranslation') includeLabelTranslation: boolean = false;
  /**
   * Subject bound to the search field , hit an event and subscribe to filter
   */
  searchSubject$ = new Subject<string>();
  /**
   * This Output is responsible to emit selected data to parent, and what data need to send it's depends on uniqueKey
   * @see uniqueKey
   */
  @Output('selectionChange') selectionChange = new EventEmitter<string[] | number[]>();
  /**
   * This Output is responsible to emit selected data Object to parent
   * @see uniqueKey
   */
  @Output('selectionObjectChange') selectionObjectChange = new EventEmitter<any>();

  baseOptionsList: any[];
  filterOptionsList: any[];
  dataSelectControl = new FormControl();
  isLoadingData: boolean = false;
  loadingSkeletonComponent = MultiSelectAutoCompleteSkeletonComponent;
  lang: string;
  tooltip: string = "";

  constructor(private translateService: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['getOptionsObs']?.currentValue && !changes['getOptionsObs']?.firstChange) {
      this.refreshData();
    }

    if (changes['getServerOptionsObs']?.currentValue && !changes['getServerOptionsObs']?.firstChange) {
      this.refreshData();
    }

    changes['initialValue']?.currentValue && this.dataSelectControl?.setValue(changes['initialValue'].currentValue);
  }

  /**
   * This function used to refresh data and get new data
   */
  private refreshData() {
    this.filterOptionsList = [];
    this.baseOptionsList = [];
    this.getOptionsObs ? this.getOption() : this.getServerOptions();
    this.subscribeSearch();
  }

  ngOnInit(): void {
    this.setLang();
    this.refreshData()
  }

  private setLang() {
    this.lang = this.translateService.currentLang;
  }

  private getOption() {
    this.isLoadingData = true;
    this.getOptionsObs().subscribe(optionsList => {
      this.baseOptionsList = optionsList;
      this.filterOptions('');
      this.isLoadingData = false;
    })
  }

  private getServerOptions(filterString?: string) {
    this.isLoadingData = true;
    this.getServerOptionsObs(filterString).subscribe(optionsList => {
      this.filterOptionsList = this.sortFilteredData(optionsList);
      this.isLoadingData = false;
    });
  }

  private subscribeSearch() {
    this.searchSubject$.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged()).subscribe(value => {
      this.filterOptions(value);
    });
  }

  handleSelect(ele?) {
    let selectedId = ele ? ele[this.uniqueKey] : 'all';
    let selectedObject = ele ?? 'all';
    this.selectionChange.emit(selectedId);
    this.selectionObjectChange.emit(selectedObject);
  }

  ngAfterViewInit() {
    this.dataSelectControl.setValue(this.initialValue);
  }
  /**
   * This function filter data depends on the input, filtered data locally and on server if filtration is on server
   * @param value
   * @private
   */

  private filterOptions(value: string): any[] {
    if (this.getServerOptionsObs) {
      this.getServerOptions(value.toLowerCase());
      return [];
    }
    this.filterOptionsList = this.baseOptionsList?.filter(ele => {
      let option: string = this.includeLabelTranslation && ele?.labelTranslation ? ele.labelTranslation[this.lang] : ele[this.filterKey];
      return option?.toLowerCase()?.includes(value?.toLowerCase());
    });
    return this.filterOptionsList;
  }


  /**
   * This function used to sorted filtered data
   * @param filteredOption
   */
  sortFilteredData(filteredOption: any[]) {
    return filteredOption?.sort(function (a, b) {
      if (a['filterKey'] < b['filterKey']) {
        return -1;
      }
      if (a['filterKey'] > b['filterKey']) {
        return 1;
      }
      return 0;
    })
  }

  resetSearchField() {
    this.searchInputRef.nativeElement.value = '';
    this.searchSubject$.next('');
  }

  reGetData() {
    this.getOptionsObs ? this.getOption() : this.getServerOptions();
  }

  get setupControlToolTip(): string{
    if(!this.setTooltip) return "";
    return this.setTooltip(this.baseOptionsList.find(data => data[this.uniqueKey] == this.dataSelectControl.value));
  }

  protected readonly MultiSelectAutoCompleteSkeletonComponent = MultiSelectAutoCompleteSkeletonComponent;
}
