import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigControlCellComponent } from './config-control-cell.component';
import { InjectControlPluginDirective } from './inject-control.directive';
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@NgModule({
  declarations: [
    ConfigControlCellComponent,
    InjectControlPluginDirective
  ],
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule
    ],
  exports: [
    ConfigControlCellComponent
  ]
})
export class ConfigControlCellModule { }
