import {Pipe, PipeTransform} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {FileManagerService} from "../services/files/file-manager.service";
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'securePrivate'
})
export class SecurePrivatePipe implements PipeTransform {

  constructor(private http: HttpClient,
              private sanitizer: DomSanitizer,
              private fileUploadService: FileManagerService) {
  }

  transform(documentId: string, documentPath?: string): Observable<SafeUrl | string> {
    if (!documentId || documentId.length == 0) return of(environment.logo)
    return this.fileUploadService.getPrivateDocumentPresign(documentId).pipe(
      map(ele => this.sanitizer.bypassSecurityTrustResourceUrl(ele)),
      catchError(e => {
        return of(documentPath)
      })
    )
  }

}
