import {Component, Input} from '@angular/core';
import {CityWeather} from "../../../core/models/weather/city-weather.model";
import {ForecastReading} from "../../../core/models/weather/forecast-reading";
import {
  HomeRectangleSkeletonComponent
} from "../../../components/loading-skeleton/home/home-rectangle-skeleton/home-rectangle-skeleton.component";

@Component({
  selector: 'gmao-actual-weather-temperature',
  templateUrl: './actual-weather-temperature.component.html',
  styleUrls: ['./actual-weather-temperature.component.scss']
})
export class ActualWeatherTemperatureComponent {
  @Input('weatherIcon') weatherIcon: string;
  @Input('currentWeather') currentWeather: CityWeather;
  @Input('forecastReadings') forecastReadings: ForecastReading[];
  loadingSkeletonComponent = HomeRectangleSkeletonComponent;
}
