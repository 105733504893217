import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {EquipmentControlSidebarAction} from '../../deprecated-models/equipment-control-sidebar-action.model';
import {SiteEquipmentModel} from "../../../models/site-equipments/site-equipment.model";


@Injectable({
  providedIn: 'root'
})
/**
 * @deprecated
 */
export class EquipmentControlSidebarService {

  private equipmentControlAction = new Subject<EquipmentControlSidebarAction>();

  private saveAction = new Subject<boolean>();

  private buildingEquipment = new Subject<SiteEquipmentModel[]>();

  private floorIndex = new Subject<number>();


  constructor() {}

  /**
   * @deprecated
   */
  floorIndexAsObservable(): Observable<any> {
    return this.floorIndex.asObservable();
  }

  /**
   * @deprecated
   */
  sendFloorIndex(floorNumber) {
    this.floorIndex.next(floorNumber);
  }

  /**
   * @deprecated
   */
  buildingEquipmentAsObservable(): Observable<SiteEquipmentModel[]>  {
    return this.buildingEquipment.asObservable();
  }

  /**
   * @deprecated
   */
  sendBuildingEquipment(buildingEquipment: SiteEquipmentModel[]) {
    this.buildingEquipment.next(buildingEquipment);
  }

  /**
   * @deprecated
   */
  equipmentControlActionAsObservable(): Observable<EquipmentControlSidebarAction> {
    return this.equipmentControlAction.asObservable();
  }

  /**
   * @deprecated
   */
  sendEquipmentControlAction(buildingEquipmentWithAction: EquipmentControlSidebarAction) {
    this.equipmentControlAction.next(buildingEquipmentWithAction);
  }

  /**
   * @deprecated
   */
  saveActionAsObservable(): Observable<boolean> {
    return this.saveAction.asObservable();
  }

  /**
   * @deprecated
   */
  sendSaveAction() {
    this.saveAction.next();
  }


}
