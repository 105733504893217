<div class="h-100p" *ngIf="errors">
    <div class="w-100p f-row j-center  h-10p">
        <strong>{{'CsvDialogs.anErrorOccurredWhileIporting'|translate}} {{fileName}}</strong>
    </div>
    <div class="w-100p h-80p over-flow-scroll-y">
        <ul>
            <li class="w-100p mb-15px" *ngFor="let lineErrors of errors">
                <div class="f-row">
                    <div class="w-25p">
                            {{'CsvDialogs.line'|translate}} {{lineErrors.line}} {{'CsvDialogs.unvalid'|translate}}:  
                    </div>
                    <div class="w-75p f-row f-wrap">
                        <div *ngFor="let error of lineErrors.errorsNames;let index = index">
                            <span *ngIf="index !==0">,</span>
                            {{'CsvDialogs.'+error|translate}}
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>

    <div class="w-100p f-row j-end a-end h-10p">
        <button mat-button (click)="close()" class="btn yellow">
            {{'cancel'| translate}}</button>
    </div>
</div>