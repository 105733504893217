import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirstAlphabetic'
})
export class CapitalizeFirstAlphabeticPipe implements PipeTransform {

  transform(input: string): string {
    let firstAlphaIndex = input.split('').findIndex(char => /[a-zA-Z]/.test(char));

    if (firstAlphaIndex === -1) return input;

    return input.slice(0, firstAlphaIndex) + input[firstAlphaIndex].toUpperCase() + input.slice(firstAlphaIndex + 1);
  }
}
