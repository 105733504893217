import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {
  EquipmentControlSidebarActionEnum
} from 'src/app/core/deprecated/deprecated-models/equipment-control-sidebar-actions.enum';
import {
  EquipmentControlSidebarService
} from 'src/app/core/deprecated/deprecated-services/deprecated-scada-toolbar/equipment-control-sidebar.service';
import {
  ControlsDashboardManagementService
} from "../../../../core/services/controls-dashboard/controls-dashboard-management.service";
import {SiteFloor} from "../../../../core/models/sites/site-floor";
import {SitesEquipmentsService} from "../../../../core/services/sites-equipments/sites-equipments.service";
import {
  SitesEquipmentsPaginationResult
} from "../../../../core/models/site-equipments/sites-equipments-pagination-result";
import {ControlsFiltrationModel} from "../../../../core/models/dashboard-controls/controls-filtration-model";
import {SiteEquipmentModel} from "../../../../core/models/site-equipments/site-equipment.model";
import {
  EquipmentConfiguration
} from "../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";
import {TranslatedToasterService} from "../../../../core/services/translated-toaster/translated-toaster.service";


@Component({
  selector: 'scada-sidebar',
  templateUrl: './scada-sidebar.component.html',
  styleUrls: ['./scada-sidebar.component.scss']
})
export class ScadaSidebarComponent implements OnDestroy {
  floor: SiteFloor = null;
  buildingEquipmentList: SiteEquipmentModel[] = [];
  selectedBuildingEquipment: SiteEquipmentModel[] = [];
  buildingEquipmentSubscription: Subscription = new Subscription();
  floorSubscription: Subscription = new Subscription();
  resetBuildingEquipmentSubscription: Subscription = new Subscription();
  selectedConfigs: number[] = [];

  constructor(
    private router: Router,
    private controlsDashboardManagementService: ControlsDashboardManagementService,
    private translatedToasterService: TranslatedToasterService,
    private sitesEquipmentsService: SitesEquipmentsService,
    private equipmentControlSidebarService: EquipmentControlSidebarService
  ) {
    this.floorSubscription = this.controlsDashboardManagementService.selectedFloorObs
      .subscribe((floor: SiteFloor) => {
        this.floor = floor;
        this.loadBuildingEquipment();
      });

    this.buildingEquipmentSubscription = this.equipmentControlSidebarService.buildingEquipmentAsObservable()
      .subscribe((selectedBuildingEquipment: SiteEquipmentModel[]) => {
        this.selectedBuildingEquipment = selectedBuildingEquipment;
        this.toggleSelected();
      });
  }

  /**
   * Set default configuration to take readings from.
   * @param event
   */
  selectedEquipmentConfiguration(event: any, equipmentIndex: number) {
    let buildingEquipment: SiteEquipmentModel = event.value.equipment;
    let selectedConfiguration: EquipmentConfiguration = event.value.configuration;
    this.selectedConfigs[equipmentIndex] = event.value.index;

    let configDataIdIndex: number;
    configDataIdIndex = buildingEquipment.equipmentControlParameter.defaultConfigs.findIndex((value: number) =>
      value == selectedConfiguration.dataId
    );

    if (configDataIdIndex !== -1) {
      buildingEquipment.equipmentControlParameter.defaultConfigs =
        buildingEquipment.equipmentControlParameter.defaultConfigs.filter((value: number) =>
          value !== selectedConfiguration.dataId
        );
    }

    buildingEquipment.equipmentControlParameter.defaultConfigs.splice(0, 0, selectedConfiguration.dataId);

    this.equipmentControlSidebarService.sendEquipmentControlAction({
      buildingEquipment: buildingEquipment,
      action: EquipmentControlSidebarActionEnum.SETDEFAULTCONFIG
    });
  }


  /**
   * Fetch the list of BuildingEquipment that are 'Schema',
   * based on BuildingId & floorIdex.
   */
  loadBuildingEquipment() {
    let filtrationModel: ControlsFiltrationModel = {
      floorId: this.floor?._id,
      categoryId: null,
      zone: null,
      siteId: this.floor?.siteId
    }
    this.sitesEquipmentsService.getEquipmentsControlsList(filtrationModel).subscribe((data: SitesEquipmentsPaginationResult) => {
      this.buildingEquipmentList = data.buildingEquipments;
    });
  }

  /**
   * Add EquipmentBuilding when selecting from the menu.
   */
  addBuildingEquipment(buildingEquipment: SiteEquipmentModel) {
    if (this.selectedBuildingEquipment.some((equipment) => equipment._id === buildingEquipment._id)) {
      this.translatedToasterService.showInfoMessage("INFO_CODES.EQUIPMENT_ALREADY_ADDED");
    } else {
      buildingEquipment.equipmentControlParameter = {
        id: buildingEquipment._id,
        xPos: 0,
        yPos: 0,
        defaultConfigs: [buildingEquipment?.equipmentControlParameter?.defaultConfigs[0]] || [buildingEquipment.equipmentControlParameter?.defaultConfigs?.[0]]
      };
      this.selectedBuildingEquipment.push(buildingEquipment);
      this.equipmentControlSidebarService.sendEquipmentControlAction({
        buildingEquipment: buildingEquipment,
        action: EquipmentControlSidebarActionEnum.ADD
      });
    }
  }


  /**
   * Remove buildingEquipment.
   * @param buildingEquipment
   */
  removeBuildingEquipment(buildingEquipment: SiteEquipmentModel) {
    this.selectedBuildingEquipment = this.selectedBuildingEquipment.filter(
      item => item['_id'] !== buildingEquipment['_id']
    );

    this.equipmentControlSidebarService.sendEquipmentControlAction({
      buildingEquipment: buildingEquipment,
      action: EquipmentControlSidebarActionEnum.DELETE
    });

  }

  /**
   * Toggle the selected Equipment Configuration in the accordion menu.
   */
  toggleSelected() {
    for (let i = 0; i < this.selectedBuildingEquipment.length; i++) {
      const index = this.selectedBuildingEquipment[i]?.equipmentConfigurations.findIndex((config: EquipmentConfiguration) => config?.dataId == this.selectedBuildingEquipment[i]?.equipmentControlParameter?.defaultConfigs[0]);
      this.selectedConfigs.push(index)
    }
  }

  /**
   * Save current EquipmentControl settings.
   */
  save() {
    this.equipmentControlSidebarService.sendSaveAction();
  }

  ngOnDestroy(): void {
    this.buildingEquipmentSubscription.unsubscribe();
    this.resetBuildingEquipmentSubscription.unsubscribe();
  }
}
