import {Injectable} from '@angular/core';
import {EquipmentConfiguration} from "../../models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";
import * as d3 from 'd3';
import {ThemeService} from "../theme/theme.service";
import {Theme} from "../../models/theme/theme.model";
import {HttpClient} from "@angular/common/http";
import {FileManagerService} from "../files/file-manager.service";
import {CreateEquipmentSvgParameters} from "../../view-models/d3-svg/create-equipment-svg-parameters";
import {RefreshSvgParameters} from "../../view-models/d3-svg/refresh-svg-parameters";
import {D3Label, D3LabelTypes} from "../../view-models/d3-svg/d3-label";
import {SiteEquipmentModel} from "../../models/site-equipments/site-equipment.model";
import {Subject} from "rxjs";
import {AccessRightEnum} from "../../models/site-equipments/equipment/equipment-configuration/device-access-right.enum";

@Injectable({providedIn: 'root'})
export class EquipmentD3Service {
  theme: Theme;
  selectConfigurationSubject = new Subject<EquipmentConfiguration>();

  constructor(private themeService: ThemeService,
              private httpClient: HttpClient,
              private fileUploadService: FileManagerService,
  ) {
    this.theme = this.themeService.getActiveTheme();
  }

  createScadaConfigurationsSvg(parameters: CreateEquipmentSvgParameters) {
    d3.selectAll(`#svgCont`).remove();
    this.fileUploadService.getPrivateDocumentPresign(parameters.imageId).subscribe((url: string) => {
      url && (parameters.imagPath = url);
      this.getImageAndConstructSvg(parameters)
    }, error => {
      this.getImageAndConstructSvg(parameters)
    })
  }

  private getImageAndConstructSvg(parameters: CreateEquipmentSvgParameters) {
    this.httpClient.get(parameters.imagPath, {responseType: 'text'}).subscribe((image) => {
      d3.select(`#${parameters.svgWrapperId}`)
        .html(image);

      let wrapper = document.getElementById(`${parameters.svgWrapperId}`)
      d3.select(`#${parameters.svgWrapperId}`)
        .selectAll('svg')
        .attr('id', 'svgCont')
        .attr('height', wrapper.offsetHeight)
        .attr('width', wrapper.offsetWidth - 100);

      parameters.labelsItem.forEach((label: D3LabelTypes) => {
        if (label['added'] && label['accessRight'] == AccessRightEnum.AccessRightEnum.R) {
          this.placeConfigurationLabel(parameters, label);
        }
      });
      parameters.loadingSubject.next(false);
    }, error => parameters.loadingSubject.next(false))
  }

  private placeConfigurationLabel(parameters: CreateEquipmentSvgParameters, label: D3Label) {
    let svg = d3.select(`#${parameters.svgWrapperId}`).select('svg');

    let uniqueId = Math.floor(Math.random() * (999 - 100 + 1) + 100).toString();

    let labelContainer = svg
      .append('g')
      .attr('class', parameters.labelsUniqueGroupId)
      .attr('id', (label._id) ? (label._id) : (label.name.replace(/\s+/g, '_') + uniqueId))
      .attr('style', 'cursor: pointer')
      .attr('fill', this.theme.properties['--background-primary']);


    if (label.xPos && label.yPos) {
      labelContainer
        .attr(
          'transform',
          'translate(' +
          label.xPos +
          ',' +
          label.yPos +
          ')'
        )
    }


    var defs = svg.append("defs");

    var filter = defs.append("filter")
      .attr("id", "dropshadow")
      .attr("filterUnits", "userSpaceOnUse")
      .attr("color-interpolation", "sRGB")

    filter.append("feComponentTransfer")
      .attr("in", "SourceAlpha")

    filter.append("feGaussianBlur")
      .attr("stdDeviation", 2)

    filter.append("feOffset")
      .attr("dx", 0)
      .attr("dy", 0)
      .attr("result", "shadow")

    filter.append("feComposite")
      .attr("in", "SourceGraphic")
      .attr("in2", "shadow")
      .attr("operator", "over")


    // Add label rectangle.
    labelContainer
      .append('rect')
      .attr('width', 110)
      .attr('height', 28)
      .attr('dx', label.xPos?.toString())
      .attr('dy', label.yPos?.toString())
      .style('fill', '#FFF')
      .attr('filter', "url(#dropshadow)");

    // Add configuration's name inside label.
    labelContainer
      .append('text')
      .attr('class', 'rectangleTextDialog')
      .attr('fill', 'black')
      .attr('dx', '2')
      .attr('dy', '18')
      .attr('style', 'font-size: 0.8em; font-weight: 700')
      .text(label[parameters.defaultConfigProperty]);
      let self = this;
      labelContainer.on('click', function () {
        self.selectConfigurationSubject.next(label as EquipmentConfiguration);
      });
    let labelTag = labelContainer
      .append('g')
      .attr('class', `${parameters.labelsUniqueGroupId}Tag`)
      .attr('id', label._id)
      .attr('style', 'cursor: pointer')
      .attr('fill', this.theme.properties['--background-primary'])
      .attr(
        'transform',
        'translate(' +
        '0' +
        ',' +
        '-20' +
        ')'
      )
    labelTag
      .append('rect')
      .attr('width', 110)
      .attr('height', 20)
      .style('fill', this.theme.properties['--background-primary']);
    let labelName = this.truncateString(label.name);

    labelTag
      .append('text')
      .attr('class', 'rectangleTextDialog')
      .attr('fill', this.theme.properties['--label-font-color'])
      .attr('dy', 14)
      .attr('dx', 2)
      .attr('style', 'font-size: 0.8em; font-weight: 700;')
      .text(labelName);

    labelTag
      .append('title')
      .text(label.name)

    if (!parameters.draggable) return

    if (label._id) {
      // Enable dragging for labels.
      d3.select(`#${parameters.svgWrapperId}`)
        .selectAll(`.${parameters.labelsUniqueGroupId}` + `#${CSS.escape(label._id)}`)
        .call(d3.drag().on('drag', this.dragMove(label)));
    } else {
      // Enable dragging for labels.
      d3.select(`#${parameters.svgWrapperId}`)
        .selectAll(`.${parameters.labelsUniqueGroupId}` + `#${label.name.replace(/\s+/g, '_') + uniqueId}`)
        .call(d3.drag().on('drag', this.dragMove(label)));
    }
  }

  private dragMove(label: D3Label) {
    return function (dragEvent) {
      let x = dragEvent.x - 45;
      let y = dragEvent.y - 20;

      label.xPos = x;
      label.yPos = y;

      d3.select(this).attr('transform', 'translate(' + x + ',' + y + ')');
    };
  }

  updateLabelDragging(drag: boolean, parameters: CreateEquipmentSvgParameters) {
    parameters.labelsItem.forEach((label) => {
      d3.select(`#${parameters.svgWrapperId}`)
        .selectAll(`.${parameters.labelsUniqueGroupId}` + `#${CSS.escape(label._id)}`)
        .attr('style', `cursor:${drag ? 'pointer' : 'text'}`)
        .call(d3.drag().on('drag', drag ? this.dragMove(label) : null));
    });
  }

  refreshD3Labels(refreshSvgParameters: RefreshSvgParameters) {
    refreshSvgParameters.equipmentConfigurations.forEach((config: EquipmentConfiguration) => {
      let value;
      if (refreshSvgParameters.plotData[config._id] && refreshSvgParameters.plotData[config._id].length > 0) {
        value = refreshSvgParameters.plotData[config._id][refreshSvgParameters.plotData[config._id].length - 1].value;
        let singularPlotData = refreshSvgParameters.plotData[config._id];
        let beforeItems = singularPlotData.filter(function (item) {
          return item.time - refreshSvgParameters.currentTime.getTime() < 0;
        });
        if (beforeItems !== null && beforeItems.length > 0) {
          value = beforeItems[beforeItems.length - 1].value;
        }
        if (!isNaN(value - parseFloat(value))) {
          value = Math.round(value * 100) / 100;
        }
        let truncatedValue = this.truncateValue((value + ' ' + config.unit).toString());
        let configLabel = d3.select(`.${refreshSvgParameters.labelsUniqueGroupId}` + `#${CSS.escape(config._id)}`)
        configLabel.select('.rectangleTextDialog').text(truncatedValue)
          .attr('dy', 18)
          .attr('dx', 2)
          .attr('style', 'cursor: pointer; font-size: 0.8em; font-weight: 700')
          .append('title').text(value + ' ' + config.unit);
      } else {
        let configLabel = d3.select(`.${refreshSvgParameters.labelsUniqueGroupId}` + `#${CSS.escape(config._id)}`)
        configLabel.select('.rectangleTextDialog').text('—').attr('dy', 20).attr('dx', 50)
      }
      refreshSvgParameters.changeDetectorRef.markForCheck();
    });
  }

  /**
   * Truncates configuration's name if it's longer than (num).
   * @param str
   * @param num
   * @returns str
   */
  private truncateString(str) {
    if (str.length > 13) {
      return str.slice(0, 13) + '...';
    } else {
      return str;
    }
  }

  private truncateValue(str: string) {
    if (str.length > 17) {
      return str.slice(0, 12) + '...';
    } else {
      return str;
    }
  }

  createLabelsListFromEquipmentConfigurations(configs: EquipmentConfiguration[]): D3LabelTypes[] {
    return configs.map((config) => {
      return {
        _id: config._id,
        yPos: config.yPos,
        xPos: config.xPos,
        name: config.name,
        unit: config.unit,
        accessRight: config.accessRight,
        added: config.added,
      }
    })
  }

  createLabelsListFromEquipmentParameters(configs: SiteEquipmentModel[]): D3LabelTypes[] {
    return configs.map((equipment) => {
      return {
        _id: equipment.equipmentControlParameter.id,
        yPos: equipment.equipmentControlParameter.yPos,
        xPos: equipment.equipmentControlParameter.xPos,
        name: equipment.nomenClature,
        defaultConfigs: equipment.equipmentControlParameter.defaultConfigs
      }
    })
  }
}
