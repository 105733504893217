import {Directive, ViewContainerRef} from "@angular/core";

@Directive({
  selector: '[injectHost]',
})
/**
 * directive representing an anchor point to tell Angular where to insert components.
 * resource :https://angular.io/guide/dynamic-component-loader
 */
export class SGInjectCellDirective{
  constructor(public viewContainerRef: ViewContainerRef) { }
}
