import {Injectable, OnDestroy} from "@angular/core";
import {DashboardSiteDto, SiteDto} from "../../models/sites/siteDTO";
import {SiteView} from "../../models/sites/site-view";
import * as _ from "underscore";

@Injectable({providedIn: 'root'})
export class SiteStoreService implements OnDestroy {
  private dashboardIndexedSits: { [siteId: string]: DashboardSiteDto } = {};


  constructor() {
  }

  storeClientsSites(sitesList: SiteDto[]) {
    let dashboardSiteList: DashboardSiteDto[] = sitesList.map(ele => {
      return {...ele, views: []}
    })
    this.dashboardIndexedSits = _.indexBy(dashboardSiteList, '_id');
  }

  getStoredSites(filter: string): DashboardSiteDto[] {
    let sitesList = Object.values(this.dashboardIndexedSits);
    if (!filter || filter.length == 0) return JSON.parse(JSON.stringify(sitesList))
    return sitesList.filter(ele => ele.name.toLowerCase().includes(filter.toLowerCase()))
  }

  getStoredSite(siteId: string): DashboardSiteDto {
    if (!siteId || !this.dashboardIndexedSits[siteId]) return null
    return JSON.parse(JSON.stringify(this.dashboardIndexedSits[siteId]))
  }

  getSiteViews(siteId: string): SiteView[] {
    return JSON.parse(JSON.stringify(this.dashboardIndexedSits[siteId].views))
  }

  updateStoredSites(sites: SiteDto[]) {
    sites.forEach(site => {
      let copiedSite = JSON.parse(JSON.stringify(site))
      if (this.dashboardIndexedSits[site._id]) {
        let currentRecordViews = this.dashboardIndexedSits[site._id].views
        this.dashboardIndexedSits[site._id] = {...copiedSite, views: currentRecordViews}
        return
      }
      this.dashboardIndexedSits[site._id] = {...copiedSite, views: null}
    })
  }

  updateStoredSiteViews(siteId: string, viewsList: SiteView[]) {
    if (!this.dashboardIndexedSits[siteId]) return
    this.dashboardIndexedSits[siteId].views = JSON.parse(JSON.stringify(viewsList))
  }

  updateStoredView(view: SiteView) {
    if (!this.dashboardIndexedSits[view.siteId]) return
    let index = this.dashboardIndexedSits[view.siteId].views.findIndex(ele => ele._id == view._id);
    let copiedView = JSON.parse(JSON.stringify(view))
    index == -1 ? this.dashboardIndexedSits[view.siteId].views.push(copiedView) : this.dashboardIndexedSits[view.siteId].views[index] = copiedView;
  }


  sitesExists(): boolean {
    let list = Object.values(this.dashboardIndexedSits)
    return list && list.length > 0
  }

  sitesViewsExists(siteId: string): boolean {
    let list = this.dashboardIndexedSits[siteId]?.views
    return !!list && list?.length > 0
  }

  siteExists(siteId: string): boolean {
    return !!this.dashboardIndexedSits[siteId]
  }

  resetStore() {
    this.dashboardIndexedSits = {};

  }

  ngOnDestroy(): void {
    this.resetStore();
  }
}
