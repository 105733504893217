<div class="display-value-plugin-container">
  <section>
    <mat-icon>speed</mat-icon>
    <p>{{ 'GENERAL.VALUE' | translate | capitalize}}</p>
  </section>
  <section>
    <p>{{ configuration.name }}</p>
    <div class="display-value-content">
      <div>{{ message | translate }}</div>
    </div>
  </section>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
