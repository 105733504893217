import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate} from "@angular/router";
import {AuthenticationsService} from "../services/auth/authenticationsService";
import {AuthorizationSpace} from "../models/app/authorizationNameSpace";
import {switchMap} from "rxjs/operators";
import {of} from "rxjs";
import PagesTypes = AuthorizationSpace.PagesTypes;

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthenticationsService,) {
  }


  canActivate(route: ActivatedRouteSnapshot) {
    if (!this.authService.isAuthenticated()) {
      this.authService.signOut();
      return false;
    }
    return this.authService.loggedInUserAuthedPagesObs.pipe(switchMap((allowedPages: PagesTypes[]) => {
      let authorized = allowedPages.includes(route.data['title'])
      if (!authorized) this.authService.signOut();
      return of(authorized)
    }))
  }

}
