import {Injectable} from "@angular/core";
import {HttpParams} from "@angular/common/http";
import {DocumentExtension} from "../../models/document/document-extensions";
import {InterventionsSpace} from "../../models/interventions/interventions.namespace";
import {SvgAttribute} from "../../view-models/d3-svg/svg-attribute";

@Injectable({providedIn: 'root'})
export class AppService {

  constructor() {
  }

  getDomainName() {
    return window.location.hostname;
  }

  createHttpPramsFormObject(object, nullable?: boolean): HttpParams {
    let httpParams = new HttpParams();
    for (let key of Object.keys(object)) {
      if (!nullable && object[key] == null) continue
      httpParams = httpParams.append(key, object[key].toString())
    }
    return httpParams
  }

  getFileIconImage(path) {
    const defaultIcon = '/assets/icons/file.png';
    const ext = path.slice(Array.from(path).lastIndexOf('.') + 1, path.length);
    const key: string = Object.keys(DocumentExtension).filter(key => DocumentExtension[key].includes(ext))[0];
    return key ? "/assets/icons/" + key + '.png' : defaultIcon;
  }

  getKeyByValue(enumObj: any, value: string): string | undefined {
    return Object.keys(enumObj).find(key => enumObj[key] === value);
  }

  getEnumValues<T extends object>(enumObj: T) {
    return Object.values(enumObj).filter(ele => !isNaN(+ele)).map(ele => Number(ele));
  }

  truncateString(value: string, length?: number) {
    if (!value) return '';
    return value.length <= length ? value : value.slice(0, length - 3) + '...';
  }

  getAdjustedDate(dateStr: string, invert: boolean = false): Date | undefined {
    const date: Date = new Date(dateStr);
    const timeOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + (invert ? timeOffset * -1 : timeOffset));
  }

  setDayHour(stringDate: string, isStart: boolean): Date {
    let date: Date = new Date(stringDate);
    if (!date) return;
    isStart ? date.setHours(0, 0, 0, 0) : date.setHours(23, 59, 59, 59);
    return date;
  }

  /**
   * Search on the first Alphabetic in the text and capitalize it
   * @param input
   */
  capitalizeFirstAlphabetic(input: string): string {
    let firstAlphaIndex = input.split('').findIndex(char => /[a-zA-ZÀ-ÖØ-öø-ÿ]/.test(char));

    if (firstAlphaIndex == -1) return input;

    return input.slice(0, firstAlphaIndex) + input[firstAlphaIndex].toUpperCase() + input.slice(firstAlphaIndex + 1);
  }

  /**
   * Parse SVG string attribute
   * @param svg
   */
  parseSvgAttributes(svg: string): SvgAttribute | null {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svg, 'image/svg+xml');
    const svgElement = doc.querySelector('svg');

    if (svgElement) {
      let viewBox = svgElement.getAttribute('viewBox');
      let width = svgElement.getAttribute('width')?.replace(/\D/g, '') ?? '1000';
      let height = svgElement.getAttribute('height')?.replace(/\D/g, '') ?? '700';
      return {viewBox, width, height};
    }
    return null;
  }
}
