<div class="map-container">
  <span class="center-full">
        <sg-map class="center-full" (selected)="changeAddress($event)" [settings]="mapSettings" [coordinatesObservable]="_mapCoordinates"></sg-map>
    </span>

  <div class="action-section">
    <button (click)="selectAddress()">{{'FORMS.SELECT' | translate | capitalize}}</button>
    <button (click)="dialogRef.close(null)">{{'FORMS.CANCEL' | translate | capitalize}}</button>
  </div>
</div>
