import { Component, HostBinding, Input } from '@angular/core';
import { PluginControl } from '../plugin-control.abstract';
import {
  EquipmentConfiguration
} from '../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model';
import {
  ControlsDashboardManagementService
} from '../../../../../core/services/controls-dashboard/controls-dashboard-management.service';

@Component({
  selector: 'gmao-sla-value-plugin',
  templateUrl: './sla-value-plugin.component.html',
  styleUrls: ['./sla-value-plugin.component.scss']
})
export class SlaValuePluginComponent extends PluginControl {

  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px bgr-red';
  @Input('configuration') configuration: EquipmentConfiguration;

  displayValue: string = '-';
  status: string = '';

  isLoading: boolean = true;

  constructor(
    private controlsDashboardManagementService: ControlsDashboardManagementService
  ) {
    super();
  }

  setValue(value: number) {
    this.setDisplayValue(value);
  }

  getValue(value: number) {
  }

  /**
   * Set the display value and set iisInRange to be displayed in html
   * The latestValue is the latest value before the selected date
   */
  setDisplayValue(value: number) {
    const data = this.controlsDashboardManagementService.getManualDataItem(this.configuration, value);
    this.status = this.controlsDashboardManagementService.isManualDataInRange(this.configuration, data.value) ? 'connected' : 'disconnected';
    this.displayValue = data.value ? data.value + ' ' + this.configuration.unit : '-';
    this.isLoading = false;
  }

}
