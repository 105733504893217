import {Component, Input, OnInit} from "@angular/core";
import {CityWeather} from "src/app/core/models/weather/city-weather.model";
import {
  HomeRectangleSkeletonComponent
} from "../../../components/loading-skeleton/home/home-rectangle-skeleton/home-rectangle-skeleton.component";

@Component({
  selector: 'app-weather-chip',
  templateUrl: './weather-chip.component.html',
  styleUrls: ['./weather-chip.component.scss']
})
export class WeatherChipComponent implements OnInit {
  @Input('weather') weather: CityWeather;
  weatherIcon: string = '';
  hoganLogo: string = 'assets/clients-logos/hoganlovells/hoganlovells_logo.svg';
  ngOnInit(): void {
    this.processWeather();
  }

  private processWeather() {
    this.weatherIcon = 'https://openweathermap.org/img/wn/' + this.weather?.weather[0]?.icon + '@2x.png';
  }
}
