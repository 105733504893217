import {NgModule} from '@angular/core';

import {LocationInputComponent} from './location-input.component';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PipesModule} from "../../../pipes/pipes.module";
import {MatIconModule} from "@angular/material/icon";
import {SitesManagementService} from "../../../services/sites/sites-management.service";


@NgModule({

  imports: [
    PipesModule,
    CommonModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatIconModule
  ],
  declarations: [
    LocationInputComponent
  ],
  exports: [
    LocationInputComponent
  ],
  providers: [SitesManagementService]

})
export class LocationInputModule {
}
