import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PluginControl } from "../plugin-control.abstract";
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

@Component({
  selector: 'power-control-plugin',
  templateUrl: './power-control-plugin.component.html',
  styleUrls: ['./power-control-plugin.component.scss']
})
export class  PowerControlPluginComponent extends PluginControl implements OnInit, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;
  @Input('templateMode') templateMode: boolean = false;

  value: number;

  subject: Subject<number> = new Subject();
  subscription: Subscription = new Subscription();

  mode: number;
  status: boolean = false;

  on: number;
  off: number;

  subscriptions: Subscription = new Subscription();
  data: [];
  plotData: any[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {

    super();

    this.subscription.add(
      this.subject.pipe(debounceTime(1000)).subscribe(() => {
        this.setMode();
      })
    );
  }

  ngOnInit(): void {
    this.on = this.configuration?.additionalInfo?.['on'];
    this.off = this.configuration?.additionalInfo?.['off'];

    if (this.templateMode) {
      this.setValue(this.configuration?.setValue);
    }

    this.setInitValue && this.setMode(true);
  }

  setValue(value: number) {
    this.value = value;
    this.mode = value;
    (this.mode == this.on) ? (this.status = true) : (this.status = false);
    this.changeDetectorRef.markForCheck();
  }

  getValue(value: number) {
    return this.value;
  }


  switchMode() {
    this.subject.next();
  }

  setMode(isDefault?: boolean) {
    (isDefault || this.mode == this.on) ? (this.mode = this.off) : (this.mode = this.on);
    this.generalSetMode(this.mode);
    this.status = (this.mode === this.on);
  }


  generalSetMode(mode: number) {
    this.configuration.setValue = mode;
    this.outputValue.next(mode);
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

