import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ReceptionWindowComponent } from "./reception-window.component";


const routes: Routes = [
  {
    path: '',
    component: ReceptionWindowComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ReceptionWindowRoutingModule {}
