<div class="equipment-scada-container">
  <div class="time-line-wrapper">
    <app-dashboard-timeline class="w-100p" [classes]="'h-100p minh-100p'"></app-dashboard-timeline>
  </div>
  <div class="field-wrapper">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'equipmentScada.selectBuildingEquipment' | translate }}</mat-label>
      <mat-spinner *ngIf="pageIsLoadingSubject | async" diameter="25" class="internal-spinner"></mat-spinner>
      <mat-select [formControl]="buildingEquipmentControl">
        <mat-option (click)="selectBuildingEquipment(buildingEquipment._id)" *ngFor="let buildingEquipment of buildingEquipmentArray" [value]="buildingEquipment._id">
          {{ buildingEquipment.nomenClature}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <div class="scada-content sg-bgr-primary">
    <div class="equipment-image">
      <div id="scadaViewSvg" class="svg-wrapper"></div>
      <p *ngIf="!selectedBuildingEquipment" class="txt-bold">{{ 'equipmentScada.noEquipmentSelected' | translate }}</p>
      <p *ngIf="!selectedBuildingEquipment?.equipment?.scadaPhoto[0]?.path && selectedBuildingEquipment" class="txt-bold">{{ 'equipmentScada.noEquipmentImage' | translate }}</p>
      <mat-spinner *ngIf="svgLoadingSubject | async" class="centered-element" diameter="80"></mat-spinner>
    </div>
    <div class="controls  sg-bgr-primary">
      <config-control-cell
        *ngFor="let configuration of equipmentConfigurations"
        [controlToAdd]="configuration"
        [withPoller]="true">
      </config-control-cell>
    </div>
  </div>


</div>
