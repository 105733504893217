<div class="weather-window-container">
  <section>
    <gmao-actual-weather-temperature [currentWeather]="currentWeather"
                                     [weatherIcon]="weatherIcon"
                                     [forecastReadings]="forecastReadings"
    ></gmao-actual-weather-temperature>
    <div class="city-temperatures-container" *ngIf="!isHomePage">
      <ng-container *skeleton="isWeatherInfoLoading;repeat: 12; component:loadingSkeletonComponent;width: '32%';height:'80px'">
        <app-weather-chip *ngFor="let weather of weatherArray" [weather]="weather"></app-weather-chip>
      </ng-container>
    </div>
  </section>
  <section *ngIf="!isHomePage">
    <app-metro-card *ngFor="let stationStop of stationStops"
                    [stationStop]="stationStop"
                    [stopInfo]="stopInfo"
    ></app-metro-card>
  </section>
</div>
