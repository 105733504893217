import { Component } from '@angular/core';
import { ControlParentPluginComponent } from '../control-parent-plugin/control-parent-plugin.component';
import { TimelineService } from '../../dashboards/dashboard-timeline/timeline.service';
import { TesseractService } from '../../../core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ControlsDashboardManagementService
} from '../../../core/services/controls-dashboard/controls-dashboard-management.service';

@Component({
  selector: 'gmao-control-sla-plugin',
  templateUrl: './control-sla-plugin.component.html',
  styleUrls: ['./control-sla-plugin.component.scss']
})
export class ControlSlaPluginComponent extends ControlParentPluginComponent {

  displayValue: string = '-';
  status: string = '';

  constructor(
    protected timelineService: TimelineService,
    protected tesseractsService: TesseractService,
    public translateService: TranslateService,
    private controlsDashboardManagementService: ControlsDashboardManagementService
  ) {
    super(timelineService, tesseractsService);
    super.isManual = true;
  }

  /**
   * Set the display value and set isInRange to be displayed in html
   * The latestValue is the latest value before the selected date
   */
  setDisplayValue() {
    const data = this.controlsDashboardManagementService.getManualDataItem(this.defaultConfiguration, this.currentTime.getTime());
    this.status = this.controlsDashboardManagementService.isManualDataInRange(this.defaultConfiguration, data.value) ? 'connected' : 'disconnected';
    this.displayValue = data.value ? data.value + ' ' + this.defaultConfiguration.unit : '-';
  }
}
