import {Component, EventEmitter, Input, Output} from '@angular/core';
import { NodePoint } from '../../../../../core/models/nodes/nodePoint';

@Component({
  selector: 'gmao-nodes-list-dialog-chips',
  templateUrl: './nodes-list-dialog-chips.component.html',
  styleUrls: ['./nodes-list-dialog-chips.component.scss']
})
export class NodesListDialogChipsComponent {
  @Input('selectedNodes') selectedNodes: NodePoint[] = [];
  /**
   * splice the selected node from the selected nodes list
   * @see NodesListDialogComponent.nodeDeleted
   */
  @Output('nodeDeleted') nodeDeleted = new EventEmitter<NodePoint>();

  /**
   * unselect node by using mat-chip
   * @param node
   */
  onNodeRemoved(node: NodePoint) {
    this.nodeDeleted.emit(node);
  }

}
