<div class="navigation-skeleton">
  <div class="loading-skeleton"></div>
  <div class="loading-skeleton"></div>
  <div class="loading-skeleton"></div>
  <div class="loading-skeleton"></div>
  <div class="loading-skeleton"></div>
  <div class="loading-skeleton"></div>
  <div class="loading-skeleton"></div>
  <div class="loading-skeleton"></div>
</div>
