import {Injectable, OnDestroy} from '@angular/core';
import {NodePoint} from "../../models/nodes/nodePoint";
import {Alarm} from "../../models/alarms/alarm";
import {AlarmTypeEnum} from "../../models/alarms/alarm-type";
import {Subscription} from "rxjs";
import {AlarmSlaConfig} from "../../view-models/AlarmSlaConfig";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {Scheduling} from '../../models/schdulaing/schdulaing.namespace';
import {TimeRangeValidator} from "../../custom-validators/time-range-validators";
import {MinMaxValidator} from "../../custom-validators/min-max-validators";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class AlarmsManagementService implements OnDestroy {
  subscriptionsList: Subscription[] = [];

  constructor(private translateService: TranslateService) {
  }

  /**
   * This function to create alarms from selected nodes
   * @param nodes
   */
  createAlarms(nodes: NodePoint[]): Alarm[] {
    return nodes.map((node: NodePoint) => {
      return {
        description: node?.labels?.slice(0, 1)[0],
        type: AlarmTypeEnum.ERROR,
        active: false,
        activeValue: "1",
        name: node.name,
        nodeId: node.nodeId,
        dataId: node.dataId,
        parent: node.parent,
        parentName: node.parentName,
        treeName: node?.resourcePath?.split('/').filter(ele => ele.length != 0).slice(-3)?.join(' > '),
        currentStatus: "new",
        _id: (-Math.floor(Math.random() * 10000) + 1).toString(),
        groups: [],
        siteId: "",
        slaData: null
      }
    });
  }

  /**
   * This function to set new alarms id value that wasn't saved in the BE to null
   * Note: the new alarms have value < 0 to be able to delete from the table and when
   *       save the alarms the BE give the alarm new id.
   * @param alarms
   */

  removeNewAlarms(alarms: Alarm[]): Alarm[] {
    return alarms.map((alarm: Alarm) => {
      alarm._id < '0' && (alarm._id = null);
      return alarm;
    })
  }

  createSlaForm(daysFormArray: boolean, sla?: AlarmSlaConfig): FormGroup {
    let slaForm = new FormGroup({
      minimumValue: new FormControl(sla?.minimumValue, [Validators.required, Validators.minLength(3)]),
      maximumValue: new FormControl(sla?.maximumValue, [Validators.required, Validators.minLength(3)]),
      startTime: new FormControl(sla?.startTime, Validators.required),
      endTime: new FormControl(sla?.endTime, Validators.required),
      days: daysFormArray ?  this.createDaysControls(sla?.days ?? []) : new FormControl(sla?.days ?? [])
    });

    slaForm.controls['endTime'].setValidators([TimeRangeValidator(slaForm.controls['startTime']), Validators.required]);
    slaForm.controls['maximumValue'].setValidators([MinMaxValidator(slaForm.controls['minimumValue']), Validators.required]);
    let timeValidatorSubscription = slaForm.get('startTime').valueChanges.subscribe(() => {
      slaForm.get('endTime').updateValueAndValidity();
    });
    this.subscriptionsList.push(timeValidatorSubscription);
    return slaForm
  }

  /**
   * since the days are check boxes , each check box needs its own control, the group of checkboxes will
   * be presented as form array
   * @private
   */
  private createDaysControls(selectedDays: string[]) {
    const controls = Object.keys(Scheduling.DaysOfWeekEnum).map((day) => new FormControl(selectedDays.includes(day)));
    return new FormArray(controls);
  }

  createSideBarOption(monthlyActiveAlarms: number[]){
    let translatedLabel = this.translateService.instant('ALARMS.ALARMS');
    let translatedAlarmsPerMonth = this.translateService.instant('ALARMS.ALARMS_PER_MONTH');
    return {
      chart: {type: 'column'},
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      exporting: {enabled: false},
      title: {
        text: translatedAlarmsPerMonth,
        style: {
          fontSize: '18px',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
        crosshair: true,
        labels: {
          format: '{value}', style: {color: 'black', fontSize: '14px', fontWeight: 'bold'}
        }
      },
      yAxis: {
        min: 0, title: {text: ''}
      },

      credits: {
        enabled: false
      },
      series: [
        {
          name: translatedLabel, showInLegend: true, color: '#fae000', data: monthlyActiveAlarms
        },
      ]
    };
  }

  ngOnDestroy(): void {
    this.subscriptionsList.forEach(ele => ele.unsubscribe())
  }
}
