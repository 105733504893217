import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PieConsumptionSourcesWidgetComponent } from './pie-consumption-sources-widget.component';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [PieConsumptionSourcesWidgetComponent],
  exports: [PieConsumptionSourcesWidgetComponent],
  imports: [
    CommonModule,
    TranslateModule,
    HighchartsChartModule

  ],
  providers: []
})
export class PieConsumptionSourcesWidgetModule {
}
