import { TimelineService } from 'src/app/components/dashboards/dashboard-timeline/timeline.service';
import { Subscription, BehaviorSubject, timer } from 'rxjs';
import { concatMap, debounceTime, map, takeWhile } from 'rxjs/operators';
import {Inject} from "@angular/core";
/**
 * This class sets the Timeline information for the components involved.
 * This class determines the interval from which to fetch
 * the data. In other words, the START and END times of
 * said interval.
 * It receives data, realtime etc... from the TimelineComponent.
 * PAY ATTENTION: This is !NOT! the actual Timeline component.
 */
export abstract class ItemTimelineManager {
  /**
   * The date used for the items : either now for realtime or the selected date
   * */
  protected date: Date = new Date();
  /**
   *
   * */
  protected endDate: Date = new Date();
  /**
   * This time is only used to set the hours and minutes of the actual date
   * */
  protected currentTime: Date = new Date();
  /**
   * Items show realtime data or not
   * */
  protected realTime = true;
  /**
   * A behaviorSubject that notifies the pollers to continue polling or not. It's also used to unsubscribe from the polling timer
   * */
  protected historicDataSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  /**
   *Subscription to all the timeline module observable
   * */
  protected timelineSubscription: Subscription = new Subscription();
  /**
   * Date selected in the date picker.
   * */

  protected selectedDate: Date = new Date();
  protected selectedEndDate: Date = new Date();
  protected reportingTimeline: boolean = false;
  protected loadDataEvent : BehaviorSubject<Boolean> = new BehaviorSubject(false);

  constructor(@Inject(TimelineService) protected timelineService: TimelineService) {
    this.selectedDate.setHours(0, 0, 0, 0);
    this.timelineSubscription.add(this.loadDataEvent.pipe(debounceTime(1000)).subscribe(()=> this.loadData()));
    this.timelineSubscription.add(
      this.timelineService.getDateObservable().subscribe(date => {
        if (date != null) {
          this.manageTimelineDateChange(date);
        } else {
          this.loadDataEvent.next(true);
        }
      })
    );
    this.timelineSubscription.add(
      this.timelineService.getTimeObservable().subscribe(date => {
        if (date != null) {
          this.manageTimelineTimeChange(date);
        }
      })
    );
    this.timelineSubscription.add(
      this.timelineService.getRealTimeObservable().subscribe(realTime => {
        if (realTime != null) {
          this.manageTimelineRealTimeChange(realTime);
        }
      })
    );
    this.timelineSubscription.add(
      this.timelineService.getEndDateObservable().subscribe(endDate => {
        if (endDate != null) {
          this.manageTimelineEndDateChange(endDate);
        }
      })
    );
    this.timelineSubscription.add(
      this.timelineService
        .getReportingTimeObservable()
        .subscribe(reportingTimeline => {
          if (reportingTimeline != null) {
            this.reportingTimeline = reportingTimeline;
          }
        })
    );
    this.currentTime.setHours(0, 0, 0, 0);
    this.date = new Date();
    this.date.setHours(0, 0, 0, 0);
  }

  /**
   * Manage date change
   * @param date
   */
  manageTimelineDateChange(date: Date) {
    this.date = date;
    let oldTime = new Date(this.currentTime);
    this.currentTime = new Date(date);
    this.currentTime.setHours(oldTime.getHours(), oldTime.getMinutes());
    this.selectedDate = date;
    this.loadDataEvent.next(true);
  }

  manageTimelineEndDateChange(date: Date) {
    this.endDate = date;
    this.selectedEndDate = date;
    this.loadDataEvent.next(true);
  }

  /**
   * Manage if the data is historic or real time
   * @param realTime
   */
  manageTimelineRealTimeChange(realTime: boolean) {
    this.realTime = realTime;
    if (this.realTime) {
      this.date = new Date();
      this.date.setHours(0, 0, 0, 0);
      this.historicDataSubject.next(false);
    } else {
      this.historicDataSubject.next(true);
      this.date = this.selectedDate;
    }

    this.loadDataEvent.next(true);
  }

  /**
   * Manage timeline slider change. The return time contains also the selected date.
   * As soon as there's new data, it gets set as CurrentTime data.
   * @param time
   */
  manageTimelineTimeChange(time: Date) {
    this.currentTime = time;
    this.refreshItem();
  }

  protected addNewPoller(
    dataLoader: Function,
    dataTransformer: Function,
    isDataComplete: Function
  ) {
    return timer(0, 30000).pipe(
      concatMap(_ => {
        return this.dataLoadFunction(dataLoader);
      }),
      map((data: any[]) => {
        return dataTransformer(data);
      }),
      takeWhile(() => {
        if (this.historicDataSubject.value) {
          return !isDataComplete();
        }
        return !this.historicDataSubject.value;
      })
    );
  }

  /**
   * This method is responsible for calling the (dataLoader)
   * method. It initializes startDate & endDate based on
   * user selection (if it's Realtime or not).
   * @param dataLoader
   * @returns dataLoader()
   */
  protected dataLoadFunction(dataLoader: Function) {
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date();
    if (!this.realTime) {
      startDate = this.date;
      endDate = new Date(this.date);
      endDate.setHours(23, 59, 59, 59);
    }
    if (this.reportingTimeline) {
      startDate = this.date;
      console.log("this.date", this.date)
      startDate.setHours(0, 0, 0, 0);

      endDate = this.endDate;

      endDate.setHours(23, 59, 59, 59);
    }

    return dataLoader(startDate, endDate);
  }

  /**
   *This method must load data and load the item content
   * */
  protected abstract loadData();

  /**
   *This method refreshes the item content according to the timeline change
   * */
  protected abstract refreshItem();

  protected unsubscribeTimeline() {
    this.timelineSubscription.unsubscribe();
    this.historicDataSubject.next(true);
    this.historicDataSubject.unsubscribe();
  }
}
