import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {EquipmentControlDashboardComponent} from "./equipment-control-dashboard.component";
import {ViewAuthGuard} from "../../../core/gaurds/view-auth-guard.service";

const routes: Routes = [
  {
    path: ':siteId/equipment-control/:name',
    component: EquipmentControlDashboardComponent,
    data: {featureType: 'VIEW',icon: 'map'},
    canActivate: [ViewAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EquipmentControlDashboardRoutingModule {
}
