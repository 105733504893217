import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../../models/user-access/user';
import {catchError, map} from 'rxjs/operators';
import {LocalStorageService} from '../localstorage/local-storage.service';
import {ErrorHandlerService} from "../error-handler/error-handler.service";
import {Register} from "../../models/user-access/register";
import {RegisterRequest} from "../../models/user-access/register-request";
import {UpdateUserPassword} from "../../models/user-access/update-user-password";
import {AuthenticationsService} from "../auth/authenticationsService";
import {VerifyCode} from "../../models/user-access/verify-code";

@Injectable()
export class UserAccessService {
  private baseUrl = `/api/sg-users/users/`;
  private credentialUrl = `/api/credentials/`;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private authService: AuthenticationsService,
    private errorHandlerService: ErrorHandlerService
  ) {
  }

  getUsers(confirmedUsers?: boolean): Observable<User[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('confirmed', String(confirmedUsers));
    return this.http.get<User[]>(this.baseUrl, {params: params}).pipe(catchError(this.errorHandlerService.handleError));
  }

  getUserById(userId: string): Observable<User> {
    let url = this.baseUrl + userId;
    return this.http.get<User>(url).pipe(catchError(this.errorHandlerService.handleError));
  }

  deleteUser(userId: string) {
    const url: string = this.baseUrl + userId
    return this.http.delete<User[]>(url).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  updateUser(user: User): Observable<User> {
    const url: string = this.baseUrl + user._id;
    return this.http.put<User>(url, user).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  createUser(newUser: User): Observable<User> {
    return this.http.post<User>(this.baseUrl, newUser).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  confirmUser(user: User): Observable<User> {
    const url: string = this.baseUrl + user._id + '/confirm-user';
    const params: HttpParams = new HttpParams().append('confirmed', user.confirmed.toString())
    return this.http.put<User>(url, null, {params: params}).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  /**
   * register new user
   * @param body
   * @param clientId
   */
  registerUser(body: RegisterRequest, clientId: string) {
    let registerPath = this.credentialUrl + clientId + '/access/register';
    return this.http.post<User>(registerPath, body).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  /**
   * used to update user's info
   * @param body
   * @param clientId
   */
  updateUserPassword(body: UpdateUserPassword, clientId: string) {
    const url = this.credentialUrl + clientId + '/access/update-password';
    return this.http.post(url, body).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  /**
   * method used to deliver resetting user password request
   * @param email user email
   * @param clientId
   */
  requestPasswordChange(email: string, clientId: string) {
    const url = this.credentialUrl + clientId + '/access/request';
    const body =
      {
        login: email,
      };
    return this.http.post(url, body,).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  /**
   * Used to send users verification code and compare it with the token in the backend
   * @param body
   * @param clientId
   */
  verifyCode(body: VerifyCode, clientId: string) {
    const url = this.credentialUrl + clientId + '/access/verify';
    return this.http.post(url, body).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

}
