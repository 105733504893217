<div class="power-plugin-container">
  <section>
    <p>{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.STATUS' | translate | capitalize}}</p>
  </section>
  <section>
    <p>{{ configuration.name }}</p>
    <button mat-button (click)="switchMode()">
      <mat-icon [ngClass]="(status) ? 'power-on' : 'power-off'">power_settings_new</mat-icon>
    </button>
  </section>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
