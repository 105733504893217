import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SitesService} from "../sites/sites.service";
import {DashboardSiteDto} from "../../models/sites/siteDTO";
import {AuthenticationsService} from "../auth/authenticationsService";
import {LocalStorageKeysEnum} from "../localstorage/local-storage-keys.enum";

@Injectable()
export class DashboardService {
  private selectedSiteSubject = new BehaviorSubject<DashboardSiteDto>(null);
  dashboardSelectedSiteObs = this.selectedSiteSubject.asObservable();

  sideNavOpened = new BehaviorSubject<boolean>(true);

  constructor(private authService: AuthenticationsService,
              private sitsService: SitesService) {
  }

  changeDashboardSite(siteId: string, clicked?) {
    this.sitsService.getSiteById(siteId).subscribe(site => {
      this.selectedSiteSubject.next(site);
    })

    if (this.authService.isAuthenticated()) {
      localStorage.setItem(LocalStorageKeysEnum.CURRENT_SITE_ID, siteId);
    }
    if (clicked)
      this.sideNavOpened.next(false);
  }

  resetSelectedSite() {
    this.selectedSiteSubject.next(null)
  }

}
