import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteEquipmentStatusCellComponent } from './site-equipment-status-cell.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [SiteEquipmentStatusCellComponent],
  exports: [SiteEquipmentStatusCellComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule
  ]
})
export class SiteEquipmentStatusCellModule { }
