<div class="controls-dashboard-container">
  <div class="time-line-wrapper">
    <app-dashboard-timeline class="w-100p" [classes]="'h-100p minh-100p'"></app-dashboard-timeline>
  </div>
  <gmao-controls-dashboard-filtering
    [filtrationModel]="filtrationModel"
    (changeViewControlTypeEmitter)="handleViewChange($event)"
    (changeFilterEmitter)="handleFilterChange()"
    (changeThumbImgEmitter)="floorThumbImg= $event"
  ></gmao-controls-dashboard-filtering>
  <section>
    <ng-container *ngTemplateOutlet="isPlanView? planView: listView"
                  [class.collapsed]="isOpenConfigurations"></ng-container>
    <div class="configurations-card" [class.expanded]="isOpenConfigurations">
      <div class="header">
        <p>{{configurationLabel}}</p>
        <div class="header-right">
          <gmao-site-equipment-status-cell [dataSrc]="status" [isControl]="true"></gmao-site-equipment-status-cell>
          <button class="custom-transitioned-icon-button" (click)="closeConfigurationsList()">
            <mat-icon>close</mat-icon>
        </button>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="configurationsList.length ? configurations: noConfigurations"></ng-container>
    </div>
  </section>
</div>


<ng-template #listView>
  <gmao-control-list-dashboard-view [filtrationModel]="filtrationModel"
                                    (selectControlEmitter)="handleSelection($event)"
  ></gmao-control-list-dashboard-view>
</ng-template>

<ng-template #planView>
  <gmao-control-plan-dashboard-view [filtrationModel]="filtrationModel"
                                    [floorThumbImg]="floorThumbImg"
                                    (selectControlEmitter)="handleSelection($event)"
  ></gmao-control-plan-dashboard-view>
</ng-template>

<ng-template #configurations>
  <config-control-cell
    *ngFor="let configuration of configurationsList"
    [controlToAdd]="configuration"
    [withPoller]="true">
  </config-control-cell>
</ng-template>

<ng-template #noConfigurations>
  <div class="no-configurations">
    <p>{{ 'CONTROLS_DASHBOARD.NO_CONFIGURATION' | translate | capitalize}}</p>
  </div>
</ng-template>
