import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SideBarType } from './sidebar-type.enum';



@Injectable({ providedIn: 'root' })
export class SideBarService {

  private sideBarType = new BehaviorSubject<SideBarType>(null);

  private hideEditButtonSubject = new BehaviorSubject<boolean>(null);
  hideEditButtonObs = this.hideEditButtonSubject.asObservable();

  changeEditButton(show: boolean) {
    this.hideEditButtonSubject.next(show)
  }

  changeSideBarType(type: SideBarType) {
    this.sideBarType.next(type);
  }

  getSideBarTypeObservable(): Observable<SideBarType>{
    return this.sideBarType.asObservable();
  }

}
