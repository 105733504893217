import {
  ControlPowerPluginComponent
} from "../control-power-plugin/control-power-plugin.component";
import {
  ControlNumericalPluginComponent
} from "../control-numerical-plugin/control-numerical-plugin.component";
import {
  ControlOperationNamePluginComponent
} from "../control-operation-name-plugin/control-operation-name-plugin.component";
import {
  ControlStringValuePluginComponent
} from "../control-string-value-plugin/control-string-value-plugin.component";
import { ControlSlaPluginComponent } from '../control-sla-plugin/control-sla-plugin.component';
import { ControlStaticValueComponent } from '../control-static-value/control-static-value.component';

export interface DynamicComponentType {
}
export const controlsComponentMap: { [widget: string]: DynamicComponentType } = {
  'POWER': ControlPowerPluginComponent,
  'MODE': ControlStringValuePluginComponent,
  'TEMPERATURE_SET_POINT': ControlNumericalPluginComponent,
  'SWING_POSITION_VERTICAL': ControlStringValuePluginComponent,
  'FAN_SPEED': ControlStringValuePluginComponent,
  'CURSOR': ControlNumericalPluginComponent,
  'VALUE': ControlNumericalPluginComponent,
  'STATUS': ControlNumericalPluginComponent,
  'CURRENT_STATUS': ControlStringValuePluginComponent,
  'SCROLLING_MENU': ControlOperationNamePluginComponent,
  'UNIFIED_SCROLLING_MENU': ControlOperationNamePluginComponent,
  'ACTION': "",
  "DISPLAY_VALUE": ControlNumericalPluginComponent,
  'SWITCHABLE_LIGHT': ControlStringValuePluginComponent,
  'SLA_VALUE': ControlSlaPluginComponent,
  'STATIC_VALUE': ControlStaticValueComponent
}
