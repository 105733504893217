import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit, Type,
  ViewChild, ViewContainerRef
} from '@angular/core';
import {EquipmentControlDynamicPluginDirective} from "./equipment-control-dynamic-plugin.directive";
import {controlsComponentMap} from "./equipment-control-dynamic-map";
import {
  ControlParentPluginComponent
} from "../control-parent-plugin/control-parent-plugin.component";
import {
  EquipmentConfiguration
} from "../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

@Component({
  selector: 'gmao-equipment-control-dynamic-plugin',
  templateUrl: './equipment-control-dynamic-plugin.component.html',
  styleUrls: ['./equipment-control-dynamic-plugin.component.scss'],
})
export class EquipmentControlDynamicPluginComponent implements OnInit {
  @ViewChild(EquipmentControlDynamicPluginDirective, {static: true}) injectHost!: EquipmentControlDynamicPluginDirective;
  @Input('defaultConfiguration') defaultConfiguration: EquipmentConfiguration = {} as EquipmentConfiguration;
  componentRef: ComponentRef<ControlParentPluginComponent> = null;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.injectComponent();
  }

  injectComponent() {
    if (!this.defaultConfiguration?.operation) return;
    const viewContainerRef: ViewContainerRef = this.injectHost.viewContainerRef;
    let componentName = controlsComponentMap[this.defaultConfiguration.operation] as Type<ControlParentPluginComponent>;
    let componentFactory: ComponentFactory<ControlParentPluginComponent> = this.componentFactoryResolver.resolveComponentFactory<ControlParentPluginComponent>(componentName);
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef.instance.defaultConfiguration = this.defaultConfiguration;
  }

}
