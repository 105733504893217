import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/timeout';
import {environment} from 'src/environments/environment';
import {LocalStorageKeysEnum} from "../localstorage/local-storage-keys.enum";
import {AuthenticationParams} from "../../models/auth/authentication-params";


@Injectable()
export class InterceptorService implements HttpInterceptor {
  mapSettings: any;

  constructor(private cookie: CookieService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params: { token: string, locale: string } = this.getParamsFromLocalStorage()
    this.mapSettings = environment.mapConfig;

    if (request.url && request.url.length > 40 && request.url.slice(0, 40) == this.mapSettings.apiServer.baseURL) {
      request = request.clone({});
    } else {
      request = request.clone({
        setHeaders: {
          // 'Content-Type': 'application/json',
          // Accept: 'application/json',
          Authorization: `Bearer ${params.token || ''}`,
          Locale: params.locale || '',
        }
      });
    }
    return next.handle(request);

  }

  private getParamsFromLocalStorage() {
    let params: AuthenticationParams = JSON.parse(localStorage.getItem(LocalStorageKeysEnum.AUTHENTICATED_USER));
    return {token: params?.token, locale: params?.language}
  }

}
