import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'AsFormGroup',
})
export class AsFormGroupPipe implements PipeTransform {
  transform(control: AbstractControl): FormGroup | null {
    return control instanceof FormGroup ? control : null;
  }
}
