import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {StationStop, StationStopInfo} from "src/app/core/models/transportation/station-stops.model";
import {StopInfo} from "src/app/core/models/transportation/stop-info.model";
import {
  HomeRectangleSkeletonComponent
} from "../../../components/loading-skeleton/home/home-rectangle-skeleton/home-rectangle-skeleton.component";

@Component({
  selector: 'app-metro-card',
  templateUrl: './metro-card.component.html',
  styleUrls: ['./metro-card.component.scss']
})
export class MetroCardComponent implements OnChanges {
  @Input('stationStop') stationStop: StationStop;
  @Input('stopInfo') stopInfo: StopInfo;
  loadingSkeletonComponent = HomeRectangleSkeletonComponent;
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['stopInfo'].currentValue) return;
    this.stopInfo = changes['stopInfo'].currentValue;
    this.calculateDeparture();
  }

  private calculateDeparture() {
    this.stationStop.stops.forEach((item: StationStopInfo) => {
      const time1: number = (this.stopInfo[item?.name1]?.expectedDepartureTime) - (new Date().getTime());
      const time2: number = (this.stopInfo[item?.name2]?.expectedDepartureTime) - (new Date().getTime());
      item.time1 = this.msToTime(time1);
      item.time2 = this.msToTime(time2);
    });
  }

  private msToTime(ms) {
    let minutes = (ms / (1000 * 60));
    if (minutes < 0) minutes = 0;
    return Math.ceil(minutes) + '';
  }
}
