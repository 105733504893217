import { NgModule } from '@angular/core';
import { ImageDialogComponent } from './image-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import {CommonModule} from "@angular/common";
import {PipesModule} from "../../../../core/pipes/pipes.module";
import {MatDialogModule} from "@angular/material/dialog";
import {
  NodesListDialogChipsModule
} from "../nodes-list-dialog/nodes-list-dialog-chips/nodes-list-dialog-chips.module";
import {
  NodesListDialogFromModule
} from "../nodes-list-dialog/nodes-list-dialog-from/nodes-list-dialog-from.module";
import {SgTableModule} from "../../../sg-components/sg-table/sg-table.module";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({

  imports: [
    MatIconModule,
    PipesModule,
    CommonModule,
    MatDialogModule,
    NodesListDialogChipsModule,
    NodesListDialogFromModule,
    SgTableModule,
    TranslateModule,
  ],
  declarations: [
    ImageDialogComponent,
  ],
  exports: [
    ImageDialogComponent,
  ],
})
export class ImageDialogModule { }
