<div #widget class="main-container vertical-center-content">
  <div class="main-container flex-row padding-top" [ngStyle]="{'height':(fittextElement.offsetWidth/4)+'px'}">
    <div class="flex-column labels ">
      <div class="title" [ngStyle]="{'font-size':(fittextElement.offsetWidth/35)+'px'}">
        {{title|translate|uppercase}}
      </div>
      <div class="flex-row padding-top">
        <div class="flex-column label-container center"
          *ngFor="let label of data; let i = index; let first = first; let last=last">

          <div *ngIf="i==0" [ngClass]="{'container-border':last}" class="char-container" title="{{label.max}}{{unit}}"
            [ngStyle]="getCharStyles(i)" (mouseover)="hover[i]=true" (mouseleave)="hover[i]=false">
            <div class="middle" [ngStyle]="{'font-size':(fittextElement.offsetWidth/25)+'px'}">{{label.char}} </div>
          </div>

          <div *ngIf="i > 0 && i < 6" [ngClass]="{'container-border':last}" class="char-container"
            title="{{label.min}}{{unit}} - {{label.max}}{{unit}}" [ngStyle]="getCharStyles(i)"
            (mouseover)="hover[i]=true" (mouseleave)="hover[i]=false">
            <div class="middle" [ngStyle]="{'font-size':(fittextElement.offsetWidth/25)+'px'}">{{label.char}} </div>
          </div>

          <div *ngIf="i==6" [ngClass]="{'container-border':last}" class="char-container"
            title="{{label.min}}{{unit}}" [ngStyle]="getCharStyles(i)" (mouseover)="hover[i]=true"
            (mouseleave)="hover[i]=false">
            <div class="middle" [ngStyle]="{'font-size':(fittextElement.offsetWidth/25)+'px'}">{{label.char}} </div>
          </div>

        </div>
      </div>

      <div class="flex-row">
        <div *ngFor="let label of data;let i = index; let first = first;let last=last"
          class="icon-size flex-column label-container center">

          <mat-icon *ngIf="i==ratingIndex" class="material-icons"
            [ngStyle]="{'font-size':(fittextElement.offsetWidth/10)+'px'}">
            arrow_drop_up</mat-icon>
        </div>
      </div>
    </div>

    <div class="value-number">
      <div class="center flex-column consumption-value vertical-center-content">
        <div *ngIf="value!==-1; else spinner">
          <div class="value" [ngStyle]="{'font-size':(fittextElement.offsetWidth/20)+'px'}">
            {{ value }}
          </div>
          <div class="unit" [ngStyle]="{'font-size':(fittextElement.offsetWidth/35)+'px'}">
            {{ unit }}
          </div>
          <div class="unit" [ngStyle]="{'font-size':(fittextElement.offsetWidth/35)+'px'}">
            {{ 'per year'|translate }}
          </div>
        </div>
        <ng-template #spinner>
          <mat-spinner class="margin-auto floating" [diameter]="(fittextElement.offsetWidth/10)">
          </mat-spinner>
        </ng-template>
      </div>
    </div>
  </div>
</div>
