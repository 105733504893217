import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/core/shared.module";
import { DashboardTimelineModule } from "src/app/components/dashboards/dashboard-timeline/dashboard-timeline.module";
import { EquipmentScadaDashboardRoutingModule } from "./equipment-scada-dashboard-routing.module";
import { EquipmentScadaDashboardComponent } from "./equipment-scada-dashboard.component";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { ConfigControlCellModule } from "src/app/components/admin/equipment/equipment-config-control/config-control-cell.module";
import {PipesModule} from "../../../core/pipes/pipes.module";


@NgModule({

  declarations: [ EquipmentScadaDashboardComponent ],

    imports: [
        CommonModule,
        SharedModule,
        DashboardTimelineModule,
        EquipmentScadaDashboardRoutingModule,
        MatSlideToggleModule,
        DashboardTimelineModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatButtonModule,
        MatSelectModule,
        ConfigControlCellModule,
        PipesModule
    ],

  providers: []
})
export class EquipmentScadaDashboardModule {}
