import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { EnergyEfficiencyStatsComponent } from './energy-efficiency-stats.component';


@NgModule({

  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule
  ],

  providers: [],

  declarations: [
    EnergyEfficiencyStatsComponent
  ],

  exports: [
    EnergyEfficiencyStatsComponent
  ],

  entryComponents: [
    EnergyEfficiencyStatsComponent
  ],

})
export class EnergyEfficiencyStatsModule { }
