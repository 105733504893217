import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardTimelineComponent } from './dashboard-timeline.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { Ng5SliderModule } from 'ng5-slider';

@NgModule({
  declarations: [DashboardTimelineComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSliderModule,
    Ng5SliderModule,
    MatIconModule,
    MatSelectModule,
    MatSlideToggleModule
  ],
  exports: [DashboardTimelineComponent],
  providers: []
})
export class DashboardTimelineModule {}
