import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { ConfigControlCellModule } from "src/app/components/admin/equipment/equipment-config-control/config-control-cell.module";
import { SharedModule } from "src/app/core/shared.module";
import { DashboardTimelineModule } from "src/app/components/dashboards/dashboard-timeline/dashboard-timeline.module";
import { EquipmentControlDashboardRoutingModule } from "./equipment-control-dashboard-routing.module";
import { EquipmentControlDashboardComponent } from "./equipment-control-dashboard.component";
import {PipesModule} from "../../../core/pipes/pipes.module";

import {
  ControlListDashboardViewModule
} from "../../../components/dashboards/controls-dashboard/control-list-dashboard-view/control-list-dashboard-view.module";
import {
  ControlPlanDashboardViewModule
} from "../../../components/dashboards/controls-dashboard/control-plan-dashboard-view/control-plan-dashboard-view.module";
import {
  ControlsDashboardFilteringModule
} from "../../../components/dashboards/controls-dashboard/controls-dashboard-filtering/controls-dashboard-filtering.module";
import {
  SgAutocompleteSingleSelectModule
} from "../../../components/sg-components/sg-autocomplete-single-select/sg-autocomplete-single-select.module";
import {
  SiteEquipmentStatusCellModule
} from "../../../components/site-equipment-list/site-equipment-status-cell/site-equipment-status-cell.module";


@NgModule({
  declarations: [ EquipmentControlDashboardComponent ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    DashboardTimelineModule,
    EquipmentControlDashboardRoutingModule,
    ConfigControlCellModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    PipesModule,
    SgAutocompleteSingleSelectModule,
    ControlListDashboardViewModule,
    ControlPlanDashboardViewModule,
    ControlsDashboardFilteringModule,
    SiteEquipmentStatusCellModule
  ],

  providers: []
})
export class EquipmentControlDashboardModule {}
