<ng-container>
  <section>
    <p>{{'FORMS.SELECTED' | translate | capitalize}}</p>
    <mat-chip-list>
      <mat-chip *ngFor="let node of selectedNodes"
                [removable]="true" (removed)="onNodeRemoved(node)">
        {{ node?.resourcePath | nodeTruncate}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <p *ngIf="selectedNodes.length == 0">{{"ALARMS.NO_NODES_WAS_SELECTED" | translate | capitalize}}</p>
  </section>

</ng-container>
