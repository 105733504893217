import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function TimeRangeValidator(targetControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startTime = targetControl.value;
    const endTime = control.value;

    if (startTime && endTime && startTime >= endTime) {
      return { timeRangeValidation: true };
    }

    return null;
  };
}
