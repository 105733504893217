<div class="efficiency-stats-container">

  <div *ngFor="let bar of energyBars; let index = index" class="energy-bars-column">
    <div class="bar">

      <div class="polygon-container" [ngStyle]="{'width': bar.width + '%'}">
        <div class="right-polygon" [ngStyle]="{  'background-color': bar.background }">
          {{ (bar.from) && (bar.to!=Number.MAX_VALUE) ? (bar.from + ('receptionScreen.to' | translate)) : index == 0 ? ('<') : ('>') }} {{ (bar.to != Number.MAX_VALUE) ? bar.to : bar.from}} {{unit}}
        </div>
      </div>

      <div class="letter-code" [ngStyle]="{'color': bar.background}">
        {{ bar.label }}
      </div>

      <div class="line-container">
        <div [ngStyle]="{'border-top': (bar.selected) ? ('solid 3px' + bar.background) : ('dashed 3px ' + bar.background)}" class="separator-line"></div>


        <p class="current-consumption">{{bar.objective}}</p>


        <mat-icon
          [ngStyle]="{'color': bar.background}"
          *ngIf="bar.selected"
          class="arrow">
          arrow_back_ios
        </mat-icon>
      </div>
    </div>
  </div>
</div>
