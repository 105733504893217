import {NgModule} from '@angular/core';
import {CapitalizePipe} from "./capitalize.pipe";
import {DatePipe} from "@angular/common";
import {SecurePublicPipe} from "./secure-public.pipe";
import {SecurePrivatePipe} from "./secure-private.pipe";
import {NodeNameTruncatePipe} from "./node-name-truncate-pipe";
import {TruncateTitlePipe} from "./truncate-title.pipe";
import { CapitalizeFirstAlphabeticPipe } from './capitalize-first-alphabetic.pipe';
import { AsFormControlPipe } from './as-form-control.pipe';
import { AsFormGroupPipe } from './as-form-group';

@NgModule({
  declarations: [
    CapitalizePipe,
    SecurePublicPipe,
    NodeNameTruncatePipe,
    SecurePrivatePipe,
    TruncateTitlePipe,
    CapitalizeFirstAlphabeticPipe,
    AsFormControlPipe,
    AsFormGroupPipe
  ],
  exports: [
    CapitalizePipe,
    CapitalizeFirstAlphabeticPipe,
    SecurePublicPipe,
    NodeNameTruncatePipe,
    SecurePrivatePipe,
    TruncateTitlePipe,
    AsFormGroupPipe
  ],
  providers: [DatePipe]
})
export class PipesModule {
}
