import { Injectable } from '@angular/core';
import { ItemTimelineManager } from '../../../gmao-gridster/gridster-item/item-timeline-manager.abstract';
import { TimelineService } from '../../../dashboards/dashboard-timeline/timeline.service';
import { PluginControl } from './plugin-control.abstract';


@Injectable()
export class PluginControlManual extends ItemTimelineManager {

    constructor(
      protected timelineService: TimelineService,
      private pluginControl: PluginControl,
    ) {
        super(timelineService);
    }

    protected loadData() {
        /**
         * Pass the time to child to calculate its value
         */
        this.pluginControl.setValue(this.currentTime.getTime());
    }
    protected refreshItem() {
    }
}
