import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'gmao-csv-display-errors-dialog',
  templateUrl: './csv-display-errors-dialog.component.html',
  styleUrls: ['./csv-display-errors-dialog.component.scss']
})
/**
 * @deprecated
 */
export class CsvDisplayErrorsDialog implements OnInit {
  fileName:string;
  errors:{line:number,errorsNames:string[]}[];
  constructor(
    public dialogRef: MatDialogRef<CsvDisplayErrorsDialog>,
    @Inject(MAT_DIALOG_DATA) public data:  {fileName:string,errors:{line:number,errorsNames:string[]}[]}
  ) {
    if (data && data.errors) {
      this.errors = data.errors;
      this.fileName = data.fileName;

    }
  }

  /**
   * @deprecated
   */
  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }
}
