import {Component} from '@angular/core';
import {ControlParentPluginComponent} from "../control-parent-plugin/control-parent-plugin.component";
import {TimelineService} from "../../dashboards/dashboard-timeline/timeline.service";
import {
  TesseractService
} from "../../../core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service";
import {
  EquipmentControlOperationType
} from "../../../core/models/dashboard-controls/equipment-control-operation-Type";
import {
  ControlsDashboardManagementService
} from "../../../core/services/controls-dashboard/controls-dashboard-management.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'gmao-control-operation-name-plugin',
  templateUrl: './control-operation-name-plugin.component.html',
  styleUrls: ['./control-operation-name-plugin.component.scss']
})
export class ControlOperationNamePluginComponent extends ControlParentPluginComponent {
  displayValue: string = '-';

  constructor(protected timelineService: TimelineService,
              private controlsDashboardManagementService: ControlsDashboardManagementService,
              private translateService: TranslateService,
              protected tesseractsService: TesseractService) {
    super(timelineService, tesseractsService);
  }

  /**
   * @Override
   * tesseracts return number value then find configuration additional value
   * if the operationValue equal the value got from tesseracts and view the operationName for this additionalInfo.
   */
  setDisplayValue() {
    this.displayValue = this.controlsDashboardManagementService.getKeyByValue(this.defaultConfiguration?.additionalInfo, this.getValue());
  }
}
