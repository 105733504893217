import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {AccessGroupDTO} from '../../models/user-access/access-group-d-t-o';
import {AccessGroup} from "../../models/user-access/create-access-group-dto";
import {ErrorHandlerService} from "../error-handler/error-handler.service";
import {AuthenticationsService} from "../auth/authenticationsService";

@Injectable()
export class GroupsAccessService {
  private baseUrl: string = `/api/sg-users/groups/`;

  constructor(
      private http: HttpClient,
      private authService: AuthenticationsService,
      private errorHandlerService: ErrorHandlerService
  ) {
  }

  createGroup(accessGroup: AccessGroup): Observable<AccessGroupDTO> {
    const url: string = this.baseUrl;
    return this.http.post<AccessGroupDTO>(url, accessGroup).pipe(
        catchError(this.errorHandlerService.handleError)
    );
  }

  getGroups(): Observable<AccessGroupDTO[]> {
    return this.http.get<AccessGroupDTO[]>(this.baseUrl).pipe(
        catchError(this.errorHandlerService.handleError)
    );
  }

  getGroupById(groupId:string): Observable<AccessGroupDTO> {
    return this.http.get<AccessGroupDTO>(this.baseUrl + groupId).pipe(
      catchError(this.errorHandlerService.handleError)
    );
  }

  updateGroup(accessGroup: AccessGroup): Observable<AccessGroupDTO> {
    const url: string = this.baseUrl + accessGroup._id;
    return this.http.put<AccessGroupDTO>(url, accessGroup).pipe(
        catchError(this.errorHandlerService.handleError)
    );
  }

  deleteGroup(groupId: string): Observable<string> {
    const url: string = this.baseUrl + groupId;
    return this.http.delete<string>(url).pipe(
        catchError(this.errorHandlerService.handleError)
    );
  }
}
