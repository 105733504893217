import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EnergyEfficiencyEvolutionComponent } from './energy-efficiency-evo.component';


@NgModule({

  imports: [
    CommonModule,
    TranslateModule
  ],

  providers: [],

  declarations: [
    EnergyEfficiencyEvolutionComponent
  ],

  exports: [
    EnergyEfficiencyEvolutionComponent
  ],

  entryComponents: [
    EnergyEfficiencyEvolutionComponent
  ],

})
export class EnergyEfficiencyEvolutionModule { }
