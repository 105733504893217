<div class="controls-dashboard-filtering-container">
  <section>
    <button [class.active]="!isPlanView" (click)="changeView(false)">
      <mat-icon>format_list_bulleted</mat-icon>
      <p>{{'CONTROLS_DASHBOARD.LIST_VIEW' | translate | capitalize}}</p></button>
    <button [class.active]="isPlanView" (click)="changeView(true)">
      <mat-icon>location_on</mat-icon>
      <p>{{'CONTROLS_DASHBOARD.MAP_VIEW' | translate | capitalize}}</p></button>
  </section>
  <section class="responsive-mat-form-field">
    <gmao-sg-autocomplete-single-select filterKey="floorLevel"
                                        uniqueKey="_id"
                                        minWidth="auto"
                                        [allowAllOption]="!isPlanView"
                                        [initialValue]="filtrationModel?.floorId || 'all'"
                                        [label]="'CONTROLS_DASHBOARD.CHOOSE_FLOOR' | translate | capitalize"
                                        [getOptionsObs]="getFloors"
                                        (selectionObjectChange)="handleFloorSelection($event)"
    ></gmao-sg-autocomplete-single-select>
    <gmao-sg-autocomplete-single-select filterKey="name"
                                        uniqueKey="_id"
                                        allowAllOption="true"
                                        minWidth="auto"
                                        [initialValue]="filtrationModel?.zone || 'all'"
                                        [isDisabled]="isPlanView && !filtrationModel.floorId"
                                        [label]="'FORMS.ZONE' | translate | capitalize"
                                        [getOptionsObs]="getZones"
                                        (selectionChange)="handleZoneSelection($event)"
    ></gmao-sg-autocomplete-single-select>
    <gmao-sg-autocomplete-single-select filterKey="category"
                                        uniqueKey="_id"
                                        allowAllOption="true"
                                        minWidth="auto"
                                        [includeLabelTranslation]="true"
                                        [initialValue]="filtrationModel?.categoryId || 'all'"
                                        [label]="'FORMS.CATEGORY' | translate | capitalize"
                                        [getOptionsObs]="getCategories"
                                        (selectionChange)="handleCategorySelection($event)"
    ></gmao-sg-autocomplete-single-select>
  </section>
</div>
