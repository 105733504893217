import {Component} from '@angular/core';
import {ControlParentPluginComponent} from "../control-parent-plugin/control-parent-plugin.component";
import {TimelineService} from "../../dashboards/dashboard-timeline/timeline.service";
import {
  TesseractService
} from "../../../core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service";

@Component({
  selector: 'gmao-control-numerical-plugin',
  templateUrl: './control-numerical-plugin.component.html',
  styleUrls: ['./control-numerical-plugin.component.scss']
})
export class ControlNumericalPluginComponent extends ControlParentPluginComponent {
  displayValue: string = '-';

  constructor(protected timelineService: TimelineService,
              protected tesseractsService: TesseractService) {
    super(timelineService, tesseractsService);
  }

  /**
   * @Override
   * tesseracts return number value then view this value with default equipment unit.
   */
  setDisplayValue() {
    let value: number = this.getValue();
    this.displayValue = (value !== null && value !== undefined) ? `${value} ${this.getUnit()}` : '-';
  }
}
