import { Component, Input, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts/highcharts';
import { forkJoin, interval } from 'rxjs';
import { DataSourceVariable } from 'src/app/core/deprecated/deprecated-models/data-source-variable.model';
import { Steps } from 'src/app/core/deprecated/deprecated-models/steps.enum';
import { TesseractService } from 'src/app/core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service';
import {Energy} from "../../../../core/models/energy/enums/energy.name-space";

@Component({
	selector: 'gmao-pie-consumption-sources-widget',
	templateUrl: './pie-consumption-sources-widget.component.html',
	styleUrls: ['./pie-consumption-sources-widget.component.scss']
})
export class PieConsumptionSourcesWidgetComponent implements OnInit {
	@Input() title: string = 'PRINCIPAUX USAGES';
	@Input() subTitle: string = 'En % de la consommation';
	@Input() labels: string[] = ['Autres', 'Chaufferie', 'Eclairage', 'Bureautique', "CTA"];
	@Input() colors: string[] = ['#66C8D0', '#702963', '#324496', '#35B7E8', '#9896ca'];
	@Input() icons: string[] = [
		'/assets/reception/icon_autre.svg',
		'/assets/reception/icon_chauffage.svg',
		'/assets/reception/icon_eclairage.svg',
		'/assets/reception/icon_informatique.svg',
		'/assets/reception/icon_CTA_3.svg'
	];
	@Input() consumption: number[] = [40, 30, 20, 10];

	@Input() centerLogo: string = '/assets/reception/eclair_energie.svg';


	// Consumption calculation ( using tesseract ) variables
	formula: string = '';

	/** sources used in calculation, storage and displaying consumption values */
	energyValuesMap: any[] = [];
	energyValuesMapForShow: any[] = [];
	consumptionUnit: string = '%';
	energyUnit = Energy.EnergyUnitEnum.KWH;

	highcharts = Highcharts;
	chart: Highcharts.Chart;
	chartOptions: any = {

		credits: {
			enabled: false
		},
		chart: {
			type: 'pie',
			events: {
				load: function () {
					let innerSize = this.userOptions.plotOptions.pie.innerSize;
					this.renderer.image(this.centerLogo,
						this.chartWidth / 2 - innerSize / 4,
						this.plotTop + this.plotSizeY / 2 - innerSize / 2,
						innerSize / 2,
						innerSize).add();
				}
			}
		},
		exporting: { enabled: false },
		title: {
			text: this.title,
			style: {
				fontSize: '1.2rem'
			},
			align: 'left',
		},
		subtitle: {
			text: this.subTitle,
			style: {
				fontSize: '0.65rem'
			},
			align: 'left',
		}, plotOptions: {
			pie: {
				innerSize: 130,
				colors: this.colors
			}
		}, series: [{
			dataLabels: {
				enabled: false,
			},
			data:  [0,0,0,0,0],
		}]
	};

	constructorType = 'chart';
	percentages: any = ["--.--%", "--.--%", "--.--%", "--.--%", "--.--%"];

	totalData: any[] = [];

	settings = {
		formulas: [
			{formula: "(a+b+asc1+asc2+asc3+asc4+asc5)/1000"},
			{formula: "v1/1000"},
			{formula: "c1/1000"},
			{formula: "b1/1000"},
			{formula: "scta1/1000"}
		],
		startDate: new Date(),
		endDate: new Date(),
		prevStartDate: new Date(),
		prevEndDate: new Date(),
		delta: true,
		step: Steps.HOUR,
		totalStep: Steps.DAYS,
		currentHour: 0,
		totalTitle: "en temps réel"
	};

	sources: DataSourceVariable[][] = [
		[//atures
			{
				source: 13,
				path: "saclay_cps1>8.14011>2.67",
				variable: "a"
			},
			{
				source: 31,
				path: "saclay_cps1>8.14011>2.67",
				variable: "b"
			},
			{
				source: 103,
				path: "saclay_cps1>8.14011>2.67",
				variable: "asc1"
			},
			{
				source: 121,
				path: "saclay_cps1>8.14011>2.67",
				variable: "asc2"
			},
			{
				source: 157,
				path: "saclay_cps1>8.14011>2.67",
				variable: "asc3"
			},
      {
				source: 193,
				path: "saclay_cps1>8.14011>2.67",
				variable: "asc4"
			},
      {
				source: 229,
				path: "saclay_cps1>8.14011>2.67",
				variable: "asc5"
			},
		],
		[//Chaufferie
			{
				source: 139,
				path: "saclay_cps1>8.14011>2.67",
				variable: "v1"
			},
			// {
			// 	source: 8570,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "v2"
			// },
			// {
			// 	source: 8587,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "cvc1"
			// },
			// {
			// 	source: 8692,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "cvc2"
			// },
			// {

			// 	source: 8051,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "cvc3"
			// },
			// {
			// 	source: 8700,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "cvc4"
			// },
			// {
			// 	source: 8741,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "cvc5"
			// }
		],
		[//Eclairage
			{
				source: 175,
				path: "saclay_cps1>8.14011>2.67",
				variable: "c1"
			}


		],
		[//Bureautique
			{
				source: 1019,
				path: "saclay_cps1>8.14011>2.67",
				variable: "b1"
			},
			// {
			// 	source: 8581,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "b2"
			// },
		],
		[ // CTA
			{
				source: 85,
				path: "saclay_cps1>8.14011>2.67",
				variable: "scta1"
			}
			// {
			// 	source: 4205,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "rcta1"
			// },
			// {
			// 	source: 5730,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "rcta2"
			// },
			// {
			// 	source: 9215,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "scta2"
			// },
			// {
			// 	source: 9227,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "scta3"
			// },
			// {
			// 	source: 8977,
			// 	path: "saclay_cps1>8.14011>2.67",
			// 	variable: "rcta3"
			// },
		]
	]


	isLoading = false;

	innerSize = 100;

	constructor(
		private readonly tesseractService: TesseractService,
		private ngZone: NgZone,
    private translate: TranslateService
	) {
		interval(300000).subscribe((func => {
			this.loadData();
		}));

	 	window.onresize = (e) => {
			this.ngZone.run(() => {
				this.resizeResponsive();
			});
		};
	}

	ngOnInit(): void {
		this.loadData();
	}

	resizeResponsive(){
		if(window.innerWidth >= 1700){
			this.chartOptions.plotOptions.pie.innerSize = 120;
			this.innerSize = 120;
		}else if(window.innerWidth >= 1600 && window.innerWidth < 1700){
			this.chartOptions.plotOptions.pie.innerSize = 100;
			this.innerSize = 100;
		}else if(window.innerWidth >= 1500 && window.innerWidth < 1600){
			this.chartOptions.plotOptions.pie.innerSize = 100;
			this.innerSize = 100;
		}else if(window.innerWidth >= 1400 && window.innerWidth < 1500){
			this.chartOptions.plotOptions.pie.innerSize = 90;
			this.innerSize = 90;
		}else if(window.innerWidth >= 1300 && window.innerWidth < 1400){
			this.chartOptions.plotOptions.pie.innerSize = 70;
			this.innerSize = 70;
		}else{
			this.chartOptions.plotOptions.pie.innerSize = 50;
			this.innerSize = 50;
		}

		if(this.chart){
			this.chart.reflow();
			this.chart.redraw();
		}
	}

  async loadTranslations() {
    await this.translate.get('dummyTranslation').toPromise().then();
    this.title = this.translate.instant('pieChartWidget.title');
    this.subTitle = this.translate.instant('pieChartWidget.subTitle');
    this.labels[0] = this.translate.instant('pieChartWidget.other');
    this.labels[2] = this.translate.instant('pieChartWidget.lighting'),
    this.labels[3] = this.translate.instant('pieChartWidget.office')
  }


	loadData(){

		this.isLoading = true;

    this.loadTranslations();

		this.settings.startDate = new Date();
		this.settings.startDate.setHours(0);
		this.settings.startDate.setMinutes(0);
		this.settings.startDate.setSeconds(0);
		this.settings.startDate.setMilliseconds(0);
		this.settings.endDate = new Date();
		let diff = 24 * 60 * 60 * 1000;
		this.settings.endDate.setTime(this.settings.startDate.getTime() + diff);


		forkJoin([
			this.tesseractService.getFormula(
				this.sources[0],
				this.settings.formulas[0],
				this.settings.startDate,
				this.settings.endDate,
				this.settings.delta,
				this.settings.totalStep
			),
			this.tesseractService.getFormula(
				this.sources[1],
				this.settings.formulas[1],
				this.settings.startDate,
				this.settings.endDate,
				this.settings.delta,
				this.settings.totalStep
			),
			this.tesseractService.getFormula(
				this.sources[2],
				this.settings.formulas[2],
				this.settings.startDate,
				this.settings.endDate,
				this.settings.delta,
				this.settings.totalStep
			),
			this.tesseractService.getFormula(
				this.sources[3],
				this.settings.formulas[3],
				this.settings.startDate,
				this.settings.endDate,
				this.settings.delta,
				this.settings.totalStep
			),
			this.tesseractService.getFormula(
				this.sources[4],
				this.settings.formulas[4],
				this.settings.startDate,
				this.settings.endDate,
				this.settings.delta,
				this.settings.totalStep
			),
		]).subscribe( (results: any) => {


			let sum = 0;
			for(let result of results){
				if(result.length > 0){
					sum += result[0].value;
				}
			}

			if(results[0].length > 0){
				this.totalData[0] = results[0][0].value;
				if(sum !== 0){
					this.percentages[0] = this.findPercentage(((results[0][0].value / sum) * 100)) + "%";
				}
			}else{
				this.totalData[0] = 0;
			}

			if(results[1].length > 0){
				this.totalData[1] = results[1][0].value;
				if(sum !== 0){
					this.percentages[1] = this.findPercentage(((results[1][0].value / sum) * 100)) + "%";
				}
			}else{
				this.totalData[1] = 0;
			}

			if(results[2].length > 0){
				this.totalData[2] = results[2][0].value;
				if(sum !== 0){
					this.percentages[2] = this.findPercentage(((results[2][0].value / sum) * 100)) + "%";
				}
			}else{
					this.totalData[2] = 0;
			}

			if(results[3].length > 0){
				this.totalData[3] = results[3][0].value;
				if(sum !== 0){
					this.percentages[3] = this.findPercentage(((results[3][0].value / sum) * 100)) + "%";
				}
			}else{
				this.totalData[3] = 0;
			}

			if(results[4].length > 0){
				this.totalData[4] = results[4][0].value;
				if(sum !== 0){
					this.percentages[4] = this.findPercentage(((results[4][0].value / sum) * 100)) + "%";
				}
			}else{
				this.totalData[4] = 0;
			}


			this.setChartOptions();

		});
 }

 findPercentage(number: number){
	return Math.round(number * 100) / 100
 }

	setChartOptions() {

		var self = this;

		this.resizeResponsive();


		this.chartOptions = {

			credits: {
				enabled: false
			},

			chart: {
				type: 'pie',
				events: {
					load: function () {
						let innerSize = this.userOptions.plotOptions.pie.innerSize;
						this.renderer.image(self.centerLogo,
							this.chartWidth / 2 - innerSize / 2,
							this.plotTop + this.plotSizeY / 2 - innerSize / 2,
							innerSize / 2,
							innerSize).add();
					}
				}
			},

			exporting: { enabled: false },
			title: {
				text: this.title,
				style: {
					fontSize: '1.2rem'
				},
				align: 'left',
			},
			subtitle: {
				text: this.subTitle,
				style: {
					fontSize: '0.7rem'
				},
				align: 'left',
			},
			plotOptions: {
				pie: {
					innerSize: this.innerSize,
					colors: this.colors
				}
			}, series: [{
				dataLabels: {
					enabled: false,
				},
				data:  this.totalData,
			}]
		}

		this.isLoading = false;

		if(this.chart){
			this.chart.redraw();
			this.chart.reflow();
		}
	}

	public loadChartInstance(chart: Highcharts.Chart) {
		this.chart = chart;
      	if(this.chart){
			this.chart.redraw();
			this.chart.reflow();
		}
	}

}
