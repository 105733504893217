import {Component, Inject, OnInit} from '@angular/core';
import {BehaviorSubject, EMPTY} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MapViewModels} from '../../../../view-models/map-settings';
import {SiteAddress} from "../../../../models/sites/site-address";

@Component({
  selector: 'map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss']
})
/**
 * @deprecated
 */
export class MapDialogComponent implements OnInit {
  mapSettings: MapViewModels.MapSettings = {} as MapViewModels.MapSettings;

  mapCoordinates: MapViewModels.MapCoordinates[] = [];
  _mapCoordinates = new BehaviorSubject<any>([]);

  searchResult: MapViewModels.MapSearchResult = {} = {} as MapViewModels.MapSearchResult;

  constructor(
    public dialogRef: MatDialogRef<MapDialogComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: MapViewModels.MapDialogInputs
  ) {
  }

  /**
   * @deprecated
   */
  ngOnInit() {
    this.mapSettings = this.data.mapSettings;
    this.mapCoordinates = [];
    if (this.data.address) {
      this.mapCoordinates.push({
        lat: this.data.address.lat,
        lon: this.data.address.lon,
        center: true
      });
    }
    this._mapCoordinates.next(this.mapCoordinates);
  }

  changeAddress(event: MapViewModels.MapSearchResult) {
    this.searchResult = event;
  }

  selectAddress() {
    let newAddress: SiteAddress = {
      lat: this.searchResult.lat,
      lon: this.searchResult.lon,
      street: this.searchResult.house_number + ', ' + this.searchResult.address.road,
      city: this.searchResult.display_name,
      country: this.searchResult.address.country,
      postalCode: this.searchResult.address.postcode
    };
    this.dialogRef.close(newAddress);
  }


}
