import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import * as d3 from 'd3';
import { Theme } from '../../../../core/models/theme/theme.model';
import { ThemeService } from '../../../../core/services/theme/theme.service';
import { TranslatedToasterService } from '../../../../core/services/translated-toaster/translated-toaster.service';
import { FileManagerService } from '../../../../core/services/files/file-manager.service';
import { TimelineService } from '../../dashboard-timeline/timeline.service';
import { MatDialog } from '@angular/material/dialog';
import { TesseractService } from 'src/app/core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service';
import { HttpClient } from '@angular/common/http';
import { ItemTimelineManager } from '../../../gmao-gridster/gridster-item/item-timeline-manager.abstract';
import {
  EquipmentControlParameter
} from '../../../../pages/home-screens/equipment-control-dashboard/equipmentControlParameter.model';
import { PolledData } from '../../../../core/models/tesseract/polledData';
import {
  EquipmentConfiguration
} from '../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model';
import { GridsterChartDialog } from '../../../gmao-gridster/gridster-item/gridster-chart-dialog.component';
import {
  EquipmentControlSidebarService
} from '../../../../core/deprecated/deprecated-services/deprecated-scada-toolbar/equipment-control-sidebar.service';
import {
  EquipmentControlSidebarActionEnum
} from '../../../../core/deprecated/deprecated-models/equipment-control-sidebar-actions.enum';
import { SiteEquipmentModel } from '../../../../core/models/site-equipments/site-equipment.model';
import {
  EquipmentControlSidebarAction
} from '../../../../core/deprecated/deprecated-models/equipment-control-sidebar-action.model';
import {
  SgAutocompleteSingleSelectComponent
} from '../../../sg-components/sg-autocomplete-single-select/sg-autocomplete-single-select.component';
import { SideBarService } from '../../../../core/services/sidebar/sidebar.service';
import { ToggleMenuService } from '../../../app/content/toggle-menu.service';
import { SideBarType } from '../../../../core/services/sidebar/sidebar-type.enum';
import {
  SitesEquipmentsPaginationResult
} from '../../../../core/models/site-equipments/sites-equipments-pagination-result';
import {
  EquipmentControlSelectionModel
} from '../../../../core/models/dashboard-controls/equipment-control-selection-model';
import {
  PolledGridsterChartDialogData
} from '../../../../core/models/dashboard-controls/polled-gridster-chart-dialog-data';
import { AppService } from '../../../../core/services/app/app.service';
import { SitesEquipmentsService } from '../../../../core/services/sites-equipments/sites-equipments.service';
import { ControlsFiltrationModel } from '../../../../core/models/dashboard-controls/controls-filtration-model';
import { Router } from '@angular/router';
import {
  ControlsDashboardManagementService
} from '../../../../core/services/controls-dashboard/controls-dashboard-management.service';
import {
  EquipmentSourceTypeEnum
} from '../../../../core/models/site-equipments/equipment/equipment/equipment-source-type.enum';
import {
  DeviceOperationEnum
} from '../../../../core/models/site-equipments/equipment/equipment-configuration/device-operation.enum';

@Component({
  selector: 'gmao-control-plan-dashboard-view',
  templateUrl: './control-plan-dashboard-view.component.html',
  styleUrls: ['./control-plan-dashboard-view.component.scss']
})
export class ControlPlanDashboardViewComponent extends ItemTimelineManager implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'd-flex flex-column h-100';
  @ViewChild('categoriesSelect') categoriesSelect: SgAutocompleteSingleSelectComponent;
  @Input('filtrationModel') filtrationModel: ControlsFiltrationModel;
  @Input("floorThumbImg") floorThumbImg: string;
  /**
   * Emit selected control value to parent
   * @see ControlsDashboardComponent.handleSelection
   */
  @Output('selectControlEmitter') selectControlEmitter = new EventEmitter<EquipmentControlSelectionModel>();
  pollersSubscriptions: Subscription = new Subscription();
  sideBarSubscriptions: Subscription = new Subscription();
  selectedSiteFloorEquipments: SiteEquipmentModel[] = [];
  plotData: { [key: number]: PolledData[] } = [];
  editMode: boolean;
  selectedSiteEquipment: SiteEquipmentModel;
  loadingSiteFloor: boolean = false;
  theme: Theme;
  controlsEquipmentsLabel = 'buildingEquipmentLabel';

  constructor(
    protected timelineService: TimelineService,
    private sideBarService: SideBarService,
    public dialog: MatDialog,
    private equipmentControlSidebarService: EquipmentControlSidebarService,
    private toggleMenuService: ToggleMenuService,
    private tesseractService: TesseractService,
    private themeService: ThemeService,
    private translatedToasterService: TranslatedToasterService,
    private fileManagerService: FileManagerService,
    private httpClient: HttpClient,
    private sitesEquipmentsService: SitesEquipmentsService,
    private appService: AppService,
    private router: Router,
    private controlsDashboardManagementService: ControlsDashboardManagementService
  ) {
    super(timelineService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes["floorThumbImg"]?.currentValue ? this.newFloorSelected() : this.getSiteFloorEquipments();
  }


  ngOnInit(): void {
    this.setUpPageParameters()
    this.subscribeSideBarEvents();
    this.sideBarService.changeSideBarType(SideBarType.EQUIPMENTCONTROL);
  }

  private setUpPageParameters() {
    this.theme = this.themeService.getActiveTheme();
  }

  newFloorSelected() {
    this.loadingSiteFloor = true;
    if (this.floorThumbImg) this.getImageAndInjectT0Svg()
    else {
      d3.selectAll('#addedImage').remove();
      this.equipmentControlSidebarService.sendBuildingEquipment([]);
      this.loadingSiteFloor = false;
    }
  }


  private getImageAndInjectT0Svg() {
    this.fileManagerService.getPublicSvgFile(this.floorThumbImg).subscribe((storageImage: string) => {
      storageImage ? this.injectSvgImgAndGetEquipments(storageImage) : this.getImageFromOldServerAndGetEquipments();
    }, error => {
      if (error.status == 500) {
        this.translatedToasterService.showErrorCodeMessage('ERROR_OCCURRED');
        this.loadingSiteFloor = false;
        return
      }
      this.getImageFromOldServerAndGetEquipments()
    })
  }

  private injectSvgImgAndGetEquipments(image: string) {
    d3.selectAll('#addedImage').remove();
    d3.select(`#siteFloorImage`).html(image);
    d3.select(`#siteFloorImage`)
      .selectAll(`svg`)
      .attr('id', 'addedImage')
      .attr('margin', '0 auto')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr("preserveAspectRatio", "xMinYMin meet")
      .append("g")
    this.getSiteFloorEquipments();
  }

  private getImageFromOldServerAndGetEquipments() {
    this.httpClient.get(this.floorThumbImg, {responseType: 'text'}).subscribe((image) => {
        this.injectSvgImgAndGetEquipments(image)
      }, error => {
        this.translatedToasterService.showErrorCodeMessage('ERROR_OCCURRED');
        this.loadingSiteFloor = false;
      }
    )
  }


  /**
   * Retrieve the list of BuildingEquipment that exist within
   * the selected floor, of the selected building.
   */
  getSiteFloorEquipments() {
    this.selectedSiteFloorEquipments = [];
    this.sitesEquipmentsService.getEquipmentsControlsList(this.filtrationModel).subscribe((response: SitesEquipmentsPaginationResult) => {
      d3.select(`#siteFloorImage`).selectAll(`.${this.controlsEquipmentsLabel}`).remove();
      this.selectedSiteFloorEquipments = response.buildingEquipments.filter((ele: SiteEquipmentModel) =>
        ele?.equipmentControlParameter &&
        ele.equipmentControlParameter.xPos &&
        ele.equipmentControlParameter.yPos)
      this.selectedSiteFloorEquipments.forEach((equipment: SiteEquipmentModel) => this.addSiteEquipment(equipment))
      this.refreshItem();
      this.loadingSiteFloor = false;
      this.loadDataEvent.next(true);
      this.equipmentControlSidebarService.sendBuildingEquipment(this.selectedSiteFloorEquipments);
    });
  }


  /**
   * Place the buildingEquipment label on the Floor's map.
   * siteEquipment
   */
  addSiteEquipment(siteEquipment: SiteEquipmentModel, draggable?: boolean) {
    let self = this;

    let svg = d3.select('#siteFloorImage').select('svg');
    let labelContainer = svg
      .append('g')
      .attr('class', `${this.controlsEquipmentsLabel}`)
      .attr('id', siteEquipment.equipmentControlParameter.id)
      .attr('style', 'cursor: pointer')
      .attr('fill', this.theme.properties['--background-primary']);

    if (siteEquipment.equipmentControlParameter?.xPos && siteEquipment.equipmentControlParameter?.yPos) {
      labelContainer
        .attr('transform', `translate(${siteEquipment.equipmentControlParameter['xPos']},${siteEquipment.equipmentControlParameter['yPos']})`
        );
    }


    let defs = svg.append("defs");
    let filter = defs.append("filter")
      .attr("id", "dropshadow")
      .attr("filterUnits", "userSpaceOnUse")
      .attr("color-interpolation", "sRGB");

    filter.append("feComponentTransfer")
      .attr("in", "SourceAlpha");

    filter.append("feGaussianBlur")
      .attr("stdDeviation", 2);

    filter.append("feOffset")
      .attr("dx", 0)
      .attr("dy", 0)
      .attr("result", "shadow");

    filter.append("feComposite")
      .attr("in", "SourceGraphic")
      .attr("in2", "shadow")
      .attr("operator", "over");

    // Add label rectangle.
    labelContainer
      .append('rect')
      .attr('width', 100)
      .attr('height', 25)
      .style('fill', '#FFF')
      .attr('filter', "url(#dropshadow)");

    // Add buildingEquipment's text inside label.
    labelContainer
      .append('text')
      .attr('class', 'rectangleTextDialog')
      .attr('fill', 'black')
      .attr('dx', '45')
      .attr('dy', '17')
      .attr('style', 'font-size: 0.8em; font-weight: 700')
      .text('');

    labelContainer.on('click', function () {
      self.openEquipmentConfigsSidebar(siteEquipment.equipmentControlParameter);
    });

    labelContainer.on('dblclick', function () {
      self.selectedSiteEquipment = self.selectedSiteFloorEquipments.find((buildingEquipment: SiteEquipmentModel) => buildingEquipment._id == siteEquipment.equipmentControlParameter.id);

      if (siteEquipment.equipmentControlParameter?.defaultConfigs?.[0]) {
        const config: EquipmentConfiguration = self.selectedSiteEquipment.equipmentConfigurations.find((config) => config.dataId === +siteEquipment.equipmentControlParameter?.defaultConfigs?.[0]);
        config && self.openDialog(config);
      }
    });

    let labelTag = labelContainer
      .append('g')
      .attr('class', `${this.controlsEquipmentsLabel}Tag`)
      .attr('id', siteEquipment.equipmentControlParameter.id)
      .attr('fill', this.theme.properties['--background-primary']) // '#A5A2A2'
      .attr('transform', 'translate(0,-20)');

    labelTag
      .append('rect')
      .attr('width', 100)
      .attr('height', 20)
      .style('fill', this.theme.properties['--background-primary']);
    let labelName = this.appService.truncateString(siteEquipment.nomenClature, 12);

    labelTag
      .append('text')
      .attr('class', 'rectangleTextDialog')
      .attr('fill', this.theme.properties['--label-font-color'])
      .attr('dy', 15)
      .attr('dx', 1)
      .attr('style', 'font-size: 0.7em; font-weight: 700;')
      .text(labelName);
    labelTag.append('title').text(siteEquipment.nomenClature);

    if (draggable) {
      d3.select('#siteFloorImage')
        .selectAll(`.${this.controlsEquipmentsLabel}` + `#${CSS.escape(siteEquipment.equipmentControlParameter.id)}`)
        .attr('style', 'cursor: pointer')
        .call(d3.drag().on('drag', this.dragMove(siteEquipment.equipmentControlParameter)));
    }

  }

  /**
   * Open a dialog that will show a plot for the data
   * in the passed Configuration.
   * @param configObject
   */
  openDialog(configObject: EquipmentConfiguration): void {
    let dataToPlot: PolledGridsterChartDialogData[] = [];

    if (this.plotData[configObject.dataId] &&
      this.plotData[configObject.dataId].length > 0) {

      this.plotData[configObject.dataId].forEach(function (item: PolledData) {
        dataToPlot.push({x: item.time, y: item.value});
      });

      this.dialog.open(GridsterChartDialog, {
        width: '650px',
        height: '400px',
        panelClass: 'formFieldWidth480',
        data: {
          type: configObject.operation,
          description: configObject.name,
          unit: configObject.unit,
          data: dataToPlot
        }
      });
    }

  }

  openEquipmentConfigsSidebar(equipmentControlParameter: EquipmentControlParameter) {
    this.selectedSiteEquipment = this.selectedSiteFloorEquipments.find((buildingEquipment: SiteEquipmentModel) => buildingEquipment._id == equipmentControlParameter.id);
    let data: EquipmentControlSelectionModel = {
      label: this.selectedSiteEquipment?.nomenClature,
      equipmentConfigurations: this.selectedSiteEquipment?.equipmentConfigurations
    }
    this.selectControlEmitter.emit(data)
  }

  /**
   * @Override
   */
  protected loadData() {
    this.pollersSubscriptions.unsubscribe();
    this.pollersSubscriptions = new Subscription();
    let isDataComplete = (): boolean => false;

    this.selectedSiteFloorEquipments.forEach((siteEquipment: SiteEquipmentModel) => {
      let config = siteEquipment.equipmentControlParameter?.defaultConfigs?.[0];
      let dataLoader = this.createDataLoader(config);
      let pollerSub = this.addNewPoller(dataLoader, this.transformData, isDataComplete).subscribe((data: PolledData[]) => {
        this.plotData[config] = data;
        this.refreshItem();
      });
      this.pollersSubscriptions.add(pollerSub);
    });

  }

  /**
   * Returns a clojure that takes start and end dates for retrieving the data associated to dataId
   * @param dataId
   */
  protected createDataLoader(dataId): Function {
    let self = this;
    return function (startDate: Date, endDate: Date) {
      return self.tesseractService.getData(dataId, startDate, endDate);
    };
  }

  /**
   * @Override
   */
  protected refreshItem() {
    let self = this;
    this.selectedSiteFloorEquipments.forEach((siteEquipment) => {
      let parameters: EquipmentControlParameter = siteEquipment.equipmentControlParameter;

      let config: number = parameters.defaultConfigs?.[0];

      if (config && this.plotData[config] && this.plotData[config].length > 0) {
        let singularPlotData: PolledData[] = this.plotData[config];
        let beforeItems: PolledData[] = singularPlotData.filter((item: PolledData) => +item.time - self.currentTime.getTime() < 0);
        let value: number = beforeItems !== null && beforeItems.length > 0 ?
          beforeItems[beforeItems.length - 1].value :
          this.plotData[config][this.plotData[config].length - 1].value;

        if (!isNaN(value - parseFloat(`${value}`))) {
          value = Math.round(value * 100) / 100;
        }

        let unit: string = '';
        let configItem: EquipmentConfiguration;
        for (let i = 0; i < this.selectedSiteFloorEquipments.length; i++) {
          configItem = this.selectedSiteFloorEquipments[i].equipmentConfigurations.find((configuration: EquipmentConfiguration) => configuration.dataId === config);
          if (configItem && Object.keys(configItem).length > 0) {
            unit = configItem?.unit;
            break;
          }
        }
        let viewValue: string= this.controlsDashboardManagementService.checkViewValue(configItem, value);
        let configLabel = d3.select(`.${this.controlsEquipmentsLabel}` + `#${CSS.escape(parameters.id)}`);
        configLabel.select('.rectangleTextDialog').text(this.appService.truncateString(viewValue, 10))
          .attr('dy', 18)
          .attr('dx', 2)
          .attr('style', 'cursor: pointer; font-size: 0.9em; font-weight: 700');

        configLabel.append('title').text(value + ' ' + unit);

      } else if (siteEquipment.sourceType === EquipmentSourceTypeEnum.MANUAL) {
        const configItem = siteEquipment.equipmentConfigurations[config];
        const value = this.controlsDashboardManagementService.getManualDataItem(configItem, this.currentTime.getTime()).value;
        const unit = siteEquipment.equipmentConfigurations[config].unit;
        let viewValue: string = (value ? value : '-') + ' ' + unit;
        let configLabel = d3.select(`.${this.controlsEquipmentsLabel}` + `#${CSS.escape(parameters.id)}`);

        if (configItem.operation === DeviceOperationEnum.DeviceOperationEnum.SLA_VALUE) {
          const status = this.controlsDashboardManagementService.isManualDataInRange(configItem, value);
          const circleColor = status ? 'green' : 'red';
          configLabel.append('circle').attr('cx', 90).attr('cy', 14).attr('r', 6).attr('fill', circleColor);
        }

        configLabel.select('.rectangleTextDialog').text(this.appService.truncateString(viewValue, 10)).attr('dy', 18).attr('dx', 2).attr('style', 'cursor: pointer; font-size: 0.9em; font-weight: 700');
        configLabel.append('title').text(viewValue);
      }
      else {
        let configLabel = d3.select(`.${this.controlsEquipmentsLabel}` + `#${CSS.escape(parameters.id)}`);
        configLabel.select('.rectangleTextDialog').text('-').attr('dx', 5);
      }

    });
  }


  /**
   * Implemented only to satisfy Inheritance.
   * @param data
   * @returns data
   */
  protected transformData = (data: any[]) => data;


  /**
   * Subscribe the update site equipments CRUD event from the sidebar for:
   * 1. updating the svg view when new building equipment added,deleted , or set back to default
   * 2. save the new updates
   * 3. start the edit mode by updating the d3 equipments labels drag/drop events
   * @private
   */
  private subscribeSideBarEvents() {
    let equipmentControlSidebarSubscription = this.equipmentControlSidebarService.equipmentControlActionAsObservable()
      .subscribe((buildingEquipmentWithAction: EquipmentControlSidebarAction) => {
        switch (buildingEquipmentWithAction.action) {

          case(EquipmentControlSidebarActionEnum.ADD):
            let buildingEquipment: SiteEquipmentModel = buildingEquipmentWithAction.buildingEquipment;
            this.addSiteEquipment(buildingEquipment, true);
            break;

          case(EquipmentControlSidebarActionEnum.DELETE):
            this.removeBuildingEquipment(buildingEquipmentWithAction.buildingEquipment);
            break;

          case(EquipmentControlSidebarActionEnum.SETDEFAULTCONFIG):
            this.setDefaultConfiguration(buildingEquipmentWithAction.buildingEquipment);
            break;
        }
      });

    let sidebarSaveSubscription = this.equipmentControlSidebarService.saveActionAsObservable().subscribe(() => {
      this.save();
    });

    let toggleMenuSubscription = this.toggleMenuService.actionMessage$.subscribe((msg: boolean) => {
      this.toggleEditMode(msg);
    });
    this.sideBarSubscriptions = this.sideBarSubscriptions.add(toggleMenuSubscription)
    this.sideBarSubscriptions = this.sideBarSubscriptions.add(sidebarSaveSubscription)
    this.sideBarSubscriptions = this.sideBarSubscriptions.add(equipmentControlSidebarSubscription)
  }

  /**
   * Remove a BE from the map by resetting its coordinates to (0, 0).
   * @param buildingEquipment
   */
  removeBuildingEquipment(buildingEquipment: SiteEquipmentModel) {
    d3.select('#siteFloorImage')
      .selectAll(`.${this.controlsEquipmentsLabel}` + `#${CSS.escape(buildingEquipment._id)}`)
      .remove();
    let deletedEquipment: SiteEquipmentModel = this.selectedSiteFloorEquipments.find((item: SiteEquipmentModel) => item._id == buildingEquipment._id);
    if (deletedEquipment?.equipmentControlParameter) {
      deletedEquipment.equipmentControlParameter.xPos = 0;
      deletedEquipment.equipmentControlParameter.yPos = 0;
    }
  }

  /**
   * Select the configuration in the BuildingEquipment from which readings
   * will be Polled.
   * @param buildingEquipment
   */
  setDefaultConfiguration(buildingEquipment: SiteEquipmentModel) {
    let targetBuildingEquipment: SiteEquipmentModel = this.selectedSiteFloorEquipments.find((equipment: SiteEquipmentModel) =>
      equipment._id == buildingEquipment._id
    );
    if (targetBuildingEquipment) {
      targetBuildingEquipment.equipmentControlParameter = buildingEquipment.equipmentControlParameter;
    }
    this.loadDataEvent.next(true);
  }

  /**
   * Toggle the page's edit mode, i.e., allow adding, modifying, or deleting
   * BuildingEquipment from the page.
   * @param msg
   */
  private toggleEditMode(msg) {
    if (msg) {
      this.selectedSiteFloorEquipments.forEach((item) => {
        d3.select('#siteFloorImage')
          .selectAll(`.${this.controlsEquipmentsLabel}` + `#${CSS.escape(item.equipmentControlParameter.id)}`)
          .attr('style', 'cursor: pointer')
          .call(d3.drag().on('drag', this.dragMove(item.equipmentControlParameter)));
      });
    } else {
      this.selectedSiteFloorEquipments.forEach((item) => {
        d3.select('#siteFloorImage')
          .selectAll(`.${this.controlsEquipmentsLabel}` + `#${CSS.escape(item.equipmentControlParameter.id)}`)
          .call(d3.drag().on('drag', null));
      });
    }
  }

  dragMove(equipmentParameters: EquipmentControlParameter) {
    return function (dragEvent) {
      equipmentParameters.xPos = dragEvent.x - 45;
      equipmentParameters.yPos = dragEvent.y - 20;
      d3.select(this).attr('transform', 'translate(' + equipmentParameters.xPos + ',' + equipmentParameters.yPos + ')');
    };
  }


  /**
   * Save the changes to buildingEquipment.
   * Aggregate requests first, then ping the API.
   */
  save() {
    this.loadingSiteFloor = true;
    let requestsArray = [];
    this.selectedSiteFloorEquipments.forEach((siteEquipmentModel: SiteEquipmentModel) => {
      requestsArray.push(
        this.sitesEquipmentsService.updateSiteEquipment(siteEquipmentModel)
      );
    });

    forkJoin(requestsArray).subscribe(() => {
      this.loadingSiteFloor = false;
      this.translatedToasterService.showSuccessCodeMessage('SUCCESSFUL_OPERATION');
    }, () => {
      this.loadingSiteFloor = false;
      this.translatedToasterService.showErrorCodeMessage('ERROR_OCCURRED');
    });
  }

  navigateToSitePage() {
    this.router.navigate(['/sites/' + this.filtrationModel.siteId], {queryParams: {tabIndex: 1}})
  }

  ngOnDestroy(): void {
    this.sideBarSubscriptions.unsubscribe();
    this.pollersSubscriptions.unsubscribe();
    this.unsubscribeTimeline();
    d3.selectAll().remove();
  }

}
