import {ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {debounceTime} from 'rxjs/operators';
import {PluginControl} from "../plugin-control.abstract";
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";
import {
  AccessRightEnum
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/device-access-right.enum";
import {
  EquipmentConfigurationPlugin
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration-plugin";


@Component({
  selector: 'temp-control-plugin',
  templateUrl: './temp-control-plugin.component.html',
  styleUrls: ['./temp-control-plugin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemperatureControlPluginComponent extends PluginControl implements OnInit, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'w-100p  bxs-black-around br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;
  @Input('templateMode') templateMode: boolean = false;
  value: number;
  subject: Subject<number> = new Subject();
  subscription: Subscription = new Subscription();
  readOnly: boolean = false;
  chosenValue: number;
  maxValue: number;
  minValue: number;
  unit: string;
  tempControl = new FormControl();
  displayTempControl = new FormControl();
  plotData: any[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef,) {
    super();
  }

  ngOnInit(): void {
    this.subscribeActions();
    this.initValue();
  }

  private subscribeActions() {
    this.subscription.add(
      this.subject.pipe(debounceTime(2000)).subscribe((amount: number) => {
          this.updateValue(amount);
        }
      )
    )
  }


  setValue(value: number) {
    this.value = value;
    this.tempControl.patchValue(this.value);
    this.displayTempControl.patchValue(this.tempControl.value + ' ' + this.unit);
    this.changeDetectorRef.markForCheck();
  }

  getValue(value: number) {
    return this.value;
  }

  private initValue() {
    this.maxValue = this.configuration?.additionalInfo?.['max'] ?? '-';
    this.minValue = this.configuration?.additionalInfo?.['min'] ?? '-';
    (!this.chosenValue) ? (this.tempControl.patchValue('-')) : null;
    this.unit = this.configuration?.unit;

    this.displayTempControl.patchValue(this.tempControl.value + ' ' + this.unit);

    (this.configuration.accessRight == AccessRightEnum.AccessRightEnum.R) ? (this.readOnly = true) : (this.readOnly = false);

    if (this.templateMode) {
      this.setValue((this.configuration?.setValue !== undefined) ? (this.configuration?.setValue) : (this.configuration?.additionalInfo['min']));
    }
  }


  send(amount: number) {
    this.tempControl.patchValue(+this.tempControl.value + amount);
    this.subject.next(amount);
  }


  updateValue(amount: number) {
    this.generalSetMode(this.tempControl.value);
    this.displayTempControl.patchValue(this.tempControl.value + ' ' + this.unit);
  }

  manualSetMode() {
    if (+this.tempControl.value < this.minValue) {
      this.tempControl.setValue(this.minValue);
    } else if (+this.tempControl.value > this.maxValue) {
      this.tempControl.setValue(this.maxValue);
    }
    this.displayTempControl.patchValue(this.tempControl.value + ' ' + this.unit);
    this.generalSetMode(this.tempControl.value);
  }

  generalSetMode(mode: number) {
    this.configuration.setValue = mode;
    if (this.configuration?.additionalSources?.length > 0) {
      this.outputValue.next(mode);
      this.configuration?.additionalSources?.forEach((src: EquipmentConfigurationPlugin.Source) => {
        if (src.source !== null) {
          this.outputValue.next({dataId: src.source, operation: mode});
        }
      });
    } else {
      this.outputValue.next(mode);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

