import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SgPaginatorComponent } from './sg-paginator.component';
import {MatPaginatorModule} from "@angular/material/paginator";



@NgModule({
  declarations: [
    SgPaginatorComponent
  ],
  exports: [
    SgPaginatorComponent,
    SgPaginatorComponent
  ],
  imports: [
    CommonModule,
    MatPaginatorModule
  ]
})
export class SgPaginatorModule { }
