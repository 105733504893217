import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subscription, timer} from "rxjs";
import {StationStop} from "src/app/core/models/transportation/station-stops.model";
import {StopInfo} from "src/app/core/models/transportation/stop-info.model";
import {CityWeather} from "src/app/core/models/weather/city-weather.model";
import {ForecastWeather} from "src/app/core/models/weather/forecast-weather.model";
import {TransportationService} from "src/app/core/services/transportation/transportation.service";
import * as _ from 'underscore';
import {WeatherService} from '../../core/services/weather/weather.service';
import {ForecastReading} from "../../core/models/weather/forecast-reading";
import {
  HomeRectangleSkeletonComponent
} from "../../components/loading-skeleton/home/home-rectangle-skeleton/home-rectangle-skeleton.component";

@Component({
  selector: 'app-weather-window',
  templateUrl: './weather-window.component.html',
  styleUrls: ['./weather-window.component.scss']
})
export class WeatherWindowComponent implements OnInit, OnDestroy {
  @Input('isHomePage') isHomePage: boolean = false;
  currentWeather: CityWeather;
  weatherArray: CityWeather[] = [];
  weatherIcon: string;
  forecastReadings: ForecastReading[] = [];

  stationStops: StationStop[] = [];
  stopInfo: StopInfo;

  private refreshTimer: Observable<number> = timer(1800000, 1800000);
  refreshTimerSubscription: Subscription;

  private transportationRefreshTimer: Observable<number> = timer(120000, 120000);
  transportationRefreshTimerSubscription: Subscription;

  isWeatherInfoLoading: boolean = true;
  loadingSkeletonComponent = HomeRectangleSkeletonComponent;

  constructor(private transportationService: TransportationService,
              private weatherService: WeatherService) {
  }


  ngOnInit(): void {
    this.stationStops = this.transportationService.stations;
    this.getWeather();
    this.getWeatherForecast();
    this.getStopsInfo();
    this.subscribeToTimers();
  }

  private getWeather() {
    this.weatherService.getWeatherForAllCities().subscribe((response: CityWeather[]) => {
      this.currentWeather = response[0];
      response.forEach((item: CityWeather, index) => {
        if (index !== 0) this.weatherArray.push(item);
      });
      this.isWeatherInfoLoading = false;
      this.weatherIcon = 'https://openweathermap.org/img/wn/' + this.currentWeather?.weather[0]?.icon + '@2x.png';
    });
  }

  private getWeatherForecast() {
    this.weatherService.getWeatherForecast('Paris,france').subscribe((response: ForecastWeather) => {
      response.list.forEach((item) => {
        item.dt_txt = item.dt_txt.split(" ")[0];
      });
      const groupedForecast = _.groupBy(response.list, 'dt_txt');
      let forecastDays: string[] = Object.keys(groupedForecast);
      let forecastReadings: ForecastReading[] = [];

      for (let i = 1; i < forecastDays.length - 1; i++) {
        const dates: string[] = groupedForecast[forecastDays[i]][0].dt_txt.split('-');
        let reading: ForecastReading = {
          dt_txt: dates[2] + '-' + dates[1],
          dt: groupedForecast[forecastDays[i]][0].dt,
          temp: groupedForecast[forecastDays[i]][0].main.temp.toFixed(0),
          icon: 'https://openweathermap.org/img/wn/' + groupedForecast[forecastDays[i]][0].weather[0].icon + '@2x.png'
        }
        forecastReadings.push(reading);
      }
      this.forecastReadings = forecastReadings;
    });
  }

  private getStopsInfo() {
    this.transportationService.getStopsInfo().subscribe((response: StopInfo) => {
      this.stopInfo = response;
    });
  }

  private subscribeToTimers() {
    this.refreshTimerSubscription = this.refreshTimer.subscribe((tick: number) => {
      this.weatherArray = [];
      this.getWeather();
      this.getWeatherForecast();
    });

    this.transportationRefreshTimerSubscription = this.transportationRefreshTimer.subscribe((tick: number) => {
      this.getStopsInfo();
    });
  }
  ngOnDestroy(): void {
    this.refreshTimerSubscription.unsubscribe();
    this.transportationRefreshTimerSubscription.unsubscribe();
  }
}
