import {ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit} from "@angular/core";
import {PluginControl} from "../plugin-control.abstract";
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

@Component({
  selector: 'display-value-plugin',
  templateUrl: './display-value-plugin.component.html',
  styleUrls: ['./display-value-plugin.component.scss']
})
export class  DisplayValuePluginComponent extends PluginControl implements OnInit, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px bgr-red';
  @Input('configuration') configuration: EquipmentConfiguration;

  status: number;

  message: string = 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.PENDING_VALUE';
  color: string = 'darkgray';

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  setValue(value: number) {
    this.status = value;
    this.renderStatus();
    this.changeDetectorRef.markForCheck();
  }

  getValue(value: number) {
    // Do sth
  }


  ngOnInit(): void {
    this.renderStatus();
  }


  renderStatus() {

    if (typeof this.status === 'string') this.status = Number(this.status);

    if (!isNaN(this.status)) {
      this.message = `${this.status} ${this.configuration?.unit}`;

    } else {
      this.message = 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.PENDING_VALUE';
    };

  }


  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }
}

