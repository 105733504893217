import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MetroCardComponent } from "./metro-card.component";
import { MatIconModule } from "@angular/material/icon";
import {LoadingSkeletonModule} from "../../../components/loading-skeleton/loading-skeleton.module";

@NgModule({
    declarations: [
        MetroCardComponent
    ],

    imports: [
        CommonModule,
        MatIconModule,
        LoadingSkeletonModule
    ],

    exports: [
        MetroCardComponent
    ]
})
export class MetroCardModule {}
