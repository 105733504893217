import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NodesListDialogChipsComponent } from './nodes-list-dialog-chips.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import { PipesModule } from '../../../../../core/pipes/pipes.module';



@NgModule({
  declarations: [
    NodesListDialogChipsComponent
  ],
  exports: [
    NodesListDialogChipsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PipesModule,
    MatChipsModule,
    MatIconModule
  ],
})
export class NodesListDialogChipsModule { }
