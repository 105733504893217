import { Component, Input, OnInit } from '@angular/core';
import { DashboardControl } from '../../../../../core/models/dashboard-controls/dashboard-control';
import {
  EquipmentConfiguration
} from '../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model';
import {
  EquipmentSourceTypeEnum
} from '../../../../../core/models/site-equipments/equipment/equipment/equipment-source-type.enum';
import {
  DeviceOperationEnum
} from '../../../../../core/models/site-equipments/equipment/equipment-configuration/device-operation.enum';


@Component({
  selector: 'gmao-control-value-cell',
  templateUrl: './control-value-cell.component.html',
  styleUrls: ['./control-value-cell.component.scss']
})
export class ControlValueCellComponent implements OnInit {
  @Input('dataSrc') dataSrc: DashboardControl;
  defaultConfiguration: EquipmentConfiguration;

  ngOnInit(): void {
    this.setDefaultConfiguration();
  }

  /**
   * set default configuration,
   * default configuration is the configuration that have the same id in equipmentControlParameter.defaultConfigs[0]
   * @private
   */
  private setDefaultConfiguration() {
    this.defaultConfiguration = this.dataSrc?.equipmentConfigurations?.find((configuration, index)=> {
      const iotCondition = this.dataSrc.equipmentControlParameter?.defaultConfigs?.[0] == configuration.dataId;
      const manualCondition = this.dataSrc.equipmentControlParameter?.defaultConfigs?.[0] == index;
      return iotCondition || manualCondition;
    })
  }
}
