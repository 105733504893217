import { Component, HostBinding, Input, OnInit, SimpleChanges } from '@angular/core';
import {Energy} from "../../../core/models/energy/enums/energy.name-space";

@Component({
  selector: 'gmao-energy-efficiency',
  templateUrl: './energy-efficiency.component.html',
  styleUrls: ['./energy-efficiency.component.scss']
})
export class EnergyEfficiencyComponent implements OnInit {
  @HostBinding('class') rootComponentClasses = 'd-flex flex-column h-100';
  /**
   * Uncomment these lines in order to allow the widget to
   * receive real dynamic data in the future
   * @author AbdulQader
   */
  @Input()
  value: number;
  @Input()
  unit: string;

  // unit: string = Energy.EnergyUnitEnum.KWH + "/m²";



  selectedClass: string = 'a';

  constructor() {}

  ngOnInit(): void {
    this.setRating();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setRating();
  }

  setRating() {
    if(this.value < 50){
      this.selectedClass = 'a';
    } else if(this.value >= 51 && this.value < 90 ){
      this.selectedClass = 'b';
    } else if(this.value >= 91 && this.value < 150){
      this.selectedClass = 'c';
    } else if(this.value >= 151 && this.value < 230){
      this.selectedClass = 'd';
    } else if(this.value >= 231 && this.value < 330){
      this.selectedClass = 'e';
    } else if(this.value >= 331 && this.value < 450){
      this.selectedClass = 'f';
    } else {
      this.selectedClass = 'g';
    }

  }

  readonly Energy = Energy;
}
