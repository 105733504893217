<div class="current-status-plugin-container">
  <section>
    <mat-icon>{{configuration.operation == deviceOperationEnum.CURRENT_STATUS ? 'tune' : 'notification_important'}}</mat-icon>
    <p>{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.STATUS' | translate | capitalize}}</p>
  </section>
  <section>
    <p>{{ configuration.name }}</p>
    <div class="current-status-content">
      <div>{{ message | translate }}</div>
    </div>
  </section>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
