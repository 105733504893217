/**
 * @deprecated
 */
export const POPULATED = '?populate=true';
/**
 * @deprecated
 */
export const CSV = 'csv';
/**
 * @deprecated
 */
export const EXPORT_CSV = 'export-csv/';
/**
 * @deprecated
 */
export const QRCODE = 'qrcode/';
/**
 * @deprecated
 */
export const MAINTENANCE_PATH = 'maintenance';
/**
 * @deprecated
 */
export const BUILDING_EQUIPMENT_PATH = 'building-equipment';

/**
 * @deprecated
 */
export enum EquipmentModelsPaths {
  EQUIPMENT = '/equipments/',
  EQUIPMENT_BY_MODEL_ID = '/equipments/by-model/',
  EQUIPMENT_CHARACTERISTICS = '/characteristics/',
  EQUIPMENT_TYPE = '/types/',
  EQUIPMENT_BRANDS = '/brands/',
  EQUIPMENT_COMPONENTS = '/components/',
  EQUIPMENT_CHARACTERISTIC_TYPE = '/characteristic-types/',
  EQUIPMENT_MODEL = '/models/',
  EQUIPMENT_CATEGORY = '/categories/',
  CONFIGURATION = '/equipments/configuration',
  SEARCH = '/equipments/search/'
}

/**
 * @deprecated
 */
export enum EquipmentComponentModelsPaths {
  MODEL = '/equipment-component/models/',
  BRAND = '/equipment-component/brands/',
  TYPE = '/equipment-component/types/',
  CATEGORY = '/equipment-component/categories/'
}

/**
 * @deprecated
 */
export enum EquipmentMaintenancePaths {
  GROUPS = 'groups/',
  LIST = 'lists/',

}
