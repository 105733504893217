import {Injectable} from "@angular/core";
import {DashboardControl} from "../../models/dashboard-controls/dashboard-control";
import {SiteEquipmentModel} from "../../models/site-equipments/site-equipment.model";
import {BehaviorSubject, Observable} from "rxjs";
import * as _ from "underscore";
import {SiteFloor} from "../../models/sites/site-floor";
import {SiteZone} from "../../models/sites/site-zone";
import {TranslateService} from "@ngx-translate/core";
import {EquipmentCategory} from "../../models/site-equipments/equipment/equipment/equipment-category";
import {
  DeviceOperationEnum
} from "../../models/site-equipments/equipment/equipment-configuration/device-operation.enum";
import {
  EquipmentConfiguration
} from "../../models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";
import { EquipmentDataHistory } from '../../models/site-equipments/equipment/additional-info/data-history.model';

@Injectable()
export class ControlsDashboardManagementService {
  private zoneSubject = new BehaviorSubject<{ [_id: string]: SiteZone }>(null);
  zoneObs: Observable<{ [_id: string]: SiteZone }> = this.zoneSubject.asObservable();

  private floorSubject = new BehaviorSubject<{ [_id: string]: SiteFloor }>(null);
  floorObs: Observable<{ [_id: string]: SiteFloor }> = this.floorSubject.asObservable();

  private selectedFloorSubject = new BehaviorSubject<SiteFloor>(null);
  selectedFloorObs: Observable<SiteFloor> = this.selectedFloorSubject.asObservable();

  constructor(private translateService: TranslateService) {
  }

  setZoneMap(siteZones: SiteZone[]) {
    let locationMap = _.indexBy(siteZones, '_id');
    this.zoneSubject.next(locationMap);
  }

  setFloorsMap(floors: SiteFloor[]) {
    let floorsMap = _.indexBy(floors, '_id');
    this.floorSubject.next(floorsMap);
  }

  setSelectedFloor(floorId: SiteFloor) {
    this.selectedFloorSubject.next(floorId);
  }

  convertEquipmentToControl(equipment: SiteEquipmentModel): DashboardControl {
    let lang: string = this.translateService.currentLang;
    let category: EquipmentCategory = equipment.equipment.equipmentModel.type.category
    return {
      zoneId: equipment.localization,
      zone: "",
      equipmentConfigurations: equipment.equipmentConfigurations,
      equipmentId: equipment.equipmentId,
      equipmentControlParameter: equipment?.equipmentControlParameter,
      floorIndex: equipment.floorIndex,
      floorId: equipment.floorId,
      floor: "",
      category: (category.labelTranslation) ? category.labelTranslation[lang] : category.category,
      id: equipment._id,
      name: equipment.nomenClature,
      status: {status: equipment.status},
      sourceType: equipment.sourceType
    }
  }

  getKeyByValue(object, value: number, withTranslation: boolean = true): string {
    let displayValue: string = '-';
    if (object instanceof Array) {
      let index: number = object.map(obj => obj.operationValue)?.findIndex((val: string) => val == value?.toString());
      const translationKey = 'equipmentConfiguration.' + object[index]?.operationName;
      const translatedText = this.translateService.instant(translationKey);
      displayValue = index != -1 ? translatedText != translationKey ? translatedText : object[index]?.operationName : '-';
    } else {
      let key: string = object && Object?.keys(object)?.find((key: string) => object[key] === value);
      const translationKey = 'equipmentConfiguration.' + key;
      const translatedText = this.translateService.instant(translationKey);
      displayValue = key ? withTranslation ? translatedText != translationKey ? translatedText : key : key : '-';
    }
    return displayValue;
  }

  checkViewValue(configItem: EquipmentConfiguration, value: number) {
    let viewValue: string = '-';
    if (configItem.operation == DeviceOperationEnum.DeviceOperationEnum.POWER) {
      viewValue = this.translateService.instant(this.getKeyByValue(configItem?.additionalInfo, value, false) == "on" ? "GENERAL.ON" : "GENERAL.OFF")
    } else if (
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.MODE ||
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.SWING_POSITION_VERTICAL ||
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.FAN_SPEED ||
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.CURRENT_STATUS ||
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.SWITCHABLE_LIGHT
    ) {
      viewValue = this.getKeyByValue(configItem?.additionalInfo, value);
    } else if (
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.TEMPERATURE_SET_POINT ||
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.CURSOR ||
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.VALUE ||
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.STATUS ||
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.DISPLAY_VALUE
    ) {
      viewValue = (value !== null && value !== undefined) ? `${value} ${configItem.unit}` : '-'
    } else if (
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.SCROLLING_MENU ||
      configItem.operation == DeviceOperationEnum.DeviceOperationEnum.UNIFIED_SCROLLING_MENU
    ) {
      viewValue = this.getKeyByValue(configItem?.additionalInfo, value)
    }
    return viewValue;
  }

  /**
   * Get the manual data value provided date and configuration
   * The returned item is latest value before or on the date
   */
  getManualDataItem(configuration: EquipmentConfiguration, timestamp: number): EquipmentDataHistory {
    const selectedDate = new Date(timestamp);
    selectedDate.setHours(23, 59, 59, 999);
    return configuration?.additionalInfo?.dataHistory ? configuration.additionalInfo.dataHistory.filter((item) => item.date < selectedDate.getTime())
      .reduce((latest, item) => {
        return item.date > latest.date ? item : latest;
      }, { value: null, date: 0 }) : { value: configuration?.additionalInfo?.value ? configuration.additionalInfo.value : configuration.value, date: 0 };
  }

  /**
   * Returns true if value in sla range
   * configuration.additionalInfo.min < value < configuration.additionalInfo.max
   * @param configuration
   * @param value
   */
  isManualDataInRange(configuration: EquipmentConfiguration, value: number) {
    const min = configuration.additionalInfo.min;
    const max = configuration.additionalInfo.max;
    return value > min && value < max;
  }
}
