import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-sg-paginator',
  templateUrl: './sg-paginator.component.html',
  styleUrls: ['./sg-paginator.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class SgPaginatorComponent implements OnInit {
  @Input('pageSize') pageSize: number = 25;
  @Input('totalItems') totalItems: number = 0;
  @Input('paginatedPageSizes') paginatedPageSizes: number[] = [15, 25, 50, 100, 1000];
  @Output('loadPaginateData') loadPaginateData= new EventEmitter<any>()
  constructor() { }

  ngOnInit(): void {
  }

}
