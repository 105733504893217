<div class="br-5px pa-5px sg-bgr-secondary sg-font-primary" style="height:100%; width:100%;">
	<div class="f-row w-100p h-100p f-row a-start j-between pos-relative">

		<div class="w-72p h-100p">
			<highcharts-chart
				[Highcharts]="highcharts"
				class="h-100p w-100p  pos-relative"
				style="display: block;"
				[constructorType]="constructorType"
				[options]="chartOptions"
				(chartInstance)="loadChartInstance($event)">
			</highcharts-chart>
		</div>

		<div class="f-col w-28p h-100p a-start j-start">
			<div *ngFor="let color of colors;let i = index;" class="icons-list f-col a-start h-100p j-start my-3px">
				<div class="wrap">
					<span class="consumption-label custom-txt-16px txt-lightgray">{{labels[i]}}</span>
				</div>
				<div class="f-row space-between">
					<div class="special-icon-width f-row a-center j-center" [style.background]="color">
						<img class="w-80p h-80p" [src]="icons[i]"/>
					</div>
					<div class="consumption-percentage-value custom-txt-16px pl-10px">
						{{percentages[i]}}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


