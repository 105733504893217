export type  ActionType = 'UPDATE' | 'ADD' | 'DELETE';

export const ActionTypeEnum = {
  UPDATE: 'UPDATE' as ActionType,
  ADD: 'ADD' as ActionType,
  DELETE: 'DELETE' as ActionType,
  RELOAD: 'RELOAD' as ActionType,
}

export interface ViewAction<T> {
  type: ActionType,
  data: T
}
