<div class="home-navigation-container" [class.home-navigation-small-gap]="withSideInfo">
  <ng-container
    *skeleton="isLoadingData;repeat: 1; component:loadingSkeletonComponent;height: '50px';width: '100%';">
    <a [routerLink]="['/dashboard']" [routerLinkActive]="['active']">
      <mat-icon>home</mat-icon>
    </a>
    <ng-container
      *ngFor="let item of navLinks"
      [ngTemplateOutlet]="item?.subViews?.length > 0 ? buttonTag : item.path ? linkTag: emptyTag"
      [ngTemplateOutletContext]="{ item: item }"
    ></ng-container>
  </ng-container>
</div>

<ng-template #buttonTag let-item="item">
  <button mat-button [matMenuTriggerFor]="menu">{{item.name}}</button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let subView of item.subViews"
            (click)="redirectTo(subView.path)">{{subView.name}}</button>
  </mat-menu>
</ng-template>
<ng-template #linkTag let-item="item">
  <a [routerLink]="[item.path]" [routerLinkActive]="['active']">
    {{item.name}}
  </a>
</ng-template>
<ng-template #emptyTag></ng-template>
