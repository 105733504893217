<div class="site-equipment-status-container">
  <ng-container [ngSwitch]="status">
    <div *ngSwitchCase="equipmentStatusEnum.CONNECTED" class="circle connected"
         [matTooltip]="('SITE_EQUIPMENTS.CONNECTED_STATUS_TOOLTIP' | translate:{date:dateValue})"></div>
    <div *ngSwitchCase="equipmentStatusEnum.DISCONNECTED" class="circle disconnected"
         [matTooltip]="('SITE_EQUIPMENTS.DISCONNECTED_STATUS_TOOLTIP' | translate:{date:dateValue})"></div>
    <ng-container *ngIf="!isControl">
      <div *ngSwitchCase="equipmentStatusEnum.DISABLED" class="circle disabled"
           [matTooltip]="disabledTooltip | translate:{date:dateValue}"
      ></div>
    </ng-container>
  </ng-container>
</div>
