import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {SideBarType} from '../../../core/services/sidebar/sidebar-type.enum';
import {SideBarService} from '../../../core/services/sidebar/sidebar.service';
import {AuthenticationsService} from "../../../core/services/auth/authenticationsService";

@Component({
  selector: 'smart-globe-bar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  sideBarTypeObservable: Observable<SideBarType>;
  sideBarType: SideBarType;
  sideBarTypeEnum = SideBarType;
  loading: boolean = true;
  logged = false;

  constructor(
    private auth: AuthenticationsService,
    private sideBarService: SideBarService
  ) {


  }

  ngOnInit() {
    this.loading = true;
    this.sideBarTypeObservable = this.sideBarService.getSideBarTypeObservable();
    this.auth.isAuthenticatedObs.subscribe((res: boolean) => {
      if (res) {
        this.logged = true;
        this.sideBarTypeObservable.subscribe((SideBarType: SideBarType) => {
          this.sideBarType = SideBarType;
          this.loading = false;

        });
      } else {
        this.loading = false;
      }
    })
  }
}
