import {AfterViewInit, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import {BehaviorSubject, Subscription} from "rxjs";
import {BuildingEquipmentService} from "src/app/core/deprecated/deprecated-services/deprecated-equipment/building-equipment.service";
import {TimelineService} from "src/app/components/dashboards/dashboard-timeline/timeline.service";
import {ItemTimelineManager} from "src/app/components/gmao-gridster/gridster-item/item-timeline-manager.abstract";
import {TesseractService} from "src/app/core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service";
import {GridsterChartDialog} from "src/app/components/gmao-gridster/gridster-item/gridster-chart-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ThemeService} from "src/app/core/services/theme/theme.service";
import {Router} from "@angular/router";
import {EquipmentD3Service} from "../../../core/services/sites-equipments/equipment-d3-.service";
import {EquipmentConfiguration} from "../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";
import {CreateEquipmentSvgParameters} from "../../../core/view-models/d3-svg/create-equipment-svg-parameters";
import {RefreshSvgParameters} from "../../../core/view-models/d3-svg/refresh-svg-parameters";
import {LocalStorageService} from 'src/app/core/services/localstorage/local-storage.service';
import {LocalStorageEquipmentKeysEnum} from "../../../core/services/localstorage/local-storage-keys.enum";
import {SiteEquipmentModel} from "../../../core/models/site-equipments/site-equipment.model";

@Component({
  selector: 'equipment-scada-page',
  templateUrl: './equipment-scada-dashboard.component.html',
  styleUrls: ['./equipment-scada-dashboard.component.scss']
})
export class EquipmentScadaDashboardComponent extends ItemTimelineManager implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'd-flex flex-column h-100';
  svgLoadingSubject = new BehaviorSubject<boolean>(false);
  isReady: boolean = false;
  plotData: any[] = [];
  buildingEquipmentControl = new FormControl(null);
  selectedBuildingEquipment: SiteEquipmentModel;
  buildingEquipmentArray: SiteEquipmentModel[] = [];
  equipmentConfigurations: EquipmentConfiguration[] = [];
  subscriptions: Subscription = new Subscription();
  theme;
  buildingId: string;
  pageIsLoadingSubject = new BehaviorSubject<boolean>(true);
  scadaConfigsLabels = 'scadaConfigsLabels';
  subscribeClickLabel: Subscription = null;

  constructor(
    private buildingEquipmentService: BuildingEquipmentService,
    protected timelineService: TimelineService,
    private tesseractService: TesseractService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private themeService: ThemeService,
    private equipmentD3Service: EquipmentD3Service,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
    super(timelineService);
  }

  ngOnInit(): void {
    this.loadPageBuildingEquipment();
    this.theme = this.themeService.getActiveTheme();
    this.subscribeSelectConfiguration();
  }

  private subscribeSelectConfiguration(){
    this.subscribeClickLabel = this.equipmentD3Service.selectConfigurationSubject.subscribe(data=>{
      this.openDialog(data);
    })
  }

  ngAfterViewInit(): void {
    this.subscribePageLoading()
  }

  private loadPageBuildingEquipment() {
    this.buildingId = this.router.url.split('/')[1];
    if (this.buildingId) {
      this.buildingEquipmentService.getBuildingEquipmentByBuildingId(this.buildingId, true, false).subscribe((equipment: SiteEquipmentModel[]) => {
        this.buildingEquipmentArray = equipment;
        this.pageIsLoadingSubject.next(false)
      });
    }
  }

  private subscribePageLoading() {
    this.pageIsLoadingSubject.subscribe((loading) => {
      if (loading) return
      this.checkLocalStorage();
    })
  }


  /**
   * This method is responsible for either retrieving a previously selected
   * BuildingEquipment from LS, or setting a new one.
   */
  private checkLocalStorage() {
    let lastScadaId = this.localStorage.getLocalStorageStringItem(LocalStorageEquipmentKeysEnum.SCADA_BUILDING_ID);
    let selectedBuildingEquipment = this.buildingEquipmentArray.find(ele => ele._id == lastScadaId);
    let initSelectId: string = selectedBuildingEquipment?._id ?? this.buildingEquipmentArray[0]?._id;
    this.buildingEquipmentControl.setValue(initSelectId);
    if (!initSelectId) return
    this.selectBuildingEquipment(initSelectId);
  }


  selectBuildingEquipment(equipmentId: string) {
    this.subscriptions.unsubscribe();
    this.svgLoadingSubject.next(true);
    this.isReady = false;
    this.selectedBuildingEquipment = this.buildingEquipmentArray.find(ele => ele._id == equipmentId);
    this.equipmentConfigurations = this.selectedBuildingEquipment?.equipmentConfigurations as EquipmentConfiguration[];


    let temporaryImagPath = this.selectedBuildingEquipment?.scadaPhoto[0]?.path
    let parameters: CreateEquipmentSvgParameters = {
      loadingSubject: this.svgLoadingSubject,
      imageId: this.selectedBuildingEquipment?.scadaPhoto[0]?.['_id'],
      imagPath: temporaryImagPath,
      draggable: false,
      defaultConfigProperty: 'unit',
      svgWrapperId: 'scadaViewSvg',
      labelsItem: this.equipmentD3Service.createLabelsListFromEquipmentConfigurations(this.equipmentConfigurations),
      labelsUniqueGroupId: this.scadaConfigsLabels
    }
    this.equipmentD3Service.createScadaConfigurationsSvg(parameters)
    this.isReady = true;
    this.localStorage.setItem(LocalStorageEquipmentKeysEnum.SCADA_BUILDING_ID, this.selectedBuildingEquipment._id);
    this.refreshItem();
    this.loadDataEvent.next(true);
  }


  openDialog(configObject: EquipmentConfiguration): void {
    var dataToPlot = [];

    if (this.plotData[configObject._id] &&
      this.plotData[configObject._id].length > 0) {

      this.plotData[configObject._id].forEach(function (item) {
        dataToPlot.push({
          x: item.time,
          y: item.value
        });
      });

      const dialogRef = this.dialog.open(GridsterChartDialog, {
        width: '650px',
        height: '400px',
        panelClass: 'formFieldWidth480',
        data: {
          type: configObject.operation,
          description: configObject.name,
          unit: configObject.unit,
          data: dataToPlot
        }
      });
    }
  }


  /**
   * Returns a clojure that takes start and end dates for retrieving the data associated to dataId
   * @param dataId
   */
  protected createDataLoader(dataId): Function {
    let self = this;
    return function (startDate: Date, endDate: Date) {
      return self.tesseractService.getData(dataId, startDate, endDate);
    };
  }

  /**
   * Implemented only to satisfy Inheritance.
   * @param data
   * @returns data
   */
  protected transformData(data: any[]) {
    return data;
  }

  /**
   * @override
   */
  protected loadData() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    let isDataComplete = function () {
      return false;
    };
    if (this.isReady) {
      this.equipmentConfigurations.forEach((config: EquipmentConfiguration) => {

        let dataLoader = this.createDataLoader(
          config.dataId
        );
        let pollerSub = this.addNewPoller(
          dataLoader,
          this.transformData,
          isDataComplete)
          .subscribe(data => {
            this.plotData[config._id] = data;
            this.refreshItem();
          });

        this.subscriptions.add(pollerSub);
      });
    }
  }

  /**
   * @override
   */
  protected refreshItem() {
    let refreshSvgParameters: RefreshSvgParameters = {
      equipmentConfigurations: this.equipmentConfigurations,
      plotData: this.plotData,
      currentTime: this.currentTime,
      changeDetectorRef: this.changeDetectorRef,
      labelsUniqueGroupId: this.scadaConfigsLabels
    }
    this.equipmentD3Service.refreshD3Labels(refreshSvgParameters)
  }

  ngOnDestroy(): void {
    this.subscribeClickLabel?.unsubscribe();
    this.unsubscribeTimeline();
    this.subscriptions.unsubscribe();
  }


}
