import {Injectable} from "@angular/core";
import {TranslatedToasterService} from "../translated-toaster/translated-toaster.service";
import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";

@Injectable({providedIn: 'root'})
export class ErrorHandlerService {
  constructor(private toaster: TranslatedToasterService) {
  }

  handleError = (error: HttpErrorResponse) => {
    let errorCode = 'ERROR_OCCURRED';
    let customError = error.headers.get('content-type') == 'text/plain' && +error.headers.get('content-length') > 0 && !error.error.includes('404');
    if (customError) errorCode = error.error;
    else if (error.error?.error) errorCode = <string>error.error?.error?.split(' ').join('_').toUpperCase() ?? '';
    this.toaster.showErrorCodeMessage(errorCode);
    return throwError(error);
  }

}
