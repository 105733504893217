import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SgTableComponent} from './sg-table.component';
import {MatTableModule} from "@angular/material/table";

import {MatIconModule} from "@angular/material/icon";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";

import {SgTableActionsCellComponent} from "./sg-table-actions-cell/sg-table-actions-cell.component";
import {SGInjectCellDirective} from "./sg-table-actions-cell/inject-cell.directive";
import {RectSkeletonModule} from "./rect-skeleton/rect-skeleton.module";
import {SgPaginatorModule} from "./sg-paginator/sg-paginator.module";
import {TranslateModule} from "@ngx-translate/core";
import {PipesModule} from "../../../core/pipes/pipes.module";
import {MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from "@angular/material/menu";


@NgModule({
  declarations: [
    SgTableComponent,
    SgTableActionsCellComponent,
    SGInjectCellDirective
  ],
  exports: [
    SgTableComponent,
    SgTableActionsCellComponent,
    SGInjectCellDirective
  ],
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatSortModule,
        MatPaginatorModule,
        RectSkeletonModule,
        SgPaginatorModule,
        PipesModule,
        TranslateModule,
        MatRippleModule,
        MatTooltipModule,
        MatMenuModule,
    ]
})
export class SgTableModule {
}
