import {NgModule} from "@angular/core";
import {SkeletonDirective} from "./skeleton-directive.directive";

@NgModule({
  declarations: [SkeletonDirective],
  exports: [SkeletonDirective]
})
export class LoadingSkeletonModule {

}
