
import { CommonModule } from '@angular/common';
import { SidebarsToolbarComponent } from './sidebars-toolbar.component';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { PipesModule } from '../../../../core/pipes/pipes.module';

@NgModule({
  declarations: [SidebarsToolbarComponent],
  exports: [SidebarsToolbarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatExpansionModule,
    MatMenuModule,
    PipesModule

  ]
})
export class SidebarsToolbarModule { }
