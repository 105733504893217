<div class="container">
  <div class="sidebar bxs-black">
    <div class="logo">
      <img
        id="client-logo-standard"
        src="../../../assets/clients-logos/saclay/paris-saclay-light.png"
        alt="Paris-Saclay-Logo"
      />
    </div>

    <div class="logo-round">
      <img
      class="w-60p"
        id="client-logo-round"
        src="assets/paris-saclay-round.png"
        alt="Paris-Saclay-Logo-Round"
      />
    </div>

    <div class="text">
      <div class="squarebrackets custom-txt-22px">
        Le tableau de bord des données d’énergies vous informe en temps réel des évolutions des consommations et de la performance énergétique du siège. Vous êtes les premiers acteurs de nos économies d’énergies, on compte sur vous !
      </div>
    </div>

    <div class="forecast f-row a-center j-center pa-10px">
      <iframe class="w-100p h-100p" scrolling="no" style="border: 0;" src="https://meteofrance.com/widget/prevision/914710"> </iframe>
    </div>
  </div>

  <div class="widgets">
    <div class="title custom-txt-27px">{{ title }}</div>
    <div class="widgets-container">
      <!-- Top Row: AreaChart + PieChart -->
      <div class="top-row">
        <div class="graph bxs-black br-5px mr-15px">
          <area-chart-widget class="w-100p h-100p sg-bgr-primary pos-relative br-5px"></area-chart-widget>
        </div>
        <div class="pie bxs-black br-5px">
          <gmao-pie-consumption-sources-widget class="w-100p h-100p sg-bgr-primary pos-relative br-5px"></gmao-pie-consumption-sources-widget>
        </div>
      </div>


      <!-- Bottom Row: Energy Efficiency + Env. Impact + Energy Prod. -->
      <div class="bottom-row">
        <div class="w-49p f-row a-center j-center sg-bgr-primary bxs-black br-5px">
          <gmao-energy-efficiency-evo></gmao-energy-efficiency-evo>
        </div>

        <!-- Environmental Impact Widget -->
        <div class="w-50p h-100p f-row a-start j-center sg-bgr-primary bxs-black br-5px">
          <gmao-env-impact></gmao-env-impact>
        </div>
      </div>
      <div class="footer">
        <img style="mix-blend-mode: multiply;" src="/src/assets/default-logos/smartglobe/Smartglobe.svg" alt="Smart-Globe-Logo"/>
      </div>
    </div>
  </div>
</div>
