<div class="container">
  <div class="header">
    <div class="primary-text">{{ "envImpact.title" | translate | uppercase }}</div>
    <div class="secondary-text">{{ "envImpact.subtext" | translate | uppercase }}</div>
  </div>
  <div class="emissions">
    <div class="gas-emissions">{{ "envImpact.gasEmissions" | translate | uppercase }}</div>
    <div class="classes-energies">
      <div [ngClass]="{'active': selectedClass === 'a'}" class="classe-energie classe-ges-A">A</div>
      <div [ngClass]="{'active': selectedClass === 'b'}" class="classe-energie classe-ges-B">B</div>
      <div [ngClass]="{'active': selectedClass === 'c'}" class="classe-energie classe-ges-C">C</div>
      <div [ngClass]="{'active': selectedClass === 'd'}" class="classe-energie classe-ges-D">D</div>
      <div [ngClass]="{'active': selectedClass === 'e'}" class="classe-energie classe-ges-E">E</div>
      <div [ngClass]="{'active': selectedClass === 'f'}" class="classe-energie classe-ges-F">F</div>
      <div [ngClass]="{'active': selectedClass === 'g'}" class="classe-energie classe-ges-G">G</div>
    </div>
    <p class="gasReading">
      <b>{{ value }}</b> {{ unit }}
    </p>
  </div>
  <div class="f-row">

    <div class="stats-container">

      <div class="travel-distance-container">
        <p class="equals-new">{{ "envImpact.equalsTo" | translate | uppercase }}</p>
        <p class="distance-new">{{ distanceDisplay }} Km</p>
        <p style="font-size: 1.3vh;">{{ "envImpact.traveled" | translate | uppercase }}</p>
      </div>

      <div class="rotations-container">
        <p class="equals-new">{{ "envImpact.around" | translate | uppercase }}</p>
        <p class="distance-new">{{ rotationsDisplay }}</p>
        <p style="font-size: 1.3vh;">{{ "envImpact.tour" | translate | uppercase }}</p>
      </div>

    </div>

    <div class="globe">
      <img class="globe-img" src="../../../../assets/icon_globe.svg" alt="icon_globe.svg" />
    </div>
  </div>
</div>
