import {Component, OnDestroy} from '@angular/core';
import {ItemTimelineManager} from "../../gmao-gridster/gridster-item/item-timeline-manager.abstract";
import {Subscription} from "rxjs";
import {PolledData} from "../../../core/models/tesseract/polledData";
import {TimelineService} from "../../dashboards/dashboard-timeline/timeline.service";
import {
  TesseractService
} from "../../../core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service";
import {
  EquipmentConfiguration
} from "../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";
import {
  DeviceOperationEnum
} from '../../../core/models/site-equipments/equipment/equipment-configuration/device-operation.enum';

@Component({
  selector: 'gmao-control-parent-plugin',
  template: ``,
})
export abstract class ControlParentPluginComponent extends ItemTimelineManager implements OnDestroy {
  pollersSubscription: Subscription;
  plotData: PolledData[];
  defaultConfiguration: EquipmentConfiguration;
  private value: number;
  private unit: string;

  protected isManual: boolean

  constructor(
    protected timelineService: TimelineService,
    protected tesseractsService: TesseractService,
  ) {
    super(timelineService);
  }

  abstract setDisplayValue();

  /**
   * create new poller for each control using default configuration dataId
   * each control has a default configuration and it's represented using configuration dataId
   * @protected
   */
  protected loadData() {
    let isDataComplete = () => false;
    let dataLoader = this.createDataLoader(this.defaultConfiguration.dataId);
    this.pollersSubscription = this.addNewPoller(dataLoader, this.transformData, isDataComplete).subscribe((data: PolledData[]) => {
      this.plotData = data;
      this.refreshItem();
    });

  }

  /**
   * Override
   * This method to update controls with new polled value
   * take the last polled value for each configuration, and get unit from configuration then concat value with unit
   * @protected
   */
  protected refreshItem() {
    if (this.defaultConfiguration.dataId && this.plotData.length > 0) {
      let beforeItems: PolledData[] = this.plotData.filter((item: PolledData) => (+item.time - this.currentTime.getTime()) < 0);
      let value: number = beforeItems !== null && beforeItems.length > 0 ?
        beforeItems[beforeItems.length - 1]?.value :
        this.plotData[this.plotData.length - 1].value;

      if (!isNaN(value - parseFloat(value?.toString()))) {
        value = Math.round(value * 100) / 100;
      }
      this.setValue(value);
      this.setUnit(this.defaultConfiguration?.unit);
      this.setDisplayValue();
    } else if(this.isManual) {
      this.setDisplayValue();
    }
  }

  /**
   * This method used to create data loader from tesseracts using configuration dataId
   * Returns a clojure that takes start and end dates for retrieving the data associated to dataId
   * @param dataId
   * @protected
   */
  protected createDataLoader(dataId): Function {
    let self = this;
    return function (startDate: Date, endDate: Date) {
      return self.tesseractsService.getData(dataId, startDate, endDate);
    };
  }

  protected transformData = (data: PolledData[]) => data;

  getValue(): number {
    return this.value;
  }

  setValue(value: number) {
    this.value = value;
  }

  getUnit(): string {
    return this.unit;
  }

  setUnit(unit: string) {
    this.unit = unit;
  }

  ngOnDestroy() {
    this.pollersSubscription?.unsubscribe();
    this.unsubscribeTimeline();
  }
}
