<div class="rootClass h-100p w-100p">
  <div class="efficiency-evo-container">

    <div class="header">
      <div class="title">{{ "energyEfficiency.title" | translate | uppercase }}</div>
      <div class="unit">En {{ Energy.EnergyUnitEnum.KWH }}/m²</div>
    </div>

    <div class="content">

      <div class="energy-bars-column">
        <div
          class="bar"
          *ngFor="let bar of energyBars; let index = index"
        >

          <div class="polygon-container"
            [ngStyle]="{'width': bar.width + '%'}"
            [ngClass]="{'polygon-shadow': selectedBar[index]}"
            >
            <div
              class="right-polygon"
              [ngStyle]="{
                'background-color': bar.background,
                'color': (selectedBar[index]) ? 'black' : 'white'
              }"
            >
              {{ bar.label }}
            </div>

          </div>

          <div
            [ngStyle]="{
              'height': (selectedBar[index]) ? '3px' : '1.5px',
              'background-color': (selectedBar[index]) ? 'grey' : '#E6E6E6'
            }"
            class="separator-line"></div>
        </div>

      </div>

      <div class="readings-column">

        <div class="cont-bar" *ngFor="let bar of energyBars; let index = index">
          <div
            *ngIf="selectedBar[index]; else separator_line"
            class="left-polygon">
            <div class="indicator-content">
              <div class="f-row a-center j-center value">
                {{ value }}
              </div>
              <div class="unit">
                {{ unit }}
              </div>
            </div>
          </div>
          <ng-template #separator_line>
            <div class="separator-line"></div>
          </ng-template>

        </div>

      </div>

    </div>


  </div>
</div>
