import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SitesEquipmentsPaginationModel} from "../../models/site-equipments/site-equipments-pagination";
import {Observable} from "rxjs";
import {SiteEquipmentDto} from "../../models/site-equipments/site-equipment-dto";
import {catchError, map} from "rxjs/operators";
import {SiteEquipmentModel} from "../../models/site-equipments/site-equipment.model";
import {SgTablePaginationResult} from "../../../components/sg-components/sg-table/sg-table-pagination-result";
import {SitesEquipmentsPaginationResult} from "../../models/site-equipments/sites-equipments-pagination-result";
import {SitesEquipmentsManagementService} from './sites-equipments-management.service';
import {EquipmentCategory} from "../../models/site-equipments/equipment/equipment/equipment-category";
import {ErrorHandlerService} from "../error-handler/error-handler.service";
import {SiteDto} from "../../models/sites/siteDTO";
import {AuthenticationsService} from "../auth/authenticationsService";
import {ControlsFiltrationModel} from "../../models/dashboard-controls/controls-filtration-model";
import {SiteEquipmentMultipleUpdate} from "../../models/site-equipments/site-equipment-multiple-update";

@Injectable({providedIn: 'root'})
export class SitesEquipmentsService {
  private baseUrl = `api/sg-equipment/`;
  private sitesEquipmentsPath = `building-equipment/`;
  private sitesEquipmentsCategoriesPath = `categories/`;
  private sitesEquipmentsQrCodePath = `qrcode/`;


  private getBuildingEquipmentsList = 'list';

  constructor(private http: HttpClient,
              private managementService: SitesEquipmentsManagementService,
              private errorHandlerService: ErrorHandlerService,
              private authService: AuthenticationsService) {
  }

  /**
   * categoriesList is temporary and the response restructuring also
   * @param paginationModel
   * @param sitesList
   * @param paginated
   */
  getSitesEquipmentsList(
    paginationModel: SitesEquipmentsPaginationModel,
    sitesList: SiteDto[],
    paginated?: boolean
  ): Observable<SgTablePaginationResult<SiteEquipmentDto>> {
    const filters = {
      buildingsIds: paginationModel.buildingsIds,
      categoriesIds: paginationModel.categoriesIds,
      nomenClature: paginationModel.nomenClature,
      localization: paginationModel.localization
    };

    const body = {
      filters: filters,
      paginate: paginated ? {
        size: paginationModel.pageSize,
        index: paginationModel.pageIndex
      } : undefined,
      populate: true
    };

    const url = `${this.baseUrl}${this.sitesEquipmentsPath}${this.getBuildingEquipmentsList}`;

    return this.http.post<SitesEquipmentsPaginationResult>(url, body).pipe(
      map((data: SitesEquipmentsPaginationResult) => {
        const sites: SiteEquipmentDto[] = data.buildingEquipments.map(ele =>
          this.managementService.convertEquipmentToDTO(ele, sitesList)
        );
        return { Items: sites, Total: data.count };
      })
    );
  }
  getSitesEquipments(buildingsIds: string[]): Observable<SiteEquipmentModel[]> {
    const body = {
      filter: {
        buildingsIds: buildingsIds,
        categoriesIds: [], nomenClature: '',
        localization: []
      },
      populate: true
    }

    const url = this.baseUrl + this.sitesEquipmentsPath + this.getBuildingEquipmentsList;
    return this.http.post<SitesEquipmentsPaginationResult>(url, body).pipe(catchError(this.errorHandlerService.handleError),
      map((data: SitesEquipmentsPaginationResult) => {
        return data.buildingEquipments
      })
    );
  }


  getSitesEquipmentsCategories(filterString: string): Observable<EquipmentCategory[]> {
    let params = new HttpParams().append('pattern', filterString);
    const url = this.baseUrl + this.sitesEquipmentsCategoriesPath;
    return this.http.get<EquipmentCategory[]>(url, {params: params}).pipe(catchError(this.errorHandlerService.handleError))
  }

  getSiteEquipmentById(siteEquipmentId: string): Observable<SiteEquipmentModel> {
    const url = this.baseUrl + this.sitesEquipmentsPath + siteEquipmentId;
    let params = new HttpParams();
    params = params.append('populate', 'true')
    return this.http.get<SiteEquipmentModel>(url, {params}).pipe(catchError(this.errorHandlerService.handleError))
  }

  getSiteEquipmentQrCode(siteEquipmentId: string): Observable<string> {
    let url = this.baseUrl + this.sitesEquipmentsPath + this.sitesEquipmentsQrCodePath + siteEquipmentId;
    return this.http.get<string>(url).pipe(catchError(this.errorHandlerService.handleError))

  }

  /**
   * temp , this api is not adding an ID to the retrieved object
   * @param newSiteEquipment
   */
  createNewSiteEquipment(newSiteEquipment: SiteEquipmentModel): Observable<SiteEquipmentModel> {
    let url = this.baseUrl + this.sitesEquipmentsPath;
    return this.http.post<SiteEquipmentModel>(url, newSiteEquipment).pipe(catchError(this.errorHandlerService.handleError))
  }


  updateSiteEquipment(siteEquipment: SiteEquipmentModel): Observable<SiteEquipmentModel> {
    let url = this.baseUrl + this.sitesEquipmentsPath + siteEquipment._id;
    return this.http.patch<SiteEquipmentModel>(url, siteEquipment).pipe(catchError(this.errorHandlerService.handleError))
  }


  getEquipmentsControlsList(filtrationModel: ControlsFiltrationModel): Observable<SitesEquipmentsPaginationResult> {
    let filters = {
      buildingsIds: [filtrationModel.siteId],
      categoriesIds: [filtrationModel.categoryId],
      localization: filtrationModel.zone
    }
    let params: HttpParams = new HttpParams()
      .append("populate", 'true')
      .append("filter", JSON.stringify(filters));
    filtrationModel.floorId && (params = params.append("floorId", filtrationModel.floorId));

    const url = this.baseUrl + this.sitesEquipmentsPath;
    return this.http.get<SitesEquipmentsPaginationResult>(url, {params}).pipe(map((data: SitesEquipmentsPaginationResult) => {
        return data;
      })
    );
  }

  createEquipmentCategory(categoryName: string): Observable<EquipmentCategory> {
    let url = this.baseUrl + this.sitesEquipmentsCategoriesPath;
    return this.http.post<EquipmentCategory>(url, {category: categoryName});
  }

  applyDataToManyEquipment(data: SiteEquipmentMultipleUpdate[]): Observable<SiteEquipmentModel[]> {
    let url = this.baseUrl + this.sitesEquipmentsPath;
    return this.http.patch<SiteEquipmentModel[]>(url, data);
  }
}
