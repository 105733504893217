import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {SgTableModel} from './sg-table.model';
import {RectSkeletonComponent} from './rect-skeleton/rect-skeleton.component';
import {TranslatedToasterService} from '../../../core/services/translated-toaster/translated-toaster.service';
import {SgTablePaginationModel} from './sg-table-pagination.model';
import {SelectedTableRowEvent} from './selected-table-row-event';
import {SgTableDataFiltrationModel} from './sg-table-data-filtration.model';
import {environment} from '../../../../environments/environment';
import {SgTableUpdateEvent} from './sg-table-update-event';
import {rowAnimations} from './row-animation';
import {SgTablePaginationResult} from './sg-table-pagination-result';
import {SgTableLocalSortModel} from './sg-table-local-sort-model';
import {DashboardControl} from '../../../core/models/dashboard-controls/dashboard-control';


@Component({
  selector: 'sg-table',
  templateUrl: './sg-table.component.html',
  styleUrls: ['./sg-table.component.scss'],
  animations: rowAnimations,
  encapsulation: ViewEncapsulation.None
})
export class SgTableComponent implements OnInit, AfterViewInit {
  rowAnimations = rowAnimations;
  /**
   * The unique attribute filed of the ITEM that the table CRUD operations based on.
   * ex , uniqueKeyOperation = id : the CRUD operation for the selected ITEM will search in the data and do the operation on the item depending on its unique id .
   */
  @Input('uniqueKeyOperation') uniqueKeyOperation: string = 'id';
  /**
   * The unique attribute filed of the ITEM that the table CRUD operations based on.
   * ex , uniqueKeyOperation = id : the CRUD operation for the selected ITEM will search in the data and do the operation on the item depending on its unique id .
   */
  @Input('dateFormat') dateFormat: string = environment.applicationViewDateFormat;
  /**
   * the table columns wanted to define , the columns type can be string or action columns
   * the string columns is using the attributeKey property to get the required data from the iterated object
   * the action columns is using to inject the actionComponent of the model , each action component should take a
   * case-sensitive dataSrc as Input , to encapsulate the actions for the passed dataSrc.
   * @requires ngOnInit
   */
  @Input('columns') columns: SgTableModel[] = [];
  /**
   * the array of the columns names wanted to view
   * @requires ngOnInit
   */
  @Input('renderedColumns') renderedColumns: string[] = [];
  /**
   * the key to access the records for filtering the table.
   * used in getAllDataObs case .if the data is BE paginated this key should be added to the pagination model and use the paginationObservable nested of getAllDataObs.
   * @see getAllDataObs
   * @see paginationObservable
   * @see paginationModel
   */
  @Input('filterKey') filterKeys: string[] = [];
  /**
   * the allowed pagination sizes options to select the size of the viewed records
   * @requires paginationObservable
   */
  @Input('paginatedPageSizes') paginatedPageSizes: number[] = [15, 25, 50, 100, 200];
  /**
   * selected page size
   * @requires paginationObservable
   */
  @Input('pageSize') pageSize: number = 25;
  /**
   * the number of skeleton rows to show when the table is loading the data
   * @see setSkeletonConfig
   */
  @Input('skeletonRows') skeletonRows: number = 15;
  /**
   * use this observable to load all the data form the data src / can be a predefined list in the obs result or can be a http request obs
   */
  @Input('getAllDataObs') getAllDataObs: (filtrationModel?: SgTableDataFiltrationModel) => Observable<any>;
  /**
   * this model holds the HTTP params keys needed for the filtration process.
   * initially This model has not keys. if another keys needed , a new interface extends the  SgTableDataFiltrationModel should be created with the extra keys.
   * This component will not update the model, the model should be updated form the parent component. the new updates will reach this component by applying the
   * pass by reference concept .
   * If wanted to update the view after updating this model the reloadCurrentView method should be called
   * @see reloadCurrentView
   */
  @Input('filtrationModel') filtrationModel: SgTableDataFiltrationModel;
  /**
   * use this observable to load a paginated data from the database that's take SgTablePaginationModel as parameter.
   * each ITEM service function will take a SgTablePaginationModel as parameter, then the service should extract the keys needed for a correct HTTP request params.
   * each service can talke an extended model form the SgTablePaginationModel to add more params to the object if another HTTP params needed.
   * in pagination process the sg-table will only change the page index key value of the pagination model , the other params will be updated form
   * the parent page and send to the ITEM service.
   * @requires paginationModel
   */
  @Input('paginationObservable') paginationObservable: (paginationModel: SgTablePaginationModel) => Observable<SgTablePaginationResult<any>>;

  /**
   * this model should be used in the BE paginated data , it should hold the HTTP params keys needed for the filtration and pagination process.
   * initially This model holds the basics pagination keys . if another keys needed , a new interface extends the  SgTablePaginationModel should be created with the extra keys.
   * This component will update only the page index value , other values should be updated form the parent component. the new updates will reach this component by applying the
   * pass by reference concept .
   * If wanted to update the view after updating this model the reloadCurrentView method should be called
   * @see reloadCurrentView
   */
  @Input('paginationModel') paginationModel: SgTablePaginationModel;

  @Input('localPaginating') localPaginating: boolean = false;
  @Input('rowSelection') rowSelection: boolean = true;
  @Input('animatedRows') animatedRows: boolean = true;
  @Output('rowSelected') rowClickedEmitter = new EventEmitter<SelectedTableRowEvent<any>>();
  /**
   * This input to tell table we need local sorting, it should use with getAllDataObs
   * @see SgTableComponent.getAllDataObs
   * @see SgTableComponent.sortByField
   */
  @Input('isLocalSort') isLocalSort: boolean = false;

  /**
   * a subscriber for the changes in the filtering string to filter the table according the passed filtering key
   * @requires filterKeys
   * @param filterString
   */
  @Input() set filterString(filterString: string) {
    if (this.filterKeys.length == 0) {
      throw new Error('Missing component Input  : filterKeys input , The filtration will be done depending on specific attributes representing by the filterKeys input');
    }
    if (!this.dataList) {
      return;
    }
    if (filterString.length == 0) {
      this.viewedDataSrc = [...this.dataList];
      this.updateTableView();
    }
    this.filteringObs$.next(filterString);
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  /**
   * the filtered records list
   */
  viewedDataSrc: any[] = [];
  /**
   * the columns that preview a text in the table and use the attributeKey to access the dataSrc object
   */
  stringColumns: SgTableModel[] = [];
  /**
   * the columns that hold the action component to inject
   */
  actionsComponentColumns: SgTableModel[] = [];
  dataSrc: MatTableDataSource<any>;
  tableIsLoading = true;
  /**
   * these next variables used for set the configuration of the skeleton view while loading and
   * fetching the paginated data to the table.
   * they build in the same idea of the above  columns and renderedColumns Input
   * @see columns
   * @see renderedColumns
   */
  skeletonRenderedColumns: string[] = [];
  skeletonConfig: SgTableModel[] = [];
  dataList: any[] = null;
  totalItems: number = 0;
  filteringObs$ = new Subject();
  selectedRowIndex: number = -1;

  /**
   * update emitter will activate an event when the table record update . it will destroy the selected row and re inject it
   */
  updateRowEmitter = new EventEmitter();
  /**
   * full skeletons as initial view
   */
  emptySkeletonDataSrc = null;
  localSortModel: SgTableLocalSortModel = { field: null, ascending: false };

  constructor(private translatedToasters: TranslatedToasterService) {
  }

  ngOnInit(): void {
    this.checkInjectedObs();
    this.filterActionsColumns();
    this.setSkeletonConfig();
    this.paginationObservable ? this.gitInitialPaginatedData() : this.gitAllData();
    this.filterKeys.length != 0 && this.subscribeFilteringString();
  }

  private checkInjectedObs() {
    if (!this.paginationObservable && !this.getAllDataObs) {
      throw new Error('Injected component Inputs are not completed, one of these observable must injected =>  paginationObservable, getAllDataOb ');
    }
    if (this.paginationObservable && !this.paginationModel) {
      this.paginationModel = {
        pageSize: this.pageSize,
        pageIndex: 0
      };
    }
  }

  /**
   * filtering the columns to tow sub lists , the string columns and the actions columns
   * @see actionsComponentColumns
   * @see stringColumns
   * @private
   */
  private filterActionsColumns() {
    this.columns.map(element => {
      this.addColumnToCorrespondingList(element);
    });
  }

  private addColumnToCorrespondingList(column: SgTableModel) {
    if (column.injectedComponent) {
      this.actionsComponentColumns.push(column);
    } else if (column.attributeKey && column.attributeKey.length != 0) {
      this.stringColumns.push(column);
    }
  }

  /**
   * define the skeleton table configuration based on the passed columns Input,
   * the same configuration but with a RectSkeletonComponent as an action component
   * @see columns
   * @private
   */
  private setSkeletonConfig() {
    this.columns.map(ele => {
      let config: SgTableModel = {
        width: ele.width,
        injectedComponent: RectSkeletonComponent,
        hideColumnName: ele.hideColumnName,
        name: ele.name
      };
      this.skeletonConfig.push(config);
      this.skeletonRenderedColumns.push(ele.name);
    });
    let src = [...Array(this.skeletonRows)].fill(1);
    this.emptySkeletonDataSrc = new MatTableDataSource([...Array(this.skeletonRows)].fill(1));
    this.dataSrc = new MatTableDataSource(src);
  }

  private gitAllData() {
    this.getAllDataObs(this.filtrationModel ? this.filtrationModel : null).subscribe((data: any[]) => {
      this.dataList = data;
      this.viewedDataSrc = [...data];
      this.dataSrc = new MatTableDataSource(this.viewedDataSrc);
      this.localPaginating && (this.dataSrc.paginator = this.paginator);
      this.tableIsLoading = false;
      this.totalItems = data.length;
      this.resetSkeletonsSrc();
    }, error => {
      this.dataList = [];
      this.viewedDataSrc = [];
      this.dataSrc = new MatTableDataSource([]);
      this.tableIsLoading = false;
      if (error.status == 401) {
        this.translatedToasters.showErrorCodeMessage('AUTHORIZATION_ERROR');
      }
    });
  }

  private gitInitialPaginatedData() {
    this.paginationObservable(this.paginationModel).subscribe((data: SgTablePaginationResult<any>) => {
      this.dataList = data.Items;
      this.viewedDataSrc = [...data.Items];
      this.totalItems = data.Total;
      this.dataSrc = new MatTableDataSource(this.viewedDataSrc);
      this.tableIsLoading = false;
      this.resetSkeletonsSrc();
    }, error => {
      this.dataList = [];
      this.viewedDataSrc = [];
      this.dataSrc = new MatTableDataSource([]);
      this.tableIsLoading = false;
      if (error.status == 401) {
        this.translatedToasters.showErrorCodeMessage('AUTHORIZATION_ERROR');
      }
    });
  }

  private subscribeFilteringString() {
    this.filteringObs$.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((filterString: string) => {
      if (filterString.length == 0) {
        return;
      }
      this.viewedDataSrc = [...this.dataList].filter(ele => {
        let condition = false;
        for (let key of this.filterKeys) {
          if (condition) {
            break;
          }
          condition = ele[key]?.toLowerCase().includes(filterString.toLowerCase());
        }
        return condition;
      });
      this.updateTableView();
    });
  }

  private updateTableView() {
    this.dataSrc = new MatTableDataSource(this.viewedDataSrc);
    this.dataSrc.paginator = this.paginator;
  }

  /**
   * show 6 rows skelton if the last data src length is 0
   * @private
   */
  private resetSkeletonsSrc() {
    this.emptySkeletonDataSrc = new MatTableDataSource([...Array(5)].fill(1));
  }


  ngAfterViewInit() {
    !this.paginationObservable && (this.dataSrc.paginator = this.paginator);
  }

  /**
   * paginate throw the data using the passed paginationObservable observable
   * @requires paginationObservable
   * @param event
   */
  updateTablePagination(event: PageEvent) {
    if (this.localPaginating) {
      return;
    }
    if (!this.paginationObservable || !this.paginationModel) {
      return;
    }
    this.tableIsLoading = true;
    this.paginationModel.pageSize = event.pageSize;
    this.paginationModel.pageIndex = event.pageIndex;
    this.loadPaginatedData();

  }

  private loadPaginatedData() {
    this.paginationObservable(this.paginationModel).subscribe((data: { Items: any[], Total: number }) => {
        this.tableIsLoading = false;
        this.dataList = data.Items;
        this.viewedDataSrc = [...data.Items];
        this.totalItems = data.Total;
        this.dataSrc = new MatTableDataSource(this.viewedDataSrc);
        this.paginator.pageIndex = this.paginationModel.pageIndex;
        this.paginator.pageSize = this.paginationModel.pageSize;
        this.paginator.length = data.Total;
      }, error => {
        this.tableIsLoading = false;
        if (error.status == 401) {
          this.translatedToasters.showErrorCodeMessage('AUTHORIZATION_ERROR');
        } else {
          this.translatedToasters.showErrorCodeMessage('SERVER_ERROR_RESPONSE');
        }
      }
    );
  }

  /**
   * CRUD and Output operations
   * @param event
   */
  updateTableRecord(event: SgTableUpdateEvent) {
    let updatedRecord = event.newRecord;
    let indexInTheViewedList = this.viewedDataSrc.findIndex(ele => ele?.[this.uniqueKeyOperation].toString() == updatedRecord?.[this.uniqueKeyOperation].toString());
    this.viewedDataSrc[indexInTheViewedList] = updatedRecord;
    this.dataSrc = new MatTableDataSource(this.viewedDataSrc);
    event.reInject && this.updateRowEmitter.emit();
    let indexInTheMainList = this.dataList.findIndex(ele => ele?.[this.uniqueKeyOperation] == updatedRecord?.[this.uniqueKeyOperation]);
    indexInTheMainList != -1 && (this.dataList[indexInTheMainList] = updatedRecord);
    if (!this.paginationObservable) this.dataSrc.paginator = this.paginator;
  }

  addNewRecord(newRecord) {
    this.viewedDataSrc.unshift(newRecord);
    this.dataSrc = new MatTableDataSource(this.viewedDataSrc);
    this.dataList.unshift(newRecord);
    this.paginationObservable ? this.paginator.length++ : this.dataSrc.paginator = this.paginator;
    console.log();
  }

  deleteRecord(recordObjectOperationKeyValue: string | number) {
    let indexInTheViewedList = this.viewedDataSrc.findIndex(ele => ele?.[this.uniqueKeyOperation].toString() == recordObjectOperationKeyValue.toString());
    this.viewedDataSrc.splice(indexInTheViewedList, 1);
    this.dataSrc = new MatTableDataSource(this.viewedDataSrc);
    let indexInTheMainList = this.dataList.findIndex(ele => ele?.[this.uniqueKeyOperation].toString() == recordObjectOperationKeyValue.toString());
    this.dataList.splice(indexInTheMainList, 1);
    this.paginationObservable ? this.paginator.length-- : this.dataSrc.paginator = this.paginator;
    console.log();
  }

  /**
   * emmit an event to the parent when a new row selected
   * @param srcObject
   * @param index
   */
  rowSelected(srcObject, index) {
    let selectedTableRowEvent: SelectedTableRowEvent<any> = {
      rowIndex: index,
      selectedItem: srcObject
    };
    this.selectedRowIndex = index;
    this.rowClickedEmitter.emit(selectedTableRowEvent);
  }

  /**
   * get the new filtration results when the filtrationModel model is externally updated
   */
  reloadCurrentView() {
    this.tableIsLoading = true;
    this.paginationObservable ? this.loadPaginatedData() : this.gitAllData();
  }

  /**
   * This function is used when the local table data is updated (the table is not connected to BE)
   * @param getAllDataObs
   */
  updateAllDataObs(getAllDataObs: (filtrationModel?: SgTableDataFiltrationModel) => Observable<any>) {
    this.getAllDataObs = getAllDataObs;
    this.reloadCurrentView();
  }


  /**
   * update sorting value and load new data
   * @see SgTableComponent.loadPaginatedData
   * @param column
   */
  sortByField(column: SgTableModel) {
    if (!column.sortFiled) return;
    if (column.sortFiled != this.paginationModel.sortFiled) {
      this.paginationModel.ascending = false;
    }
    this.paginationModel.sortFiled = column.sortFiled;
    this.paginationModel.ascending = !this.paginationModel.ascending;
    this.reloadCurrentView();
  }

  /**
   * update sorting local model and emit it
   * @see SgTableComponent.loadPaginatedData
   * @param column
   */
  localSortByField(column: SgTableModel) {
    if (this.localSortModel.field == column.sortFiled) {
      this.localSortModel.ascending = !this.localSortModel.ascending;
    } else {
      this.localSortModel = { field: column.sortFiled, ascending: false };
    }
    let sortedList: DashboardControl[] = this.localSortModel.ascending ?
      this.dataList.sort((a: DashboardControl, b: DashboardControl) => a[this.localSortModel.field].localeCompare(b[this.localSortModel.field])) :
      this.dataList.sort((a: DashboardControl, b: DashboardControl) => b[this.localSortModel.field].localeCompare(a[this.localSortModel.field]));
    this.dataList = sortedList;
    this.viewedDataSrc = [...sortedList];
    this.dataSrc = new MatTableDataSource(sortedList);
    this.totalItems = sortedList.length;
    this.localPaginating && (this.dataSrc.paginator = this.paginator);
  }
}
