<div class="orientation-control-plugin-container">
  <section>
    <img src="../../../../../../assets/control-plugins/orientation-control-plugin.png" alt="orientationIcon">
    <p>{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.ORIENTATION' | translate }}</p>
  </section>
  <section>
    <div class="auto-section"
         [ngClass]="{'selected-button': selectedButton == 2}"
         (click)="send(ModesEnum.ALL)">
      <p>{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.ALL' | translate }}</p>
    </div>

    <div class="multi-selection" [ngClass]="{'selected-button': selectedButton == 1}">
      <p (click)="send(ModesEnum.MANUAL)">{{ 'equipmentConfiguration.' + operationAsDisplay?.name | translate }}</p>
      <div class="divider"></div>
      <div matRipple class="switch-mode-button" [matMenuTriggerFor]="menu">
        <mat-icon>keyboard_double_arrow_down</mat-icon>
      </div>
      <mat-menu #menu="matMenu">

        <button mat-menu-item disabled>
          <span>{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.DISPLAYED_OPERATION' | translate }}</span>
        </button>

        <button mat-menu-item *ngFor="let operation of orientationOperations" (click)="assignOperationAsDisplay(operation); send(1)">
          <span>{{ 'equipmentConfiguration.' + operation?.name | translate }}</span>
        </button>

      </mat-menu>
    </div>
  </section>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
