import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../core/shared.module';
import { SideNaveModule } from '../sidebars/sidebar.module';
import { AppContentComponent } from './app-content.component';
import { ToggleMenuService } from './toggle-menu.service';
import { RouterModule } from '@angular/router';
import { SmallScreensMenuModule } from 'src/app/components/app/small-screens-menu/small-screens-menu.module';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatCardModule } from '@angular/material/card';
import { HomeNavigationHeaderModule } from '../../home/home-navigation-header/home-navigation-header.module';
import { SidenavLinkModule } from '../sidenav-link/sidenav-link.module';
import { UserAccessService } from '../../../core/services/user-access/user-access.service';
import { AccessManagementService } from '../../../core/services/user-access/access-management.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    SideNaveModule,
    MatTreeModule,
    MatIconModule,
    MatTabsModule,
    MatMenuModule,
    MatBadgeModule,
    MatButtonModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSelectModule,
    FormsModule,
    RouterModule,
    SmallScreensMenuModule,
    PipesModule,
    MatCardModule,
    HomeNavigationHeaderModule,
    SidenavLinkModule,
    MatProgressSpinnerModule
  ],

  providers: [
    DatePipe,
    ToggleMenuService,
    UserAccessService,
    AccessManagementService
  ],
  declarations: [AppContentComponent],
  exports: [AppContentComponent]
})
export class AppContentModule {
}
