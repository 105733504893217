import {AccessRightEnum} from "./device-access-right.enum";
import {DeviceOperationEnum} from "./device-operation.enum";

export class EquipmentConfiguration {
  _id?: string;
  dataId: number;
  actionId: number;
  actionId2?: number;
  name: string;
  resourceName: string;
  value?: number | string;
  unit: string;
  operation: DeviceOperationEnum.DeviceOperation;
  accessRight: AccessRightEnum.AccessRight;
  added: boolean;
  xPos: number;
  yPos: number;
  additionalInfo: any;
  setValue?: number;
  additionalSources?: []

}
