import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PluginControl } from '../plugin-control.abstract';
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

interface FanOperation {
  name: string;
  operation: number;
}

@Component({
  selector: 'speed-control-plugin',
  templateUrl: './speed-control-plugin.component.html',
  styleUrls: ['./speed-control-plugin.component.scss']
})
export class  SpeedControlPluginComponent extends PluginControl implements OnInit, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;
  @Input('templateMode') templateMode: boolean = false;
  value: number;

  auto: number;
  manualNormal: number;

  operationAsDisplay = {} as FanOperation;

  fanOperations: FanOperation[] = [
    {
      name: 'low',
      operation: null
    },
    {
      name: 'mid',
      operation: null
    },
    {
      name: 'high',
      operation: null
    }
  ];

  initialized: boolean = false;

  subject: Subject<any> = new Subject();
  subscription: Subscription = new Subscription();

  selectedButton: number = 0;

  subscriptions: Subscription = new Subscription();
  data: [];
  plotData: any[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {

    super();

    this.subscription.add(
      this.subject.pipe(debounceTime(1000)).subscribe((data) => {
        this.setMode(data.mode, data?.initialize);
      })
    );
  }

  ngOnInit(): void {
    this.auto = this.configuration.additionalInfo['auto'];
    this.initializeModes();
    if (this.templateMode) {
      this.setValue(this.configuration?.setValue);
    };
    this.setInitValue && this.send(2);
  }

  setValue(value: number) {
    this.value = value;
    this.send(this.value, 'initialize');
    this.changeDetectorRef.markForCheck();
  }

  getValue(value: number) {
    return this.value;
  }

  initializeModes() {
    this.fanOperations.forEach((operation: FanOperation) => {
      operation.operation = this.configuration.additionalInfo[operation.name];
    });

    this.assignOperationAsDisplay(this.fanOperations[0]);
  }

  assignOperationAsDisplay(operation: FanOperation) {

    if (operation == undefined) {
      this.selectedButton = 0;
      return;
    };


    (this.operationAsDisplay.name) ? (this.fanOperations.push(JSON.parse(JSON.stringify(this.operationAsDisplay)))) : (null);
    this.operationAsDisplay = operation;
    this.fanOperations = this.fanOperations.filter((op: FanOperation) => op.name !== operation.name);

  }


  send(mode: number, initialize?) {
    this.subject.next({
      mode: mode,
      initialize: initialize
    });
  }

  setMode(mode, initialize?) {

    if (initialize) {

      if (mode == this.auto) {
        this.selectedButton = 2;
      } else {
        this.selectedButton = 1;
        (this.operationAsDisplay.operation == mode) ? (this.selectedButton = 1) : (this.assignOperationAsDisplay(this.fanOperations.find((op: FanOperation) => op.operation == mode)));
      };

    } else {
      switch (mode) {

        case (1):
          this.selectedButton = 1;

          (!initialize) ? (this.generalSetMode(this.operationAsDisplay.operation)) : (null);
          break;

        case (2):
          this.selectedButton = 2;
          (!initialize) ? (this.generalSetMode(this.auto)) : (null);
          break;

        default:
          // Do nothing

      };
    }

  }

  generalSetMode(mode: number) {
    this.configuration.setValue = mode;
    this.outputValue.next(mode);
  }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

