import { Component, HostBinding, Input, OnInit, SimpleChanges } from '@angular/core';
import {Energy} from "../../../core/models/energy/enums/energy.name-space";

@Component({
  selector: 'gmao-energy-efficiency-evo',
  templateUrl: './energy-efficiency-evo.component.html',
  styleUrls: ['./energy-efficiency-evo.component.scss']
})
export class EnergyEfficiencyEvolutionComponent implements OnInit {
  @HostBinding('class') rootComponentClasses = 'd-flex flex-column h-100 w-100';

  energyBars: any[] = [];
  selectedBar: boolean[] = [];


  value: number = 108;
  unit: string = Energy.EnergyUnitEnum.KWH + "/m²";


  constructor() {}

  ngOnInit(): void {
    this.initEnergyBars();
    this.setRating();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setRating();
  }

  setRating() {
    if(this.value < 50){
      this.selectedBar[0] = true;
    } else if(this.value >= 51 && this.value < 90 ){
      this.selectedBar[1] = true;
    } else if(this.value >= 91 && this.value < 150){
      this.selectedBar[2] = true;
    } else if(this.value >= 151 && this.value < 230){
      this.selectedBar[3] = true;
    } else if(this.value >= 231 && this.value < 330){
      this.selectedBar[4] = true;
    } else if(this.value >= 331 && this.value < 450){
      this.selectedBar[5] = true;
    } else {
      this.selectedBar[6] = true;
    }
  }

  initEnergyBars() {
    this.energyBars = [
      {
        label: 'A',
        width: '20',
        background: '#018133'
      },
      {
        label: 'B',
        width: '25',
        background: '#27b432'
      },
      {
        label: 'C',
        width: '30',
        background: '#88e213'
      },
      {
        label: 'D',
        width: '40',
        background: '#f0ef00'
      },
      {
        label: 'E',
        width: '50',
        background: '#fc9000'
      },
      {
        label: 'F',
        width: '60',
        background: '#fd0000'
      },
      {
        label: 'G',
        width: '70',
        background: '#fd001d'
      }

    ];

    this.selectedBar = [
      false,
      false,
      false,
      false,
      false,
      false,
      false
    ];

  }

  readonly Energy = Energy;
}
