import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LabelRatingComponent } from './label-rating.component';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({

  imports:[
    TranslateModule,
    MatProgressSpinnerModule,
    CommonModule,

  ],
  declarations: [
    LabelRatingComponent,

  ],
  exports: [
    LabelRatingComponent,

  ],

})
export class LabelRatingModule { }
