import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highcharts';
import { environment } from '../../../../../environments/environment';
import { forkJoin, Subscription } from 'rxjs';
import { AlarmSidenavModel } from '../../../../core/models/alarms/alarm-sidenav-model';
import { AlarmsManagementService } from '../../../../core/services/alarms/alarms-management.service';
import { AlarmsService } from '../../../../core/services/alarms/alarms.service';
import { SitesService } from '../../../../core/services/sites/sites.service';
import { AlarmsPaginationModel } from '../../../../core/models/alarms/alarms-pagination-model';
import { LocalStorageKeysEnum } from '../../../../core/services/localstorage/local-storage-keys.enum';
import { LocalStorageService } from '../../../../core/services/localstorage/local-storage.service';

@Component({
  selector: 'alarm-sidebar',
  templateUrl: './alarm-sidebar.component.html',
  styleUrls: ['./alarm-sidebar.component.scss']
})
export class AlarmSidebarComponent implements OnInit {
  logo: string = environment.logo;
  chart: Highcharts.Chart;
  highcharts = Highcharts;
  chartOptions;
  isLoadingResults: boolean = true;
  subscription: Subscription;
  sideNavData: AlarmSidenavModel;

  constructor(private sitesService: SitesService,
              private alarmsService: AlarmsService,
              private localStorageService: LocalStorageService,
              private alarmsManagementService: AlarmsManagementService) {
  }

  ngOnInit() {
    this.getSideNavInfo();
  }

  getSideNavInfo() {
    this.isLoadingResults = true;
    let alarmsPagination: AlarmsPaginationModel = { pageSize: 10, pageIndex: 0, name: '', active: true };
    let siteId = this.localStorageService.getLocalStorageStringItem(LocalStorageKeysEnum.CURRENT_SITE_ID);
    forkJoin({
      site: this.sitesService.getSiteById(siteId),
      alarmChartData: this.alarmsService.getMonthlyAlarmJournals('alarms'),
      alarm: this.alarmsService.getPaginatedViewAlarms(alarmsPagination)
    }).subscribe(data => {
      this.sideNavData = {
        imagePath: data.site.logo,
        name: data.site.name,
        monthlyActiveAlarms: data.alarmChartData,
        activeAlarms: data.alarm.Total
      };
      this.chartOptions = this.alarmsManagementService.createSideBarOption(data.alarmChartData);
      this.isLoadingResults = false;
    }, error => {
      this.isLoadingResults = false;
    });
  }
}
