import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserProfileRoutingModule} from './user-profile-routing.module';
import {UserProfileComponent} from './user-profile.component';
import {UserAccessService} from "../../core/services/user-access/user-access.service";
import {LoadingSkeletonModule} from "../../components/loading-skeleton/loading-skeleton.module";
import {UserFormModule} from "../../components/users/user-form/user-form.module";
import {GroupsAccessService} from "../../core/services/user-access/groups-access.service";


@NgModule({
  declarations: [
    UserProfileComponent
  ],
  imports: [
    CommonModule,
    UserProfileRoutingModule,
    LoadingSkeletonModule,
    UserFormModule
  ],
  providers: [UserAccessService, GroupsAccessService]
})
export class UserProfileModule {
}
