import {DatePipe, registerLocaleData} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {AppContentModule} from './components/app/content/app-content.module';
import {AppRoutingModule} from './app-routing.module';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {CustomMatPaginatorIntl} from './components/paginator-intl/paginator-intl';
import {ReceptionWindowModule} from './pages/reception-window/reception-window.module';
import {
  EquipmentScadaDashboardModule
} from './pages/home-screens/equipment-scada-dashboard/equipment-scada-dashboard.module';
import {
  EquipmentControlDashboardModule
} from './pages/home-screens/equipment-control-dashboard/equipment-control-dashboard.module';
import {ToastrModule} from 'ngx-toastr';
import {WeatherWindowModule} from './pages/weather-window/weather-window.module';
import {UserProfileModule} from './pages/user-profile/user-profile.module';

import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatOptionModule
} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {PipesModule} from './core/pipes/pipes.module';
import {JWT_OPTIONS, JwtHelperService} from '@auth0/angular-jwt';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {SgHeaderModule} from './components/app/header/sg-header.module';
import {DateFormatDirective} from './components/app/date-format-directive/date-format.directive';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {SG_DATE_FORMATS} from "./core/models/date-formats/sg-date-formats";

registerLocaleData(localeFr, 'fr', localeFrExtra);

// import ngx-translate and the http loader
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// tslint:disable-next-line: max-classes-per-file2
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    EquipmentScadaDashboardModule,
    EquipmentControlDashboardModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    AppContentModule,
    ReceptionWindowModule,
    WeatherWindowModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    UserProfileModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    PipesModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SgHeaderModule
  ],
  providers: [
    MatDatepickerModule,
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    JwtHelperService,
    DatePipe,
    {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: SG_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'fr'}
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
