<div
     class="informations_sidnav_component w-100p h-100p f-col a-center j-start pa-15px scroll-y">

  <mat-spinner class="py-20px" *ngIf="isLoadingResults" [diameter]="30"></mat-spinner>

  <div class="w-100p h-100p" *ngIf="!isLoadingResults">
    <div class="w-100p my-10px br-5px bxs-black sg-bgr-primary f-col a-center j-start pointer">

      <div class="w-100p h-120px f-row a-center j-center no-scroll">
        <img [src]="building?.logo | securePublic | async" (error)="$event.target['src']=logo" alt="building image" id="building-logo">
      </div>

      <div class="w-100p px-10px my-10px f-row a-center j-start txt-13px txt-bold" id="building-name">
        {{building?.name}}
      </div>

    </div>

    <div class="w-100p h-200px my-10px bxs-black">
      <sg-map id="sg-map" class="w-100p h-100p" [settings]="mapSettings"
              [coordinatesObservable]="_mapCoordinates">
      </sg-map>
    </div>

    <mat-accordion class="w-100p my-10px">
      <mat-expansion-panel class="w-100p my-5px br-5px sg-bgr-primary bxs-black" [expanded]="true">
        <mat-expansion-panel-header id="expansion-panel-header-plans">
          <mat-panel-title id="panel-title-plans" class="uppercase"> {{'plans' | translate}} </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngFor="let img of floorsImages; let i = index">
          <img [src]="img.thumbImage | securePublic | async" (error)="$event.target['src']=logo" alt="floor image" [id]="'floorsImage_'+i" class="rounded img-Dimensions c-pointer"
               (click)="openDialog(img.thumbImage)">
        </div>
      </mat-expansion-panel>

    </mat-accordion>
  </div>

</div>
