import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gmao-home-rectangle-skeleton',
  templateUrl: './home-rectangle-skeleton.component.html',
  styleUrls: ['./home-rectangle-skeleton.component.scss']
})
export class HomeRectangleSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
