import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
/**
 * @deprecated
 */
export class SchedulingMessagesService {

    constructor() {}

    //change tab to template page
    private tabMessageSource = new Subject<any>();
  /**
   * @deprecated
   */
    tabMessage$ = this.tabMessageSource.asObservable();

  /**
   * @deprecated
   */
    changeTab(message: any){
        this.tabMessageSource.next(message);
    }

    //change template to edit
    private templateMessageSource = new Subject<any>();
  /**
   * @deprecated
   */
    templateMessage$ = this.templateMessageSource.asObservable();

  /**
   * @deprecated
   */
    changeTemplate(message: any){
        this.templateMessageSource.next(message);
    }
}
