import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeNavigationHeaderComponent} from "./home-navigation-header.component";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {PipesModule} from "../../../core/pipes/pipes.module";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {MatToolbarModule} from "@angular/material/toolbar";
import {_MatMenuDirectivesModule, MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {LoadingSkeletonModule} from "../../loading-skeleton/loading-skeleton.module";


@NgModule({
  declarations: [HomeNavigationHeaderComponent],
  exports: [HomeNavigationHeaderComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    PipesModule,
    RouterModule,
    TranslateModule,
    MatToolbarModule,
    _MatMenuDirectivesModule,
    MatButtonModule,
    MatMenuModule,
    LoadingSkeletonModule
  ]
})
export class HomeNavigationHeaderModule {
}
