import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {Alarm} from '../../models/alarms/alarm';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {ErrorHandlerService} from "../error-handler/error-handler.service";
import {catchError, map} from "rxjs/operators";
import {AlarmsPaginationResults} from "../../models/alarms/alarms-pagination-results";
import {SgTablePaginationResult} from "../../../components/sg-components/sg-table/sg-table-pagination-result";
import {AlarmsPaginationModel} from "../../models/alarms/alarms-pagination-model";
import {SgTablePaginationModel} from "../../../components/sg-components/sg-table/sg-table-pagination.model";
import {AlarmJournal} from "../../models/alarms/alarm-journal";
import {AlarmJournalPaginationModel} from "../../models/alarms/alarm-journal-pagination-model";
import {AuthenticationsService} from "../auth/authenticationsService";

@Injectable()
export class AlarmsService {
  private baseUrl: string = `/api/sg-alarms/alarms/`;
  private alarmJournals: string = '/alarm-journals';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationsService,
    private handler: ErrorHandlerService
  ) {
  }

  getPaginatedViewAlarms(alarmsPagination: AlarmsPaginationModel): Observable<SgTablePaginationResult<Alarm>> {
    let params: HttpParams = new HttpParams()
      .append('itemsPerPage', alarmsPagination.pageSize.toString())
      .append('currentPage', (alarmsPagination.pageIndex).toString())
      .append('name', alarmsPagination.name)

    if (alarmsPagination?.active != undefined) {
      params = params.append('active', alarmsPagination?.active?.toString());
    }
    return this.http.get<AlarmsPaginationResults>(this.baseUrl, {params: params}).pipe(
      map((data: AlarmsPaginationResults) => {
        return {Items: data.alarms, Total: data.count}
      })
    );
  }

  deleteSelectedAlarms(alarmIds: string[]) {
    const options = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: alarmIds
    };
    return this.http.delete(this.baseUrl, options).pipe(catchError(this.handler.handleError));
  }

  saveAlarms(data: Alarm[]): Observable<Alarm[]> {
    return this.http.put<Alarm[]>(this.baseUrl, data).pipe(catchError(this.handler.handleError));
  }

  /**
   * Muntaser : when Salah finished works on the alarm-journals create model, then change this API and change table
   * @param alarmId
   * @param journalsPagination
   */
  getAlarmsJournalsPaginated(alarmId: string, journalsPagination: SgTablePaginationModel): Observable<SgTablePaginationResult<AlarmJournal>> {
    let path = this.baseUrl + alarmId + this.alarmJournals;
    let params: HttpParams = new HttpParams()
      .append('itemsPerPage', journalsPagination.pageSize.toString())
      .append('currentPage', (journalsPagination.pageIndex).toString());
    return this.http.get<AlarmJournalPaginationModel>(path, {params: params}).pipe(
      catchError(this.handler.handleError),
      map((data: AlarmJournalPaginationModel) => {
        return {Items: data.alarmJournals, Total: data.count}
      })
    );
  }


  getMonthlyAlarmJournals(alarmId: string): Observable<number[]> {
    let path = this.baseUrl + alarmId + this.alarmJournals + '/monthly/summary';
    let params = new HttpParams().append('year', new Date().getFullYear().toString())
    return this.http.get<number[]>(path, {params: params});
  }
}
