<div class="scroll-menu-plugin-container">
  <section>
    <mat-icon>list</mat-icon>
    <p class="label">{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.LIST' | translate | capitalize}}</p>
  </section>
  <section>
    <p>{{ configuration.name }}</p>
    <div class="scroll-menu-content">
      <div class="scroll-menu-list-container">
        <mat-select (selectionChange)="applyOperation($event)">
          <mat-option
            *ngFor="let config of configuration.additionalInfo"
            [value]="config">
            {{ config.operationName }}
          </mat-option>
        </mat-select>
      </div>
      <button [matTooltip]="'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.REDO'| translate" mat-mini-fab (click)="reapplyOperation()">
        <mat-icon>cached</mat-icon>
      </button>
    </div>
  </section>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
