<div class="container">
  <div class="table-cont" *ngIf=" !tableIsLoading ;else tableSkeletonView">
    <table mat-table matSort [dataSource]="dataSrc" [class.animated-rows]="animatedRows">
      <ng-container *ngFor="let column of stringColumns" [matColumnDef]="column?.attributeKey" [sticky]="column?.sticky"
                    [stickyEnd]="column?.stickyEnd">
        <th mat-header-cell [id]="column?.attributeKey.toLowerCase()+'_header_th'" [style]="'min-width:'+column?.width"
            *matHeaderCellDef>
          <div class="header-cell">
            <ng-container
              *ngTemplateOutlet="column.sortFiled? isLocalSort ? localSortable : sortable: noSortable; context:{'column':column}"></ng-container>
            <ng-container *ngFor="let icon of column?.headerIcons">
              <ng-container [ngTemplateOutlet]="icon.matMenu ? TriggerIcon : SimpleIcon"
                            [ngTemplateOutletContext]="{icon:icon}"></ng-container>
            </ng-container>
          </div>
        </th>
        <td mat-cell
            [id]="column?.attributeKey.toLowerCase()+'_record_'+index+'_td'"
            (click)="rowSelection && rowSelected(srcObject,index)"
            [ngStyle]="{'width': column?.width, 'font-weight': 500,'padding-right': column.textPadding, 'max-width': column?.width}"
            *matCellDef="let  srcObject;let index=index">
          {{
          !srcObject[column?.attributeKey] ? '-' :
            (column.date ?
              (srcObject[column?.attributeKey] | date:column.datePattern ? column.datePattern : dateFormat) :
              ((!column.translateKey ?
                srcObject[column?.attributeKey].toString() :
                (column.translateKey + (!column.translateEnum ?
                  srcObject[column?.attributeKey] :
                  column.translateEnum[srcObject[column?.attributeKey]])) | translate)) | capitalize)
          }}
        </td>
      </ng-container>
      <ng-container *ngFor="let actionColumn of actionsComponentColumns" [matColumnDef]="actionColumn?.name"
                    [sticky]="actionColumn?.sticky" [stickyEnd]="actionColumn?.stickyEnd">
        <th mat-header-cell [id]="actionColumn?.name.toLowerCase()+'_header_th'"
            [style]="'min-width:'+actionColumn?.width" *matHeaderCellDef>
          <div class="header-cell">
            <ng-container
              *ngTemplateOutlet="actionColumn.sortFiled? isLocalSort ? localSortable : sortable: noSortable; context:{'column':actionColumn}"></ng-container>
            <ng-container *ngFor="let icon of actionColumn?.headerIcons">
              <ng-container [ngTemplateOutlet]="icon.matMenu ? TriggerIcon : SimpleIcon"
                            [ngTemplateOutletContext]="{icon:icon}"></ng-container>
            </ng-container>
          </div>
        </th>
        <td
          mat-cell
          [id]="actionColumn?.name+'_record_'+index+'_td'"
          [ngStyle]="{'width': actionColumn?.width}"
          [class.selected]="selectedRowIndex==index"
          (click)="rowSelection && !actionColumn.actionsComponent && rowSelected(srcObject,index)"
          *matCellDef="let srcObject;let  index=index">
          <!--
             here where the action component injected with the srcObject
          -->
          <sg-table-actions-cell
            (deleteRecord)="deleteRecord($event)"
            (updateRecord)="updateTableRecord($event)"
            [updateRowEmitter]="updateRowEmitter"
            [parentRowSelected]="selectedRowIndex==index"
            [rowClickedEmitter]="rowClickedEmitter"
            [injectComponentInputs]="actionColumn.injectComponentInputs"
            [index]="index"
            [cellObject]="srcObject"
            [componentToInject]="actionColumn.injectedComponent">
          </sg-table-actions-cell>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="renderedColumns;sticky: true"></tr>
      <tr mat-row [@rowsAnimation]="rowAnimations" *matRowDef="let row;
          columns: renderedColumns;let i = index"
          [class.selected]="selectedRowIndex==i"
          [class.cursor-pointer]="rowSelection"
      >
    </table>
  </div>
  <ng-template #tableSkeletonView>
    <div class="table-cont">
      <table mat-table matSort [dataSource]="viewedDataSrc.length ? dataSrc :emptySkeletonDataSrc ">
        <ng-container *ngFor="let actionColumn of skeletonConfig" [matColumnDef]="actionColumn?.name">
          <th mat-header-cell [id]="actionColumn?.name.toLowerCase()+'_header_th'"
              [style]="'min-width:'+actionColumn?.width" *matHeaderCellDef>
            <div class="header-cell">
              <span [class.display-none]="actionColumn?.hideColumnName">{{ actionColumn?.name |capitalize }}</span>
            </div>
          </th>
          <td mat-cell [style]="'width:'+actionColumn?.width" *matCellDef="let srcObject">
            <sg-table-actions-cell [cellObject]="srcObject"
                                   [componentToInject]="actionColumn.injectedComponent"></sg-table-actions-cell>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="skeletonRenderedColumns;sticky: true"></tr>
        <tr mat-row [@rowsAnimation]="rowAnimations" *matRowDef="let row; columns: skeletonRenderedColumns;">
      </table>
    </div>
  </ng-template>

  <mat-paginator #paginator *ngIf="paginationObservable || localPaginating"
                 showFirstLastButtons
                 [pageSize]="pageSize"
                 [length]="totalItems"
                 [pageSizeOptions]="paginatedPageSizes"
                 (page)="updateTablePagination($event)">
  </mat-paginator>
</div>

<ng-template #TriggerIcon let-icon='icon'>
  <mat-icon mat-ripple [style.color]="icon.color" [style.margin-left]="icon?.leftMargin" [matTooltip]="icon.toolTip"
            [matMenuTriggerFor]="icon.matMenu" (click)="icon.action && icon.action()">{{ icon.icon }}</mat-icon>
</ng-template>

<ng-template #SimpleIcon let-icon='icon'>
  <mat-icon mat-ripple [style.color]="icon.color" [style.margin-left]="icon?.leftMargin" [matTooltip]="icon.toolTip"
            (click)="icon.action && icon.action()">{{ icon.icon }}</mat-icon>
</ng-template>


<ng-template #sortable let-column="column">
              <span [class.display-none]="column?.hideColumnName"
                    class="cursor-pointer"
                    [ngClass]="paginationModel.sortFiled != column.sortFiled ? ''
                   :paginationModel.ascending ? 'ascending': 'descending'"
                    (click)="sortByField(column)">
              {{ column?.name |capitalize }}
            </span>
</ng-template>


<ng-template #localSortable let-column="column">
              <span [class.display-none]="column?.hideColumnName"
                    class="cursor-pointer"
                    [ngClass]="localSortModel.field != column.sortFiled ? ''
                   :localSortModel.ascending ? 'ascending': 'descending'"
                    (click)="localSortByField(column)">
              {{ column?.name |capitalize }}
            </span>
</ng-template>

<ng-template #noSortable let-column="column">
  <span [class.display-none]="column?.hideColumnName">
    {{ column?.name |capitalize }}
  </span>
</ng-template>
