
<!-- dashboard menu icon -->
<div [matMenuTriggerFor]="menu" class="tool-mobile w-55px cursor-pointer dashboard-menu mobile sg-bgr-primary sg-font-primary">
  <mat-icon>more_vert</mat-icon>
</div>
<!-- dashboard menu -->
<mat-menu #menu="matMenu" class="dashboard-menu sg-bgr-primary">
  <div *ngFor="let link of navLinks" style="width: 200px;" class="middle">
    <button mat-menu-item *ngIf="link.subViews.length == 0" [routerLink]="link.path" routerLinkActive class="middle sg-font-primary"
      #rla="routerLinkActive" [class.active]="rla.isActive" disableRipple="true" [innerText]="link.name"></button>

    <div *ngIf="link.subViews.length > 0" style="width: 200px;">
      <button mat-menu-item [matMenuTriggerFor]="menu" routerLinkActive #rla="routerLinkActive"
        [class.active]="rla.isActive" disableRipple="true" [innerText]="link.name" class="sg-bgr-primary sg-font-primary"></button>
      <mat-menu #menu="matMenu" class="sg-bgr-primary sg-font-primary">
        <button mat-menu-item class="middle sg-bgr-primary sg-font-primary" *ngFor="let subPath of link.subViews" [routerLink]="subPath.path" disableRipple="true"
          [innerText]="subPath.name" [disabled]="false"></button>
      </mat-menu>
    </div>
  </div>
</mat-menu>
