import {Injectable} from "@angular/core";
import {MenuLink} from "../../models/menu/menu-link";
import {AuthorizationSpace} from "../../models/app/authorizationNameSpace";
import PagesTypes = AuthorizationSpace.PagesTypes;
import {BehaviorSubject, Observable} from "rxjs";
import {SiteZone} from "../../models/sites/site-zone";

@Injectable({providedIn: 'root'})
export class MenuLinksManagementService {

  private includeGMAOSubject = new BehaviorSubject<boolean>(true);
  gmaoObs: Observable<boolean> = this.includeGMAOSubject.asObservable();

  checkGMAOFeature(included: boolean){
    this.includeGMAOSubject.next(included);
  }

  getAllLinks(): MenuLink[] {
    return [
      {
        name: 'DASHBOARD',
        icon: 'map',
        path: '/dashboard',
        feature: 'DASHBOARD'
      },
      {
        name: 'MAINTENANCE_GROUP',
        icon: 'engineering',
        isExpanded: false,
        children: [
          {
            name: 'GMAO',
            icon: 'build',
            isExpanded: false,
            children: [
              {
                name: 'GMAO_TICKETS',
                icon: 'view_list',
                path: '/gmao',
                feature: 'GMAO'
              },
              {
                name: 'GMAO_INDICATORS',
                icon: 'dashboard',
                path: '/gmao-indicators',
                feature: 'GMAO'
              },
              {
                name: 'INTERVENTIONS_TICKETS',
                icon: 'view_list',
                path: '/interventions',
                feature: 'INTERVENTIONS'
              },
              {
                name: 'INTERVENTIONS_INDICATORS',
                icon: 'dashboard',
                path: '/interventions-indicators',
                feature: 'INTERVENTIONS'
              }
            ]
          },
          {
            name: 'EQUIPMENT',
            icon: 'hvac',
            path: '/admin/building-equipment/home',
            feature: 'EQUIPMENTS'
          },
          {
            name: 'MAINTENANCE',
            icon: 'handyman',
            path: '/admin/maintenance',
            feature: 'MAINTENANCE'
          }
        ]
      },
      {
        name: 'DOCUMENTS',
        icon: 'snippet_folder',
        isExpanded: false,
        children: [
          {
            name: 'EXPLORER',
            icon: 'folder',
            path: '/explorer',
            feature: 'EXPLORER'
          },
          {
            name: 'BILLS',
            icon: 'request_quote',
            path: 'admin/bills',
            feature: 'BILLS'
          },
          {
            name: 'EXPENSES',
            icon: 'euro_symbol',
            path: '/admin/expenses',
            feature: 'EXPENSES'
          }
        ]
      },
      {
        name: 'ENERGY_MANAGER',
        icon: 'energy_savings_leaf',
        isExpanded: false,
        children: [
          {
            name: 'ENERGY',
            icon: 'electric_bolt',
            isExpanded: false,
            children: [
              {
                name: 'ENERGY_CONSUMPTION',
                icon: 'monetization_on',
                path: 'admin/energy-consumption',
                feature: 'ENERGY'
              },
              {
                name: 'ENERGY_SITE_INSIGHTS',
                icon: 'insights',
                path: 'admin/energy-consumption/building-consumption/-1',
                feature: 'ENERGY'
              },
              {
                name: 'ENERGY_CONSUMPTION_ANALYSIS',
                icon: 'query_stats',
                path: 'admin/energy-consumption-analysis',
                feature: 'ENERGY'
              }
            ]
          },
          {
            name: 'WATER_CONSUMPTION',
            icon: 'water_drop',
            isExpanded: false,
            children: [
              {
                name: 'WATER_CONSUMPTION',
                icon: 'monetization_on',
                path: 'admin/water-consumption',
                feature: 'ENERGY'
              },
              {
                name: 'WATER_SITE_INSIGHTS',
                icon: 'insights',
                path: 'admin/water-consumption/water-building-consumption/-1',
                feature: 'ENERGY'
              },
              {
                name: 'WATER_CONSUMPTION_ANALYSIS',
                icon: 'query_stats',
                path: 'admin/water-consumption-analysis',
                feature: 'ENERGY'
              }
            ]
          }
        ]
      },
      {
        name: 'SCHEDULING',
        icon: 'date_range',
        isExpanded: false,
        children: [
          {
            name: 'PLANNING_TEMPLATES',
            icon: 'alarm_add',
            path: 'admin/planning-templates',
            feature: 'SCHEDULING'
          },
          {
            name: 'SCHEDULING',
            icon: 'edit_calendar',
            path: 'admin/scheduling',
            feature: 'SCHEDULING'
          }
        ]
      },
      {
        name: 'REPORTS',
        icon: 'document_scanner',
        isExpanded: false,
        children: [
          {
            name: 'REPORTS_TEMPLATES',
            icon: 'document_scanner',
            path: '/reports-templates',
            feature: 'REPORTS'
          },
          {
            name: 'REPORTS',
            icon: 'web',
            path: '/reports',
            feature: 'REPORTS'
          }
        ]
      },
      {
        name: 'PARAMETERS',
        icon: 'room_preferences',
        isExpanded: false,
        children: [
          {
            name: 'SITES',
            icon: 'apartment',
            path: '/sites',
            feature: 'SITES'
          },
          {
            name: 'USERS',
            icon: 'groups',
            path: 'admin/users-access',
            feature: 'USERS'
          },
          {
            name: 'VIEWS',
            icon: 'widgets',
            path: '/view-controller',
            feature: 'VIEWS'
          },
          {
            name: 'ALARMS',
            icon: 'alarm',
            path: '/admin/alarms',
            feature: 'ALARMS'
          },
          {
            name: 'ENERGY_METERS',
            icon: 'electric_meter',
            path: 'admin/energy-meters',
            feature: 'ENERGY'
          },
          {
            name: 'ENERGY_CONTRACTS',
            icon: 'draw',
            path: 'admin/meters-contracts-management',
            feature: 'ENERGY'
          },
          {
            name: 'PROFILE',
            icon: 'person',
            path: 'user-profile',
            feature: ''
          }
        ]
      },
      {
        name: 'EXIT',
        icon: 'exit_to_app',
        path: 'logOut',
        feature: ''
      },
    ];
  };

  filterMenuLinks(links: MenuLink[], allowedFeatures: string[] = []): MenuLink[] {
    const filteredLinks: MenuLink[] = [];

    links.forEach((link: MenuLink) => {
      if (link.children) {
        // if their children filter it (using recursive)
        const filteredChildren: MenuLink[] = this.filterMenuLinks(link.children, allowedFeatures);

        // If there are visible children, update the link with the filtered children
        if (filteredChildren.length) {
          filteredLinks.push({...link, children: filteredChildren});
        }
      } else {
        // Check if the link should be visible based on allowed features
        // like PROFILE and EXIT or features that return from BE
        if (link.feature === '' || allowedFeatures.includes(link.feature)) {
          filteredLinks.push(link);
        }
      }
    });

    return filteredLinks;
  }

  handleExpandedMenuLinkGroup(menuLinks: MenuLink[], itemName: string): MenuLink[] {
    if (!menuLinks || menuLinks.length === 0) {
      return;
    }
    menuLinks.forEach((link: MenuLink) => {
      link.isExpanded = itemName == link.name ? !link.isExpanded : false;

      if (link.children && link.children.length > 0) {
        this.handleExpandedMenuLinkGroup(link.children, itemName);
      }
    });
    return menuLinks;
  }

}
