import {Component} from '@angular/core';
import {ControlParentPluginComponent} from "../control-parent-plugin/control-parent-plugin.component";
import {TimelineService} from "../../dashboards/dashboard-timeline/timeline.service";
import {
  TesseractService
} from "../../../core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service";
import {
  ControlsDashboardManagementService
} from "../../../core/services/controls-dashboard/controls-dashboard-management.service";

@Component({
  selector: 'gmao-control-power-plugin',
  templateUrl: './control-power-plugin.component.html',
  styleUrls: ['./control-power-plugin.component.scss']
})
export class ControlPowerPluginComponent extends ControlParentPluginComponent {
  status: boolean;
  constructor(protected timelineService: TimelineService,
              protected controlsDashboardManagementService: ControlsDashboardManagementService,
              protected tesseractsService: TesseractService) {
    super(timelineService, tesseractsService);
  }


  /**
   * @Override
   * check if the get value is on/off to change icon color,
   * tesseracts return number value then got the key using value to check if the value is on/off.
   */
  setDisplayValue() {
    this.status = this.controlsDashboardManagementService.getKeyByValue(this.defaultConfiguration.additionalInfo, this.getValue(), false) == "on";
  }
}
