<div class="mode-control-plugin-container">
  <section>
    <p>{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.MODE' | translate  | capitalize}}</p>
  </section>
  <section>
    <div class="auto-section"
         *ngIf="auto != null && auto != undefined"
         [ngClass]="{'selected-button': selectedButton == 2}"
         (click)="send(2)">
      <p>{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.AUTO' | translate }}</p>
    </div>

    <div class="multi-selection" [ngClass]="{'selected-button': selectedButton == 1}">
      <p (click)="send(1)">{{ 'equipmentConfiguration.' + operationAsDisplay.name | translate }}</p>
      <div class="divider"></div>
      <div matRipple class="switch-mode-button" [matMenuTriggerFor]="menu">
        <mat-icon>keyboard_double_arrow_down</mat-icon>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item disabled class="mode-plugin-option">
          <p>{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.DISPLAYED_OPERATION' | translate }}</p>
        </button>
        <button mat-menu-item *ngFor="let operation of modeOperations"
                class="mode-plugin-option"
                (click)="assignOperationAsDisplay(operation); send(1)">
          <p>{{ 'equipmentConfiguration.' + operation.name | translate }}</p>
        </button>
      </mat-menu>
    </div>
  </section>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
