import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {AuthenticationsService} from "../services/auth/authenticationsService";
import {map, switchMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {DashboardService} from "../services/dashboard/dashboard.service";
import {DashboardSiteDto} from "../models/sites/siteDTO";
import {SiteView, ViewAccessGroupPermission} from "../models/sites/site-view";
import {UserSpace} from "../models/user-access/user.enum";
import {AccessControlService} from "../deprecated/deprecated-services/deprecated-access/access-control.service";
import {SiteStoreService} from "../services/sites/site-store.service";
import {SitesService} from "../services/sites/sites.service";
import AuthedUserAttributesEnum = UserSpace.AuthedUserAttributesEnum;
import UserRoleEnum = UserSpace.UserRoleEnum;

@Injectable({providedIn: 'root'})
export class ViewAuthGuard implements CanActivate {

  constructor(private authService: AuthenticationsService,
              private router: Router,
              private sitesV2Service: SitesService,
              private siteStore: SiteStoreService,
              private accessService: AccessControlService,
              private dashboardService: DashboardService) {
  }


  canActivate(route: ActivatedRouteSnapshot) {
    console.log("Im in ViewAuthGuard")
    /**
     * First stage : authorization
     * Reject if the user is authorized
     */
    if (!this.authService.isAuthenticated()) {
      this.authService.signOut();
      return false;
    }
    /**
     * Second stage : role authorization
     * Admins and Super admins are have full permissions (from old code not me)
     * Sent the UI permission boolean array to the opened view
     */
    if (this.isAdmin()) {
      this.accessService.currentAccessStatus.next([true, true, true]);
      return true
    }
    /**
     * Third stage : selected view path authorization
     * 1. Reject id the view is not exists in the last selected site (the user must select a site before showing the views)
     * 2. Check if the url path is exists in the selected site views tree
     * 3. Reject if the path is not exists in the selected site views tree
     * 4. Check if the user is has the permissions to access this view
     */
    return this.getSiteViewsSrcObs(route).pipe(switchMap((selectedSiteViews: SiteView[]) => {
      console.log('This the selected site views', selectedSiteViews)
      if (!selectedSiteViews || selectedSiteViews.length == 0) return this.rejectAuthorization()
      console.log('views passed')
      let selectedView = this.getTargetViewObjectUsingRoutUrl(selectedSiteViews, route);
      console.log('Get the view from the tree :', selectedView)
      if (!selectedView) return this.rejectAuthorization();
      console.log('views was found ')
      if (!this.checkViewPermissions(selectedView)) return this.rejectAuthorization();
      console.log('permissions passed')
      return of(true)
    }))
  }

  private getSiteViewsSrcObs(route: ActivatedRouteSnapshot): Observable<SiteView[]> {
    let routeSiteId = route['_routerState'].url.split('/')[1];
    let isViewExists = this.siteStore.sitesViewsExists(routeSiteId);
    // the views will be returned from the store , because in the first emit the obs will still hold the unUpdated site with no views)
    let dashboardSelectedSiteObs: Observable<SiteView[]> = this.dashboardService.dashboardSelectedSiteObs.pipe(map((selectedSite: DashboardSiteDto) => {
      return this.siteStore.getSiteViews(selectedSite._id)
    }))
    return isViewExists ? dashboardSelectedSiteObs : this.sitesV2Service.getSiteViews(routeSiteId)
  }

  /**
   * Check if the rout (view name section) is included(exists) in one of the views tree nodes path
   * @param authorizedUserViews
   * @param route
   * @private
   */
  private getTargetViewObjectUsingRoutUrl(authorizedUserViews: SiteView[], route: ActivatedRouteSnapshot): SiteView {
    let pathToAuthorize = route['_routerState'].url;
    console.log('search on this path :', pathToAuthorize)
    return this.searchInViewUrl(authorizedUserViews, pathToAuthorize)
  }

  /**
   * BFS algorithm
   * @param authorizedUserViews
   * @param pathToAuthorize
   * @private
   */
  private searchInViewUrl(authorizedUserViews: SiteView[], pathToAuthorize: string): SiteView {
    let searchQueue: SiteView[] = [...authorizedUserViews];
    while (searchQueue.length > 0) {
      let viewToCheck: SiteView = searchQueue.shift();
      if (viewToCheck.path == pathToAuthorize) return viewToCheck
      if (viewToCheck?.subViews?.length > 0) searchQueue = [...searchQueue, ...viewToCheck?.subViews]
    }
    return null
  }

  /**
   * 1. Get the logged-in user access group from auth service(local storage)
   * 2. If the groups are not exists : Reject
   * 3. filter the view group permission based on the logged-in user access groups
   * 4. If the permission are empty : Reject
   * 5. Sum all the filtered permissions arrays
   * 6. Send the permissions array to the access service
   * 7. The user is authorized if any permission is true
   * @param selectedView
   * @private
   */
  private checkViewPermissions(selectedView: SiteView): boolean {
    let authorizedGroups: string[] = this.authService.getAuthedUserData(AuthedUserAttributesEnum.ACCESS_GROUPS);
    console.log('logged is user permissions: ', authorizedGroups)
    if (!authorizedGroups) return false;
    let authorizedGroupInSelectedView: ViewAccessGroupPermission[] = selectedView.accessGroupPermissions.filter(ele => authorizedGroups.includes(ele.group.id));
    let authorizedPermissionMatrix: boolean[][] = authorizedGroupInSelectedView.map(ele => ele.permissions);
    console.log('Selected groups permissions Matrix: ', authorizedPermissionMatrix)
    if (!authorizedPermissionMatrix?.length) return false;
    let viewAccessPermissions: boolean[] = authorizedPermissionMatrix.reduce((permission: boolean[], currentValue: boolean[]) => {
      return permission.map((ele, index) => ele || currentValue[index])
    }, [false, false, false]);
    console.log('permissions Matrix result: ', viewAccessPermissions)
    this.accessService.currentAccessStatus.next(viewAccessPermissions);
    return viewAccessPermissions.includes(true)
  }

  /**
   * Lock the UI and sign out the user
   * @private
   */
  private rejectAuthorization(): Observable<boolean> {
    this.accessService.currentAccessStatus.next([false, false, false]);
    this.authService.signOut();
    return of(false)
  }

  isAdmin(): boolean {
    let role = +this.authService.getAuthedUserData(AuthedUserAttributesEnum.ROLE);
    console.log("My role is :", role)
    return role === UserRoleEnum.SUPER_ADMIN || role === UserRoleEnum.ADMIN;
  }


}
