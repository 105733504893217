<div class="metro-card-container">
  <ng-container *skeleton="!stopInfo;repeat: 1; component:loadingSkeletonComponent;width: '100%';height:'100%'">
  <section>
    <img src="assets/weather-window/metro-m.svg" alt="metro-m">
    <p class="metro-name">{{ stationStop.name }}</p>
    <div>
      <p>{{ stationStop.walkingDistance }}</p>
      <mat-icon>directions_walk</mat-icon>
    </div>
  </section>
  <section>
    <div class="station" *ngFor="let stop of stationStop.stops; let index=index">
      <img [src]="'assets/weather-window/' + stop.icon" alt="metro-station">
      <div class="route" *ngIf="stopInfo">
        <ng-container
          *ngTemplateOutlet="stopSection; context: { stopName: stop?.name1, stopTime: stop.time1}"></ng-container>
        <div class="divider" *ngIf="stop?.time2"></div>
        <ng-container
          *ngTemplateOutlet="stopSection; context: { stopName: stop?.name2, stopTime: stop.time2}"></ng-container>
      </div>
    </div>
  </section>
  </ng-container>
</div>


<ng-template #stopSection let-stopName="stopName" let-stopTime="stopTime">
  <div class="sub">
    <p>{{ stopInfo[stopName]?.destinationName }}</p>
    <div>{{ stopTime }}mn</div>
  </div>
</ng-template>
