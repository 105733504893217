import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PluginControl } from '../plugin-control.abstract';
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

interface FanOperation {
  name: string;
  operation: number;
}

enum Modes {
  ALL,
  MANUAL
}

@Component({
  selector: 'orientation-control-plugin',
  templateUrl: './orientation-control-plugin.component.html',
  styleUrls: ['./orientation-control-plugin.component.scss']
})
export class OrientationControlPluginComponent extends PluginControl implements OnInit, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;
  @Input('templateMode') templateMode: boolean = false;

  value: number;

  ModesEnum: typeof Modes = Modes;

  all: number;
  manualNormal: number;

  operationAsDisplay = {} as FanOperation;

  orientationOperations: FanOperation[] = [
    {
      name: 'none',
      operation: null
    },
    {
      name: 'vertical',
      operation: null
    },
    {
      name: 'horizontal',
      operation: null
    },
  ];

  initialized: boolean = false;

  subject: Subject<any> = new Subject();
  subscription: Subscription = new Subscription();

  selectedButton: number = 0;

  subscriptions: Subscription = new Subscription();
  data: [];
  plotData: any[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {

    super();

    this.subscription.add(
      this.subject.pipe(debounceTime(1000)).subscribe((data) => {
        this.setMode(data.mode, data?.initialize);
      })
    );
  }

  ngOnInit(): void {
    this.all = this.configuration.additionalInfo['all'];
    this.initializeModes();

    if (this.templateMode) {
      this.setValue(this.configuration?.setValue);
    };

    this.setInitValue && this.send(Modes.ALL);
  }

  setValue(value: number) {
    this.value = value;
    this.send(this.value, 'initialize');
    this.changeDetectorRef.markForCheck();
  }

  getValue(value: number) {
    // Do sth
  }

  initializeModes() {
    this.orientationOperations.forEach((operation: FanOperation) => {
      operation.operation = this.configuration.additionalInfo[operation.name];
    });

    if (this.orientationOperations?.length > 0) { this.assignOperationAsDisplay(this.orientationOperations[0]) };

  }

  assignOperationAsDisplay(operation: FanOperation) {

    if (operation == undefined) {
      this.selectedButton = 0;
      return;
    };

    (this.operationAsDisplay.name) ? (this.orientationOperations.push(JSON.parse(JSON.stringify(this.operationAsDisplay)))) : (null);
    this.operationAsDisplay = operation;
    this.orientationOperations = this.orientationOperations.filter((op: FanOperation) => op?.name !== operation?.name);

  }


  send(mode: number, initialize?) {
    this.subject.next({
      mode: mode,
      initialize: initialize
    });
  }

  setMode(mode, initialize?) {

    if (initialize) {

      if (mode == this.all) {
        this.selectedButton = 2;
      } else  {
        this.selectedButton = 1;
        (this.operationAsDisplay.operation == mode) ? (this.selectedButton = 1) : (this.assignOperationAsDisplay(this.orientationOperations.find((op: FanOperation) => op.operation == mode)));
      }

    } else {
      switch (mode) {

        case (Modes.MANUAL):
          this.selectedButton = 1;
          if (!initialize) { this.generalSetMode(this.operationAsDisplay.operation) };
          break;

        case (Modes.ALL):
          this.selectedButton = 2;
          if (!initialize) { this.generalSetMode(this.all) };
          break;

        default:
          // Do nothing

      };
    }



  }

  generalSetMode(mode: number) {
    this.configuration.setValue = mode;
    this.outputValue.next(mode);
  }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

