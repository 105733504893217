import { Observable, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
/**
 * Contains Behavioral Subjects that other Timeline-related
 * components use to communicate. e.g.,
 * timeline.component.ts
 * item-timeline-manager.abstract.ts
 * This is a communication channel that alerts the other
 * components of value changes.
 */
@Injectable({providedIn:'root'})
export class TimelineService {
  private date = new BehaviorSubject<Date>(null); //Behavioral Subject have an initial value.
  private endDate = new BehaviorSubject<Date>(null);
  private time = new BehaviorSubject<Date>(null);
  private realTime = new BehaviorSubject<boolean>(null);
  private reportingTime = new BehaviorSubject<boolean>(false);

  constructor() {}

  setDate(date: Date) {
    this.date.next(date);
  }

  setEndDate(date: Date) {
    this.endDate.next(date);
  }

  setReportingTime(reporting: boolean) {
    this.reportingTime.next(reporting);
  }

  setTime(time: Date) {
    this.time.next(time);
  }

  setRealTime(realTime: boolean) {
    this.realTime.next(realTime);
  }

  getDateObservable(): Observable<Date> {
    return this.date.asObservable().pipe(debounceTime(500));
  }

  getTimeObservable(): Observable<Date> {
    return this.time.asObservable().pipe(debounceTime(500));
  }

  getRealTimeObservable(): Observable<boolean> {
    return this.realTime.asObservable();
  }

  getEndDateObservable(): Observable<Date> {
    return this.endDate.asObservable().pipe(debounceTime(500));
  }

  getReportingTimeObservable(): Observable<boolean> {
    return this.reportingTime.asObservable();
  }



}
