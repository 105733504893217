import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SiteFloor} from "../../../../core/models/sites/site-floor";
import {SitesService} from "../../../../core/services/sites/sites.service";
import {EquipmentUtilsService} from "../../../../core/services/sites-equipments/equipment-utils.service";
import {debounceTime, map, tap} from "rxjs/operators";
import {UtilsConfigurations} from "../../../../core/models/site-equipments/equipment/equipment-utils";
import {Observable, of, Subject, Subscription} from "rxjs";
import {EquipmentCategory} from "../../../../core/models/site-equipments/equipment/equipment/equipment-category";
import {FloorZone} from "../../../../core/models/dashboard-controls/floor-zone";
import {
  ControlsFiltrationLocalStorage,
  ControlsFiltrationModel
} from "../../../../core/models/dashboard-controls/controls-filtration-model";
import {SiteZone} from "../../../../core/models/sites/site-zone";
import {
  ControlsDashboardManagementService
} from "../../../../core/services/controls-dashboard/controls-dashboard-management.service";
import {LocalStorageService} from "../../../../core/services/localstorage/local-storage.service";
import {LocalStorageKeysEnum} from "../../../../core/services/localstorage/local-storage-keys.enum";
import {ActivatedRoute} from "@angular/router";
import {SideBarService} from "../../../../core/services/sidebar/sidebar.service";


@Component({
  selector: 'gmao-controls-dashboard-filtering',
  templateUrl: './controls-dashboard-filtering.component.html',
  styleUrls: ['./controls-dashboard-filtering.component.scss']
})
export class ControlsDashboardFilteringComponent implements OnInit {
  /**
   * Filter object, updated when select new floor, zone and category
   */
  @Input('filtrationModel') filtrationModel: ControlsFiltrationModel = {} as ControlsFiltrationModel;
  /**
   * Emit boolean value to determine which view should show, emitted when click on view icons
   * @see ControlsDashboardFilteringComponent.changeView
   */
  @Output('changeViewControlTypeEmitter') changeViewControlTypeEmitter = new EventEmitter<boolean>();
  /**
   * Emit thumb image for selected floor, thumb image used in plan view
   * Emitted when selected new floor
   * @see ControlsDashboardFilteringComponent.handleFloorSelection
   */
  @Output('changeThumbImgEmitter') changeThumbImgEmitter = new EventEmitter<string>();
  /**
   * Emit filter values to parent, to use in get filtered controls
   * @see ControlsDashboardFilteringComponent.subscribeOptionsChanging
   * @see ControlsDashboardComponent.handleFilterChange
   */
  @Output('changeFilterEmitter') changeFilterEmitter = new EventEmitter<void>();
  isPlanView: boolean = false;
  filterObs$: Subject<void> = new Subject();
  subscription: Subscription = null;
  siteId: string;
  floors: SiteFloor[];
  constructor(private sitesService: SitesService,
              private activatedRoute: ActivatedRoute,
              private controlsDashboardManagementService: ControlsDashboardManagementService,
              private localStorageService: LocalStorageService,
              private sideBarService: SideBarService,
              private utilsService: EquipmentUtilsService) {
  }

  ngOnInit(): void {
    this.setSiteId();
    this.subscribeOptionsChanging();
    this.setEditMenuSetting();
  }

  private setSiteId() {
    this.siteId = this.activatedRoute.snapshot.params['siteId'];
  }

  /**
   * Subscribe filter value changes, and emit new filters value to parent after 1500ms
   * @private
   */
  private subscribeOptionsChanging() {
    this.subscription = this.filterObs$.pipe(debounceTime(1500)).subscribe(() => {
      let equipmentFilter: ControlsFiltrationLocalStorage = {
        floorId: this.filtrationModel.floorId,
        zone: this.filtrationModel.zone,
        categoryId: this.filtrationModel.categoryId
      }
      this.localStorageService.setItem(LocalStorageKeysEnum.EQUIPMENT_CONTROLS_FILTERS, equipmentFilter)
      this.changeFilterEmitter.emit();
    })
  }

  /**
   * set the initial value of menu edit toggle
   */
  setEditMenuSetting() {
    this.sideBarService.changeEditButton(this.isPlanView);
  }

  /**
   * Handle change view selection, and emit boolean value to switch view depends on boolean value
   * @param isPlanView
   */
  changeView(isPlanView: boolean) {
    this.isPlanView = isPlanView;
    this.changeViewControlTypeEmitter.emit(isPlanView);
    if(this.isPlanView && !this.filtrationModel.floorId){
      this.filtrationModel.floorId = this.floors[0]?._id;
      this.filtrationModel.zone = null;
      this.controlsDashboardManagementService.setSelectedFloor(this.floors[0]);
      this.changeThumbImgEmitter.emit(this.floors[0]?.thumbImage);
    }

    this.sideBarService.changeEditButton(this.isPlanView);
  }


  getFloors = (): Observable<SiteFloor[]> => {
    return this.sitesService.getSiteFloors(this.siteId).pipe(
      tap((floors: SiteFloor[]) => {
        this.floors = floors;
        this.controlsDashboardManagementService.setFloorsMap(floors);
        this.initialSearchForm(floors);
      })
    )
  }

  private initialSearchForm(floors: SiteFloor[]) {
    let equipmentFilter: ControlsFiltrationLocalStorage = this.localStorageService.getLocalStorageItem(LocalStorageKeysEnum.EQUIPMENT_CONTROLS_FILTERS);
    let initialFloorId: string = equipmentFilter?.floorId;
    let initialFloor: SiteFloor = floors.find((floor: SiteFloor) => floor._id == equipmentFilter?.floorId) ?? floors[0];
    let initialThumbImg: string = initialFloor?.thumbImage;
    let filterModel: ControlsFiltrationModel = {
      siteId: this.siteId,
      floorId: initialFloorId,
      categoryId: equipmentFilter?.categoryId,
      zone: equipmentFilter?.zone
    }
    Object.assign(this.filtrationModel, filterModel);
    this.changeFilterEmitter.emit();
    this.changeThumbImgEmitter.emit(initialThumbImg);
    this.controlsDashboardManagementService.setSelectedFloor(initialFloor);
    this.getZones = () => this.getZoneObservable(this.filtrationModel.floorId);
  }
  /**
   * Handle new floor selection by emit floor thumb image, emit new filters value and get new zones list value
   * @see ControlsDashboardFilteringComponent.subscribeOptionsChanging
   * @param result
   */
  handleFloorSelection(result: SiteFloor) {
    this.filtrationModel.floorId = result._id == 'all' ? null : result._id;
    this.filtrationModel.zone = null;
    this.controlsDashboardManagementService.setSelectedFloor(result);
    this.changeThumbImgEmitter.emit(result.thumbImage);
    this.getZones = () => this.getZoneObservable(this.filtrationModel.floorId)
    this.filterObs$.next();
  }


  private getZoneObservable(floorId: string): Observable<SiteZone[]> {
    let floorIds: string[] = floorId ? [floorId] : null;
    return this.sitesService.getSiteZone(floorIds).pipe(
      tap((data: SiteZone[]) => this.controlsDashboardManagementService.setZoneMap(data))
    );
  }

  getZones = (): Observable<FloorZone[]> => {
    return of([])
  }

  /**
   * Handle zone selection
   * @see ControlsDashboardFilteringComponent.subscribeOptionsChanging
   * @param zoneId
   */
  handleZoneSelection(zoneId: string) {
    if (!zoneId || zoneId == this.filtrationModel.zone) return;
    this.filtrationModel.zone = zoneId == 'all' ? null : zoneId;
    this.filterObs$.next();
  }

  getCategories = (): Observable<EquipmentCategory[]> => {
    return this.utilsService.getAllUtils().pipe(map((data: UtilsConfigurations) => data.equipment?.categories))
  }


  /**
   * Handle Category selection
   * @see ControlsDashboardFilteringComponent.subscribeOptionsChanging
   * @param categoryId
   */
  handleCategorySelection(categoryId: string) {
    if (!categoryId || (categoryId == 'all' && !this.filtrationModel.categoryId)) return;
    this.filtrationModel.categoryId = categoryId == 'all' ? null : categoryId;
    this.filterObs$.next();
  }
}
