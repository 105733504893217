import {Injectable} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {LocalStorageKeysEnum, LocalStorageKeysTypes} from "./local-storage-keys.enum";
import {UserSpace} from "../../models/user-access/user.enum";
import LanguagesTypes = UserSpace.LanguagesTypes;


@Injectable({providedIn: 'root'})
export class LocalStorageService {
  constructor(private translate: TranslateService) {
    this.saveApplicationGeneralData();
  }

  getLocalStorageItem(itemKey: LocalStorageKeysTypes) {
    return JSON.parse(localStorage.getItem(itemKey));
  }

  getLocalStorageStringItem(itemKey: LocalStorageKeysTypes) {
    return localStorage.getItem(itemKey);
  }

  setItem(itemKey: LocalStorageKeysTypes, item) {
    if (typeof item == typeof '') {
      localStorage.setItem(itemKey, item)
      return
    }
    localStorage.setItem(itemKey, JSON.stringify(item));
  }

  removeItem(itemKey: LocalStorageKeysTypes) {
    localStorage.removeItem(itemKey)
  }

  removeItems(itemKeys: LocalStorageKeysTypes[]) {
    itemKeys.forEach(key => localStorage.removeItem(key))
  }


  private setApplicationLAngularSettings() {
    if (!localStorage.getItem('defaultLang'))
      localStorage.setItem('defaultLang', 'fr');
    if (!localStorage.getItem(LocalStorageKeysEnum.USED_LANGUAGE))
      localStorage.setItem(LocalStorageKeysEnum.USED_LANGUAGE, 'fr');
    this.translate.setDefaultLang(localStorage.getItem('defaultLang'));
    this.translate.use(localStorage.getItem(LocalStorageKeysEnum.USED_LANGUAGE));
  }

  changeAppLanguage(language: LanguagesTypes) {
    localStorage.setItem(LocalStorageKeysEnum.USED_LANGUAGE, language);
    this.translate.use(language);
  }

  private saveApplicationGeneralData() {
    this.setApplicationLAngularSettings();
  }

}
