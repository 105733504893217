import {Injectable} from '@angular/core';
import {SiteEquipmentModel} from "../../models/site-equipments/site-equipment.model";
import {PopulatedEquipment} from "../../models/site-equipments/equipment/populated.equipment";
import {SiteDto} from "../../models/sites/siteDTO";
import {SiteFloor} from "../../models/sites/site-floor";
import {BehaviorSubject, Observable} from "rxjs";
import {SiteZone} from "../../models/sites/site-zone";
import * as _ from "underscore";
import {SiteEquipmentDto} from "../../models/site-equipments/site-equipment-dto";
import {EquipmentCategory} from "../../models/site-equipments/equipment/equipment/equipment-category";
import {
  SiteEquipmentMultipleUpdate,
  SiteEquipmentMultipleUpdateData
} from "../../models/site-equipments/site-equipment-multiple-update";
import {FilterAreaConfig} from "../../view-models/filter-area-config";
import {SiteEquipmentsFiltersArea} from "../../models/site-equipments/site-equipments-filters-area";


@Injectable({providedIn: 'root'})
export class SitesEquipmentsManagementService {

  private zoneSubject = new BehaviorSubject<{ [_id: string]: SiteZone }>(null);
  zoneObs: Observable<{ [_id: string]: SiteZone }> = this.zoneSubject.asObservable();

  private floorSubject = new BehaviorSubject<{ [_id: string]: SiteFloor }>(null);
  floorObs: Observable<{ [_id: string]: SiteFloor }> = this.floorSubject.asObservable();

  constructor() {
  }

  getSiteEquipmentCategory(equipment: SiteEquipmentModel): EquipmentCategory {
    return equipment?.equipment?.equipmentModel?.type?.category
  }

  getSiteEquipmentSiteName(equipment: SiteEquipmentModel, sitesList: SiteDto[]): string {
    let index = sitesList.findIndex(ele => ele._id == equipment.buildingId);
    return sitesList[index]?.name
  }

  setZoneMap(siteZones: SiteZone[]) {
    let locationMap = _.indexBy(siteZones, '_id');
    this.zoneSubject.next(locationMap);
  }

  setFloorsMap(floors: SiteFloor[]) {
    let floorsMap = _.indexBy(floors, '_id');
    this.floorSubject.next(floorsMap);
  }

  getInitialEquipmentObject(): SiteEquipmentModel {
    let newEquipment: PopulatedEquipment = {
      characteristics: [],
      documents: [],
      equipmentComponents: [],
      equipmentConfigurations: [],
      nomenClature: '',
      note: '',
      photos: [],
      scadaPhoto: [],
      update: true,
      version: '',
      year: null,
      equipmentModel: {
        model: '',
        _id: null,
        type: {
          type: '',
          _id: null,
          category: {
            _id: null,
            category: ''
          }
        },
        brand: {
          brand: '',
          _id: null
        }
      }
    }

    return {
      accessGroups: [],
      buildingId: '',
      equipment: newEquipment,
      equipmentConfigurations: [],
      equipmentId: null,
      floorIndex: null,
      isControl: false,
      isScada: false,
      localization: '',
      nomenClature: '',
      qrCode: '',
      scadaPhoto: [],
      serialNumber: '',
      status: null
    }
  }

  convertEquipmentToDTO(equipment: SiteEquipmentModel, sitesList: SiteDto[]): SiteEquipmentDto {
    return {
      name: equipment.nomenClature,
      siteId: equipment.buildingId,
      category: this.getSiteEquipmentCategory(equipment),
      id: equipment._id,
      type: equipment.equipment.equipmentModel.type.type,
      floorId: equipment.floorId,
      localization: equipment.localization,
      siteName: this.getSiteEquipmentSiteName(equipment, sitesList),
      status: equipment.status
    }
  }


  prepareUpdatedEquipments(data: SiteEquipmentMultipleUpdateData): SiteEquipmentMultipleUpdate[] {
    let equipments: SiteEquipmentMultipleUpdate[] = [];
    data.selectedEquipmentList.forEach((equipment: SiteEquipmentModel) => {
      let equipmentUpdated: SiteEquipmentMultipleUpdate = {
        _id: equipment._id,
        accessGroups: data.selectedGroups
      }
      data?.selectedFloor && (equipmentUpdated['floorId'] = data.selectedFloor);
      data?.selectedZone && (equipmentUpdated['localization'] = data.selectedZone);
      equipments.push(equipmentUpdated);
    })
    return equipments;
  }


  createFiltrationAreaConfig(categories: EquipmentCategory[]): FilterAreaConfig {
    return {
      nomenClature: {
        label: "FORMS.NAME",
        placeHolder: "FORMS.NAME",
        value: ""
      },
      categoriesIds: {
        label: "FORMS.CATEGORY",
        placeHolder: "FORMS.CATEGORY",
        value: [],
        optionList: categories,
        filterKay: 'category',
        uniqueId: "_id"
      }
    }
  }
}

