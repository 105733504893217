<div class="w-100p" *ngIf="canEdit && roleId <= maxAllowedRoleId">
  <mat-form-field class="w-100p" [ngClass]="{'display-none':!outline}" appearance="outline">
    <mat-icon matSuffix *ngIf="error" class="txt-red">info</mat-icon>
    <mat-label *ngIf="showLabel" class="txt-darkgray"> {{label | translate | capitalize}}</mat-label>
    <div [ngClass]="classes">
      <input id="location-input" #location type="text" matInput (keyup)="filterLocations($event.target.value)"
             [(ngModel)]="locationName"
             [matAutocomplete]="locationAuto">
    </div>
  </mat-form-field>
  <div class="w-100p" [ngClass]="{'display-none':outline}">
    <mat-label *ngIf="showLabel" class="txt-darkgray"> {{ label | translate | capitalize}}</mat-label>
    <div [ngClass]="classes" class=" w-100p">
      <input placeholder="{{label | translate | capitalize}}" #location1 id="location-input"
             class="w-100p h-100p px-15px" type="text" matInput (keyup)="filterLocations($event.target.value)"
             [(ngModel)]="locationName"
             [matAutocomplete]="locationAuto">
    </div>
  </div>
  <mat-autocomplete #locationAuto="matAutocomplete">
    <mat-option *ngIf="locationLoading" (click)="$event.stopPropagation()">
      <mat-spinner [diameter]="25" class="margin-auto"></mat-spinner>
    </mat-option>
    <div *ngIf="!locationLoading">
      <mat-option *ngFor="let location of filteredLocations | async; let index = index"
                  [value]="location?._id" [matTooltip]="location?.name" (click)="selectLocation(location);"
                  [id]="'location-input-option_'+index">
        <div>
          {{location?.name}}
        </div>
      </mat-option>
    </div>
  </mat-autocomplete>
</div>
