import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'gmao-label-rating',
  templateUrl: './label-rating.component.html',
  styleUrls: ['./label-rating.component.scss']
})
/**
 * Take value and each label's range, then display the label rating.
 * @deprecated
 */
export class LabelRatingComponent implements OnInit {
  /**
   * @deprecated
   */
  @Input()
  value: number;
  /**
   * @deprecated
   */
  @Input()
  unit: string;
  /**
   * an array of objects contain color and range for each label
   * @deprecated
   */
  @Input()
  data: any[];
  /**
   * @deprecated
   */
  @Input()
  title: string;
  ratingIndex: number = 0;
  hover: boolean[] = [];
  @ViewChild('widget', { static: true })
  widget: ElementRef;
  fittextElement;
  constructor(private translateService: TranslateService) {}

  /**
   * @deprecated
   */
  ngOnInit() {
    this.fittextElement = this.widget.nativeElement;
    this.setRatingIndex();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setRatingIndex();
  }

  setRatingIndex() {
    for (let index = this.data.length - 1; index > 0; index--) {
      if (this.data[index].min <= this.value) {
        this.ratingIndex = index;
        break;
      }
    }
  }

  getCharStyles(index) {
    let styles = {
      'background-color': this.data[index].color,
      'font-size': this.fittextElement.offsetWidth / 25 + 'px'
    };
    if (this.hover[index]) {
      styles['border-top-color'] = this.data[index].color;
      styles['border-bottom-color'] = this.data[index].color;
      if (index === 0) {
        styles['border-left-color'] = this.data[index].color;
      } else if ((index = this.data.length - 1)) {
        styles['border-right-color'] = this.data[index].color;
      }
    }
    return styles;
  }
}
