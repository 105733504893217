import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EquipmentScadaDashboardComponent} from './equipment-scada-dashboard.component';
import {ViewAuthGuard} from "../../../core/gaurds/view-auth-guard.service";

const routes: Routes = [
  {
    path: ':siteId/equipment-scada/:name',
    component: EquipmentScadaDashboardComponent,
    data: {featureType: 'VIEW',icon: 'map'},
    canActivate: [ViewAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class EquipmentScadaDashboardRoutingModule {
}
