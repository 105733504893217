export enum SideBarType {
  HOME,
  SCHEMA,
  ADMIN,
  SYNTHESE,
  ALARM,
  INFORMATION,
  GMAO,
  EMPTY,
  SUIVICONSO,
  TOOLBAR,
  EQUIPMENTCONTROL
}
