<div class="nodes-list-dialog-form bold-500-form-text">
  <mat-form-field appearance="outline">
    <mat-label>{{'FORMS.GATEWAYS' | translate}}</mat-label>
    <mat-spinner *ngIf="loadingGatewaysLists" class="internal-spinner" diameter="20"></mat-spinner>
    <mat-select [value]="iotDeviceNodeId" [placeholder]="'FORMS.GATEWAYS' | translate | capitalize" [disabled]="!gatewaysList || gatewaysList?.length==0" (selectionChange)="newGatewaySelected($event)" panelClass="customized-500-bold-mat-select">
      <mat-option *ngFor="let gateways  of gatewaysList" [value]="gateways.nodeId">
        {{gateways.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'FORMS.APPLICATIONS' | translate}}</mat-label>
    <mat-spinner *ngIf="loadingApplicationsLists" class="internal-spinner" diameter="20"></mat-spinner>
    <mat-select [value]="applicationNodeId" [placeholder]="'FORMS.APPLICATIONS' | translate | capitalize" [disabled]="!applicationsList || applicationsList?.length==0" (selectionChange)="newApplicationSelected($event)" panelClass="customized-500-bold-mat-select">
      <mat-option *ngFor="let application  of applicationsList" [value]="application.nodeId" [matTooltip]="application.name">
        {{application.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'FORMS.SEARCH' | translate | capitalize}}</mat-label>
    <input matInput [(ngModel)]="filterString" #searchInput [disabled]="!selectedContainer"
           [placeholder]="'FORMS.SEARCH' | translate"
           (keyup)="filterStringUpdated.emit(searchInput.value)">
  </mat-form-field>

</div>
