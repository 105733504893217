import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

interface EfficiencyBar {
  label: string,
  from: number,
  to: number,
  width: string,
  background: string,
  objective: string,
  selected: boolean,
  percent?: string
}

@Component({
  selector: 'gmao-energy-efficiency-stats',
  templateUrl: './energy-efficiency-stats.component.html',
  styleUrls: ['./energy-efficiency-stats.component.scss']
})

export class EnergyEfficiencyStatsComponent implements OnInit, OnChanges {
  @Input('referenceYearConsumption') referenceYearConsumption: number = 0;
  @Input('targetYearConsumption') targetYearConsumption: number = 0;
  @Input() unit: string = 'kWh';
  energyBars: EfficiencyBar[] = [];

  efficiencyTiers = [
    {label: 'A', min: 0, max: 50},
    {label: 'B', min: 51, max: 90},
    {label: 'C', min: 91, max: 150},
    {label: 'D', min: 151, max: 230},
    {label: 'E', min: 231, max: 330},
    {label: 'F', min: 331, max: 450},
    {label: 'G', min: 450, max: Number.MAX_VALUE},
  ]

  percentages = [
    0.3,
    0.4,
    0.5,
    0.6,
  ]

  constructor(
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.calculateEfficiency();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.calculateEfficiency();
  }

  calculateEfficiency() {
    this.energyBars = [
      {
        label: this.efficiencyTiers[0].label,
        from: this.efficiencyTiers[0].min,
        to: this.efficiencyTiers[0].max,
        width: '20',
        background: '#018133',
        objective: this.calculateObjectivePercent(0),
        selected: this.targetYearConsumption <= 50 || this.referenceYearConsumption <= 50,
      },
      {
        label: this.efficiencyTiers[1].label,
        from: this.efficiencyTiers[1].min,
        to: this.efficiencyTiers[1].max,
        width: '22',
        background: '#27b432',
        objective: this.calculateObjectivePercent(1),
        selected: this.between(this.targetYearConsumption, 50, 90) || this.between(this.referenceYearConsumption, 50, 90),
      },
      {
        label: this.efficiencyTiers[2].label,
        from: this.efficiencyTiers[2].min,
        to: this.efficiencyTiers[2].max,
        width: '24',
        background: '#88e213',
        objective: this.calculateObjectivePercent(2),
        selected: this.between(this.targetYearConsumption, 90, 150) || this.between(this.referenceYearConsumption, 90, 150),
      },
      {
        label: this.efficiencyTiers[3].label,
        from: this.efficiencyTiers[3].min,
        to: this.efficiencyTiers[3].max,
        width: '26',
        background: '#f0ef00',
        objective: this.calculateObjectivePercent(3),
        selected: this.between(this.targetYearConsumption, 150, 230) || this.between(this.referenceYearConsumption, 150, 230),
      },
      {
        label: this.efficiencyTiers[4].label,
        from: this.efficiencyTiers[4].min,
        to: this.efficiencyTiers[4].max,
        width: '28',
        background: '#fc9000',
        objective: this.calculateObjectivePercent(4),
        selected: this.between(this.targetYearConsumption, 230, 330) || this.between(this.referenceYearConsumption, 230, 330),
      },
      {
        label: this.efficiencyTiers[5].label,
        from: this.efficiencyTiers[5].min,
        to: this.efficiencyTiers[5].max,
        width: '30',
        background: '#fd0000',
        objective: this.calculateObjectivePercent(5),
        selected: this.between(this.targetYearConsumption, 330, 450) || this.between(this.referenceYearConsumption, 330, 450),
      },
      {
        label: this.efficiencyTiers[6].label,
        from: this.efficiencyTiers[6].min,
        to: this.efficiencyTiers[6].max,
        width: '32',
        background: '#fd001d',
        objective: this.calculateObjectivePercent(6),
        selected: this.targetYearConsumption >= 450 || this.referenceYearConsumption >= 450,
      }

    ];

  }

  private calculateObjectivePercent(index: number): string {
    let objectiveText = '';

    if (this.between(this.referenceYearConsumption, this.efficiencyTiers[index].min, this.efficiencyTiers[index].max)) {
      objectiveText += `${this.translateService.instant('ENERGY_CONSUMPTION.REFERENCE')} : ${this.referenceYearConsumption.toFixed()} ${this.unit}`;
    } else {
      let targets = `${this.translateService.instant('ENERGY_CONSUMPTION.TARGET')} : `;
      let setTargets = false;
      this.percentages.forEach(value => {
        let objectiveNumber = this.referenceYearConsumption * (1 - value);
        if (this.between(objectiveNumber, this.efficiencyTiers[index].min, this.efficiencyTiers[index].max)) {
          targets += '-' + value * 100 + '% ';
          setTargets = true;
        }
      })
      setTargets && (objectiveText += targets);
    }

    if (this.between(this.targetYearConsumption, this.efficiencyTiers[index].min, this.efficiencyTiers[index].max)) {
      if (objectiveText.length != 0) objectiveText += ' | ';
      objectiveText += `${this.translateService.instant('ENERGY_CONSUMPTION.CURRENT')} : ${this.targetYearConsumption.toFixed()} ${this.unit}`;
    }


    return objectiveText;
  }

  private between(x, min, max) {
    return x >= min && x < max;
  }

  protected readonly Number = Number;
}
