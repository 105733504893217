import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gmao-multi-select-auto-complete-skeleton',
  templateUrl: './multi-select-auto-complete-skeleton.component.html',
  styleUrls: ['./multi-select-auto-complete-skeleton.component.scss']
})
export class MultiSelectAutoCompleteSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
