import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SGInjectCellDirective} from "./inject-cell.directive";
import {Subscription} from 'rxjs';
import {SgTableUpdateEvent} from '../sg-table-update-event';
import {SelectedTableRowEvent} from "../selected-table-row-event";

@Component({
  selector: 'sg-table-actions-cell',
  templateUrl: './sg-table-actions-cell.component.html',
  styleUrls: ['./sg-table-actions-cell.component.scss']
})
export class SgTableActionsCellComponent implements OnInit {

  @Input('cellObject') cellObject: any = null;
  @Input('index') index: number = 0;
  @Input('updateRowEmitter') updateRowEmitter: EventEmitter<null>;
  @Input('componentToInject') componentToInject = null;
  @Input('injectComponentInputs') injectComponentInputs = null;
  @Input('rowClickedEmitter') rowClickedEmitter: EventEmitter<SelectedTableRowEvent<any>>;
  @Output('deleteRecord') deleteRecord = new EventEmitter<string | number>();
  @Output('updateRecord') updateRecord = new EventEmitter<SgTableUpdateEvent>();
  /**
   * reference for the injection point , used when injecting a plugin component
   */
  @ViewChild(SGInjectCellDirective, {static: true}) injectHost!: SGInjectCellDirective;

  private updateRowSubscription: Subscription = null;

  @Input() set parentRowSelected(selected: boolean) {
    this.updateRowEmitter && selected ? this.subscribeRecordChanges() : this.updateRowSubscription?.unsubscribe();
  };

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.injectComponent()
  }

  injectComponent() {
    const viewContainerRef = this.injectHost.viewContainerRef;
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentToInject);
    viewContainerRef.clear();
    const componentRef: any = viewContainerRef.createComponent(componentFactory);

    componentRef.instance.dataSrc = this.cellObject;
    componentRef.instance.cellIndex = this.index;
    componentRef.instance.rowClickedEmitter = this.rowClickedEmitter;
    if (this.injectComponentInputs) componentRef.instance.injectComponentInputs = this.injectComponentInputs;
    if (componentRef.instance.deleteRecord)
      componentRef.instance.deleteRecord.subscribe((keyValue: string | number) => {
        this.deleteRecord.emit(keyValue)
      })
    if (componentRef.instance.updateRecord)
      componentRef.instance.updateRecord.subscribe((event: SgTableUpdateEvent) => {
        this.updateRecord.emit(event)
      })
  }

  private subscribeRecordChanges() {
    this.updateRowEmitter.subscribe(() => {
      this.injectComponent()
    })
  }

}
