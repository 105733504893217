<div class="control-sla-control">
  <ng-container *ngTemplateOutlet="displayValue? content : empty"></ng-container>
</div>

<ng-template #content>
  <span>{{displayValue}}</span>
  <div class="circle" [ngClass]="status"></div>
</ng-template>
<ng-template #empty>
  <p>-</p>
</ng-template>
