import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { PluginControl } from "../plugin-control.abstract";
import {TranslatedToasterService} from "../../../../../core/services/translated-toaster/translated-toaster.service";
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

interface Operation {
  operationName: string;
  operationValue: number;
}

@Component({
  selector: 'unified-scroll-menu-control-plugin',
  templateUrl: './unified-scroll-menu-control-plugin.component.html',
  styleUrls: ['./unified-scroll-menu-control-plugin.component.scss']
})
export class  UnifiedScrollMenuControlPluginComponent extends PluginControl implements OnInit {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;
  @Input('templateMode') templateMode: boolean = false;

  selectedOperation = {} as Operation;

  subscriptions: Subscription = new Subscription();
  data: [];
  plotData: any[] = [];

  preSelectedConfig: any[] = [];

  currentOperation: number;


  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translatedToasterService: TranslatedToasterService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.templateMode) {
      this.setValue(this.configuration?.setValue);
    }
  }

  setValue(value: number) {
    this.currentOperation = value;
    this.selectedOperation = this.configuration.additionalInfo.find((info) => info.operationValue == this.currentOperation);
    this.currentOperation = this.selectedOperation?.operationValue;
    this.changeDetectorRef.markForCheck();
  }

  getValue(value: number) {
    // Do sth
  }

  applyOperation(event) {
    this.selectedOperation = this.configuration.additionalInfo.find((info) => info.operationValue == event.value);
    this.generalSetMode(this.configuration.dataId, this.selectedOperation.operationValue);
  }

  reapplyOperation() {
    if (this.selectedOperation.operationValue == null || this.selectedOperation.operationValue == undefined) return;
    this.generalSetMode(this.configuration.dataId, this.selectedOperation.operationValue);
  }

  generalSetMode(dataId: number, operation: number) {
    this.configuration.setValue = operation;
    this.outputValue.next(operation);
    this.translatedToasterService.showInfoMessage("SITE_EQUIPMENTS.ORDER_TRANSMITTED_SUCCESS");
  }

}

