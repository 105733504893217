import {EventEmitter, Injectable} from "@angular/core";
import {Energy} from "../../models/energy/enums/energy.name-space";
import {HomeEnergyTypeIcon} from "../../models/home/homeEnergyTypeIcon";
import {RankedSiteInfo} from "../../models/home/ranked-site-info";
import {Home} from "../../models/home/home";
import {SiteEnergiesConsumptionsRequest} from "../../models/energy/consumptions/site-energies-consumptions-request";
import {DatePipe} from "@angular/common";
import {RankSiteRequest} from "../../models/energy/consumptions/rank-site-request";
import {RankedSite} from "../../models/home/ranked-site";
import {TranslateService} from "@ngx-translate/core";

@Injectable({providedIn: 'root'})
export class HomeManagementService {
  selectSiteEmitter: EventEmitter<string> = new EventEmitter<string>();
  highestSiteConsumptionColors: string[] = Home.highestSiteConsumptionColors;
  lowestSiteConsumptionColors: string[] = Home.lowestSiteConsumptionColors;

  constructor(public datepipe: DatePipe, private translateService: TranslateService) {
  }


  getEnergyTypesIcons(energyTypesList: string[]) {
    let energyTypeIcons: HomeEnergyTypeIcon[] = energyTypesList.map(ele => ({
      name: Energy.EnergyTypeEnum[ele],
      icon: Energy.EnergyTypeIconsEnum[ele],
      selected: false
    }));

    energyTypeIcons.push({
      name: Energy.EnergyTypeEnum.TOTAL,
      icon: './assets/icons/select-all.png',
      selected: false
    });

    return energyTypeIcons;
  }


  convertRankSiteConsumptionUnits(rankedSites: RankedSite[], isWaterEnergy: boolean, isHighestSitesConsumption: boolean): RankedSiteInfo[] {
    return rankedSites.map((site, index) => {
      let data = this.convertConsumptionsUnits(site.consumption, isWaterEnergy);
      return {
        ...site,
        color: isHighestSitesConsumption ? this.highestSiteConsumptionColors[index] : this.lowestSiteConsumptionColors[index],
        convertedTargetPeriodConsumption: `${data.value} ${this.translateService.instant(`ENERGY.ENERGY_UNIT.${data.unit}`)}`,
      }
    })
  }

  convertConsumptionsUnits(value: number, isWater?: boolean): { value: string, unit: string } {
    let unit = 'KWH';
    let convertedValue = value?.toFixed(0).toString();
    if (isWater) {
      unit = 'METER3';
    } else {
      if (value > 9999 && value < 999999) {
        convertedValue = (value / 1000).toFixed(1);
        unit = 'MWH'
      } else if (value > 999999) {
        convertedValue = (value / 1000000).toFixed(2);
        unit = 'GWH'
      }
    }
    return {value: convertedValue, unit: unit}
  }

  defaultRankingList(isHighestSitesConsumption?: boolean): RankedSiteInfo[] {
    return [...Array(4).fill(null)].map((ele, index) => ele = {
      siteReference: "",
      convertedTargetPeriodConsumption: '0',
      consumption: 0,
      color: isHighestSitesConsumption ? this.highestSiteConsumptionColors[index] : this.lowestSiteConsumptionColors[index],
    })
  }


  setStartDate() {
    let date = new Date(new Date().getFullYear(), 0, 1);
    let userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return this.datepipe.transform(new Date(date.getTime() - userTimezoneOffset), "yyyy-MM-dd'T'HH:mm:ss")
  }

  /**
   * Initial request for the statistics component to get all the energies of all sites in the system
   */
  setRequestConsumptionObject(energyTypes: Energy.EnergyTypeEnum[], siteId: string): SiteEnergiesConsumptionsRequest {
    return {
      energyTypes: energyTypes,
      siteReferences: [siteId],
      startDate: this.setStartDate(),
      endDate: this.datepipe.transform(new Date(new Date().getFullYear(), 11, 31), "yyyy-MM-dd'T'HH:mm:ss"),
      referenceYear: (new Date().getFullYear() - 1).toString(),
      consumptionPeriod: Energy.EnergyPeriodEnum.MONTH
    }
  }

  setRequestRankSiteObject(energyTypes: Energy.EnergyTypeEnum[], siteId: string, limit: number): RankSiteRequest {
    return {
      energyTypes: energyTypes,
      siteReference: siteId,
      startDate: this.setStartDate(),
      endDate: this.datepipe.transform(new Date(new Date().getFullYear(), 11, 31), "yyyy-MM-dd'T'HH:mm:ss"),
      referenceYear: (new Date().getFullYear() - 1).toString(),
      consumptionPeriod: Energy.EnergyPeriodEnum.MONTH,
      limit: limit
    }
  }

  setEnergyTypes(selectedEnergyType: Energy.EnergyTypeEnum, siteEnergyTypes: Energy.EnergyTypeEnum[]): Energy.EnergyTypeEnum[] {
    let isWaterEnergy = selectedEnergyType == Energy.EnergyTypeEnum.WATER;
    if (isWaterEnergy) return [Energy.EnergyTypeEnum.WATER];
    if (selectedEnergyType != Energy.EnergyTypeEnum.TOTAL) return [selectedEnergyType, Energy.EnergyTypeEnum.TOTAL];
    let waterIndex = siteEnergyTypes.indexOf(Energy.EnergyTypeEnum.WATER);
    waterIndex != -1 && siteEnergyTypes.splice(waterIndex, 1);
    return [...siteEnergyTypes, Energy.EnergyTypeEnum.TOTAL]
  }
}
