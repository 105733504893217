import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnergyComponentType } from '../../deprecated-models/deprecated-energy/energy-component-type.enum';
import { TesseractService } from '../deprecated-tesseract/tesseract.service';
import { EnergySource } from '../../deprecated-models/deprecated-energy/energy-source.model';
import { Formula } from '../../deprecated-models/formula';
import { PeriodFormula } from '../../deprecated-models/deprecated-energy/periods-fourmla.model';
import { DeltaType } from '../../deprecated-models/deprecated-energy/delta-type.enum';
import { PeriodsFormula } from '../../deprecated-models/periods-formula';
import { AuthenticationsService } from '../../../services/auth/authenticationsService';

@Injectable({
  providedIn: 'root'
})

/**
 * @author Fathy Zatry
 *
 * this service manages Building Energy components records
 * works mainly with building service to add/update/delete energy records
 * @deprecated
 */
export class EnergyService {
  private energyTypePath = `/api/energyTypes/`;
  private thermalPowerPath = `/api/thermalPowers/`;
  private energySourcePath = `/api/energySources/`;
  private formula = '';
  private periodsFormula: PeriodFormula[] = [];

  saveType = new Subject<EnergyComponentType>();
  energySourceList: [][] = [];

  public energySourcesMetaData = new BehaviorSubject(undefined);
  public energyTypesMetaData = new BehaviorSubject(undefined);


  constructor(private authService: AuthenticationsService, private tesseractService: TesseractService,
              private http: HttpClient) {
  }

  /**
   * @deprecated
   */
  get energySourcesMeta(): Observable<any> {
    return of([]);
    // return this.energySourcesMetaData.asObservable();
  }

  /**
   * @deprecated
   */
  get energyTypesMeta(): Observable<any> {
    return of([]);
    // return this.energyTypesMetaData.asObservable();
  }


  /**
   * method that retrieves Energy records within a specific building for a specific energy component
   * @param buildingId
   * @param type represents a energy component type as enum
   * @deprecated
   */
  getByBuildingId(buildingId, type: EnergyComponentType) {
    let path = this.getPath(type);

    if (path !== undefined) {

      path = path + `building/` + buildingId + '/';
      return of([]);
      // return this.http.get(path).pipe(
      //   catchError(this.handleError)
      // );
    }
  }

  /**
   * return a different path for each energy component
   * @param type represents a energy component type as enum
   * @deprecated
   */
  private getPath(type: EnergyComponentType) {
    switch (type) {
      case EnergyComponentType.ENERGYTYPE:
        return this.energyTypePath;
      case EnergyComponentType.ENERGYSOURCE:
        return this.energySourcePath;
      case EnergyComponentType.THERMALPOWERSOURCE:
        return this.thermalPowerPath;
      default:
        return undefined;
    }
  }

  /**
   * method used to remove a specific energy record using its id
   * @param id
   * @param type represents a energy component type as enum
   * @deprecated
   */
  remove(id: string, type: EnergyComponentType): Observable<any> {
    let path = this.getPath(type);
    if (path !== undefined) {
      path = path + id + '/';
      return of(true);
      // return this.http.delete(path).pipe(
      //   retry(1),
      //   catchError(this.handleError)
      // );
    }
  }

  /**
   * method that save the energy records
   * @param buildingId  building Id
   * @param userEnergyRecordsList  array of energy records which the user had access on (able to modify)
   * @param type represents a energy component type as enum
   * @deprecated
   */
  save(buildingId, userEnergyRecordsList: any[], type: EnergyComponentType): Observable<any> {
    let path = this.getPath(type);

    if (path !== undefined) {
      userEnergyRecordsList.forEach(element => {
        element.buildingId = buildingId;
      });
      let savePath = path + `building/` + buildingId + '/';
      const body = JSON.stringify(userEnergyRecordsList);
      return of(true);
      // return this.http.post(savePath, body).pipe(
      //   retry(1),
      //   catchError(this.handleError)
      // );
    }
  }

  /**
   * @deprecated
   */
  getConsumptionPrice(energySources, startDate, endDate, step, timeZone?): Observable<any> {
    this.periodsFormula = [];
    this.formula = '';
    let sources = this.createEnergySourcesFormula(energySources, true);
    let formulaObject: PeriodsFormula = { formula: this.formula, periodsFormula: this.periodsFormula };
    return this.tesseractService.getFormula(
      sources,
      formulaObject,
      startDate,
      endDate,
      DeltaType.DELTA_PERIOD,
      step,
      timeZone
    );
  }

  /**
   * @deprecated
   */
  getConsumption(energySources, startDate, endDate, delta = 'true', step?, timezone?): Observable<any> {
    this.formula = '';
    let sources = this.createEnergySourcesFormula(energySources, false);
    let formulaObject: Formula = { formula: this.formula };

    return this.tesseractService.getFormula(
      sources,
      formulaObject,
      startDate,
      endDate,
      delta,
      step,
      timezone
    );
  }

  /**
   * retrieve energy sources formula
   * @deprecated
   * @param energySources
   */
  private createEnergySourcesFormula(energySources: EnergySource[], price: boolean) {
    let energySourceList: any[] = [];

    for (let index = 0; index < energySources.length; index++) {
      energySourceList.push({
        source: energySources[index].dataSource,
        path: energySources[index].dataSource,
        variable: 'pe' + index
      });
      if (price) {
        let plus = (this.formula.length === 0) ? '' : '+';
        // this.formula = this.formula.concat(plus, "pe" + index) + "+" + energySources[index].type.annualSubscriptionPrice;
        let conversionFormulaKWH = energySources[index].conversionFormulaKWH.replace(new RegExp('pe', 'gi'), 'pe' + index);
        //FIXMME reloplace with energyTypeId
        // energySources[index].type.periodPrice.forEach(period => {
        //   this.periodsFormula.push(new PeriodFormula(period.startTime, period.stopTime, energySources[index].dataSource, "(" + conversionFormulaKWH + ")*" + period.price));
        // })
      } else {
        let plus = (this.formula.length === 0) ? '((' : '+(';
        this.formula = this.formula.concat(plus, energySources[index].conversionFormulaKWH?.replace(new RegExp('pe', 'gi'), 'pe' + index) + ')');
      }
    }
    if (!price) {
      this.formula = this.formula + ')';
    }
    return energySourceList;
  }

  /**
   * method used to handle errors during api requests
   * @deprecated
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error && error.error.occurrences) {
      return throwError(error.error.occurrences);

    } else if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError({ 'error': error.error.message, 'statuscode': error.status });

    } else {
      // The backend returned an unsuccessful response code.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError({ 'error': 'backend error', 'statuscode': error.status });

    }
  }

}
