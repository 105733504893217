import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nodeTruncate'
})
export class NodeNameTruncatePipe implements PipeTransform {

  constructor() {
  }

  transform(text: string): string {
    let arr = text?.split('/').filter(ele => ele.length != 0);
    return arr?.slice(-3)?.join(' > ')
  }


}

