import {Injectable} from "@angular/core";
import {SiteAddress} from "../../models/sites/site-address";
import {AppService} from "../app/app.service";


@Injectable()
export class SitesManagementService {
  constructor(private appService: AppService) {
  }

  getSiteLocation(address: SiteAddress): string {
    return `${this.justifyLocation(address?.streetNumber)}${this.justifyLocation(address?.street)}${this.justifyLocation(address?.city, true)}`.trim();
  }


  getFullSiteLocation(address: SiteAddress): string {
    return `${this.justifyLocation(address?.streetNumber)}${this.justifyLocation(address?.street)}${this.justifyLocation(address?.city)}${this.justifyLocation(address?.postalCode)}${this.justifyLocation(address?.country, true)}`.trim();
  }

  justifyLocation(location: string, lastTerm?: boolean) {
    if (!location) return ''
    let extractedValue = '';
    extractedValue = location.toLowerCase().replace('undefined', '');
    extractedValue[0] == ',' && (extractedValue = extractedValue.substr(1));
    let data = this.appService.capitalizeFirstAlphabetic(extractedValue);
    return `${data}${!lastTerm ? ', ' : ''}`;
  }

  /**
   * A method used to creat a template for popup in home component
   *
   * @param {*} someBuilding the building used in creating template
   * @returns a template to be used in popup
   * @memberof HomeComponent
   */


  createHtmlTemplate(someBuilding) {
    const html = `
    <div class='home-map-entire-container w-100 d-flex gap-10px flex-direction-column'>
      <div class='header-container d-flex flex-direction-row align-items-center justify-content-between'>
        <div class='info-container'>
          <div class='name-container'>${someBuilding.name}</div>
        </div>
        <!--
        <div class='image-container'>
          <img src='${someBuilding.logo}' width='130px' alt='selectedBuilding image'>
        </div>
        -->
      </div>
      <div class='other-container'>
        ${this.getAddressHtml(someBuilding.address)}
      </div>
    </div>`;
    return html;
  }

  getAddressHtml(address) {
    return `<span>${this.getFullSiteLocation(address)}</span>`;
  }

  validateLocationId(locationId: string): boolean {
    const regex = new RegExp("^[0-9a-fA-F]{24}$", 'i');
    return regex.test(locationId);
  }

}
