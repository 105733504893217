import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSelectChange} from "@angular/material/select";
import { NodePoint } from '../../../../../core/models/nodes/nodePoint';
import { NodesService } from '../../../../../core/services/nodes/node.service';

@Component({
  selector: 'gmao-nodes-list-dialog-from',
  templateUrl: './nodes-list-dialog-from.component.html',
  styleUrls: ['./nodes-list-dialog-from.component.scss']
})
export class NodesListDialogFromComponent implements OnInit {
  @Input('iotDeviceNodeId') iotDeviceNodeId: number;
  @Input('applicationNodeId') applicationNodeId: number;
  @Output('error') error = new EventEmitter();
  /**
   * send the last level nodes for the form , then update the table obs to pull the new records (last node level)
   * @see NodesListDialogComponent.updateTableRecords
   * @see newApplicationSelected
   */
  @Output('updateTableRecords') updateTableRecords = new EventEmitter<string>();
  /**
   * capture the last level nodes for the form , then update the table obs to pull the new records (last node level)
   * @see NodesListDialogComponent.filterString
   */
  @Output('filterStringUpdated') filterStringUpdated = new EventEmitter<string>();
  filterString = null;
  gatewaysList: NodePoint[] = null;
  applicationsList: NodePoint[] = null;
  loadingGatewaysLists = true;
  loadingApplicationsLists = false;
  selectedContainer: NodePoint = null;

  constructor(private nodesService: NodesService) {
  }

  ngOnInit(): void {
    this.getGatewaysList();
  }

  private getGatewaysList() {
    this.nodesService.getNodesByParentId('1').subscribe((data: NodePoint[]) => {
      this.gatewaysList = data;
      this.loadingGatewaysLists = false;
      this.iotDeviceNodeId && this.newGatewaySelected({value:this.iotDeviceNodeId} as MatSelectChange);
    }, error => this.error.emit())
  }

  newGatewaySelected(event: MatSelectChange) {
    this.applicationsList = [];
    this.selectedContainer = null;
    this.filterString = '';
    this.loadingApplicationsLists = true;
    this.nodesService.getNodesByParentId(event.value).subscribe((data: NodePoint[]) => {
      this.applicationsList = data;
      this.loadingApplicationsLists = false;
      this.applicationNodeId && this.newApplicationSelected({value:this.applicationNodeId} as MatSelectChange)
    }, error => this.loadingApplicationsLists = false)
  }

  /**
   * send the last level nodes for the form , then update the table obs to pull the new records (last node level)
   * @see NodesListDialogComponent.updateTableRecords
   * @see updateTableRecords
   */
  newApplicationSelected(event: MatSelectChange) {
    this.selectedContainer = event.value;
    this.filterString = '';
    this.updateTableRecords.emit(event.value)
  }


}
