import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlsDashboardFilteringComponent} from './controls-dashboard-filtering.component';
import {PipesModule} from "../../../../core/pipes/pipes.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {
  SgAutocompleteSingleSelectModule
} from "../../../sg-components/sg-autocomplete-single-select/sg-autocomplete-single-select.module";


@NgModule({
  declarations: [ControlsDashboardFilteringComponent],
  exports: [ControlsDashboardFilteringComponent],
  imports: [
    CommonModule,
    SgAutocompleteSingleSelectModule,
    PipesModule,
    TranslateModule,
    MatIconModule
  ]
})
export class ControlsDashboardFilteringModule {
}
