export namespace AccessRightEnum {

  export type AccessRight = 'R' | 'W' | 'RW';

    export const AccessRightEnum = {
    R: 'R' as AccessRight,
    W: 'W' as AccessRight,
    RW: 'RW' as AccessRight,
  }

  export const AccessRightList = [
    AccessRightEnum.R,
    AccessRightEnum.W,
    AccessRightEnum.RW
  ]
}
