import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActualWeatherTemperatureComponent } from './actual-weather-temperature.component';
import {TranslateModule} from "@ngx-translate/core";
import {LoadingSkeletonModule} from "../../../components/loading-skeleton/loading-skeleton.module";



@NgModule({
  declarations: [
    ActualWeatherTemperatureComponent
  ],
  exports: [
    ActualWeatherTemperatureComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    LoadingSkeletonModule
  ]
})
export class ActualWeatherTemperatureModule { }
