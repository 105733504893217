<div class="control-plan-dashboard-view-container">
  <ng-container *ngTemplateOutlet="
  floorThumbImg? thumbImg :
  filtrationModel?.floorId? noPlanImg: NoFloorSelected"></ng-container>
  <mat-spinner *ngIf="loadingSiteFloor" class="centered-element" diameter="80"></mat-spinner>
</div>

<ng-template #noPlanImg>
  <span class="span-link"
        (click)="navigateToSitePage()">{{'CONTROLS_DASHBOARD.UPLOAD_FLOOR_PLAN_MESSAGE' | translate | capitalize}}</span>
</ng-template>

<ng-template #NoFloorSelected>
  <p>{{'CONTROLS_DASHBOARD.FLOOR_NOT_SELECTED' | translate | capitalize}}</p>
</ng-template>

<ng-template #thumbImg>
  <div id="siteFloorImage" *ngIf="floorThumbImg"></div>
</ng-template>
