import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AccessComponentType} from '../../deprecated-models/deprecated-access/access-component-type.enum';
import {catchError, retry} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {AuthenticationsService} from '../../../services/auth/authenticationsService';
import {UserSpace} from '../../../models/user-access/user.enum';
import AuthedUserAttributesEnum = UserSpace.AuthedUserAttributesEnum;

@Injectable({
  providedIn: 'root'
})

/**
 * @author Fathy Zatry
 *
 * this service manages access control of different classes records
 * @deprecated
 */
export class AccessControlService {
  groupPath = `/api/sg-users/groups/`;
  userPath = `/api/sg-users/users/`;
  private tempTokenPath = `/api/credentials/access/`;

  groupsUpdated = new Subject<any[]>();
  userAccessUpdated = new Subject<any>();

  currentAccessStatus = new BehaviorSubject<any[]>([false, false, false]);
  isSubmitting = new BehaviorSubject<boolean>(false);


  constructor(private authService: AuthenticationsService, private http: HttpClient) {

  }

  /**
   * @deprecated
   * method that retrieves access (user/group..etc) records within a specific building for a specific access (user/group..etc) component
   * @param type represents a access (user/group..etc) component type as enum
   */
  get(type: AccessComponentType) {
    let path;
    if (type == AccessComponentType.USER) {
      path = this.userPath;
    } else {
      path = this.getPath(type);
    }

    if (path !== undefined) {
      return this.http.get(path).pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
    }
  }

  /**
   * @deprecated
   * method that filters building views which current user has the permission to read/view
   * @param views building views
   */
  filterViewsToRead(views) {
    /** admin & super admin have full access :) */
    if (this.isAdmin()) {
      return views;
    }

    let userGroups = this.authService.getAuthedUserData(AuthedUserAttributesEnum.ACCESS_GROUPS);
    if (userGroups === undefined || userGroups === null || userGroups.length === 0) {
      return [];
    }

    let userViews = [];
    if (views && views.length > 0) {
      /** checking all views */
      views.forEach(view => {
        if (view.accessGroupPermissions && (view.type !== 'menu' || view.subViews.length !== 0)) {

          /** checking view access permissions */
          for (let i = 0; i < view.accessGroupPermissions.length; i++) {
            let access = view.accessGroupPermissions[i];

            /** checking if user groups have access to this view*/
            let filtered = userGroups.filter(userGroup => userGroup.id === access.group.id);
            if (filtered.length > 0 && access.permissions.includes(true)) {
              userViews.push(view);
              break;
            }
          }
        }
      });
    }

    /** checking all subViews */
    userViews = this.filterSubViewsToRead(userViews, userGroups);

    return userViews.filter(view => view.type.toLowerCase() !== 'menu' || view.subViews.length !== 0);
  }

  /**
   * method that filters  views subViews which current user has the permission to read/view
   * @param views building views
   * @deprecated
   */
  private filterSubViewsToRead(userViews, userGroups): any[] {

    /** checking all subViews */
    userViews.forEach(view => {

      let userSubViews = [];
      view.subViews.forEach(subView => {
        if (subView.accessGroupPermissions) {

          /** checking subView access permissions */
          for (let i = 0; i < subView.accessGroupPermissions.length; i++) {
            const accessGroupPermission = subView.accessGroupPermissions[i];

            /** checking if user groups have access to this subView */
            let filtered = userGroups.filter(userGroup => userGroup.id === accessGroupPermission.group.id);
            if (filtered.length > 0 && accessGroupPermission.permissions.includes(true)) {
              userSubViews.push(subView);
              break;
            }
          }
        }
      });
      view.subViews = userSubViews;
    });
    return userViews;
  }

  /**
   * @deprecated
   */
  isAdmin(): boolean {
    let admin = false;
    let role = +this.authService.getAuthedUserData(AuthedUserAttributesEnum.ROLE);
    if (role === 0 || role === 1) {
      admin = true;
    }
    return admin;
  }

  /**
   * @deprecated
   * method that save the access (user/group..etc) records
   * @param type represents a access (user/group..etc) component type as enum
   */
  save(records: any[], type: AccessComponentType): Observable<any> {
    let path = this.getPath(type);

    if (path !== undefined) {
      let savePath = path + '/create';
      const body = JSON.stringify(records);
      return this.http.post(savePath, body).pipe(
        retry(1),
        catchError(this.handleError)
      );
    }
  }


  /**
   * return a different path for each access (user/group..etc) component
   * @param type represents a access (user/group..etc) component type as enum
   * @deprecated
   */
  private getPath(type: AccessComponentType) {
    this.groupPath = `/api/sg-users/groups/`;
    this.userPath = `/api/sg-users/users/`;

    switch (type) {
      case AccessComponentType.GROUP:
        return this.groupPath;
      case AccessComponentType.USER:
        return this.userPath;
      default:
        return undefined;
    }
  }

  /**
   * method used to handle errors during api requests
   * @param error
   * @deprecated
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error && error.error.occurrences) {
      return throwError(error.error.occurrences);

    } else if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError({ 'error': error.error.message, 'status code': error.status, 'body': error.error });

    } else {
      // The backend returned an unsuccessful response code.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError({ 'error': 'backend error', 'status code': error.status, 'body': error.error });
    }
  }


}
