import {Theme} from "./theme.model";

export const darkTheme: Theme = {
    name: "dark-theme",
    properties: {
        /* New Properties */
        "--sg-bgr-main": "#505050",
        "--sg-bgr-primary": "#424242",
        "--sg-bgr-secondary": "#3d3d3d",
        "--sg-bgr-tertiary": "#505050",
        "--sg-bgr-hover": "#595959",
        "--sg-font-primary": "#ffffff",
        "--sg-font-primary-invert": "#000000",
        "--sg-font-danger": "#f44336",
        "--sg-color-blue": "#4AAFDB",
        "--sg-button-font": "#ffffff",
        "--sg-error": "#f44336",
        "--sg-box-shadow-primary": "0",
        "--sg-box-shadow-webkit-primary": "0",
        /* New Properties */

        "--background-primary": "#4fb98c",
        "--background-secondary": "#4fb98c",
        "--background-tertiary": "#fff",
        "--background-quaternary": "red",
        "--background-quinary": "red",
        "--background-light": "red",
        "--background-primary-light": "#4fb98c",
        "--background-logo-color": "#fff",
        "--font-button-primary": "#000000",
        "--font-primary": "#ffffff",
        "--font-secondary": "#fae000",
        "--font-tertiary": "#fff",
        "--font-quaternary": "#fae000",
        "--font-quinary": "#222221",
        "--font-light": "red",
        "--error-default": "red",
        "--error-dark": "#800600",
        "--error-light": "#ffcecc",
        "--background-size": "auto 100%",
        "--logo-one-width": "170px",
        "--logo-one-height": "40px",
        "--background-logo": "url('/assets/Smart-Globe.png') left center no-repeat",
        "--label-font-color": "#000000"
    }
};
