import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlFloorCellComponent } from './control-floor-cell.component';
import {PipesModule} from "../../../../../core/pipes/pipes.module";



@NgModule({
  declarations: [ControlFloorCellComponent],
  exports: [ControlFloorCellComponent],
  imports: [
    CommonModule,
    PipesModule
  ]
})
export class ControlFloorCellModule { }
