export namespace Energy {
  export type EnergyTypeEnum = 'ELECTRICITY' | 'GAZ' | 'FUEL_OIL' | 'WATER' | 'GENERAL_VEHICLES_GAZ' | 'DIESEL' | 'BENZINE' | 'HOT_WATER' | 'COLD_WATER' | 'TOTAL'
  export const EnergyTypeEnum = {
    ELECTRICITY: 'ELECTRICITY' as EnergyTypeEnum,
    GAZ: 'GAZ' as EnergyTypeEnum,
    FUEL_OIL: 'FUEL_OIL' as EnergyTypeEnum,
    WATER: 'WATER' as EnergyTypeEnum,
    GENERAL_VEHICLES_GAZ: 'GENERAL_VEHICLES_GAZ' as EnergyTypeEnum,
    DIESEL: 'DIESEL' as EnergyTypeEnum,
    BENZINE: 'BENZINE' as EnergyTypeEnum,
    HOT_WATER: 'HOT_WATER' as EnergyTypeEnum,
    COLD_WATER: 'COLD_WATER' as EnergyTypeEnum,
    TOTAL: 'TOTAL' as EnergyTypeEnum,
  }

  export type EnergyPeriodTypes = 'MINUTE' | 'HOUR' | 'DAY' | 'MONTH' | 'YEAR'
  export const EnergyPeriodEnum = {
    MINUTE: 'MINUTE' as EnergyPeriodTypes,
    HOUR: 'HOUR' as EnergyPeriodTypes,
    DAY: 'DAY' as EnergyPeriodTypes,
    MONTH: 'MONTH' as EnergyPeriodTypes,
    YEAR: 'YEAR' as EnergyPeriodTypes,
  }

  export type EnergyUnitEnum = 'KW' | 'W' | 'KWH'| 'MWH' | 'WH' | 'LITER' | 'METER3' | 'CALORIES'
  export const EnergyUnitEnum = {
    KW: 'KW' as EnergyUnitEnum,
    W: 'W' as EnergyUnitEnum,
    KWH: 'KWH' as EnergyUnitEnum,
    MWH: 'MWH' as EnergyUnitEnum,
    WH: 'WH' as EnergyUnitEnum,
    LITER: 'LITER' as EnergyUnitEnum,
    METER3: 'METER3' as EnergyUnitEnum,
    CALORIES: 'CALORIES' as EnergyUnitEnum,
  }

  export type SourceType = 'EXTERNAL_API' | 'INTERNAL_API' | 'MANUAL';
  export const SourceTypeEnum = {
    EXTERNAL_API: "EXTERNAL_API" as SourceType,
    INTERNAL_API: "INTERNAL_API" as SourceType,
    MANUAL: "MANUAL" as SourceType,
  }

  export type EnergyProviderEnum = 'BUBBLE_UP' | 'ENEDIS' | 'GRDF';
  export const EnergyProviderEnum = {
    BUBBLE_UP: "BUBBLE_UP" as EnergyProviderEnum,
    ENEDIS: "ENEDIS" as EnergyProviderEnum,
    GRDF: "GRDF" as EnergyProviderEnum,
  }

  export const EnergyTypesUnitsMapping = {
    ELECTRICITY: 'kWh',
    GAZ: 'kWh',
    FUEL_OIL: 'kWh',
    WATER: 'm³',
    GENERAL_VEHICLES_GAZ: 'kWh',
    DIESEL: 'kWh',
    BENZINE: 'kWh',
    TOTAL: 'kWh',
  }
  export const EnergyTypeIconsEnum = {
    ELECTRICITY: "assets/icons/energy-electric-icon.png",
    GAZ: "assets/icons/energy-gas-icon.png",
    FUEL_OIL: "assets/icons/energy-fuel-oil-icon.png",
    WATER: "assets/energy_water_icon.svg",
    GENERAL_VEHICLES_GAZ: "assets/icons/energy-gas-icon.png",
    DIESEL: "assets/icons/energy-diesel-icon.png",
    BENZINE: "assets/icons/energy-benzine-icon.png",
    HOT_WATER: "assets/icons/energy-hot-water.png",
    COLD_WATER: "assets/icons/energy-cold-water.png",
  }

  export const EnergyCurrencyEnum = {
    USD: "$",
    EUR: "€",
    POUND: "£",
  }

  export const MetersColorsPresets: string[] = ['#000', '#2F4F4F','#696969','#778899','#C0C0C0' ,'#FABC02','#FB9902','#FD5308','#FE2712','#A7194B','#8601AF','#3D01A4','#0247FE','#0391CE','#66B032','#D0EA2B','#FEFE33'];

  export const EnergyTypeUnits = {
    [EnergyTypeEnum.GAZ]: [
      EnergyUnitEnum.METER3,
      EnergyUnitEnum.WH,
      EnergyUnitEnum.KWH,
      EnergyUnitEnum.MWH
    ],
    [EnergyTypeEnum.WATER]: [
      EnergyUnitEnum.METER3,
      EnergyUnitEnum.LITER
    ]
  }
}
