import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AreaChartWidgetComponent } from './area-chart-widget.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AreaChartWidgetComponent],
  exports: [AreaChartWidgetComponent],
  imports: [
    CommonModule,
    HighchartsChartModule,
    TranslateModule

  ],
  providers: []
})
export class AreaChartWidgetModule {
}
