import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from 'rxjs';
import { PluginControl } from "../plugin-control.abstract";
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

@Component({
  selector: 'status-review-plugin',
  templateUrl: './status-review-plugin.component.html',
  styleUrls: ['./status-review-plugin.component.scss']
})
export class  StatusReviewPluginComponent extends PluginControl implements OnInit, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;

  value: number;

  subject: Subject<number> = new Subject();
  subscription: Subscription = new Subscription();

  status: number;

  yes: number;
  no: number;

  icon: string = '';
  message: string = 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.PENDING_STATUS';
  color: string = 'darkgray';

  subscriptions: Subscription = new Subscription();
  data: [];
  plotData: any[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  setValue(value: number) {
    this.value = value;
    this.status = value;
    this.renderStatus();
    this.changeDetectorRef.markForCheck();
  }

  getValue(value: number) {
    // Do sth
  }


  ngOnInit(): void {
    this.yes = this.configuration?.additionalInfo?.['yes'];
    this.no = this.configuration?.additionalInfo?.['no'];
  }

  renderStatus() {

    switch(this.status) {

      case(this.yes):
        this.icon = 'cancel_outline';
        this.message = '';
        this.color = 'red';
        break;

      case(this.no):
        this.icon = 'check_circle_outline';
        this.message = '';
        this.color = 'green';
        break;

      default:
        this.message = 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.PENDING_STATUS';
        this.icon = '';
        this.color = 'darkgray';
        break;
    }

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

