import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnergyMetersFilteringComponent } from './energy-meters-filtering.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {PipesModule} from "../../../../core/pipes/pipes.module";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {
    SgAutocompleteSingleSelectModule
} from "../../../sg-components/sg-autocomplete-single-select/sg-autocomplete-single-select.module";



@NgModule({
  declarations: [
    EnergyMetersFilteringComponent
  ],
  exports: [
    EnergyMetersFilteringComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        TranslateModule,
        PipesModule,
        FormsModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        SgAutocompleteSingleSelectModule
    ]
})
export class EnergyMetersFilteringModule { }
