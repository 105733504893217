import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function MinMaxValidator(targetControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const minimumValue = targetControl.value;
    const maximumValue = control.value;

    if (minimumValue && maximumValue && minimumValue >= maximumValue) {
      return { minMaxValidation: true };
    }

    return null;
  };
}
