import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlListDashboardViewComponent } from './control-list-dashboard-view.component';
import {SgTableModule} from "../../../sg-components/sg-table/sg-table.module";
import {ControlValueCellModule} from "./control-value-cell/control-value-cell.module";
import {ControlFloorCellModule} from "./control-floor-cell/control-floor-cell.module";
import {TranslateModule} from "@ngx-translate/core";
import {PipesModule} from "../../../../core/pipes/pipes.module";



@NgModule({
  declarations: [ControlListDashboardViewComponent],
  exports: [ControlListDashboardViewComponent],
  imports: [
    CommonModule,
    SgTableModule,
    ControlValueCellModule,
    ControlFloorCellModule,
    TranslateModule,
    PipesModule
  ]
})
export class ControlListDashboardViewModule { }
