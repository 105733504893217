import { Component, ElementRef, HostBinding, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'gmao-env-impact',
  templateUrl: './env-impact.component.html',
  styleUrls: ['./env-impact.component.scss']
})
export class EnvImpactComponent implements OnInit {
  @HostBinding('class') rootComponentClasses = 'd-flex flex-column h-100 w-100';
  /**
   * Uncomment these lines in order to allow the widget to
   * receive real dynamic data in the future.
   * @author AbdulQader
   */

  @Input()
  value: number;
  @Input()
  unit: string;


  selectedClass: string = 'a';

  distance: number;
  distanceDisplay: string;

  rotations: number;
  rotationsDisplay: string;

  constructor() {}

  ngOnInit(): void {
    this.setRatingStatic();
    this.setDistance();
    this.setRotations();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setRatingStatic();
    this.setDistance();
    this.setRotations();
  }

  setRatingStatic(){
    this.selectedClass = 'c';
  }



  setRating() {
    if(this.value < 5){
      this.selectedClass = 'a';
    } else if(this.value >= 6 && this.value < 10 ){
      this.selectedClass = 'b';
    } else if(this.value >= 11 && this.value < 20){
      this.selectedClass = 'c';
    } else if(this.value >= 21 && this.value < 35){
      this.selectedClass = 'd';
    } else if(this.value >= 36 && this.value < 55){
      this.selectedClass = 'e';
    } else if(this.value >= 56 && this.value < 80){
      this.selectedClass = 'f';
    } else {
      this.selectedClass = 'g';
    }
  }

  // Calculate the distance equivalent around the world (in Km).
  setDistance(){
    this.distance = this.value * 84.2;
    this.distanceDisplay = this.distance.toFixed(0);
  }

  // Calculate rotations around Earth.
  setRotations(){
    this.rotations = this.distance * 0.00002133179;
    this.rotationsDisplay = this.rotations.toFixed(2)
  }


}
