import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EnergyEfficiencyComponent } from './energy-efficiency.component';


@NgModule({

  imports: [
    CommonModule,
    TranslateModule,
  ],

  providers: [],


  declarations: [
    EnergyEfficiencyComponent
  ],

  exports: [
    EnergyEfficiencyComponent
  ],


  entryComponents: [
    EnergyEfficiencyComponent
  ],

})
export class EnergyEfficiencyModule { }
