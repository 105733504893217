import {HttpClient} from '@angular/common/http';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {divIcon, icon, latLng, Map, map, marker, tileLayer} from 'leaflet';
import {MarkerClusterGroup} from 'leaflet.markercluster';
import {BehaviorSubject} from 'rxjs';
import {catchError, debounceTime, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {SitesService} from "../../../services/sites/sites.service";

/**
 * @deprecated
 */
const customIcon = icon({
  iconSize: [50, 50],
  iconAnchor: [25, 25],
  popupAnchor: [0, -25],
  iconUrl: 'assets/cursor_1x2.png'
});

@Component({
  selector: 'sg-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
/**
 * @deprecated
 */
export class MapComponent implements OnInit {

  map: Map;
  savedLayer: any;
  savedCluster: any;
  markerCluster: any;

  //Future Use Note: both Toner and Terrain have a maximum zoom level of 17
  layerOptions = [
    {
      name: 'Leaflet',
      path: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: 'Map data © OpenStreetMap contributors'
    }
  ];

  searchCtrl = new FormControl();
  filteredLocations: any = [];
  isLoading = false;
  errorMsg: string;

  coordinates: any;
  markerList: any = [];
  indexedMarkerList: any = [];
  /**
   * @deprecated
   */
  @Input() settings: any = {
    maxZoom: 17,
    initialZoom: 12,
    showZoomControl: true,
    showLayersControl: true,
    showSearchControl: false,
    havePopups: true,
    dragDisabled: false,
    doubleClickDisabled: false,
    center: {lat: 48.864716, lon: 2.349014},
  };
  /**
   * @deprecated
   */
  @Input() coordinatesObservable: BehaviorSubject<any>;
  /**
   * @deprecated
   */
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @ViewChild('map', {static: true}) mapRef: ElementRef;

  constructor(private translateService: TranslateService, private http: HttpClient, private sitesV2Service: SitesService) {
  }

  /**
   * @deprecated
   */
  ngOnInit() {
    this.markerCluster = new MarkerClusterGroup(
      {
        iconCreateFunction: function (cluster) {
          var childCount = cluster.getChildCount();


          return divIcon({html: '<div><b>' + childCount + '</b></div>', iconSize: [50, 50]});
        }
      }
    );

    this.createMap();
    this.coordinatesObservable.subscribe((data) => {
      this.coordinates = data;
      if (this.markerList.length == 0) {
        this.addMarkers();
      } else {
        this.map.setZoom(17);
        if (this.coordinates != null && typeof this.coordinates[Symbol.iterator] === 'function') {
          for (let coordinate of this.coordinates) {
            if (coordinate.center) {
              if (data.length == 1) {
                this.map.flyTo([data[0].lat, data[0].lon], 17);
                this.addMarkers();
              } else {
                this.map.flyTo(this.indexedMarkerList[coordinate.itemId].getLatLng(), 17);
                this.indexedMarkerList[coordinate.itemId].openPopup();
                // marker([coordinate.lat, coordinate.lon]).bindPopup(coordinate.template);
                marker([coordinate.lat, coordinate.lon]).openPopup(coordinate.template);
                // console.log(this.indexedMarkerList[coordinate.itemId])
              }
            }
          }
        }
      }
    });

    if (this.settings.showSearchControl) {
      this.searchCtrl.valueChanges.pipe(
        debounceTime(1000),
        filter(value => value.length >= 5),
        tap(() => {
          this.filteredLocations = [];
          this.isLoading = true;
        }),
        switchMap(value => this.sitesV2Service.locateSiteMap(value).pipe(
          finalize(() => {
            this.isLoading = false
          }),
          catchError(() => this.filteredLocations = [])
        ))
      ).subscribe(data => {
        if (data == undefined) {
          this.filteredLocations = [];
        } else {
          this.filteredLocations = data;
        }
      }, error => {
        ////console.log('error: ', error);
        this.filteredLocations = [];
      });
    }
  }

  /**
   * A method to generate new leaflet map
   *
   * @memberof MapComponent
   */
  createMap() {

    this.savedLayer = tileLayer(this.layerOptions[0].path, {
      maxZoom: this.settings.maxZoom,
      attribution: this.layerOptions[0].attribution
    });
    this.map = map(this.mapRef.nativeElement, {
      center: latLng(this.settings.center.lat, this.settings.center.lon),
      zoom: this.settings.initialZoom,
      zoomControl: this.settings.showZoomControl,
      layers: [
        this.savedLayer
      ],
    });

    setTimeout(() => {
      this.map.invalidateSize();
    });

    if (this.settings.showZoomControl) {
      this.map.zoomControl.setPosition('topright');
    }
    if (document.getElementsByClassName("leaflet-control-attribution")?.[0]?.innerHTML)
      document.getElementsByClassName("leaflet-control-attribution")[0].innerHTML = document.getElementsByClassName("leaflet-control-attribution")[0]?.textContent?.slice(9);

    if (this.settings.dragDisabled) {
      this.map.dragging.disable();
    }

    if (this.settings.doubleClickDisabled) {
      this.map.doubleClickZoom.disable();
      this.map.scrollWheelZoom.disable();
    }

  }

  /**
   * A method to add markers to map
   *
   * @memberof MapComponent
   */
  addMarkers() {
    if (this.coordinates != null && typeof this.coordinates[Symbol.iterator] === 'function') {
      for (let coordinate of this.coordinates) {
        let newMarker = marker([coordinate.lat, coordinate.lon]).setIcon(customIcon);
        this.markerCluster.addLayer(newMarker);

        if (coordinate.center) {
          this.map.flyTo(newMarker.getLatLng(), this.map.getZoom());
        }

        if (this.settings.havePopups) {
          if (coordinate.center) {
            newMarker.bindPopup(coordinate.template).openPopup();
          } else {
            newMarker.bindPopup(coordinate.template);
          }
        }

        if (coordinate.itemId) {
          this.markerList.push({
            itemId: coordinate.itemId,
            marker: newMarker
          });

          this.indexedMarkerList[coordinate.itemId] = newMarker
        } else {
          this.markerList.push({
            marker: newMarker
          });
        }
      }
      this.markerCluster.addTo(this.map);
      this.savedCluster = this.markerCluster;
    }
  }

  /**
   * A method used to change map main layer
   *
   * @param {any} layerOption used to define which layer will be used
   * @memberof MapComponent
   */
  changeLayerOption(layerOption: any) {
    this.map.removeLayer(this.savedLayer);
    this.savedLayer = tileLayer(layerOption.path, {
      maxZoom: this.settings.maxZoom,
      attribution: layerOption.attribution
    });
    this.savedLayer.addTo(this.map);

    if (document.getElementsByClassName("leaflet-control-attribution")?.[0]?.innerHTML)
      document.getElementsByClassName("leaflet-control-attribution")[0].innerHTML = document.getElementsByClassName("leaflet-control-attribution")[0]?.textContent?.slice(9);
  }

  getItems() {
    return this.filteredLocations.filter((item) => item.hasOwnProperty('lon') && item.hasOwnProperty('lat')
      && item.address.hasOwnProperty('country') && item.address.hasOwnProperty('road')
      && item.address.hasOwnProperty('postcode'));
  }

  flyToLocation(location) {
    this.coordinates = [{
      lat: location.lat,
      lon: location.lon,
      center: true
    }];
    this.addMarkers();

    this.selected.emit(location);
  }

  freeMarkers() {
    for (let obj of this.markerList) {
      obj.marker.remove();
    }

    this.markerList = [];
    this.indexedMarkerList = [];

    if (this.savedCluster) {
      this.savedCluster.remove();
    }
  }
}
