import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Observable, of, Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {MetersFiltrationModel} from "../../../../core/models/pagination models/meters-pagination.model";
import {Energy} from "../../../../core/models/energy/enums/energy.name-space";
import {SiteDto} from "../../../../core/models/sites/siteDTO";
import {
  SgAutocompleteSingleSelectComponent
} from "../../../sg-components/sg-autocomplete-single-select/sg-autocomplete-single-select.component";

@Component({
  selector: 'gmao-energy-meters-filtering',
  templateUrl: './energy-meters-filtering.component.html',
  styleUrls: ['./energy-meters-filtering.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnergyMetersFilteringComponent implements OnInit, OnDestroy {
  @ViewChild('siteSingleSelect') siteSingleSelect: SgAutocompleteSingleSelectComponent;
  @Input('filtrationModel') filtrationModel: MetersFiltrationModel;
  @Input('sitesList') sitesLists: SiteDto[] = [];
  @Input('disableSearch') disableSearch: boolean = false;
  @Output('filteringFieldsUpdated') filteringFieldsUpdated = new EventEmitter();
  metersType = Object.keys(Energy.SourceTypeEnum);
  energyType = Object.values(Energy.EnergyTypeEnum).filter(ele => ele != Energy.EnergyTypeEnum.TOTAL);
  energyIcons = Energy.EnergyTypeIconsEnum;
  search$ = new Subject<string>();
  searchSubscription: Subscription = null;

  constructor() {
  }

  ngOnInit(): void {
    this.subscribeFilteringSubject();
  }

  getSites = (data?: string): Observable<SiteDto[]> => {
    let filteredSites: SiteDto[] = [...this.sitesLists].filter((site: SiteDto) => site.name.toLowerCase().includes(data?.toLowerCase() || ''))
    return of(filteredSites)
  }

  filterOptionsChanged(nameChanged?: boolean) {
    nameChanged ? this.search$.next(this.filtrationModel.filterString) : this.filteringFieldsUpdated.emit();
  }

  private subscribeFilteringSubject() {
    this.searchSubscription = this.search$.pipe(distinctUntilChanged(), debounceTime(400)).subscribe((name: string) => {
      this.filteringFieldsUpdated.emit()
    })
  }

  newSiteSelected(event: SiteDto) {
    if(this.filtrationModel.siteReference == event._id) return;
    this.filtrationModel.siteReference = event._id;
    this.siteSingleSelect?.resetSearchField();
    this.filteringFieldsUpdated.emit();
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }
}
