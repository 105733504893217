import { ScadaSidebarComponent } from './scada-sidebar.component';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {
  ControlsDashboardManagementService
} from "../../../../core/services/controls-dashboard/controls-dashboard-management.service";

@NgModule({
  declarations: [ScadaSidebarComponent],
  exports: [ScadaSidebarComponent],
  imports: [
    MatRippleModule,
    MatIconModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatRadioModule,
    CommonModule,
    MatButtonModule,
  ],
  providers: [ControlsDashboardManagementService]
})
export class ScadaSidebarModule { }
