import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationsService} from "../../../core/services/auth/authenticationsService";
import {MenuLink} from "../../../core/models/menu/menu-link";
import {cloneDeep as _cloneDeep} from "lodash";
import {MenuLinksManagementService} from "../../../core/services/menu-link/menu-links-management.service";
import {AlarmsSkeletonComponent} from "../../loading-skeleton/alarms-skeleton/alarms-skeleton.component";
import {MenuLinkSkeletonComponent} from "../../loading-skeleton/menu-link-skeleton/menu-link-skeleton.component";
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'gmao-sidenav-link',
  templateUrl: './sidenav-link.component.html',
  styleUrls: ['./sidenav-link.component.scss'],
  animations: [
    trigger('expandCollapse', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('0.3s ease-out', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('0.3s ease-out', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class SidenavLinkComponent implements OnChanges {
  @Input('links') links: MenuLink[];
  @Input('isExpanded') isExpanded: boolean;
  currentLinks: MenuLink[];
  loadingSkeletonComponent = MenuLinkSkeletonComponent;
  constructor(private router: Router,
              private menuLinksManagementService: MenuLinksManagementService,
              public authService: AuthenticationsService,) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['links']?.currentValue || this.currentLinks?.length) return;
    this.currentLinks = _cloneDeep(this.links);
  }


  handleClick(event: Event, link: MenuLink): void {
    event.preventDefault();
    if (!link?.path) {
      this.currentLinks = this.menuLinksManagementService.handleExpandedMenuLinkGroup(this.currentLinks, link.name);
    } else {
      if (link.path == 'logOut') {
        this.authService.signOut(true);
      } else {
        this.router.navigate([link.path]);
      }
    }
  }
}
