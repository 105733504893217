import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { SiteInformationSidebarComponent } from './site-information-sidebar.component';
import { ImageDialogModule } from '../../../global/dialogs/image-dialog/image-dialog.module';
import { MapModule } from '../../../../core/deprecated/deprecated-components/deprecated-map/map.module';

@NgModule({
  declarations: [SiteInformationSidebarComponent],
  exports: [SiteInformationSidebarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatExpansionModule,
    MatMenuModule,
    PipesModule,
    ImageDialogModule,
    MapModule

  ]
})
export class SiteInformationSidebarModule {
}
