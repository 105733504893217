import { Component, HostBinding, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, interval } from 'rxjs';
import { DataSourceVariable } from 'src/app/core/deprecated/deprecated-models/data-source-variable.model';
import { Steps } from 'src/app/core/deprecated/deprecated-models/steps.enum';
import { TesseractService } from 'src/app/core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service';
import {Energy} from "../../core/models/energy/enums/energy.name-space";

@Component({
  selector: 'gmao-reception-window',
  templateUrl: './reception-window.component.html',
  styleUrls: ['./reception-window.component.scss']
})
export class ReceptionWindowComponent implements OnInit {
  @HostBinding('class') rootComponentClasses = 'd-flex flex-column h-100';

  title: String = "TABLEAU DE BORD DES CONSOMMATIONS D'ÉNERGIE";

  svgs: string[] = [
    '../../../../assets/reception/Perf_energetique.svg',
    '../../../../assets/reception/comparatif_intersites.svg',
    '../../../../assets/reception/Impact_environmt.svg'
  ];

  mainTitle: any;

  settings = {
    title: "PRODUCTION D'ÉNERGIE",
    unit: "En "+ Energy.EnergyUnitEnum.KWH,
    description: "par panneaux photovoltaïques",
    solarSVG: "../../../assets/reception/icon_photovoltaique.svg",
    formula: {
       formula: "cm1*1"
    },
    startDate: new Date(),
    endDate: new Date(),
    delta: true,
    totalStep: Steps.DAYS,
    totalTitle: "en temps réel"
  };

  sources: DataSourceVariable[] = [
      {
         source: 6686,
         path: "saclay_cps1>8.14011>2.67",
         variable: "cm1"
      }
   ]
 isLoading = false;

 totalData: number | string = "-";

  constructor(
    private readonly tesseractService: TesseractService,
    private translate: TranslateService
  ) {
    interval(300000).subscribe((func => {
      this.loadData();
   }));
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadTranslations(){
    // Make an await 'dummy' call before the real translations.
    // After the response, every instant call will work bc we're sure
    // translations are loaded.
    await this.translate.get('dummyTranslation').toPromise().then();
    this.title = this.translate.instant('energyConsumptionDashboard');
    this.settings.title = this.translate.instant('energyProduction.title');
    this.settings.description = this.translate.instant('energyProduction.description');
    this.settings.totalTitle = this.translate.instant('energyProduction.totalTitle');
  }

  async loadData(){
    this.isLoading = true;
    this.loadTranslations();
    this.settings.startDate = new Date();
    this.settings.startDate.setHours(0);
    this.settings.startDate.setMinutes(0);
    this.settings.startDate.setSeconds(0);
    this.settings.startDate.setMilliseconds(0);
    this.settings.endDate = new Date(this.settings.startDate);
    let diff = 24 * 60 * 60 * 1000;
    this.settings.endDate.setTime(this.settings.endDate.getTime() + diff);

    forkJoin([
       this.tesseractService.getFormula(
          this.sources,
          this.settings.formula,
          this.settings.startDate,
          this.settings.endDate,
          this.settings.delta,
          this.settings.totalStep
       ),
    ]).subscribe( (results: any) => {

      if (results[0].length > 0) {
        this.totalData = results[0][0].value;
      } else {
        this.totalData = "-";
      };

      this.isLoading = false;
    });
 }
}
