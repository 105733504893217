import {ComponentFactoryResolver, Directive, Input, SimpleChanges, TemplateRef, ViewContainerRef} from "@angular/core";

/**
 * @author Aysar Isbeih
 */
@Directive({selector: '[skeleton]'})
export class SkeletonDirective {
  @Input() set skeleton(context: any) {
  }

  @Input('skeletonRepeat') size = 1;
  @Input('skeletonWidth') width: string;
  @Input('skeletonHeight') height: string;
  @Input('skeletonClassName') className: string;
  @Input('skeletonComponent') component: any;
  @Input('skeletonConfig') config: any;

  constructor(private tpl: TemplateRef<any>,
              private componentFactoryResolver: ComponentFactoryResolver,
              private vcr: ViewContainerRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['skeleton']) {
      this.vcr.clear();
      if (changes['skeleton'].currentValue) {
        Array.from({length: this.size}).forEach(() => {

          let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
          const ref: any = this.vcr.createComponent(componentFactory);

          if (this.config) ref.instance['configurations'] = this.config;
          let element: HTMLElement = <HTMLElement>ref.location.nativeElement;
          element.style.width = this.width;
          element.style.height = this.height;
          element.style.minHeight = this.height;
          element.classList.add(this.className);
        })
      } else {
        this.vcr.createEmbeddedView(this.tpl);
      }
    }
  }
}
