import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlValueCellComponent} from './control-value-cell.component';
import {
  EquipmentControlDynamicPluginModule
} from "../../../../equipment-controls/equipment-control-dynamic-plugin/equipment-control-dynamic-plugin.module";
import {ControlPowerPluginModule} from "../../../../equipment-controls/control-power-plugin/control-power-plugin.module";
import {
  ControlsDashboardManagementService
} from "../../../../../core/services/controls-dashboard/controls-dashboard-management.service";

@NgModule({
  declarations: [ControlValueCellComponent],
  exports: [ControlValueCellComponent],
  imports: [
    CommonModule,
    EquipmentControlDynamicPluginModule,
    ControlPowerPluginModule,
  ],
  providers: [ControlsDashboardManagementService]
})
export class ControlValueCellModule {
}
