import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AlarmSidebarComponent} from "./alarm-sidebar.component";
import {HighchartsChartModule} from "highcharts-angular";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {PipesModule} from "../../../../core/pipes/pipes.module";
import {TranslateModule} from "@ngx-translate/core";
import {AlarmsService} from "../../../../core/services/alarms/alarms.service";
import {AlarmsManagementService} from "../../../../core/services/alarms/alarms-management.service";


@NgModule({
  declarations: [AlarmSidebarComponent],
  exports: [AlarmSidebarComponent],
  imports: [
    CommonModule,
    HighchartsChartModule,
    MatProgressSpinnerModule,
    PipesModule,
    TranslateModule
  ],
  providers: [AlarmsService, AlarmsManagementService]
})
export class AlarmSidebarModule { }
