import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EquipmentUtilsPaths } from '../../models/site-equipments/equipment/equipment-models-paths.enum';
import { EquipmentsUtilsType } from '../../models/site-equipments/equipment/equipments-utils.type';
import { Observable } from 'rxjs';
import { UtilsConfigurations } from '../../models/site-equipments/equipment/equipment-utils';

@Injectable({providedIn: 'root'})
export class EquipmentUtilsService {
  private baseUrl = `api/sg-equipment`;
  private allUtilsUrl = '/equipments/configuration'

  constructor(private http: HttpClient) {
  }

  createEquipmentUtil(modelPath: EquipmentUtilsPaths.EquipmentUtilsPathsTypes, body: EquipmentsUtilsType): Observable<EquipmentsUtilsType> {
    let path = this.baseUrl + modelPath;
    return this.http.post<EquipmentsUtilsType>(path, body)
  }

  getEquipmentUtil(modelPath: EquipmentUtilsPaths.EquipmentUtilsPathsTypes): Observable<EquipmentsUtilsType[]> {
    let path = this.baseUrl + modelPath;
    return this.http.get<EquipmentsUtilsType[]>(path)
  }

  getEquipmentUtilById(modelPath: EquipmentUtilsPaths.EquipmentUtilsPathsTypes, utilId: string): Observable<EquipmentsUtilsType> {
    let path = this.baseUrl + modelPath + utilId;
    return this.http.get<EquipmentsUtilsType>(path)
  }

  updateEquipmentUtil(modelPath: EquipmentUtilsPaths.EquipmentUtilsPathsTypes, body: EquipmentsUtilsType): Observable<EquipmentsUtilsType> {
    let path = this.baseUrl + modelPath + body._id;
    return this.http.put<EquipmentsUtilsType>(path, body)
  }

  deleteEquipmentUtil(modelPath: EquipmentUtilsPaths.EquipmentUtilsPathsTypes, utilId: string) {
    let path = this.baseUrl + modelPath + utilId;
    return this.http.delete(path)
  }

  getAllUtils(): Observable<UtilsConfigurations> {
    let path = this.baseUrl + this.allUtilsUrl;
    return this.http.get<UtilsConfigurations>(path)
  }
}
