import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {
  EquipmentControlSelectionModel
} from "../../../core/models/dashboard-controls/equipment-control-selection-model";
import {
  EquipmentConfiguration
} from "../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";
import {ControlsFiltrationModel} from "../../../core/models/dashboard-controls/controls-filtration-model";
import {SiteEquipmentDto} from "../../../core/models/site-equipments/site-equipment-dto";

@Component({
  selector: 'equipment-control-page',
  templateUrl: './equipment-control-dashboard.component.html',
  styleUrls: ['./equipment-control-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EquipmentControlDashboardComponent implements OnInit {
  /**
   * This boolean value that determine view
   */
  isPlanView: boolean = false;
  /**
   * Store the control configurations to pass config-control-cell
   * @see ConfigControlCellComponent
   */
  configurationsList: EquipmentConfiguration[] = [];
  /**
   * Store thumb image for selected floor
   * @see ControlsDashboardFilteringComponent.changeThumbImgEmitter
   * @see ControlsDashboardFilteringComponent.handleFloorSelection
   * @see ControlPlanDashboardViewComponent.floorThumbImg
   */
  /**
   * Store filtrationModel  value and passed new value to list and plan views
   * @see ControlsDashboardFilteringComponent.changeFilterEmitter
   * @see ControlsDashboardFilteringComponent.subscribeOptionsChanging
   * @see ControlsDashboardComponent.handleFilterChange
   * @see ControlListDashboardViewComponent.paginationModel
   * @see ControlPlanDashboardViewComponent.paginationModel
   */
  filtrationModel: ControlsFiltrationModel;
  floorThumbImg: string = null;
  isOpenConfigurations: boolean = false;
  configurationLabel: string;
  status: SiteEquipmentDto;

  ngOnInit(): void {
    this.initPaginationModel();
  }

  /**
   * Init paginationModel value and passed it as reference between component
   * @see ControlsDashboardFilteringComponent.paginationModel
   * @see ControlListDashboardViewComponent.paginationModel
   * @see ControlPlanDashboardViewComponent.paginationModel
   * @private
   */

  private initPaginationModel() {
    this.filtrationModel ={
      siteId: '',
      zone: '',
      categoryId: '',
      floorId: ''
    };
  }

  /**
   * Handle view change  event
   * @see ControlsDashboardFilteringComponent.changeViewControlTypeEmitter
   */
  handleViewChange(event: boolean) {
    this.configurationsList = [];
    this.isOpenConfigurations = false;
    this.isPlanView = event;
  }

  /**
   * Handle paginationModel change value event
   * @see ControlsDashboardFilteringComponent.changeFilterEmitter
   */
  handleFilterChange() {
    this.configurationsList = [];
    this.filtrationModel = Object.assign({}, this.filtrationModel);
  }

  /**
   * Handle select control event
   * @see ControlListDashboardViewComponent.selectControlEmitter
   * @see ControlListDashboardViewComponent.rowSelected
   * @see ControlPlanDashboardViewComponent.selectControlEmitter
   */
  handleSelection(selection: EquipmentControlSelectionModel) {
    this.isOpenConfigurations = true;
    this.configurationLabel = selection.label;
    this.status = selection.status;
    this.configurationsList = selection.equipmentConfigurations;
  }

  /**
   * Handle close configurations list event
   */
  closeConfigurationsList() {
    this.isOpenConfigurations = false;
  }
}
