export namespace Home {
  export const highestSiteConsumptionColors: string[] = ['#FFB9B9', '#FFDEB9', '#FDFFB9', '#F3F3F3'];
  export const lowestSiteConsumptionColors: string[] = ['#A1FFA7', '#C4FFBC', '#D9FFD7', '#F3F3F3'];
  export const consumptionPieChartColors: string[] = ['#086C4D', '#BE3378', '#FFB9B9', '#C4FFBC', '#FFDEB9', '#FDFFB9', '#F3F3F3'];

  export const TRENDING_FLAT = '/assets/same.svg';
  export const TRENDING_DOWN = '/assets/down.svg';
  export const TRENDING_UP = '/assets/up.svg';

  export const TRENDING_FLAT_COLOR = '#666';
  export const TRENDING_DOWN_COLOR = '#7AC943';
  export const TRENDING_UP_COLOR = '#FF1D25';

  export const EnergyTypeColorMap: {[type:string]:string} = {
    ELECTRICITY: '#06B78B',
    GAZ: '#F12827',
    FUEL_OIL: '#333333',
    WATER: '#0F87CC',
    GENERAL_VEHICLES_GAZ: '#F56867',
    DIESEL: '#FF8029',
    BENZINE: '#2CA05A',
    HOT_WATER: '#91B8FF',
    COLD_WATER: '#03CCFF',
    TOTAL: '#FF5757',
  }


}
