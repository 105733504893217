import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, map, retry} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import * as _ from "underscore";
import {AuthenticationsService} from "../../../services/auth/authenticationsService";


@Injectable()
/**
 * @deprecated
 */
export class BuildingService {
  mapSettings: any;
  buildingPath = `/api/sg-sites/sites/`;

  constructor(private http: HttpClient, private authService: AuthenticationsService) {
  }

  /**
   * @deprecated
   */
  getBuildingById(buildingId): Observable<any> {
    if (buildingId === "general") {
      return of({
        buildingId: "general",
        currentBuildingId: "general",
        name: "General",
        views: [],
      })
    }
    const path = this.buildingPath + buildingId + '/';
    return this.http.get(path).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  getBuildings() {
    return this.http.get(this.buildingPath).pipe(
      map(res => res = _.sortBy(res, 'name')),
      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  searchBuildings(query: string) {


    const params = {
      params: new HttpParams().set('criteria', JSON.stringify({searchRegex: query}))
    };

    return this.http.get(this.buildingPath, params).pipe(
      catchError(this.handleError)
    );

  }




  /**
   * @deprecated
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError({'error': error.error.message, 'statuscode': error.status});

    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError({'error': 'backend error', 'statuscode': error.status});

    }
    // return an observable with a user-facing error message
  }
}
