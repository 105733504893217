import { Pipe } from '@angular/core';

@Pipe({
  name: 'truncateTitle'
})
export class TruncateTitlePipe {
  transform(value: string, length: number): string {
    if (!value) {
      return '';
    }

    return value.length <= length
      ? value
      : value.slice(0, length - 3) + '...';
  }
}
