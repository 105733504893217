<div class="equipment-sidebar-container">

  <div matRipple class="utility-button bxs-black f-row j-center a-center w-100p h-5p gap-5px"
    (click)="save()">

    <mat-icon>save</mat-icon>
    {{ 'equipmentControl.save' | translate }}

  </div>

  <hr>

  <mat-form-field class="building-equipment-selector w-100p" appearance="outline">
    <mat-label>{{ 'equipmentControl.addEquipment' | translate }}</mat-label>
      <mat-select>
        <mat-option
          *ngFor="let equipment of buildingEquipmentList"
          [value]="equipment.nomenClature"
          (click)="addBuildingEquipment(equipment)"
          [title]="equipment?.nomenClature"
        >
          {{ equipment?.nomenClature }}
        </mat-option>
      </mat-select>
  </mat-form-field>

  <hr>

  <mat-accordion>

    <mat-expansion-panel *ngFor="let equipment of selectedBuildingEquipment; let equipmentIndex=index">

      <mat-expansion-panel-header>
        <mat-panel-title [title]="equipment?.nomenClature">
          <b class="accordion-item-title">{{ equipment?.nomenClature }}</b>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-radio-group>

        <div *ngFor="let configuration of equipment?.equipmentConfigurations; let index=index">
          <p *ngIf="configuration.added">
            <mat-radio-button
              class="radio-button"
              [checked]="configuration.dataId === equipment?.equipmentControlParameter?.defaultConfigs[0]"
              [value]="{
                equipment: equipment,
                configuration: configuration,
                index: index
              }"
              (change)="selectedEquipmentConfiguration($event, equipmentIndex)">
              <div
                [title]="configuration.name"
                class="radio-button-text">
                {{ configuration?.name }}
              </div>
            </mat-radio-button>
          </p>
        </div>

      </mat-radio-group>

      <div class="w-100p f-row j-center a-center">
        <button (click)="removeBuildingEquipment(equipment)" mat-raised-button>{{ 'equipmentControl.delete' | translate }}</button>
      </div>

    </mat-expansion-panel>

  </mat-accordion>


</div>
