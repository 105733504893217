import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NotificationsSetting, User} from '../../../core/models/user-access/user';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatchValidator} from '../../../core/custom-validators/match-validator';
import {UserSpace} from '../../../core/models/user-access/user.enum';
import {UserAccessService} from '../../../core/services/user-access/user-access.service';
import {GroupsAccessService} from '../../../core/services/user-access/groups-access.service';
import {AccessGroupDTO} from '../../../core/models/user-access/access-group-d-t-o';
import {ActionTypeEnum, ViewAction} from "../../../core/view-models/actionType";
import {Observable, Subscription} from "rxjs";
import {LocalStorageService} from "../../../core/services/localstorage/local-storage.service";
import UserRoleEnum = UserSpace.UserRoleEnum;
import LanguagesEnum = UserSpace.LanguagesEnum;
import LanguagesTypes = UserSpace.LanguagesTypes;

@Component({
  selector: 'gmao-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserFormComponent implements OnInit, OnDestroy {
  @Input('isProfile') isProfile: boolean = false;
  @Output('updateUser') updateUser = new EventEmitter<ViewAction<User>>();
  @Output('closeForm') closeForm = new EventEmitter();
  userForm: FormGroup = null;
  editMode = false;
  userRoles: string[] = Object.values(UserSpace.UserRoleNamesEnum);
  languages: string[] = Object.keys(UserSpace.LanguagesEnum);
  UserRoleEnum = UserRoleEnum;
  languagesEnum = LanguagesEnum;
  groupsList: AccessGroupDTO[] = [];
  loading = true;
  fetchedUser: User = null;
  subscription: Subscription = null;

  constructor(
    private userAccessService: UserAccessService,
    private localStorageService: LocalStorageService,
    private userAccessGroups: GroupsAccessService) {
  }

  ngOnInit(): void {
    this.initForm(null);
    this.getGroups();
  }

  private getGroups() {
    this.userAccessGroups.getGroups().subscribe((data: AccessGroupDTO[]) => {
      this.groupsList = data;
      this.loading = false;
      this.groupsList.length == 0 ? this.userForm.controls['groups'].disable() : this.userForm.controls['groups'].enable();
    }, error => this.loading = false);
  }

  fetchSelectedUser(user: User) {
    this.fetchedUser = user;
    this.editMode = !!user;
    this.initForm(user)
  }

  private initForm(user: User) {
    this.subscription?.unsubscribe();
    let passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[ !"#$%&\'()*+,-./:;<=>?@\\\\[\\]^_`{|}~]).{8,}$';
    let passwordValidators = this.editMode ? [Validators.pattern(passwordPattern)] : [Validators.required, Validators.pattern(passwordPattern)];
    let gmaoNotification: NotificationsSetting = user?.notificationSettings?.find((notification: NotificationsSetting) => notification?.name == 'gmao')
    this.userForm = new FormGroup({
      userName: new FormControl(user?.name, [Validators.required, Validators.minLength(3)]),
      email: new FormControl(user?.login, [Validators.required, Validators.email]),
      role: new FormControl(user?.roleId, [Validators.required]),
      groups: new FormControl({value: user?.groups, disabled: !user}),
      password: new FormControl('', passwordValidators),
      confirmedPassword: new FormControl({value: '', disabled: true}),
      language: new FormControl(user?.language),
      notifications: new FormControl(!!gmaoNotification?.notification),
    });

    this.subscription = this.userForm.controls['password'].valueChanges.subscribe((value) => {
      let confirmPasswordValidators = [];
      let passwordValidators = this.editMode ? [Validators.pattern(passwordPattern)] : [Validators.required, Validators.pattern(passwordPattern)];
      this.userForm.controls['password'].setValidators(passwordValidators);
      value?.length != 0 ? this.userForm.controls['confirmedPassword'].enable() : this.userForm.controls['confirmedPassword'].disable();
      !this.editMode || this.editMode && value?.length != 0 ? confirmPasswordValidators = [MatchValidator(this.userForm.controls['password']), Validators.required] : confirmPasswordValidators = [];
      this.userForm.controls['confirmedPassword'].setValidators(confirmPasswordValidators);
      this.userForm.controls['confirmedPassword'].reset();
    });
  }

  backToList() {
    this.closeForm.emit();
  }

  private saveObs = (user: User): Observable<User> => {
    return this.editMode ? this.userAccessService.updateUser(user) : this.userAccessService.createUser(user);
  }

  saveForm() {
    this.loading = true;
    let user: User = this.getFormData();
    this.saveObs(user).subscribe((response: User) => {
      let action: ViewAction<User> = {
        type: this.editMode ? ActionTypeEnum.UPDATE : ActionTypeEnum.ADD,
        data: response
      }
      this.updateUser.emit(action);
      this.isProfile && this.localStorageService.changeAppLanguage(response.language as LanguagesTypes);
      this.loading = false;
    }, error => this.loading = false)
  }

  private getFormData(): User {
    let user: User = {
      _id: this.editMode && this.fetchedUser._id,
      name: this.userForm.value['userName'],
      login: this.userForm.value['email'].toLowerCase(),
      password: this.getValueFromControl('language') ? this.userForm.controls['password'].value : '',
      roleId: this.userForm.controls['role'].value,
      groups: this.userForm.controls['groups'].value,
      accessGroups: this.userForm.controls['groups'].value,
      notificationSettings: [{name: "gmao", notification: this.userForm.value['notifications']}],
      language: this.getValueFromControl('language') ? this.userForm.controls['language'].value : 'fr',
      salt: this.editMode ? this.fetchedUser.salt : '',
      active: this.editMode ? this.fetchedUser.active : true,
      confirmed: this.editMode ? this.fetchedUser.confirmed : true,
      confirmedEmail: this.editMode ? this.fetchedUser.confirmedEmail : false,
      last_login: this.editMode ? this.fetchedUser.last_login : null,
    }
    if (user.password.length == 0) delete user.password;
    return user
  }

  private getValueFromControl(formControl: string): boolean {
    return this.userForm.controls[formControl].value && this.userForm.controls[formControl].value.lenght != 0
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
