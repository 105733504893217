<div class="user-profile-cont">
  <div class="profile-wrapper">
    <ng-container *skeleton="!loggedInUser ;repeat: 9; component:loadingSkeletonComponent;height: '68px';width: '100%';">
    </ng-container>
    <gmao-user-form #userForm
                    [isProfile]="true"
                    [class.hidden]="!loggedInUser">
    </gmao-user-form>
  </div>
</div>
