<div id="map" #map>
  <div class="width-100" id="layerOptionsBtn">
    <button mat-icon-button *ngIf="settings.showLayersControl" class="map-button" [matMenuTriggerFor]="menu" block>
      <mat-icon>layers</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button *ngFor="let layerOption of layerOptions" mat-menu-item (click)="changeLayerOption(layerOption)">
        <span>{{layerOption.name}}</span>
      </button>
    </mat-menu>
  </div>

  <form class="map-search" *ngIf="settings.showSearchControl">
    <mat-form-field class="map-field">
      <input matInput placeholder="Search" aria-label="State" autofocus [matAutocomplete]="autoMap" [formControl]="searchCtrl">
      <mat-autocomplete #autoMap="matAutocomplete" >
        <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let location of getItems()" [value]="location.display_name" (click)="flyToLocation(location)">
            <span><b>{{location.display_name}}</b></span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
