import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { WeatherChipComponent } from "./weather-chip.component";
import { TranslateModule } from "@ngx-translate/core";
import {LoadingSkeletonModule} from "../../../components/loading-skeleton/loading-skeleton.module";

@NgModule({
    declarations: [
        WeatherChipComponent
    ],

  imports: [
    CommonModule,
    TranslateModule,
    LoadingSkeletonModule
  ],

    exports: [
        WeatherChipComponent
    ]
})
export class WeatherChipModule {}
