import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CsvDisplayErrorsDialog } from './csv-display-errors-dialog.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {PipesModule} from "../../../pipes/pipes.module";



@NgModule({

  imports:[
    TranslateModule,
    PipesModule,
    CommonModule,
    MatButtonModule
  ],
  declarations: [
    CsvDisplayErrorsDialog,
  ],
  exports: [
    CsvDisplayErrorsDialog
  ],

})
export class CsvDisplayErrorsDialogModule { }
