import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HomeManagementService} from "../../../core/services/home/home-management.service";
import {SitesService} from "../../../core/services/sites/sites.service";
import {SiteView} from "../../../core/models/sites/site-view";
import {NavigationSkeletonComponent} from "../../loading-skeleton/home/navigation-skeleton/navigation-skeleton.component";
import {AuthenticationsService} from 'src/app/core/services/auth/authenticationsService';
import {Subscription} from "rxjs";
import {DashboardSiteDto} from "../../../core/models/sites/siteDTO";
import {DashboardService} from "../../../core/services/dashboard/dashboard.service";

@Component({
  selector: 'gmao-navigation-v2',
  templateUrl: './home-navigation-header.component.html',
  styleUrls: ['./home-navigation-header.component.scss']
})
export class HomeNavigationHeaderComponent implements OnInit, OnDestroy {
  @Input('withSideInfo') withSideInfo: boolean = false;
  navLinks: SiteView[] = [];
  isLoadingData: boolean = true;
  loadingSkeletonComponent = NavigationSkeletonComponent;
  previousId: string;
  routSubscription: Subscription = null;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dashboardService: DashboardService,
              private auth: AuthenticationsService,
              private homeManagementService: HomeManagementService,
              private sitesService: SitesService) {
  }


  ngOnInit(): void {
    this.checkCurrentPageAndSetInitialValues();
    this.subscribeSelected();
  }

  private checkCurrentPageAndSetInitialValues() {
    let url: string[] = this.router.url.split('/');
    if (url[1] == 'dashboard') return
    let siteId = this.router.url.split('/')[1];
    this.previousId = siteId;
    this.dashboardService.changeDashboardSite(siteId);
    this.getSiteViews(siteId);
  }

  private getSiteViews(id: string) {
    this.isLoadingData = true;
    this.sitesService.getSiteViews(id).subscribe((views: SiteView[]) => {
      this.navLinks = views;
      this.isLoadingData = false;
    })
  }

  private subscribeSelected() {
    this.dashboardService.dashboardSelectedSiteObs.subscribe((site: DashboardSiteDto) => {
      if (!site || this.previousId == site._id) return;
      this.previousId = site._id;
      this.getSiteViews(site._id);
    })
  }

  redirectTo(url: string) {
    this.router.navigate([url]);
  }

  ngOnDestroy(): void {
    this.routSubscription?.unsubscribe();
    this.dashboardService.resetSelectedSite()
  }
}
