<div class="sla-value-plugin-container">
  <section>
    <mat-icon>tune</mat-icon>
    <p>{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.STATUS' | translate | capitalize}}</p>
  </section>
  <section>
    <p>{{ configuration.name }}</p>
    <span class="display-value-content">
      {{ displayValue }}
    </span>
    <div class="display-value-content">
      <div class="range">
        <span>{{ configuration.additionalInfo.min }}</span>
        <span>{{ 'SITE_EQUIPMENTS.MANUAL_DATA.SLA_SEPARATOR' | translate }}</span>
        <span>{{ configuration.additionalInfo.max + ' ' + configuration.unit }}</span>
      </div>
    </div>
  </section>
  <section>
    <div class="circle" [ngClass]="status"></div>
  </section>
  <div class="loading-shade" *ngIf="isLoading">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
