import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar.component';
import { AlarmSidebarModule } from './alarm-sidebar/alarm-sidebar.module';
import { ScadaSidebarModule } from './scada-sidebar/scada-sidebar.module';
import { SidebarsToolbarModule } from './sidebars-toolbar/sidebars-toolbar.module';
import { SiteInformationSidebarModule } from './site-information-sidebar/site-information-sidebar.module';

@NgModule({
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  imports: [
    CommonModule,
    AlarmSidebarModule,
    ScadaSidebarModule,
    SidebarsToolbarModule,
    SiteInformationSidebarModule
  ],
  providers: []
})

export class SideNaveModule {

}
