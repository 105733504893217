import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as Highcharts from 'highcharts/highcharts';
import * as moment from 'moment';
import * as MomentTimeZone from 'moment-timezone';
import {TranslateService} from "@ngx-translate/core";
import {HighchartsLanguage} from "../../../core/models/high-charts/high-charts-language";
import {UserSpace} from "../../../core/models/user-access/user.enum";
import LanguagesEnum = UserSpace.LanguagesEnum;

declare var require: any;

window.moment = moment;
MomentTimeZone();
const IndicatorZigZag = require('highcharts/modules/exporting');
IndicatorZigZag(Highcharts);
const IndicatorsCore = require('highcharts/modules/export-data');
IndicatorsCore(Highcharts);

@Component({
  selector: 'gridster-chart-dialog',
  templateUrl: './gridster-chart-dialog.component.html'
})
export class GridsterChartDialog implements OnInit {
  highcharts = Highcharts;
  constructorType = 'chart';
  chartOptions;
  chart: Highcharts.Chart;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<GridsterChartDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.setHighchartsLang();
  }

  private setHighchartsLang() {
    Highcharts.setOptions({
      lang: this.translateService.currentLang == LanguagesEnum.FRENCH ? HighchartsLanguage.LocaleFrench : HighchartsLanguage.LocaleEnglish
    });
  }

  protected loadChartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
  }
  ngOnInit(): void {
    this.chartOptions = {
      global: {
        useUTC: true
      },
      chart: {
        zoomType: false
      },
      time: {
        timezone: 'Europe/Paris'
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['downloadPNG', 'downloadJPEG', 'downloadSVG', 'downloadCSV','downloadXLS'],
          },
        },
      },
      title: {
        text: this.data.description
      },
      xAxis: [
        {
          crosshair: true,
          type: 'datetime'
        }
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}' + this.data.unit,
            style: {
              color: 'black',
              fontSize: '13px'
            }
          },
          title: {
            text: this.data.unit,
            style: {
              color: 'black'
            }
          }
        }
      ],
      tooltip: {
        shared: true,
        style: {
          fontSize: '16px'
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          turboThreshold: 0,
          type: 'spline',
          color: '#000',
          lineWidth: 3,
          data: this.data.data,
          name: this.data.description,
          tooltip: {
            valueSuffix: ''
          }
        }
      ]
    };
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}


declare global {
  interface Window { moment: any; }
}

window.moment = window.moment || {};
