import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthRequest} from "../../models/auth/auth-request";
import {AuthenticationParams} from "../../models/auth/authentication-params";
import {BehaviorSubject, Observable} from "rxjs";
import {LocalStorageService} from '../localstorage/local-storage.service';
import {catchError, tap} from "rxjs/operators";
import {LocalStorageKeysEnum, SigningOutKeys} from "../localstorage/local-storage-keys.enum";
import {UserSpace} from "../../models/user-access/user.enum";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {User} from "../../models/user-access/user";
import {AuthorizationSpace} from "../../models/app/authorizationNameSpace";
import {SiteStoreService} from "../sites/site-store.service";
import {ErrorHandlerService} from "../error-handler/error-handler.service";
import AuthedUserAttributes = UserSpace.AuthedUserAttributes;
import AuthedUserAttributesEnum = UserSpace.AuthedUserAttributesEnum;
import PagesTypes = AuthorizationSpace.PagesTypes;
import SuperAdminAuthorizedPages = AuthorizationSpace.SuperAdminAuthorizedPages;

@Injectable({providedIn: 'root'})
export class AuthenticationsService {
  private loggedInUserAuthedPages = new BehaviorSubject<PagesTypes[]>(SuperAdminAuthorizedPages);
  loggedInUserAuthedPagesObs: Observable<PagesTypes[]> = this.loggedInUserAuthedPages.asObservable();

  private isSmartkizSubject = new BehaviorSubject<boolean>(false);
  isSmartkizObs = this.isSmartkizSubject.asObservable();

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isAuthenticatedObs: Observable<boolean> = this.isAuthenticatedSubject.asObservable();
  private baseUrl = 'api/credentials/';

  constructor(private http: HttpClient,
              private router: Router,
              private jwtHelper: JwtHelperService,
              private siteStore: SiteStoreService,
              private localStorageService: LocalStorageService,
              private errorHandler: ErrorHandlerService
  ) {
  }

  loadToken(payload: AuthRequest, clientName: string): Observable<AuthenticationParams> {
    let url = this.baseUrl + clientName;
    return this.http.post<AuthenticationParams>(url, payload).pipe(tap((responseObject: AuthenticationParams) => {
      this.localStorageService.setItem(LocalStorageKeysEnum.AUTHENTICATED_USER, responseObject)
    }), catchError(this.errorHandler.handleError))
  }

  addLoggedInUserAccessToLocalStorage(user: User) {
    let authParams: AuthenticationParams = this.localStorageService.getLocalStorageItem(LocalStorageKeysEnum.AUTHENTICATED_USER);
    authParams.groups = user?.groups;
    authParams.accessGroups = user?.accessGroups;
    this.localStorageService.setItem(LocalStorageKeysEnum.AUTHENTICATED_USER, authParams)
  }

  setLoggedInUserAuthedPages(pageTypes: PagesTypes[]) {
    this.loggedInUserAuthedPages.next(pageTypes);
  }

  public isAuthenticated(): boolean {
    if (this.isAuthenticatedObs == null) {
      this.isAuthenticatedObs = this.isAuthenticatedSubject.asObservable();
    }
    const token: string = this.getAuthedUserData(AuthedUserAttributesEnum.TOKEN) as string;
    if (token) {
      this.isAuthenticatedSubject.next(!this.jwtHelper.isTokenExpired(token))
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      this.isAuthenticatedSubject.next(false);
      return false
    }
  }

  getAuthedUserData(attribute: AuthedUserAttributes) {
    let authParams: AuthenticationParams = this.localStorageService.getLocalStorageItem(LocalStorageKeysEnum.AUTHENTICATED_USER);
    return authParams?.[attribute] != null ? authParams[attribute] : ''
  }

  getClientId(): string {
    return this.localStorageService.getLocalStorageStringItem(LocalStorageKeysEnum.CLIENT_ID)
  }

  signOut(navigateToLogin = true) {
    this.isAuthenticatedSubject.next(false);
    this.siteStore.resetStore();
    this.localStorageService.removeItems(SigningOutKeys);
    navigateToLogin && this.router.navigate(['/login']);
  }

  getSubDomain() {
    const domain = window.location.hostname;
    let subDomain;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'www'
    ) {
      subDomain = '';
    } else {
      subDomain = domain.split('.')[0];
    }

    return subDomain;
  }

  /**
   * Get attribute value from the token
   * @param attribute
   */
  getAttributeFromToken(attribute:string) {
    let token: string = this.localStorageService.getLocalStorageItem(LocalStorageKeysEnum.AUTHENTICATED_USER).token;
    if (token && token.length > 1) {
      let decodedToken: string = atob(token.split('.')[1]);
      return JSON.parse(decodedToken)?.[attribute];
    }
    return 'NONE';
  }

  /**
   * check if the user is smartkiz user
   */
  checkSmartkiz(){
    let isSmartkiz: boolean =  this.getAttributeFromToken('smartkiz');
    this.isSmartkizSubject.next(isSmartkiz);
  }
}
