import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, EMPTY, forkJoin } from 'rxjs';
import { ImageDialogComponent } from '../../../global/dialogs/image-dialog/image-dialog.component';
import { BuildingService } from '../../../../core/deprecated/deprecated-services/deprecated-building/building-service.service';
import { Router } from '@angular/router';
import { SitesService } from '../../../../core/services/sites/sites.service';
import { SiteFloor } from '../../../../core/models/sites/site-floor';
import { LocalStorageKeysEnum } from '../../../../core/services/localstorage/local-storage-keys.enum';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'site-information-sidebar',
  templateUrl: './site-information-sidebar.component.html',
  styleUrls: ['./site-information-sidebar.component.scss']
})
export class SiteInformationSidebarComponent implements OnInit {
  buildingId;
  building: any;
  floorsImages: SiteFloor[] = [];
  isLoadingResults: boolean = false;

  mapSettings = {
    maxZoom: 17,
    initialZoom: 16,
    showZoomControl: false,
    showLayersControl: false,
    showSearchControl: false,
    havePopups: false,
    dragDisabled: true,
    doubleClickDisabled: true,
    center: { lat: 48.864716, lon: 2.349014 }
  };

  mapCoordinates: any = [];
  _mapCoordinates = new BehaviorSubject<any>([]);

  panelOpenState = false;
  logo = environment.logo;

  constructor(
    private buildingService: BuildingService,
    public dialog: MatDialog,
    private siteService: SitesService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.buildingId = window.localStorage.getItem(LocalStorageKeysEnum.CURRENT_SITE_ID);
    // !!this.buildingId && (this.buildingId = this.router.url.split('/')[1]);
    if (this.buildingId) {
      let siteObs$ = this.buildingService.getBuildingById(this.buildingId);
      let siteFloorObs$ = this.siteService.getSiteFloors(this.buildingId);

      forkJoin([siteObs$, siteFloorObs$]).subscribe(result => { // should be fixed to use documents service
        this.building = result[0];
        this.floorsImages = result[1];
        this.isLoadingResults = false;
        this.setMapCooridnates();

      }, error => {
        this.isLoadingResults = false;
        console.log({ error });
      });
    }
  }

  /**
   * assigning building coordinates
   */
  setMapCooridnates() {
    this.mapCoordinates = [];
    if (this.building?.address) {
      this.mapCoordinates.push({
        buildingId: this.building._id,
        lat: parseFloat(this.building.address.lat),
        lon: parseFloat(this.building.address.lon),
        center: true
      });
    }
    this._mapCoordinates.next(EMPTY);
    this._mapCoordinates.next(this.mapCoordinates);
    this.isLoadingResults = false;
  }

  openDialog(url) {

    let dialogRef;

    dialogRef = this.dialog.open(ImageDialogComponent, {
      width: '80%',
      height: '80%',
      data: url
    });
  }
}
