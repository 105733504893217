<div class="status-review-plugin-container">
  <section>
    <mat-icon>notification_important</mat-icon>
    <p class="label">{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.STATUS' | translate | capitalize}}</p>
  </section>
  <section>
    <p>{{ configuration.name }}</p>
    <div class="status-review-content">
      <mat-icon [ngStyle]="{'color': color}" >{{ icon }}</mat-icon>
      <div>{{ message | translate }}</div>
    </div>
  </section>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
