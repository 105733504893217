import {EventEmitter, Injectable} from '@angular/core';
import {User} from "../../models/user-access/user";
import {BehaviorSubject, Observable} from "rxjs";
import * as _ from "underscore";

@Injectable()
export class AccessManagementService {
  constructor() {
  }

  private waitingListUpdateEmitter = new EventEmitter<User>();
  waitingListUpdateObs$ = this.waitingListUpdateEmitter.asObservable();

  private usersSubject = new BehaviorSubject<{ [_id: string]: User }>(null);
  usersObs: Observable<{ [_id: string]: User }> = this.usersSubject.asObservable();

  updateUsersList(acceptedUser?: User) {
    this.waitingListUpdateEmitter.emit(acceptedUser)
  }

  setUsersMap(users: User[]) {
    let usersMap = _.indexBy(users, '_id');
    this.usersSubject.next(usersMap);
  }
}
