import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlPlanDashboardViewComponent } from './control-plan-dashboard-view.component';
import {ConfigControlCellModule} from "../../../admin/equipment/equipment-config-control/config-control-cell.module";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TranslateModule} from "@ngx-translate/core";
import {PipesModule} from "../../../../core/pipes/pipes.module";
import {
  EquipmentControlDynamicPluginModule
} from "../../../equipment-controls/equipment-control-dynamic-plugin/equipment-control-dynamic-plugin.module";
import {
  ControlsDashboardManagementService
} from "../../../../core/services/controls-dashboard/controls-dashboard-management.service";



@NgModule({
    declarations: [
        ControlPlanDashboardViewComponent
    ],
    exports: [
        ControlPlanDashboardViewComponent
    ],
  imports: [
    CommonModule,
    ConfigControlCellModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslateModule,
    PipesModule,
    EquipmentControlDynamicPluginModule
  ],
  providers:[
    ControlsDashboardManagementService
  ]
})
export class ControlPlanDashboardViewModule { }
