import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserFormComponent} from './user-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../../../core/pipes/pipes.module';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {EnergyMetersFilteringModule} from '../../energy/meters/energy-meters-filtering/energy-meters-filtering.module';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from "@angular/material/button";


@NgModule({
  declarations: [
    UserFormComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        TranslateModule,
        PipesModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        EnergyMetersFilteringModule,
        MatIconModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatButtonModule
    ], exports: [UserFormComponent]
})
export class UserFormModule {
}
