
<mat-toolbar [ngClass]="classes">

  <mat-slide-toggle class="input-headline"
                    [checked]="realTime" (change)="onChangeRealTime('change',$event)">
    Temps réel
  </mat-slide-toggle>

  <mat-form-field class="input-headline input-date" >
    <input matInput [matDatepickerFilter]="startDateFilter" [matDatepicker]="picker1" placeholder="Date de début"
           [formControl]="startDateControl" (dateChange)="setStartDate('change', $event)" [disabled]="realTime">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>

  <div class="custom-slider">
    <ng5-slider [(value)]="value" [options]="options" (valueChange)="sliderValueChange($event)"></ng5-slider>
  </div>

  <div class="button-item">
    <button type="button" class="btn" (click)="playPause()" [disabled]="realTime">
      <mat-icon *ngIf="!play">play_arrow</mat-icon>
      <mat-icon *ngIf="play">pause</mat-icon>
    </button>
  </div>

    <mat-form-field class="input-headline" >
      <mat-select [(value)]="playSpeed" placeholder="Vitesse de déroulement" (selectionChange)="changeSpeed($event)" [disabled]="realTime">
        <mat-option *ngFor="let speed of speeds" [value]="speed.value">
          {{speed.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>



</mat-toolbar>
