import { Component, OnInit } from '@angular/core';
import { ControlParentPluginComponent } from '../control-parent-plugin/control-parent-plugin.component';
import { TimelineService } from '../../dashboards/dashboard-timeline/timeline.service';
import { TesseractService } from '../../../core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ControlsDashboardManagementService
} from '../../../core/services/controls-dashboard/controls-dashboard-management.service';

@Component({
  selector: 'gmao-control-static-value',
  templateUrl: './control-static-value.component.html',
  styleUrls: ['./control-static-value.component.scss']
})
export class ControlStaticValueComponent extends ControlParentPluginComponent {

  displayValue: string = '-';
  status: string = '';

  constructor(
    protected timelineService: TimelineService,
    protected tesseractsService: TesseractService,
    public translateService: TranslateService,
    private controlsDashboardManagementService: ControlsDashboardManagementService
  ) {
    super(timelineService, tesseractsService);
    super.isManual = true;
  }

  setDisplayValue() {
    this.displayValue = this?.defaultConfiguration?.additionalInfo?.value ? this.defaultConfiguration.additionalInfo.value + ' ' + this.defaultConfiguration.unit : '-';
  }
}
