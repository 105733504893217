<div class="card-container">
  <div class="user-card-header" *ngIf="!isProfile">
    <div class="header">
      <button class="custom-transitioned-icon-button" (click)="backToList()" [matTooltip]="'FORMS.CLOSE' |translate| capitalize">
        <mat-icon>close</mat-icon>
      </button>
      <p>{{(editMode ? ('ACCESS.UPDATE_USER' | translate :{name: userForm.get('userName').value}) : 'ACCESS.CREATE_USER' | translate) | capitalize }}</p>
      <button class="custom-transitioned-icon-button"
              [disabled]="userForm.invalid"
              (click)="saveForm()"
              [matTooltip]="'FORMS.SAVE' |translate| capitalize">
        <mat-icon>save</mat-icon>
      </button>
    </div>
    <hr>
  </div>

  <form class="user-forms bold-500-form-text" [formGroup]="userForm" (submit)="saveForm()">
    <mat-form-field appearance="outline" (submit)="saveForm()">
      <mat-label>{{'FORMS.NAME' | translate | capitalize}}</mat-label>
      <input type="text" formControlName="userName" matInput required>
      <mat-error *ngIf="userForm.get('userName').hasError('required')"
                 [innerHTML]="'FORMS.REQUIRED_MESSAGE' | translate | capitalize">
      </mat-error>
      <mat-error *ngIf="userForm.get('userName').hasError('minlength') && !userForm.get('userName').hasError('required')"
                 [innerHTML]="('FORMS.MIN_LENGTH_FILED_MESSAGE' | translate: {length:3}) | capitalize">
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{'FORMS.EMAIL' | translate | capitalize}}</mat-label>
      <input formControlName="email" matInput required>
      <mat-error *ngIf="userForm.get('email').hasError('required')"
                 [innerHTML]="'FORMS.REQUIRED_MESSAGE' | translate | capitalize">
      </mat-error>
      <mat-error *ngIf="userForm.get('email').hasError('email') && !userForm.get('email').hasError('required')"
                 [innerHTML]="'FORMS.NOT_EMAIL_MESSAGE' | translate | capitalize">
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{'FORMS.ROLE' | translate | capitalize}}</mat-label>
      <mat-select [disabled]="isProfile" formControlName="role" panelClass="customized-500-bold-mat-select">
        <mat-option *ngFor="let role of userRoles" [value]="UserRoleEnum[role]">
          {{('ACCESS.USER_ROLES.' + role) | translate | capitalize}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="userForm.get('role').touched && userForm.get('role').invalid"
                 [innerHTML]="'FORMS.REQUIRED_MESSAGE' | translate | capitalize">
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!isProfile" appearance="outline">
      <mat-label>{{'FORMS.GROUP' | translate | capitalize}}</mat-label>
      <mat-select panelClass="customized-500-bold-mat-select" formControlName="groups" multiple>
        <mat-option *ngFor="let group of groupsList" [value]="group._id">
          {{group.name | translate | capitalize}}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field appearance="outline" class="password-input">
      <mat-icon [matTooltip]="(passwordInput.type == 'text' ? 'FORMS.HIDE_PASSWORD' : 'FORMS.SHOW_PASSWORD') | translate"
                [class.visibility_off]="passwordInput.type=='text'"
                (click)="passwordInput.type=='text' ? passwordInput.type='password' : passwordInput.type='text'"
                class="eye-icon">{{passwordInput.type == 'text' ? 'visibility' : 'visibility_off'}}
      </mat-icon>
      <mat-label>{{'FORMS.PASSWORD' | translate | capitalize}}</mat-label>
      <input #passwordInput type="password" formControlName="password" matInput>
      <mat-error *ngIf="userForm.get('password').hasError('pattern')"
                 [innerHTML]="'FORMS.PASSWORD_PATTERN' | translate | capitalize">
      </mat-error>
    </mat-form-field>


    <mat-form-field appearance="outline" class="password-input">
      <mat-icon [matTooltip]="(confirmationPassword.type == 'text' ? 'FORMS.HIDE_PASSWORD' : 'FORMS.SHOW_PASSWORD') | translate"
                [class.visibility_off]="confirmationPassword.type=='text'"
                (click)="userForm.get('confirmedPassword').enabled && (confirmationPassword.type=='text' ? confirmationPassword.type='password' : confirmationPassword.type='text')"
                class="eye-icon">{{confirmationPassword.type == 'text' ? 'visibility' : 'visibility_off'}}
      </mat-icon>
      <mat-label>{{'FORMS.CONFIRMED_PASSWORD' | translate | capitalize}}</mat-label>
      <input #confirmationPassword type="password" formControlName="confirmedPassword" matInput>
      <mat-error *ngIf="userForm.get('confirmedPassword').hasError('required')"
                 [innerHTML]="'FORMS.REQUIRED_MESSAGE' | translate | capitalize">
      </mat-error>
      <mat-error *ngIf="!userForm.get('confirmedPassword').hasError('required') && userForm.get('confirmedPassword').hasError('mismatch')"
                 [innerHTML]="'FORMS.PASSWORDS_MATCH_MESSAGE' | translate | capitalize">
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{'FORMS.LANGUAGE' | translate | capitalize}}</mat-label>
      <mat-select panelClass="customized-500-bold-mat-select" formControlName="language">
        <mat-option *ngFor="let lang of languages" [value]="languagesEnum[lang]">
          {{('GENERAL.' + lang) | translate | capitalize}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox color="primary" formControlName="notifications">{{'ACCESS.RECEIVE_NOTIFICATIONS_FROM_GROUPS' | translate | capitalize}}</mat-checkbox>
    <button *ngIf="isProfile" color="primary" mat-raised-button class="profile-button"
            [disabled]="userForm.invalid"
            type="submit" [matTooltip]="'FORMS.SAVE' |translate| capitalize">
      {{'ACCESS.SAVE_PROFILE' | translate | capitalize}}
    </button>
  </form>


  <mat-spinner *ngIf="loading" class="centered-element" [diameter]="80"></mat-spinner>

</div>
