import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentControlDynamicPluginComponent } from './equipment-control-dynamic-plugin.component';
import {EquipmentControlDynamicPluginDirective} from "./equipment-control-dynamic-plugin.directive";


@NgModule({
  declarations: [EquipmentControlDynamicPluginComponent, EquipmentControlDynamicPluginDirective],
  exports: [EquipmentControlDynamicPluginComponent],
  imports: [CommonModule]
})
export class EquipmentControlDynamicPluginModule { }
