import { ChangeDetectorRef, Injectable, Input, OnDestroy, OnInit } from "@angular/core";
import { ItemTimelineManager } from "src/app/components/gmao-gridster/gridster-item/item-timeline-manager.abstract";
import { GreeniotService } from "src/app/core/deprecated/deprecated-services/deprecated-greeniot/greeniot.service";
import { TesseractService } from "src/app/core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service";
import { TreeService } from "src/app/core/deprecated/deprecated-services/deprecated-nodes-tree/tree.service";
import { TimelineService } from "src/app/components/dashboards/dashboard-timeline/timeline.service";
import { PluginControl } from "./plugin-control.abstract";
import { TreeNode } from "src/app/core/models/nodes/tree-node.model";
import {Subscription} from "rxjs";
import {
  SwitchableLightValue
} from "../../../../core/models/site-equipments/equipment/equipment-configuration/switchable-light-value";
import {
  EquipmentConfiguration
} from "../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

@Injectable()
export class PluginControlPoller extends ItemTimelineManager implements OnInit, OnDestroy {


  plotData: any[] =[];
  pollersSubscriptions: Subscription = new Subscription();
  constructor(
    protected timelineService: TimelineService,
    private tesseractService: TesseractService,
    private changeDetectorRef: ChangeDetectorRef,
    private treeService: TreeService,
    private greenIoTService: GreeniotService,
    private pluginControl: PluginControl,
    private configuration: EquipmentConfiguration
  ) {
    super(timelineService);
    this.startPolling();
  }

  ngOnInit(): void { }

  private startPolling() {
    this.pluginControl.outputValue?.subscribe((value) => {
      (typeof value != 'number') ? (this.compoundAction(value.dataId, value.operation)) : (this.executeAction(value));
    });

    this.pluginControl.outputValueActions?.subscribe((data: SwitchableLightValue) => {
      this.executeAction(data.value, data.isFirstAction)
    });
  }

  /**
   * Load Data.
   * @Override
   */
  protected loadData() {

    let self = this;

    let isDataComplete = function () {
      return false;
    };

    if (true) {
      let dataLoader = this.createDataLoader(
        this.configuration.dataId
      );
      let pollerSub = this.addNewPoller(
        dataLoader,
        this.transformData,
        isDataComplete)
      .subscribe(data => {
        this.plotData[this.configuration._id] = data;
        this.refreshItem();
      });
      this.pollersSubscriptions.add(pollerSub);
    }
  }

  protected refreshItem() {

    let self = this;
    let value;

    if (this.plotData[this.configuration._id] && this.plotData[this.configuration._id].length > 0) {

      value = this.plotData[this.configuration._id][this.plotData[this.configuration._id].length - 1].value;
      let singularPlotData = this.plotData[this.configuration._id];

      let beforeItems = singularPlotData.filter(function(item) {
        return item.time - self.currentTime.getTime() < 0;
      });

      if (beforeItems !== null && beforeItems.length > 0) {
        value = beforeItems[beforeItems.length - 1].value;
      };

      if (!isNaN(value - parseFloat(value))) {
        value = Math.round(value * 100) / 100;
      };

      this.pluginControl?.setValue(value);
    };

    this.changeDetectorRef.markForCheck();

  }

  transformData(data) {
    return data;
  }

  protected createDataLoader(dataId): Function {
    let self = this;
    return function (startDate: Date, endDate: Date) {
      return self.tesseractService.getData(dataId, startDate, endDate);
    };
  }

  executeAction(value: number, isFirstAction: boolean = true) {
    this.pluginControl.sending = true;
    const dataId: number = (this.configuration?.actionId) ? isFirstAction ? this.configuration.actionId : this.configuration.actionId2 : this.configuration.dataId;
    this.treeService.getNodeByDataId(dataId).subscribe((node: TreeNode) => {
      this.greenIoTService.callAction(
        this.configuration.dataId,
        'setValue',
        node[0]?.resourcePath,
        {
          name: "value",
          value: value
        },
        this.configuration,
        node
      ).subscribe(()=> {
        this.pluginControl.sending = false;
      },error => this.pluginControl.sending = false);
    });
  }

  compoundAction(dataId: number, operation: number) {
    this.pluginControl.sending = true;
    this.treeService.getNodeByDataId(dataId).subscribe((node: TreeNode) => {
      this.greenIoTService.callAction(
        dataId,
        'setValue',
        node[0]?.resourcePath,
        {
          name: "value",
          value: operation
        },
        this.configuration,
        node
      ).subscribe(()=> {
        this.pluginControl.sending = false;
      },error => this.pluginControl.sending = false);
    });
  }

  ngOnDestroy(): void {
    this.pollersSubscriptions.unsubscribe();
    this.unsubscribeTimeline();
  }


}
