import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
// tslint:disable-next-line:no-implicit-dependencies no-submodule-imports
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  itemsPerPageLabel = 'itemsPerPage:';
  nextPageLabel = 'nextPage';
  previousPageLabel = 'previousPage';
  Paginator = 'Paginator.';
  ofWord = 'of';
  constructor(private translateService: TranslateService) {
    super();
    ////console.log('this.Paginator+this.itemsPerPageLabel',this.Paginator+this.itemsPerPageLabel);

    this.translateService
      .get(this.Paginator + this.itemsPerPageLabel)
      .subscribe((value: string) => {
        this.itemsPerPageLabel = value;
      });
    this.translateService.get(this.Paginator + this.nextPageLabel).subscribe((value: string) => {
      this.nextPageLabel = value;
    });
    this.translateService
      .get(this.Paginator + this.previousPageLabel)
      .subscribe((value: string) => {
        this.previousPageLabel = value;
      });

    this.translateService
      .get(this.Paginator + this.ofWord)
      .subscribe((value: string) => {
        this.ofWord = value;
        this.getRangeLabel = (page: number, pageSize: number, length: number) => {
          if (length === 0 || pageSize === 0) {
            return `0 ${this.ofWord} ${length}`;
          }

          length = Math.max(length, 0);

          const startIndex = page * pageSize;

          // If the start index exceeds the list length, do not try and fix the end index to the end.
          const endIndex =
            startIndex < length
              ? Math.min(startIndex + pageSize, length)
              : startIndex + pageSize;

          return `${startIndex + 1} - ${endIndex} ${this.ofWord} ${length}`;
        };
      });

  }
}
