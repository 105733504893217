import {Component} from '@angular/core';
import {ControlParentPluginComponent} from "../control-parent-plugin/control-parent-plugin.component";
import {TimelineService} from "../../dashboards/dashboard-timeline/timeline.service";
import {
  TesseractService
} from "../../../core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service";
import {TranslateService} from "@ngx-translate/core";
import {AppService} from "../../../core/services/app/app.service";
import {
  ControlsDashboardManagementService
} from "../../../core/services/controls-dashboard/controls-dashboard-management.service";

@Component({
  selector: 'gmao-control-string-value-plugin',
  templateUrl: './control-string-value-plugin.component.html',
  styleUrls: ['./control-string-value-plugin.component.scss']
})
export class ControlStringValuePluginComponent extends ControlParentPluginComponent {
  displayValue: string = '-';

  constructor(protected timelineService: TimelineService,
              protected controlsDashboardManagementService: ControlsDashboardManagementService,
              protected tesseractsService: TesseractService,
              public translateService: TranslateService) {
    super(timelineService, tesseractsService);
  }

  /**
   * @Override
   * tesseracts return number value then got the key using value and view translation for the key.
   */
  setDisplayValue() {
    this.displayValue = this.controlsDashboardManagementService.getKeyByValue(this.defaultConfiguration.additionalInfo, this.getValue());
  }
}
