<div class="weather-chip-container">
  <img [src]="hoganLogo" alt="hl-logo">
  <section>
    <p>{{ ('CITIES.' + weather?.name?.replace(' ', '_').toUpperCase()) | translate }}</p>
    <p>{{ ('COUNTRIES.' + weather?.sys?.country?.toUpperCase()) | translate }}</p>
  </section>

  <section class="weather">
    <img [src]="weatherIcon" alt="weather-icon">
    <p>{{weather?.main?.temp.toFixed(0)}}°</p>
  </section>
</div>
