<div class="supply-control-plugin-container">
  <p>{{ configuration.name }}</p>
  <p>{{ unit }}</p>
  <p *ngIf="polledValue && showCurrentValue">
    {{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.CURRENT_READING' | translate | capitalize}} :
  </p>
  <p *ngIf="polledValue && showCurrentValue">{{ this.polledValue }}</p>
  <input type="range" [min]="min" [max]="high" [(ngModel)]="value" (change)="setValueFromSlider()">
  <input [(ngModel)]="value" (blur)="setValueFromInput()" class="slider-input" type="number">
  <div>
    <p>{{ "SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.MIN" | translate }}</p>
    <p>{{ "SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.MID" | translate }}</p>
    <p>{{ "SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.MAX" | translate }}</p>
  </div>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
