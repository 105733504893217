import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Options, LabelType } from 'ng5-slider';
import { formatDate } from '@angular/common';
import { timer, Observable, Subscription } from 'rxjs';
import { TimelineService } from './timeline.service';

@Component({
  selector: 'app-dashboard-timeline',
  templateUrl: './dashboard-timeline.component.html',
  styleUrls: ['./dashboard-timeline.component.scss']
})
/**
 * This component is the component that MANAGES the TimeLine itself.
 */
export class DashboardTimelineComponent implements OnInit, OnDestroy {
  startDate: Date = new Date();
  play: boolean = false;
  autoTicks = false;
  disabled = false;
  invert = false;
  max = (60 / 5) * 24;
  min = 0;
  showTicks = true;
  step = 0;
  thumbLabel = true;
  timerSubscription: Subscription;
  interval$: Observable<number>;
  realTime: boolean = true;
  private currentTickIndex = 0;
  startDateControl = new FormControl({
    value: new Date(),
    disabled: this.realTime
  });
  speeds = [
    {
      value: 5000,
      viewValue: '5 secondes'
    },
    {
      value: 10000,
      viewValue: '10 secondes'
    },
    {
      value: 15000,
      viewValue: '15 secondes'
    }
  ];

  playSpeed = this.speeds[0].value;

  private dateRange: Date[] = this.createDateRange();

  value: number;

  options: Options;
  @Input()
  classes: string;
  constructor(private timelineService: TimelineService) {}

  ngOnInit() {
    this.options = {
      stepsArray: this.dateRange.map((date: Date) => {
        return { value: date.getTime() };
      }),
      translate: (value: number, label: LabelType): string => {
        return formatDate(value, 'H:mm', 'fr');
      },
      disabled: this.realTime
    };
    this.value = this.dateRange[0].getTime();
    this.timelineService.setRealTime(null);
    this.timelineService.setDate(null);
    this.timelineService.setTime(null);
    this.timelineService.setReportingTime(false);
  }

  onChangeRealTime(change, $event): void {
    this.realTime = $event.checked;
    this.startDateControl.reset({
      value: this.startDate,
      disabled: this.realTime
    });
    this.options = Object.assign({}, this.options, { disabled: this.realTime });
    this.timelineService.setRealTime(this.realTime);
  }

  updateTimer() {
    if (this.timerSubscription != undefined) {
      this.timerSubscription.unsubscribe();
    }
    if (this.play) {
      this.timerSubscription = new Subscription();
      this.timerSubscription.add(
        timer(0, this.playSpeed).subscribe(_ => {
          this.moveTick(1);
        })
      );
    }
  }

  playPause() {
    this.play = !this.play;
    this.updateTimer();
  }

  changeSpeed(event) {
    this.updateTimer();
  }

  createDateRange(): Date[] {
    const dates: Date[] = [];
    for (let hour: number = 0; hour < 24; hour++) {
      for (let minute: number = 0; minute < 60; minute += 5) {
        dates.push(
          new Date(
            this.startDate.getFullYear(),
            this.startDate.getMonth(),
            this.startDate.getDay(),
            hour,
            minute
          )
        );
      }
    }
    return dates;
  }

  startDateFilter = (d: Date): boolean => {
    let now = new Date();
    // Prevent Saturday and Sunday from being selected.
    return now >= d;
  };

  updateTime() {
    let currentTime = new Date(this.value);
    let currentDate = new Date(this.startDate);
    let now = new Date();

    currentDate.setHours(currentTime.getHours(), currentTime.getMinutes());
    if(currentDate > now) {
      currentDate = now;
    }

    this.timelineService.setTime(currentDate);
  }

  sliderValueChange(value: number) {
    this.currentTickIndex = this.options.stepsArray.findIndex(
      item => item.value === this.value
    );
    this.updateTime();
  }

  moveTick(index) {
    this.currentTickIndex = this.options.stepsArray.findIndex(
      item => item.value === this.value
    );
    this.currentTickIndex += index;
    if (this.currentTickIndex < 0) {
      this.currentTickIndex = 0;
    } else if (this.currentTickIndex > this.options.stepsArray.length - 1) {
      this.currentTickIndex = 0;
    }
    this.value = this.options.stepsArray[this.currentTickIndex].value;

    this.updateTime();
  }

  setStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = new Date(event.value);
    this.timelineService.setDate(this.startDate);
  }

  ngOnDestroy(): void {
    if (this.timerSubscription != undefined) {
      this.timerSubscription.unsubscribe();
    }
  }
}
