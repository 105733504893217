import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavLinkComponent } from './sidenav-link.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {RouterModule} from "@angular/router";
import {LoadingSkeletonModule} from "../../loading-skeleton/loading-skeleton.module";
import {MatTableModule} from "@angular/material/table";



@NgModule({
  declarations: [SidenavLinkComponent],
  exports: [SidenavLinkComponent],
    imports: [CommonModule, TranslateModule, MatIconModule, RouterModule, LoadingSkeletonModule, MatTableModule]
})
export class SidenavLinkModule { }
