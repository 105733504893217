import {Component, Input, OnInit} from '@angular/core';
import {DashboardControl} from "../../../../../core/models/dashboard-controls/dashboard-control";
import {
  ControlsDashboardManagementService
} from "../../../../../core/services/controls-dashboard/controls-dashboard-management.service";
import {SiteZone} from "../../../../../core/models/sites/site-zone";

@Component({
  selector: 'gmao-control-zone-cell',
  templateUrl: './control-zone-cell.component.html',
  styleUrls: ['./control-zone-cell.component.scss']
})
export class ControlZoneCellComponent implements OnInit {
  @Input('dataSrc') dataSrc: DashboardControl;
  @Input('injectComponentInputs') injectComponentInputs: string = '';
  zoneName: string = '-';
  constructor(private controlsDashboardManagementService: ControlsDashboardManagementService) {
  }

  ngOnInit(): void {
    this.getLocation();
  }

  /**
   * Get Location name using location id
   * @private
   */
  private getLocation(){
    if(!this.dataSrc.zoneId) return;
    this.controlsDashboardManagementService.zoneObs.subscribe((zoneMap: {[_id:string]: SiteZone}) =>{
      if(!zoneMap) return;
      this.zoneName = zoneMap[this.dataSrc.zoneId]?.name ?? '-';
      this.dataSrc.zone = this.zoneName;
    })
  }
}
