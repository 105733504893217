import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlPowerPluginComponent } from './control-power-plugin.component';
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [ControlPowerPluginComponent],
  exports: [ControlPowerPluginComponent],
    imports: [
        CommonModule,
        MatIconModule
    ]
})
export class ControlPowerPluginModule { }
