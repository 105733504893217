import { Component, HostBinding, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { forkJoin, interval } from 'rxjs';
import { DataSourceVariable } from 'src/app/core/deprecated/deprecated-models/data-source-variable.model';
import { Steps } from 'src/app/core/deprecated/deprecated-models/steps.enum';
import { TesseractService } from 'src/app/core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Energy } from '../../../../core/models/energy/enums/energy.name-space';

@Component({
  selector: 'area-chart-widget',
  templateUrl: './area-chart-widget.component.html',
  styleUrls: ['./area-chart-widget.component.scss']
})
export class AreaChartWidgetComponent implements OnInit {
  color: string = '#FFFFFF';

  highcharts = Highcharts;
  constructorType = 'chart';

  /*
    "atGridster"
    This property is set to detect the page you're in,
    in order to disable the readings widget (the blue rectangle to the right).
  */
  atGridster: boolean = false;

  chart: Highcharts.Chart;

  chartOptions: any = {
    chart: {
      type: 'area'
    },
    exporting: { enabled: false },
    title: {
      text: "CONSOMMATIONS D'ÉNERGIE",
      style: {
        fontSize: '1.2rem'
      },
      align: 'left'
    },
    subtitle: {
      style: {
        position: 'absolute',
        fontSize: '1.2rem',
        right: '0px',
        bottom: '10px'
      }
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      x: 0,
      y: 0,
      floating: false,
      backgroundColor: '#FFFFFF'
    },
    xAxis: {
      categories: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24'
      ],
      labels: {
        formatter: function() {
          return this.value + 'h';
        }
      }
    },
    yAxis: {
      title: {
        text: 'En KW'
      },
      min: 0
    },
    tooltip: {
      formatter: function() {
        return this.x + ':00h' + ': ' + this.y;
      }
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false
        },
        linecap: 'round',
        lineWidth: 0,
        fillOpacity: 0.6
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Référence Précédente',
        type: 'area',
        color: this.color,
        data: []
      },
      {
        name: 'Temps Réel',
        type: 'line',
        color: '#324496',
        data: []
      }
    ]
  };

  settings = {
    formula: {
      formula: 'cm1*1'
    },
    startDate: new Date(),
    endDate: new Date(),
    prevStartDate: new Date(),
    prevEndDate: new Date(),
    delta: true,
    step: Steps.HOUR,
    totalStep: Steps.DAYS, //
    currentHour: 0,
    totalTitle: 'en temps réel'
  };

  sources: DataSourceVariable[] = [
    {
      source: 214,
      path: 'saclay_crd>8.2641>2.40009',
      variable: 'cm1'
    }
  ];

  lineData: number[] = [];
  areaData: number[] = [
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0
  ];
  totalData: number = 0;

  isLoading = false;

  @HostBinding('class') rootComponentClasses = 'd-flex flex-column h-100';
  constructor(
    private router: Router,
    private readonly tesseractService: TesseractService,
    private translate: TranslateService
  ) {
    interval(300000).subscribe(func => {
      this.loadData();
    });
  }

  ngOnInit() {
    this.loadData();
    if (this.router.url.includes('/synthese')) {
      this.atGridster = true;
    }
  }

  async loadTranslations() {
    // Make an await 'dummy' call before the real translations.
    // After the response, every instant call will work bc we're sure
    // translations are loaded.
    await this.translate.get('dummyTranslation').toPromise().then();
    this.chartOptions.title.text = this.translate.instant('areaChartWidget.title');
    this.chartOptions.series[0].name = this.translate.instant('areaChartWidget.reference');
    this.chartOptions.series[1].name = this.translate.instant('areaChartWidget.real');
    this.settings.totalTitle = this.translate.instant('areaChartWidget.inReal');
  }


  loadData() {
    this.isLoading = true;
    this.settings.startDate = new Date();
    this.settings.startDate.setHours(0);
    this.settings.startDate.setMinutes(0);
    this.settings.startDate.setSeconds(0);
    this.settings.startDate.setMilliseconds(0);
    this.settings.endDate = new Date();

    let diff = 24 * 60 * 60 * 1000;

    this.settings.prevStartDate = new Date();
    this.settings.prevEndDate = new Date();

    this.settings.prevStartDate.setTime(
      this.settings.startDate.getTime() - diff
    );
    this.settings.prevEndDate.setTime(
      this.settings.prevStartDate.getTime() + diff
    );

    this.settings.currentHour = new Date().getHours();

    forkJoin([
      this.tesseractService.getFormula(
        this.sources,
        this.settings.formula,
        this.settings.startDate,
        this.settings.endDate,
        this.settings.delta,
        this.settings.step
      ),
      this.tesseractService.getFormula(
        this.sources,
        this.settings.formula,
        this.settings.prevStartDate,
        this.settings.prevEndDate,
        this.settings.delta,
        this.settings.step
      ),
      this.tesseractService.getFormula(
        this.sources,
        this.settings.formula,
        this.settings.startDate,
        this.settings.endDate,
        this.settings.delta,
        this.settings.totalStep
      )
    ]).subscribe((results: any) => {
      this.lineData = [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ];
      this.areaData = [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ];

      if (results[0].length > 0) {
        for (let row of results[0]) {
          let currHour = new Date(row.time).getHours();
          this.lineData[currHour] = row.value;
        }
      }

      if (results.length > 1 && results[1].length > 0) {
        for (let row of results[1]) {
          let currHour = new Date(row.time).getHours();
          this.areaData[currHour] = row.value;
        }

        while (this.areaData.length < 25) {
          this.areaData.push(0);
        }
      }

      if (results.length > 2 && results[2].length > 0) {
        this.totalData = Math.round(results[2][0].value)/1000;
      } else {
        this.totalData = 0;
      }

      this.setChartOptions();
    });
  }

  setChartOptions() {
    this.chartOptions = {
      chart: {
        type: 'area'
      },
      exporting: { enabled: false },
      title: {
        text: "CONSOMMATIONS D'ÉNERGIE",
        style: {
          fontSize: '1.2rem'
        },
        align: 'left'
      },
      subtitle: {
        style: {
          position: 'absolute',
          fontSize: '0.8rem',
          right: '0px',
          bottom: '10px'
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        floating: false,
        backgroundColor: '#FFFFFF'
      },
      xAxis: {
        categories: [
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24'
        ],
        labels: {
          formatter: function() {
            return this.value + 'h';
          }
        }
      },
      yAxis: {
        title: {
          text: 'En KW'
        },
        min: 0
      },
      tooltip: {
        formatter: function() {
          return this.x + ':00h' + ': ' + this.y;
        }
      },
      plotOptions: {
        area: {
          marker: {
            enabled: false
          },
          linecap: 'round',
          lineWidth: 0,
          fillOpacity: 0.6
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Référence Précédente',
          type: 'area',
          color: '#FFE005',
          data: this.areaData
        },
        {
          name: 'Temps Réel',
          type: 'line',
          color: '#324496',
          data: this.lineData
        }
      ]
    };

    this.loadTranslations();

    this.isLoading = false;

    if (this.chart) {
      this.chart.reflow();
      this.chart.redraw();
    }
  }

  public loadChartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
    if (this.chart) {
      this.chart.redraw();
      this.chart.reflow();
    }
  }

    protected readonly Energy = Energy;
}
