<div class="temp-control-plugin-container">
  <section>
    <mat-icon>thermostat</mat-icon>
    <p class="label">{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.TEMPERATURE' | translate | capitalize}}</p>
  </section>
  <section>
    <p>{{ configuration?.name }}</p>
    <div class="temp-controls-content">
      <ng-container *ngTemplateOutlet="readOnly ? readOnlyDisplay: writeDisplay"></ng-container>
    </div>
  </section>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>


<ng-template #readOnlyDisplay>
  <p>{{ displayTempControl.value }}</p>
</ng-template>
<ng-template #writeDisplay>
  <button [disabled]="tempControl.value == minValue || (tempControl.value == '-')"
          (click)="send(-1)" mat-mini-fab>
    <mat-icon>remove</mat-icon>
  </button>
  <mat-form-field>
    <input matInput [formControl]="tempControl" type="number" (blur)="manualSetMode()"/>
    <span matSuffix>{{unit}}</span>
  </mat-form-field>
  <button [disabled]="tempControl.value == maxValue || (tempControl.value == '-')"
          (click)="send(1)" mat-mini-fab>
    <mat-icon>add</mat-icon>
  </button>
</ng-template>
