import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SgTableModel} from "../../../sg-components/sg-table/sg-table.model";
import {TranslateService} from "@ngx-translate/core";
import {SelectedTableRowEvent} from "../../../sg-components/sg-table/selected-table-row-event";
import {Observable, of} from "rxjs";
import {SgTableComponent} from "../../../sg-components/sg-table/sg-table.component";
import {DashboardControl} from "../../../../core/models/dashboard-controls/dashboard-control";
import {ControlsFiltrationModel} from "../../../../core/models/dashboard-controls/controls-filtration-model";
import {ControlValueCellComponent} from "./control-value-cell/control-value-cell.component";
import {ControlZoneCellComponent} from "./control-zone-cell/control-zone-cell.component";
import {SitesEquipmentsService} from "../../../../core/services/sites-equipments/sites-equipments.service";
import {
  EquipmentControlSelectionModel
} from "../../../../core/models/dashboard-controls/equipment-control-selection-model";
import {map} from "rxjs/operators";
import {SiteEquipmentModel} from "../../../../core/models/site-equipments/site-equipment.model";
import {
  ControlsDashboardManagementService
} from "../../../../core/services/controls-dashboard/controls-dashboard-management.service";
import {
  SitesEquipmentsPaginationResult
} from "../../../../core/models/site-equipments/sites-equipments-pagination-result";
import {ControlFloorCellComponent} from "./control-floor-cell/control-floor-cell.component";

@Component({
  selector: 'gmao-control-list-dashboard-view',
  templateUrl: './control-list-dashboard-view.component.html',
  styleUrls: ['./control-list-dashboard-view.component.scss']
})
export class ControlListDashboardViewComponent implements OnInit, OnChanges {
  @ViewChild('sgTable') sgTable: SgTableComponent;
  @Input('filtrationModel') filtrationModel: ControlsFiltrationModel;
  /**
   * Emit selected control value to parent
   * @see ControlsDashboardComponent.handleSelection
   */
  @Output('selectControlEmitter') selectControlEmitter = new EventEmitter<EquipmentControlSelectionModel>();
  tableColumns: SgTableModel[] = [];
  renderedColumns: string[] = [];
  controlsList: DashboardControl[] = []

  constructor(private translateService: TranslateService,
              private controlsDashboardManagementService: ControlsDashboardManagementService,
              private sitesEquipmentsService: SitesEquipmentsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['filtrationModel']?.currentValue?.siteId && this.sgTable && this.updateTable();
  }

  /**
   * Update filter when the filter value changes
   * @private
   */
  private updateTable() {
    this.sgTable.reloadCurrentView();
  }

  ngOnInit(): void {
    this.defineSgTableConfig();
  }

  private defineSgTableConfig() {
    this.translateService.get(['FORMS.CATEGORY', 'FORMS.NAME', 'SITES.FLOOR', 'FORMS.ZONE', 'GENERAL.VALUE']).subscribe(
      (translation: { [key: string]: string }) => {
        this.tableColumns = [
          {
            name: translation['FORMS.CATEGORY'],
            width: '20%',
            attributeKey: 'category',
            sortFiled: 'category'
          },
          {
            name: translation['FORMS.NAME'],
            width: '20%',
            attributeKey: 'name',
            sortFiled: 'name'
          },
          {
            name: translation['SITES.FLOOR'],
            width: '20%',
            injectedComponent: ControlFloorCellComponent,
            injectComponentInputs: this.filtrationModel.siteId,
            sortFiled: 'floor'
          },
          {
            name: translation['FORMS.ZONE'],
            width: '25%',
            injectedComponent: ControlZoneCellComponent,
            injectComponentInputs: this.filtrationModel.siteId,
            sortFiled: 'zone'
          },
          {
            name: translation['GENERAL.VALUE'],
            width: '15%',
            injectedComponent: ControlValueCellComponent
          }
        ];
        this.renderedColumns = ['category', 'name', translation['SITES.FLOOR'], translation['FORMS.ZONE'], translation['GENERAL.VALUE']];
      })
  }

  /**
   * Get Equipment data and convert it to controls
   */
  getControls = (): Observable<DashboardControl[]> => {
    if(!this.filtrationModel?.siteId) return of([]);
    return this.sitesEquipmentsService.getEquipmentsControlsList(this.filtrationModel).pipe(map(((data: SitesEquipmentsPaginationResult) => {
      this.controlsList= [];
      data.buildingEquipments.forEach((value: SiteEquipmentModel) => {
        this.controlsList.push(this.controlsDashboardManagementService.convertEquipmentToControl(value))
      });
      return this.controlsList;
    })));
  }

  /**
   * Handle table row selection and emit selected control to parent
   * @see ControlListDashboardViewComponent.selectControlEmitter
   * @see ControlsDashboardComponent.handleSelection
   * @param control
   */
  rowSelected(control: SelectedTableRowEvent<DashboardControl>) {
    let data: EquipmentControlSelectionModel = {
      label: control.selectedItem?.name,
      equipmentConfigurations: control.selectedItem?.equipmentConfigurations,
      status: control.selectedItem?.status
    }
    this.selectControlEmitter.emit(data);
  }
}
