import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SiteEquipmentDto} from "../../../core/models/site-equipments/site-equipment-dto";
import {TesseractService} from "../../../core/deprecated/deprecated-services/deprecated-tesseract/tesseract.service";
import {
  EquipmentStatusEnum,
  EquipmentStatusType
} from "../../../core/models/site-equipments/equipment/equipment/equipment-status";
import {PolledData} from "../../../core/models/tesseract/polledData";
import {DatePipe} from "@angular/common";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'gmao-site-equipment-status-cell',
  templateUrl: './site-equipment-status-cell.component.html',
  styleUrls: ['./site-equipment-status-cell.component.scss']
})
export class SiteEquipmentStatusCellComponent implements OnInit, OnChanges {
  @Input('dataSrc') dataSrc: SiteEquipmentDto;
  @Input('isControl') isControl: boolean = false;
  status: EquipmentStatusType = EquipmentStatusEnum.DISABLED;
  equipmentStatusEnum = EquipmentStatusEnum;
  dateValue: string;
  disabledTooltip: string = '';

  constructor(private tesseractsService: TesseractService, private datePipe: DatePipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['dataSrc']?.currentValue) return;
    this.getEquipmentStatus();
  }
  ngOnInit(): void {
    this.getEquipmentStatus();
  }

  /**
   * Get tesseracts data and set status
   *
   * Status:
   * CONNECTED : if polled date equal connected value and the difference between current date and polled data less than 60min
   * DISCONNECTED:if polled date not equal connected value and the difference between current date and polled data less than 60min
   * DISABLED:
   * 1. the difference between current date and polled data more than 60min.
   * 2. No polled date
   * 3. status was not selected
   */
  getEquipmentStatus() {
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date();
    endDate.setHours(23, 59, 59, 59);
    this.tesseractsService.getData(this.dataSrc?.status?.dataId, startDate, endDate).subscribe((result: PolledData[]) => {
      let lastIndex: number = result?.length - 1;
      let value: number = result?.[lastIndex]?.value;
      let time: Date = result?.[lastIndex]?.time;
      let isDateLessThan60Minutes: boolean = this.isDateLessThan60Minutes(new Date(time));
      this.dateValue = this.datePipe.transform(time, environment.applicationViewDateFormat);
      let isConnected = value == this.dataSrc?.status?.connected;
      if ((value != null || value != undefined) && isConnected && isDateLessThan60Minutes) {
        this.status = EquipmentStatusEnum.CONNECTED;
      } else if ((value != null || value != undefined) && value != this.dataSrc?.status?.connected && isDateLessThan60Minutes) {
        this.status = EquipmentStatusEnum.DISCONNECTED;
      } else {
        this.status = EquipmentStatusEnum.DISABLED;
        this.checkDisabledToolTip(result?.[lastIndex], isConnected);
      }
    })
  }

  /**
   * Determine the Tooltip message on disabled status
   * NOT_DEFINED: if the status was not defined
   * NO_DATA: if there is no polled data
   * CONNECTED: if the status connected value equal polled value but the date < 60m
   * DISCONNECTED: if the status connected value not equal polled value but the data < 60m
   * @param polledData
   * @param isConnected
   * @private
   */
  private checkDisabledToolTip(polledData: PolledData, isConnected: boolean) {
    if (!this.dataSrc?.status?.dataId) {
      this.disabledTooltip = 'SITE_EQUIPMENTS.DISABLED_STATUS_TOOLTIP_NOT_DEFINED';
    } else if (!polledData?.time) {
      this.disabledTooltip = 'SITE_EQUIPMENTS.DISABLED_STATUS_TOOLTIP_NO_DATA';
    } else {
      this.disabledTooltip = isConnected ? 'SITE_EQUIPMENTS.DISABLED_STATUS_TOOLTIP_CONNECTED' : 'SITE_EQUIPMENTS.DISABLED_STATUS_TOOLTIP_DISCONNECTED';
    }
  }

  /**
   * compare current date with polled date and return true if the difference is less than 60 min
   * @param dataTimestamp
   * @private
   */
  private isDateLessThan60Minutes(dataTimestamp: Date): boolean {
    if(!dataTimestamp) return false;
    const now: Date = new Date();
    const sixtyMinutesInMillis: number = 60 * 60 * 1000;
    const difference: number = now.getTime() - dataTimestamp.getTime();
    return difference < sixtyMinutesInMillis;
  }
}
