import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import {
  EquipmentConfiguration
} from '../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model';
import { PluginControl } from '../plugin-control.abstract';

@Component({
  selector: 'gmao-static-value-plugin',
  templateUrl: './static-value-plugin.component.html',
  styleUrls: ['./static-value-plugin.component.scss']
})
export class StaticValuePluginComponent extends PluginControl {

  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px bgr-red';
  @Input('configuration') configuration: EquipmentConfiguration;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  setValue(value: number) {}

  getValue(value: number) {}
}
