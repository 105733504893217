<div class="control-list-dashboard-view-container">
  <sg-table *ngIf="tableColumns.length"
            #sgTable
            [columns]="tableColumns"
            [renderedColumns]="renderedColumns"
            [localPaginating]="true"
            [isLocalSort]="true"
            [getAllDataObs]="getControls"
            (rowSelected)="rowSelected($event)">
  </sg-table>
</div>
