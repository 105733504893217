import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { MapComponent } from './map.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MapDialogComponent } from './deprecated-map-dialog/map-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../../../pipes/pipes.module';



@NgModule({

  imports: [
    MatMenuModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatInputModule,
    CommonModule,
    MatButtonModule,
    TranslateModule,
    PipesModule
  ],
  declarations: [
    MapComponent,
    MapDialogComponent,
  ],
  exports: [
    MapComponent,
    MapDialogComponent
  ],

})
export class MapModule { }
