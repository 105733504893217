export namespace Scheduling {
  export type DaysOfWeekType = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  export const DaysOfWeekEnum = {
    MONDAY: 'MONDAY' as DaysOfWeekType,
    TUESDAY: 'TUESDAY' as DaysOfWeekType,
    WEDNESDAY: 'WEDNESDAY' as DaysOfWeekType,
    THURSDAY: 'THURSDAY' as DaysOfWeekType,
    FRIDAY: 'FRIDAY' as DaysOfWeekType,
    SATURDAY: 'SATURDAY' as DaysOfWeekType,
    SUNDAY: 'SUNDAY' as DaysOfWeekType
  };

  export type ScheduleType = 'DEFAULT' | 'OVERRIDE';
  export const ScheduleTypeEnum = {
    DEFAULT: 'DEFAULT' as DaysOfWeekType,
    OVERRIDE: 'OVERRIDE' as DaysOfWeekType,
  };

}

