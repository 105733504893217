<div class="alarms_sidenav_component" *ngIf="!isLoadingResults">
  <section>
    <img [src]="sideNavData?.imagePath  | securePublic | async" (error)="$event.target['src']=logo">
    <p>{{sideNavData?.name}}</p>
  </section>
  <section>
    <div>{{sideNavData?.activeAlarms}}</div>
    <div>{{ 'ALARMS.NUMBER_OF_ACTIVE_ALARMS' | translate | capitalize}}</div>
  </section>
  <section>
    <highcharts-chart [Highcharts]="highcharts" constructorType="chart" [options]="chartOptions" id="chart"></highcharts-chart>
  </section>
</div>
<mat-spinner *ngIf="isLoadingResults" [diameter]="30"></mat-spinner>


