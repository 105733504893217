import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LabelRatingModule } from 'src/app/core/deprecated/deprecated-components/deprecated-energy-label/label-rating.module';

import { EnvImpactComponent } from "./env-impact.component"


@NgModule({

  imports: [
    CommonModule,
    TranslateModule,
    LabelRatingModule
  ],

  providers: [],


  declarations: [
    EnvImpactComponent
  ],

  exports: [
    EnvImpactComponent
  ],


  entryComponents: [
    EnvImpactComponent
  ],

})
export class EnvImpactModule { }
