import {AfterViewInit, Component, HostBinding, Input, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {GreeniotService} from "src/app/core/deprecated/deprecated-services/deprecated-greeniot/greeniot.service";
import {TranslatedToasterService} from "src/app/core/services/translated-toaster/translated-toaster.service";
import {TreeService} from "src/app/core/deprecated/deprecated-services/deprecated-nodes-tree/tree.service";
import {PluginControl} from "../plugin-control.abstract";
import {TreeNode} from "src/app/core/models/nodes/tree-node.model";
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

@Component({
  selector: 'action-plugin',
  templateUrl: './action-plugin.component.html',
  styleUrls: ['./action-plugin.component.scss']
})
export class ActionPluginComponent extends PluginControl implements OnInit, AfterViewInit {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;

  pluginInterface: number = 2
  isLoading: boolean = false;

  constructor(
    private treeService: TreeService,
    private greeniotService: GreeniotService,
    private translatedToasterService: TranslatedToasterService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.determineInterface();
  }

  /**
   * Determine the plugin's interface based on
   * the number of parameters.
   */
  determineInterface() {
    const configsLength: number = this.configuration?.additionalInfo?.length;
    this.pluginInterface = (configsLength === 0 || configsLength === 1) ? (configsLength) : (2);
  }

  setValue(value: number) {
    // Empty definition to satisfy inheritance.
  }

  getValue(value: number) {
    // Empty definition to satisfy inheritance.
  }

  sendAction(value: number[] | string[]) {
    this.isLoading = true;
    this.treeService.getNodeByDataId(this.configuration.dataId).subscribe((node: TreeNode) => {
      this.greeniotService.callAction(
        this.configuration.dataId,
        'setValue',
        node[0]?.resourcePath,
        {
          name: 'value',
          value: value
        },
        this.configuration
      ).subscribe(() => {
        this.isLoading = false;
        this.translatedToasterService.showSuccessMessage("SUCCESS_CODES.ACTION_SUBMIT_SUCCESSFULLY")

      }, error => {
        this.isLoading = false;
        if (error.status === 400) {
          this.translatedToasterService.showErrorMessage(error?.error?.error);
        } else {
          this.translatedToasterService.showErrorCodeMessage(error.status);
        };
      });

    }, error => {
      this.isLoading = false;
      this.translatedToasterService.showErrorCodeMessage(error.status);
    });
  }

}

