import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { WeatherWindowComponent } from "./weather-window.component";

const routes: Routes = [
  {path: '', component: WeatherWindowComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WeatherWindowRoutingModule {
}