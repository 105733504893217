import { Injectable } from '@angular/core';
import { throwError, Subject } from 'rxjs';

@Injectable()
export class ToggleMenuService {

  constructor() {}

  private actionMessageSource = new Subject<any>();
  actionMessage$ = this.actionMessageSource.asObservable();

  sendAction(message: any){
      this.actionMessageSource.next(message);
  }
}
