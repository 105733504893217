import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, map, tap} from "rxjs/operators";
import {SiteListFilter} from "../../models/home/siteListFilter";
import {Observable, of} from 'rxjs';
import {SiteDto} from "../../models/sites/siteDTO";
import {Contact} from "../../models/sites/contact";
import {SiteHoursSchedule} from "../../models/sites/site-week-schedule";
import {ErrorHandlerService} from "../error-handler/error-handler.service";
import {SiteView} from "../../models/sites/site-view";
import {environment} from 'src/environments/environment';
import {SiteFloor} from "../../models/sites/site-floor";
import {AuthenticationsService} from "../auth/authenticationsService";
import {SiteStoreService} from './site-store.service';
import {SiteGeneralImage} from "../../models/sites/site-general-image";
import {SiteZone} from "../../models/sites/site-zone";
import {SiteFloorsTree} from "../../models/sites/siteFloorsTree";
import {SiteGroupDto} from "../../models/sites/site-group.dto";


@Injectable({providedIn: 'root'})
export class SitesService {
  private baseUrl = `/api/sg-sites`;
  private sitesUrl = '/sites/';
  private locationsUrl = '/locations';
  private locationsTreeUrl = '/locations/tree';
  private viewsUrl = '/views';
  private floorsUrl = '/floors';
  private generalImageUrl = '/images';
  private scheduleUrl = '/schedule';
  private contactsUrl = '/contacts';
  private groupsUrl = 'all/groups';
  private importUrl = 'import';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationsService,
    private siteStore: SiteStoreService,
    private handler: ErrorHandlerService
  ) {
    this.storeSites();
  }

  private storeSites() {
    this.getAllSites().pipe(tap((data: SiteDto[]) => this.siteStore.storeClientsSites(data)))
  }


  /**
   * Site General Infos
   */
  getAllSites(filters?: SiteListFilter | string): Observable<SiteDto[]> {
    if ((!filters || typeof filters == 'string') && this.siteStore.sitesExists()) return of(this.siteStore.getStoredSites(filters as string))
    let url = this.baseUrl + this.sitesUrl;
    return this.http.get<SiteDto[]>(url).pipe(
      tap((sites) => {
        if (!filters) this.siteStore.storeClientsSites(sites)
      }),
      catchError(this.handler.handleError)
    )
  }

  deleteSiteById(siteId: string): Observable<string> {
    let url = this.baseUrl + this.sitesUrl + siteId;
    return this.http.delete<string>(url).pipe(catchError(this.handler.handleError));
  }

  getSiteById(id: string): Observable<SiteDto> {
    if (this.siteStore.siteExists(id)) return of(this.siteStore.getStoredSite(id))
    const url = this.baseUrl + this.sitesUrl + id;
    return this.http.get<SiteDto>(url).pipe(catchError(this.handler.handleError))
  }

  createNewSite(siteDTO: SiteDto): Observable<SiteDto> {
    const url: string = this.baseUrl + this.sitesUrl
    return this.http.post<SiteDto>(url, siteDTO).pipe(
      tap((site) => {
        this.siteStore.updateStoredSites([site])
      }),
      catchError(this.handler.handleError)
    )
  }

  updateSite(siteDTO: SiteDto): Observable<SiteDto> {
    const url: string = this.baseUrl + this.sitesUrl + siteDTO._id;
    return this.http.put<SiteDto>(url, siteDTO).pipe(
      tap((site) => {
        this.siteStore.updateStoredSites([site])
      }),
      catchError(this.handler.handleError)
    )
  }

  /**
   * Site Locations
   * @param siteId
   */
  getSiteTreeZone(siteId: string): Observable<SiteFloorsTree> {
    const url = this.baseUrl + this.sitesUrl + siteId + this.locationsTreeUrl;
    return this.http.get<SiteFloorsTree>(url).pipe(catchError(this.handler.handleError));
  }

  getFloorTreeZone(floorId: string): Observable<SiteFloorsTree> {
    const url = this.baseUrl + this.sitesUrl + floorId + this.locationsTreeUrl;
    return this.http.get<SiteFloorsTree>(url).pipe(catchError(this.handler.handleError));
  }

  getSiteZone(sitesIds?: string[], name?: string): Observable<SiteZone[]> {
    const url: string = this.baseUrl + this.sitesUrl + 'all' + this.locationsUrl;
    let params: HttpParams = new HttpParams();
    name && params.append('name', name);
    sitesIds && sitesIds.forEach((siteId: string) => {
      params = params.append('sitesIds', siteId.toString())
    })
    return this.http.get<SiteZone[]>(url, {params: params}).pipe(catchError(this.handler.handleError));
  }

  getZoneById(sitesId: string, zoneId: string): Observable<SiteZone> {
    const url: string = this.baseUrl + this.sitesUrl + sitesId + this.locationsUrl + '/' + zoneId;
    return this.http.get<SiteZone>(url);
  }

  addSiteNodeZone(nodeName: string, parentNodeId: string, siteId: string, floorId: string): Observable<SiteZone> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.locationsUrl;
    let body: Partial<SiteZone> = {
      parent: parentNodeId,
      name: nodeName,
      siteId: floorId
    }
    return this.http.post<SiteZone>(url, body).pipe(catchError(this.handler.handleError));
  }

  updateSiteNodeZone(node: SiteFloorsTree, siteId: string): Observable<SiteZone> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.locationsUrl + '/' + node._id;
    return this.http.put<SiteZone>(url, node).pipe(catchError(this.handler.handleError));
  }

  deleteSiteZoneNode(zoneId: string, siteId: string): Observable<string> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.locationsUrl + '/' + zoneId;
    return this.http.delete<string>(url).pipe(catchError(this.handler.handleError));
  }

  /**
   * SIte level maps
   * @param siteId
   */
  getLevelMaps(siteId: string): Observable<SiteFloor[]> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.floorsUrl;
    let params: HttpParams = new HttpParams().append('siteId', siteId);
    return this.http.get<SiteFloor[]>(url, {params: params}).pipe(catchError(this.handler.handleError));
  }


  getLevelMapsTree(siteId: string): Observable<SiteFloorsTree[]> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.floorsUrl;
    let params: HttpParams = new HttpParams().append('siteId', siteId);
    return this.http.get<SiteFloor[]>(url, {params: params}).pipe(catchError(this.handler.handleError)).pipe(
      map((siteFloors: SiteFloor[]) => siteFloors.map((data: SiteFloor) => {
          return {
            name: data.floorLevel,
            _id: data._id,
            parentId: siteId,
            children: null
          }
        })
      )
    );
  }

  updateLevelMap(newLevel: SiteFloor, siteId: string): Observable<SiteFloor> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.floorsUrl + '/' + newLevel._id;
    return this.http.put<SiteFloor>(url, newLevel).pipe(catchError(this.handler.handleError));
  }

  createLevelMap(newLevel: SiteFloor, siteId: string): Observable<SiteFloor> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.floorsUrl;
    return this.http.post<SiteFloor>(url, newLevel).pipe(catchError(this.handler.handleError));
  }

  upsertLevelMap(mapLevel: SiteFloor, siteId: string): Observable<SiteFloor> {
    return mapLevel._id ? this.updateLevelMap(mapLevel, siteId) : this.createLevelMap(mapLevel, siteId);
  }

  deleteLevelMap(level: SiteFloor, siteId: string): Observable<SiteFloor[]> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.floorsUrl + '/' + level._id;
    let params: HttpParams = new HttpParams().append('siteId', siteId);
    return this.http.delete<SiteFloor[]>(url, {params: params}).pipe(catchError(this.handler.handleError));
  }

  /**
   * Site general images
   * @param siteId
   */
  getSiteGeneralImages(siteId: string): Observable<string[]> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.generalImageUrl;
    let params: HttpParams = new HttpParams().append('siteId', siteId);
    return this.http.get<string[]>(url, {params: params}).pipe(catchError(this.handler.handleError));
  }

  createGeneralImage(siteId: string, newImage: SiteGeneralImage): Observable<string> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.generalImageUrl;
    return this.http.post<string>(url, newImage).pipe(catchError(this.handler.handleError));
  }


  deleteGeneralImage(siteId: string, imagePath: string): Observable<any> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.generalImageUrl;
    let options = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: {
        imagePath: imagePath
      }
    }
    return this.http.delete<SiteGeneralImage>(url, options).pipe(catchError(this.handler.handleError));
  }


  /**
   * Site contacts
   * @param siteId
   */
  getAllSiteContacts(siteId: string): Observable<Contact[]> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.contactsUrl;
    return this.http.get<Contact[]>(url).pipe(catchError(this.handler.handleError));
  }

  createContact(siteId: string, contact: Contact): Observable<Contact> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.contactsUrl;
    return this.http.post<Contact>(url, {...contact, siteId: siteId}).pipe(catchError(this.handler.handleError));
  }

  updateContact(siteId: string, contact: Contact): Observable<Contact> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.contactsUrl + '/' + contact._id;
    return this.http.put<Contact>(url, contact).pipe(catchError(this.handler.handleError));
  }

  deleteContact(siteId: string, contactId: string): Observable<string> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.contactsUrl + '/' + contactId;
    return this.http.delete<string>(url).pipe(catchError(this.handler.handleError));
  }


  /**
   *
   * @param siteId
   * @param type
   */

  getSiteScheduleHours(siteId: string, type: string): Observable<SiteHoursSchedule[]> {
    let url: string = this.baseUrl + this.sitesUrl + siteId + this.scheduleUrl;
    let params: HttpParams = new HttpParams().append('type', type);
    return this.http.get<SiteHoursSchedule[]>(url, {params: params}).pipe(catchError(this.handler.handleError))
  }

  createScheduleHours(events: SiteHoursSchedule): Observable<SiteHoursSchedule> {
    let url: string = this.baseUrl + this.sitesUrl + events.siteId + this.scheduleUrl
    return this.http.post<SiteHoursSchedule>(url, events).pipe(catchError(this.handler.handleError));
  }

  saveScheduleHours(events: SiteHoursSchedule): Observable<SiteHoursSchedule> {
    let url: string = this.baseUrl + this.sitesUrl + events.siteId + this.scheduleUrl + '/' + events._id;
    return this.http.put<SiteHoursSchedule>(url, events).pipe(catchError(this.handler.handleError));
  }


  /**
   * site views API's
   * @param siteId
   */
  getSiteViews(siteId: string): Observable<SiteView[]> {
    if (this.siteStore.sitesViewsExists(siteId)) return of(this.siteStore.getSiteViews(siteId))
    const path = this.baseUrl + this.sitesUrl + siteId + this.viewsUrl;
    return this.http.get<SiteView[]>(path).pipe(
      tap((views: SiteView[]) => {
        this.siteStore.updateStoredSiteViews(siteId, views)
      }),
      catchError(this.handler.handleError)
    )
  }


  getPageSiteViews(pageName: string): Observable<SiteView[]> {
    let params: HttpParams = new HttpParams().append('type', pageName);
    const path = this.baseUrl + this.sitesUrl + 'all' + this.viewsUrl;
    return this.http.get<SiteView[]>(path, {params: params}).pipe(
      catchError(this.handler.handleError)
    )
  }

  updateView(siteId: string, view: SiteView): Observable<SiteView> {
    const path = this.baseUrl + this.sitesUrl + siteId + this.viewsUrl + '/' + view._id;
    return this.http.put<SiteView>(path, view).pipe(
      tap((view: SiteView) => {
        this.siteStore.updateStoredView(view)
      }),
      catchError(this.handler.handleError)
    )
  }

  createView(siteId: string, view: SiteView): Observable<SiteView> {
    const path = this.baseUrl + this.sitesUrl + siteId + this.viewsUrl;
    return this.http.post<SiteView>(path, view).pipe(
      tap((view: SiteView) => {
        this.siteStore.updateStoredView(view)
      }),
      catchError(this.handler.handleError)
    )
  }

  deleteView(siteId: string, viewId: string) {
    const path: string = this.baseUrl + this.sitesUrl + siteId + this.viewsUrl + '/' + viewId;
    return this.http.delete(path).pipe(
      catchError(this.handler.handleError)
    )
  }


  /**
   * site floors
   * @param siteId
   */
  getSiteFloors(siteId: string): Observable<SiteFloor[]> {
    let url = this.baseUrl + this.sitesUrl + siteId + this.floorsUrl;
    return this.http.get<SiteFloor[]>(url).pipe(catchError(this.handler.handleError));
  }

  /**
   * Get all flores for sites list
   * @param sitesIds
   */
  getAllSiteFloors(sitesIds?: string[]): Observable<SiteFloor[]> {
    const body = {
      sitesIds: sitesIds
    }
    let url = this.baseUrl + this.sitesUrl + 'all' + this.floorsUrl + '/list';
    return this.http.post<SiteFloor[]>(url, body).pipe(catchError(this.handler.handleError));
  }

  /**
   * Map site APIs
   * @param value
   */

  locateSiteMap(value: string) {
    let mapSettings = environment.mapConfig;
    const locateURL = mapSettings.apiServer.baseURL +
      "?key=" + mapSettings.credentials.key +
      "&q=" + value +
      "&format=" + mapSettings.rules.format +
      "&limit=" + mapSettings.rules.limit +
      "&addressdetails=" + mapSettings.rules.addressdetails +
      "&accept-language=" + mapSettings.rules.language +
      "&countrycodes=" + mapSettings.rules.countrycodes + "";
    return this.http.get(locateURL).pipe(
      catchError(this.handler.handleError)
    );
  }

  createSiteGroup(siteGroupDto: SiteGroupDto): Observable<SiteGroupDto> {
    return this.http.post<SiteGroupDto>(`${this.baseUrl}${this.sitesUrl}${this.groupsUrl}`, siteGroupDto).pipe(
        catchError(this.handler.handleError)
    );
  }

  getSiteGroups(): Observable<SiteGroupDto[]> {
    return this.http.get<SiteGroupDto[]>(`${this.baseUrl}${this.sitesUrl}${this.groupsUrl}`).pipe(
        catchError(this.handler.handleError)
    );
  }

  getSiteGroupById(id: string): Observable<SiteGroupDto> {
    return this.http.get<SiteGroupDto>(`${this.baseUrl}${this.sitesUrl}${this.groupsUrl}/${id}`).pipe(
        catchError(this.handler.handleError)
    );
  }

  updateSiteGroup(siteGroupDto: SiteGroupDto): Observable<SiteGroupDto> {
    return this.http.put<SiteGroupDto>(`${this.baseUrl}${this.sitesUrl}${this.groupsUrl}/${siteGroupDto._id}`, siteGroupDto).pipe(
        catchError(this.handler.handleError)
    );
  }

  deleteSiteGroup(id: string) {
    return this.http.delete(`${this.baseUrl}${this.sitesUrl}${this.groupsUrl}/${id}`).pipe(
        catchError(this.handler.handleError)
    )
  }

  importSites(file: File, logo?: string): Observable<SiteDto[]> {
    const url: string = `${this.baseUrl}${this.sitesUrl}${this.importUrl}`;

    const formData: FormData = new FormData();
    formData.append('siteCSV', file);
    logo && formData.append('logo', logo);

    return this.http.post<SiteDto[]>(url, formData).pipe(
        tap((sites: SiteDto[]) => {
          this.siteStore.updateStoredSites(sites)
        }),
        catchError(this.handler.handleError)
    );
  }
}
