import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RectSkeletonComponent } from './rect-skeleton.component';



@NgModule({
  declarations: [
    RectSkeletonComponent
  ],
  imports: [
    CommonModule
  ],exports:[RectSkeletonComponent]
})
export class RectSkeletonModule { }
