<div class="static-value-plugin-container">
  <section>
    <mat-icon>speed</mat-icon>
    <p>{{ 'GENERAL.VALUE' | translate | capitalize}}</p>
  </section>
  <section>
    <p>{{ configuration.name }}</p>
    <span class="display-value-content">
      {{ configuration.additionalInfo.value + ' ' + configuration.unit }}
    </span>
  </section>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
