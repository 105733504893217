import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {NodePagination} from '../../models/nodes/nodePagination';
import {catchError, map} from "rxjs/operators";
import {ErrorHandlerService} from "../error-handler/error-handler.service";
import {HttpClient} from "@angular/common/http";
import {NodePoint} from "../../models/nodes/nodePoint";
import {AuthenticationsService} from "../auth/authenticationsService";

@Injectable({providedIn: 'root'})
export class NodesService {
  private baseUrl: string = `/api/sg-tree/tree/`;
  private dataIdUrl = `search/data-id/`;
  private nodesUrl = `nodes/`;

  constructor(private authService: AuthenticationsService,
              private http: HttpClient,
              private handler: ErrorHandlerService) {
  }


  getNode(data: NodePagination): Observable<{ Items: NodePoint[], Total: number }> {
    return this.http.get(this.baseUrl).pipe(
      catchError(this.handler.handleError),
      map((ele: NodePoint[]) => {
        return {Items: ele.slice(0, 50), Total: 51}
      }),
    )
  }

  getNodeByDataId(dataId: number): Observable<NodePoint[]> {
    let url = this.baseUrl + this.dataIdUrl + dataId
    return this.http.get<NodePoint[]>(url).pipe(catchError(this.handler.handleError))
  }

  getNodesByParentId(parentId: string): Observable<NodePoint[]> {
    let url = this.baseUrl + this.nodesUrl + parentId
    return this.http.get<NodePoint[]>(url).pipe(catchError(this.handler.handleError))
  }
}
