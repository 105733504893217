import {Directive, ViewContainerRef} from "@angular/core";

@Directive({
  selector: '[injectHost]',

})

export class EquipmentControlDynamicPluginDirective{
  constructor(public viewContainerRef: ViewContainerRef) { }
}
