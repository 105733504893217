import {Pipe, PipeTransform} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {FileManagerService} from "../services/files/file-manager.service";
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'securePublic'
})
export class SecurePublicPipe implements PipeTransform {

  constructor(private http: HttpClient,
              private sanitizer: DomSanitizer,
              private fileUploadService: FileManagerService) {
  }

  transform(documentPath: string): Observable<SafeUrl | string> {
    if (!documentPath || documentPath.length == 0) return of(environment.logo)
    return this.fileUploadService.getPublicDocumentFile(documentPath).pipe(
      map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
      catchError(e => {
        return of(documentPath)
      })
    )
  }

}
