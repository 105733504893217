export const WeatherCities: string[] = [
  'Paris,france',
  'London,uk',
  'Sydney,australia',
  'Berlin,germany',
  'Tokyo,japan',
  'Amsterdam,netherlands',
  'Los+Angeles,us',
  'New+York,us',
  'Mexico+city,mexico',
  'Sao+paulo,brazil',
  'Shanghai,china',
  'Hanoi,vietnam',
  'Milan,italy'
];
