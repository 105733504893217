<div id="container" *ngIf="logged && !loading" class="sg-bgr-primary">
  <ng-container [ngSwitch]="sideBarType">
    <gmao-sidebars-toolbar *ngSwitchCase="sideBarTypeEnum.TOOLBAR"></gmao-sidebars-toolbar>
    <scada-sidebar *ngSwitchCase="sideBarTypeEnum.EQUIPMENTCONTROL"></scada-sidebar>
    <alarm-sidebar *ngSwitchCase="sideBarTypeEnum.ALARM"></alarm-sidebar>
    <site-information-sidebar *ngSwitchCase="sideBarTypeEnum.SYNTHESE"></site-information-sidebar>
    <site-information-sidebar *ngSwitchCase="sideBarTypeEnum.INFORMATION"></site-information-sidebar>
    <site-information-sidebar *ngSwitchCase="sideBarTypeEnum.GMAO"></site-information-sidebar>
    <site-information-sidebar *ngSwitchCase="sideBarTypeEnum.SCHEMA"></site-information-sidebar>
  </ng-container>
</div>

