import {AfterViewInit, Component, HostBinding, ViewChild} from '@angular/core';
import {UserAccessService} from "../../core/services/user-access/user-access.service";
import {User} from "../../core/models/user-access/user";
import {Router} from "@angular/router";
import {FullDimensionRectSkeletonComponent} from "../../components/loading-skeleton/full-dimention-rectangel-skeleton/full-dimension-rect-skeleton.component";
import {UserFormComponent} from "../../components/users/user-form/user-form.component";
import { AuthenticationsService } from 'src/app/core/services/auth/authenticationsService';
import {UserSpace} from "../../core/models/user-access/user.enum";
import AuthedUserAttributesEnum = UserSpace.AuthedUserAttributesEnum;

@Component({
  selector: 'gmao-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements AfterViewInit {
  @HostBinding('class') rootComponentClasses = 'h-100 w-100';
  @ViewChild('userForm') userForm: UserFormComponent;
  loggedInUser: User = null;
  loadingSkeletonComponent = FullDimensionRectSkeletonComponent;

  constructor(private usersService: UserAccessService,
              private auth:AuthenticationsService,
              private router: Router) {
  }

  ngAfterViewInit(): void {
    this.getLoggedInUser()
  }

  private getLoggedInUser() {
    let currentUserId=this.auth.getAuthedUserData(AuthedUserAttributesEnum.ID);
    this.usersService.getUserById(currentUserId).subscribe((data: User) => {
      this.loggedInUser = data;
      this.userForm.fetchSelectedUser(data);
    }, error => {
      this.router.navigate(['home'])
    })
  }


}
