import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PluginControl } from '../plugin-control.abstract';
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

@Component({
  selector: 'supply-control-plugin',
  templateUrl: './supply-control-plugin.component.html',
  styleUrls: ['./supply-control-plugin.component.scss']
})
export class SupplyControlPluginComponent extends PluginControl implements OnInit, OnDestroy {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;
  @Input('templateMode') templateMode: boolean = false;

  value: number = 0;
  manuallySetValue = 0;
  min: number;
  mid: number;
  high: number;

  unit: string;

  min10percent: number;
  mid10percent: number;
  high10percent: number;

  subscriptions: Subscription = new Subscription();

  data = [];
  plotData: any[] = [];
  polledValue;
  showCurrentValue: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  setValue(value: number) {
    this.value = value;
    this.changeDetectorRef.markForCheck();
  }

  getValue(value: number) {
    this.value;
  }


  ngOnInit(): void {
    let values = this.configuration.additionalInfo;
    this.min = values?.['min'] ?? 0;
    this.high = values?.['high'] ?? 100;
    this.mid = values?.['mid'] ?? (this.min + this.high) / 2;
    this.calculate10percentRange();

    if (this.templateMode) {
      this.setValue(this.configuration?.setValue);
    }

    this.setUnit();

    this.value = this.min;
    this.setValueManually();
  }

  setUnit() {
    this.unit = (!this.configuration.unit || this.configuration.unit === ' ') ? ('') : (`(${this.configuration.unit})`);
  }

  calculate10percentRange() {
    this.high10percent = +this.high * 0.1;
    this.mid10percent = +this.mid * 0.1;
    this.min10percent = +this.min * 0.1;
  }


  setValueFromSlider() {

    if (this.value >= +this.high - this.high10percent) {
      this.value = this.high;
    };

    if ((this.value >= (+this.mid) - this.mid10percent) && (this.value <= (+this.mid) + this.mid10percent)) {
      this.value = this.mid;
    };

    if (this.value <= +this.min + this.min10percent) {
      this.value = this.min;
    };

    this.manuallySetValue = this.value;
    this.setValueManually();

  }

  setValueManually() {
    this.configuration.setValue = this.value;
    this.outputValue.next(this.value);
  }

  setValueFromInput() {
    if (!this.value) return;

    if (this.value > +this.high) {
      this.value = this.high;
    } else if (this.value < +this.min) {
      this.value = this.min;
    };
    this.manuallySetValue = this.value;
    this.setValueManually();
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
