<div class="energy-meters-filtering-inputs responsive-mat-form-field">
  <gmao-sg-autocomplete-single-select #siteSingleSelect
                                      filterKey="name"
                                      uniqueKey="_id"
                                      minWidth="auto"
                                      [initialValue]="this.sitesLists[0]?._id"
                                      [label]="'ENERGY_CONSUMPTION.SITES' | translate | capitalize"
                                      [getOptionsObs]="getSites"
                                      (selectionObjectChange)="newSiteSelected($event)"
  ></gmao-sg-autocomplete-single-select>

  <mat-form-field appearance="outline">
    <mat-label>{{'FORMS.METERS_SRC_TYPE' | translate | capitalize}}</mat-label>
    <mat-select panelClass="customized-energy-meters-filtering-mat-select" (selectionChange)="filterOptionsChanged()" [(value)]="filtrationModel.sourceType">
      <mat-option [value]="null">
        <span>{{('GENERAL.ALL') | translate | capitalize}}</span>
      </mat-option>
      <mat-option *ngFor="let type of metersType" [value]="type">{{('ENERGY.METERS_SRC_TYPE.' + type) |translate| capitalize}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'FORMS.ENERGY_TYPE' | translate | capitalize}}</mat-label>
    <mat-select panelClass="customized-energy-meters-filtering-mat-select" (selectionChange)="filterOptionsChanged()" [(value)]="filtrationModel.energyType">
      <mat-select-trigger *ngIf="filtrationModel.energyType; else ALL;">
        <span><img alt="energy-icon" [src]="energyIcons[filtrationModel.energyType]"></span>
        {{filtrationModel.energyType ? (('ENERGY.ENERGY_TYPES.' + filtrationModel.energyType) |translate| capitalize) : ''}}
      </mat-select-trigger>
      <ng-template #ALL>
        {{('GENERAL.ALL') | translate | capitalize}}
      </ng-template>

      <mat-option value="{{null}}">
        <span>{{('GENERAL.ALL') | translate | capitalize}}</span>
      </mat-option>

      <mat-option *ngFor="let type of energyType" [value]="type">
        <span *ngIf="type">
          <img alt="energy-icon" [src]="energyIcons[type]">
          {{('ENERGY.ENERGY_TYPES.' + type) |translate| capitalize}}
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="!disableSearch">
    <mat-label>{{'FORMS.SEARCH' | translate | capitalize}}</mat-label>
    <input matInput class="name-input" [(ngModel)]="filtrationModel.filterString" (keyup)="filterOptionsChanged(true)" [placeholder]="'FORMS.SEARCH' | translate">
  </mat-form-field>

</div>
