import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {Menu} from "../../models/menu/menu";
import {HttpClient, HttpParams} from "@angular/common/http";
import {SiteZone} from "../../models/sites/site-zone";

@Injectable({providedIn: 'root'})
export class MenuLinkService {

  constructor(private http: HttpClient) {}

  getMenu(featureActive: boolean, featureRole: number): Observable<Menu> {
    let utl = `/api/sg-users/`;
    let params = new HttpParams().append('featureActive', featureActive +"").append('featureRole', featureRole.toString());
    return this.http.get<Menu>(utl, {params: params})
  }
}
