import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {ActionContent} from '../../../models/greeniot/action-content.model';
import {TreeNode} from '../../../models/nodes/tree-node.model';
import {AuthenticationsService} from "../../../services/auth/authenticationsService";
import {
  EquipmentConfiguration
} from "../../../models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

@Injectable()
export class GreeniotService {
  baseUrl: string;
  actionPath = `/api/greeniot/action/`;
  schedulePath = `/api/greeniot/schedule/`;
  templatePath = '/api/greeniot/template/';

  nodesUrl = `/api/tree/tree/`;

  sgActionsPath = `/api/sg-actions/action`;

  headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthenticationsService) { }

  callAction(dataId: number, name: string, resourcePath: string, attributes: any, configuration: EquipmentConfiguration, treeNode?: TreeNode) {

    let content: ActionContent = {
      name: name,
      dataId: dataId,
      nodePath: resourcePath,
      attributes: attributes,
      treeNode: treeNode,
      configuration: configuration
    };

    const body = JSON.stringify(content);

    const headers = new HttpHeaders().set('Authorization', 'my-auth-token').set('Content-Type', 'application/json');

    return this.http.post(this.sgActionsPath, body, { headers: headers });
  }

  upsertSchedules(data){
    const body = JSON.stringify(data);
    return this.http.post(this.schedulePath, body);
  }

  createTemplate(template){
    const body = JSON.stringify(template);
    return this.http.post(this.templatePath, body);
  }

  deleteTemplate(template) {
    let  encoded = encodeURIComponent(template.templatePath);
    let id = template.templateId;
    let path = this.templatePath+'?path='+encoded+'&templateId='+id;

    return this.http.delete(path);
  }


}
