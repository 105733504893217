export const DocumentExtension:{[ext:string]:string} ={
  doc: 'doc,odt,docx,dox,docm,dot',
  xls: 'xls,xlsb,xlsm',
  jpg:'jpg,gif,ico,jpeg,xlsx,xlt,xltx',
  mp4: 'mp4,flv,m4v,mov,mpg,wmv',

  file: 'file',
  png: 'png',
  ai: 'ai',
  avi: 'avi',
  css: 'css',
  csv: 'csv',
  dbf: 'dbf',
  dwg: 'dwg',
  exe: 'exe',
  fla: 'fla',
  html: 'html',
  iso: 'iso',
  json: 'json',
  mp3: 'mp3',
  pdf: 'pdf',
  ppt: 'ppt',
  psd: 'psd',
  rtf: 'rtf',
  svg: 'svg',
  txt: 'txt',
  xml: 'xml',
  zip: 'zip',
}

