<div class="action-plugin-container sg-bgr-secondary">

  <div class="content">
    
    <div [ngSwitch]="pluginInterface" class="inner">
      
      <div *ngSwitchCase="0" class="trigger-action">
        <button (click)="sendAction(null)" [disabled]="isLoading">
          <mat-spinner [diameter]="25" *ngIf="isLoading; else sendButton"></mat-spinner>
          <ng-template #sendButton>
            <mat-icon>send</mat-icon>
            <div [title]="configuration.name" class="truncate">{{ configuration.name }}</div>
          </ng-template>
        </button>
      </div>
      
      <div *ngSwitchCase="1" class="send-single-parameter">
        <input 
          #paramInput type="text" 
          class="input-field"
          [disabled]="isLoading" 
          [placeholder]="'deviceOperation.VALUE' | translate">
        <button (click)="sendAction([paramInput.value])" [disabled]="isLoading || !paramInput.value">
          <mat-spinner [diameter]="25" *ngIf="isLoading; else sendParamButton"></mat-spinner>
          <ng-template #sendParamButton>
            <mat-icon>send</mat-icon>
          </ng-template>
        </button>
      </div>
      
    </div>
  
  </div>
    
</div>
  