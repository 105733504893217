import {Component, Input, OnInit} from '@angular/core';
import {DashboardControl} from "../../../../../core/models/dashboard-controls/dashboard-control";
import {SiteFloor} from "../../../../../core/models/sites/site-floor";
import {
  ControlsDashboardManagementService
} from "../../../../../core/services/controls-dashboard/controls-dashboard-management.service";

@Component({
  selector: 'gmao-control-floor-cell',
  templateUrl: './control-floor-cell.component.html',
  styleUrls: ['./control-floor-cell.component.scss']
})
export class ControlFloorCellComponent implements OnInit {
  @Input('dataSrc') dataSrc: DashboardControl;
  floorName: string = '-';

  constructor(private controlsDashboardManagementService: ControlsDashboardManagementService) {
  }

  ngOnInit(): void {
    this.getFloor();
  }

  private getFloor() {
    if(!this.dataSrc.floorId) return;
    this.controlsDashboardManagementService.floorObs.subscribe((floorMap: {[_id:string]: SiteFloor}) =>{
      if(!floorMap) return;
      this.floorName = floorMap[this.dataSrc.floorId]?.floorLevel ?? '-';
      this.dataSrc.floor = this.floorName;
    })
  }
}
