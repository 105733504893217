import { Directive, ViewContainerRef } from "@angular/core";

@Directive({
  selector: '[configControlPluginHost]',
})
/**
 * Directive representing an anchor point to tell Angular where to insert components.
 * resource :https://angular.io/guide/dynamic-component-loader
 */
export class InjectControlPluginDirective{
  constructor(public viewContainerRef: ViewContainerRef) { }
}
