<ng-container *skeleton="!links.length;repeat: 1; component:loadingSkeletonComponent;height: '10px';width: '100%';">
<div *ngFor="let link of currentLinks; let i= index"
     class="main-menu-item-container"
     id="main_menu_container">
  <ul class="close-main-menu"
      [ngClass]="{'close-main-menu': !isExpanded}"
      [id]="'main_menu_group_'+i"
      [@expandCollapse]>
    <li>
      <span (click)="handleClick($event,link)" [id]="'main_menu_item_event_'+i">
      <a [routerLink]="[link?.path]"
         [routerLinkActive]="['active']"
         [class.disabled]="!link?.path"
         [class.close-padding]="!isExpanded"
         [routerLinkActiveOptions]="{exact: !link?.path?.includes('dashboard')}">
        <mat-icon [id]="'main_menu_item_icon_'+i">{{link.icon}}</mat-icon>
        <span *ngIf="isExpanded" class="fade-in-delayed" [id]="'main_menu_item_title_'+link.name">
          {{('PAGES_TITLES.' + link.name) | translate}}
        </span>
        <mat-icon *ngIf="link?.children?.length && link.isExpanded">keyboard_arrow_up</mat-icon>
      </a>
      </span>
      <gmao-sidenav-link [links]="link.children" *ngIf="link.children && link.isExpanded"
                           [isExpanded]="isExpanded"></gmao-sidenav-link>
      <hr [class.open]="isExpanded" *ngIf="(link?.isExpanded && !link?.path) && link?.path != 'logOut'"/>
    </li>
  </ul>
</div>
</ng-container>
