import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NodesListDialogFromComponent } from './nodes-list-dialog-from.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {TranslateModule} from "@ngx-translate/core";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
  declarations: [
    NodesListDialogFromComponent
  ],
  exports: [
    NodesListDialogFromComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        TranslateModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        FormsModule,
        MatInputModule,
        PipesModule,
        MatTooltipModule
    ]
})
export class NodesListDialogFromModule { }
