<div class="actual-weather-temperature-container">
  <ng-container *skeleton="!currentWeather;repeat: 1; component:loadingSkeletonComponent;width: '100%';height:'100%'">
  <section>
    <p>{{ 'CITIES.PARIS' | translate }}, {{ 'COUNTRIES.FR' | translate }}</p>
    <div class="current-reading">
      <img [src]="weatherIcon" alt="">
      <p>{{ currentWeather?.main?.temp?.toFixed(0) }}°</p>
    </div>
  </section>
  <section>
    <div *ngFor="let reading of forecastReadings">
      <p>{{ reading.dt_txt }}</p>
      <img [src]="reading.icon" alt="icon">
      <p>{{ reading.temp }}°</p>
    </div>
  </section>
  </ng-container>
</div>
