export const environment = {
  production: true,
  name: 'production',
  gmaoBaseURL: '/api/gmao',
  sentryDsn: 'SENTRY_DSN_CONF',
  monitorLogBySentry: 'disabled',
  mapConfig: {
    credentials: {
        key: "4f0d6ecc05d340"
    },
    apiServer: {
        baseURL: "https://eu1.locationiq.com/v1/search.php"
    },
    rules: {
        format: "json",
        limit: 50,
        addressdetails: 1,
        language: "<ISO 639-1 fr>",
        countrycodes: "<ISO_3166-1_alpha-2 fr>"
    }
  }
};
