import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
/**
 * this service manages toolbar side nav
 * works mainly with side bar service to switch between side bars and toolbar
 */
@Injectable({
  providedIn: 'root'
})
/**
 * @deprecated
 */
export class ToolbarSideNavService {
  private isSaved = new Subject<boolean>();
  /**
   * @deprecated
   */
  isSavedObservable$ = this.isSaved.asObservable();

  private selectedToolbarItem = new BehaviorSubject<any>("");
  /**
   * @deprecated
   */
  selectedToolbarItemObservable$ = this.selectedToolbarItem.asObservable();

  constructor() { }


  /**
   * @deprecated
   */
  selectToolbarItem(dialogAction: any) {
    this.selectedToolbarItem.next(dialogAction);
  }

  /**
   * @deprecated
   */
  isItemsSaved(saved: boolean) {
    this.isSaved.next(saved);
  }
}
