import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationsService } from '../../../services/auth/authenticationsService';

@Injectable({
  providedIn: 'root'
})
/**
 * @deprecated
 */
export class DeprecatedAlarmService {
  baseUrl: string;
  alarmPath = `/api/sg-alarms/alarms/`;
  alarmJournalPath = `/api/sg-alarms/alarms/journal/`;
  alarmsJournalsPath = `/api/sg-alarms/alarmsJournals/`;

  constructor(private http: HttpClient, private authService: AuthenticationsService) {
  }

  /**
   * @deprecated
   */
  create(params: any) {
    let path = this.alarmPath;
    const body = JSON.stringify(params);
    return this.http.post(path, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  getAlarms(type?: string) {
    let path = this.alarmPath;
    if (type != undefined) {
      path += '?type=' + type;
    }

    return this.http.get(path).pipe(
      map((alarms) => alarms['alarms']),
      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  getAlarmById(alarmId): Observable<any> {

    let url = this.alarmPath + alarmId;
    return this.http.get(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  update(params: any, dataId) {
    let path = this.alarmPath + dataId;
    const body = JSON.stringify(params);
    return this.http.put(path, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError({ 'error': error.error.message, 'statuscode': error.status });

    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError({ 'error': 'backend error', 'statuscode': error.status });

    }
    // return an observable with a user-facing error message
  }
}
