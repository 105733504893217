import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';
import {AuthenticationsService} from "../../../services/auth/authenticationsService";

@Injectable()
/**
 * @deprecated
 */
export class TreeService {
  baseUrl: string;
  searchUrl = `/api/sg-tree/tree/search/`;
  nodesUrl = `/api/sg-tree/tree/`;
  nodesSearchUrl = `/api/sg-tree/tree/1/search/autocomplete`;
  nodeUrl = `/api/sg-tree/tree/nodeInformation/`;
  dataIdUrl =`/api/sg-tree/tree/search/data-id/`;

  headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthenticationsService) {
    let httpHeader = {
      'Content-Type': 'application/json',
      Accept: 'q=0.8;application/json;q=0.9'
    };
    this.headers = new HttpHeaders(httpHeader);
  }

  //send nodes from node search to other components
  private treeMessageSource = new Subject<any>();
  /**
   * @deprecated
   */
  treeMessage$ = this.treeMessageSource.asObservable();

  /**
   * @deprecated
   */
  sendNodes(message: any){
      this.treeMessageSource.next(message);
  }

  //send nodes from node search to other components
  private equipmentMessageSource = new Subject<any>();
  /**
   * @deprecated
   */
  equipmentMessage$ = this.equipmentMessageSource.asObservable();

  /**
   * @deprecated
   */
  getNodes() {
    let options = {
      headers: this.headers
    };
    return (
      this.http
        .get(this.nodesUrl, options)
        // With the new HttpClient, .map is no more.
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  /**
   * @deprecated
   */
  searchTreeNodes(searchWords, limit?) {
    let sentLimit;
    if (limit) {
      sentLimit = limit;
    } else {
      sentLimit = '10';
    }
    const params = {
      params: new HttpParams()
        .set('words', searchWords)
        .set('limit', sentLimit.toString())
    };
    return (
      this.http
        .get(this.nodesSearchUrl, params)
        // With the new HttpClient, .map is no more.
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  /**
   * @deprecated
   */
  getNodeByDataId(dataId){
    let url = this.dataIdUrl+dataId
    return (
      this.http
        .get(url)
        // With the new HttpClient, .map is no more.
        .pipe(
          catchError(this.handleError)
        )
    );
  }
  /**
   * @deprecated
   */
  searchNodes(terms) {
    let params = new HttpParams().set('words', terms).set('limit', '50');
    let options = {
      headers: this.headers,
      params: params
    };

    return (
      this.http
        .get(this.searchUrl, options)
        // With the new HttpClient, .map is no more.
        .pipe(

          catchError(this.handleError)
        )
    );
  }

  /**
   * @deprecated
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
