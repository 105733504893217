/**
 * @deprecated
 */
export enum Steps{
    HOUR="hours",
    DAYS="days",
    WEEKS="weeks",
    MONTHS="months",
    YEAR="year"
}
