import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SgHeaderComponent } from './sg-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { SmallScreensMenuModule } from '../small-screens-menu/small-screens-menu.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SgHeaderComponent],
  exports: [SgHeaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PipesModule,
    SmallScreensMenuModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule
  ]
})

export class SgHeaderModule {

}
