import {Injectable} from "@angular/core";
import {Theme,} from "../../models/theme/theme.model";
import {ClientsThemes} from "../../models/theme/clientsThemes";
import {OverlayContainer} from "@angular/cdk/overlay";
import {LocalStorageKeysEnum} from "../localstorage/local-storage-keys.enum";
import {AuthenticationsService} from '../auth/authenticationsService';
import {LocalStorageService} from '../localstorage/local-storage.service';

@Injectable({
  providedIn: "root"
})
export class ThemeService {
  private active: Theme = ClientsThemes['sg'];
  private activeMatTheme: string = '';

  constructor(
    private overlayContainer: OverlayContainer,
    private localStorageService: LocalStorageService,
    private auth: AuthenticationsService
  ) {
  }

  setTheme(reset = false) {
    let client = reset ? 'sg' : this.auth.getClientId();
    let theme: Theme = client && ClientsThemes.hasOwnProperty(client) ? ClientsThemes[client] : ClientsThemes['sg'];
    !theme && (theme = ClientsThemes['sg']);
    this.setActiveTheme(theme);
    this.setActiveMatTheme(reset);
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;
    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(property, this.active.properties[property]);
    });
  }

  getActiveTheme() {
    return this.active;
  }

  setActiveMatTheme(reset = false) {
    let client = reset ? 'sg' : this.auth.getClientId();
    let theme = this.localStorageService.getLocalStorageStringItem(LocalStorageKeysEnum.THEME);
    if (!theme) {
      theme = 'light'
      this.localStorageService.setItem(LocalStorageKeysEnum.THEME, theme);
    }

    let currentTheme = '';
    if (client?.length != 0 && ClientsThemes.hasOwnProperty(client)) currentTheme = client + '-' + theme;
    else currentTheme = theme + '-theme';
    this.activeMatTheme = currentTheme;
    this.overlayContainer.getContainerElement().classList.add(currentTheme);
  }

  getActiveMatTheme() {
    return this.activeMatTheme;
  }
}
