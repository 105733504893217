import {Component, HostBinding, Input, OnInit} from "@angular/core";
import {PluginControl} from "../plugin-control.abstract";
import {TranslatedToasterService} from "../../../../../core/services/translated-toaster/translated-toaster.service";
import {
  EquipmentConfiguration
} from "../../../../../core/models/site-equipments/equipment/equipment-configuration/equipment-configuration.model";

interface Operation {
  dataId: number;
  operationName: string;
  operationValue: number;
}

@Component({
  selector: 'scroll-menu-control-plugin',
  templateUrl: './scroll-menu-control-plugin.component.html',
  styleUrls: ['./scroll-menu-control-plugin.component.scss']
})
export class  ScrollMenuControlPluginComponent extends PluginControl implements OnInit {
  @HostBinding('class') rootComponentClasses = 'w-100p bxs-black-around br-10px';
  @Input('configuration') configuration: EquipmentConfiguration;

  selectedOperation = {} as Operation;


  constructor(private translatedToasterService: TranslatedToasterService) {
    super();
  }

  setValue(value: number) {
    // Do sth
  }

  getValue(value: number) {
    // Do sth
  }

  ngOnInit(): void {
    this.setConfigurationName();
  }

  setConfigurationName() {

  }

  applyOperation(event) {
    this.selectedOperation = event.value;
    this.generalSetMode(event.value.dataId, event.value.operationValue);
  }

  reapplyOperation() {
    if (this.selectedOperation.dataId == null || this.selectedOperation.dataId == undefined) return;
    this.generalSetMode(this.selectedOperation.dataId, this.selectedOperation.operationValue);
  }

  generalSetMode(dataId: number, operation: number) {
    // this.configuration.setValue = mode;
    this.outputValue.next({dataId: dataId, operation: operation});
    this.translatedToasterService.showInfoMessage("SITE_EQUIPMENTS.ORDER_TRANSMITTED_SUCCESS");
  }

}

