<div class="switchable-light-plugin-container">
  <section>
    <p>{{ 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.STATUS' | translate }}</p>
  </section>
  <section>
    <p>{{ configuration.name }}</p>
    <mat-slide-toggle color="primary" [checked]="status" (change)="switchMode($event)">
      {{(status ? 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.ON' : 'SITE_EQUIPMENTS.EQUIPMENT_PLUGINS.OFF') | translate}}
    </mat-slide-toggle>
  </section>
  <div class="loading-shade" *ngIf="sending">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
