<div class="container">
  <div class="header">
    <p class="primary-text">{{ "energyEfficiency.title" | translate | uppercase }}</p>
    <p class="secondary-text">En {{ Energy.EnergyUnitEnum.KWH }}/m²</p>
  </div>
  <div class="body">
    <div
      [ngClass]="selectedClass === 'a' ? 'big-bar' : 'small-bar'"
      class="bar"
    >

    <div
        [ngClass]="{ 'add-border': selectedClass === 'a' }"
        class="borderBox width-a"
      >
    <span
        [ngStyle]="{ color: selectedClass === 'a' ? 'black' : 'white' }"
        class="right-poly a"
        >A</span
      >

    </div>

      <div
      [ngClass]="
      selectedClass === 'a' ? 'thick-line' : 'line'
    "
        class="line"
      ></div>
      <div *ngIf="selectedClass === 'a'" class="left-poly">
        <div class="cursor">
          <div class="energy-reading">{{ value }}</div>
          <div class="unit">{{ unit }}</div>
        </div>
      </div>
    </div>

    <div
      [ngClass]="selectedClass === 'b' ? 'big-bar' : 'small-bar'"
      class="bar"
    >

    <div
        [ngClass]="{ 'add-border': selectedClass === 'b' }"
        class="borderBox width-b"
      >
    <span
        [ngStyle]="{ color: selectedClass === 'b' ? 'black' : 'white' }"
        class="right-poly b"
        >B</span
      >

    </div>

      <div
      [ngClass]="
      selectedClass === 'b' ? 'thick-line' : 'line'
    "
        class="line"
      ></div>
      <div *ngIf="selectedClass === 'b'" class="left-poly">
        <div class="cursor">
          <div class="energy-reading">{{ value }}</div>
          <div class="unit">{{ unit }}</div>
        </div>
      </div>
    </div>

    <div
      [ngClass]="selectedClass === 'c' ? 'big-bar' : 'small-bar'"
      class="bar"
    >

    <div
        [ngClass]="{ 'add-border': selectedClass === 'c' }"
        class="borderBox width-c"
      >
     <span
        [ngStyle]="{ color: selectedClass === 'c' ? 'black' : 'white' }"
        class="right-poly c"
        >C</span
      >
    </div>


      <div
      [ngClass]="
      selectedClass === 'c' ? 'thick-line' : 'line'
    "
        class="line"
      ></div>
      <div *ngIf="selectedClass === 'c'" class="left-poly">
        <div class="cursor">
          <div class="energy-reading">{{ value }}</div>
          <div class="unit">{{ unit }}</div>
        </div>
      </div>
    </div>

    <div
      [ngClass]="selectedClass === 'd' ? 'big-bar' : 'small-bar'"
      class="bar"
    >

      <div
        [ngClass]="{ 'add-border': selectedClass === 'd' }"
        class="borderBox width-d"
      >
        <span
          [ngStyle]="{ color: selectedClass === 'd' ? 'black' : 'white' }"
          class="right-poly d"
          >D</span
        >
      </div>

      <div
      [ngClass]="
      selectedClass === 'd' ? 'thick-line' : 'line'
    "
        class="line"
      ></div>

      <div *ngIf="selectedClass === 'd'" class="left-poly">
        <div class="cursor">
          <div class="energy-reading">{{ value }}</div>
          <div class="unit">{{ unit }}</div>
        </div>
      </div>
    </div>

    <div
      [ngClass]="selectedClass === 'e' ? 'big-bar' : 'small-bar'"
      class="bar"
    >
    <div
        [ngClass]="{ 'add-border': selectedClass === 'e' }"
        class="borderBox width-e"
      >
    <span
        [ngStyle]="{ color: selectedClass === 'e' ? 'black' : 'white' }"
        class="right-poly e"
        >E</span
      >
    </div>

      <div
      [ngClass]="
      selectedClass === 'e' ? 'thick-line' : 'line'
    "
        class="line"
      ></div>
      <div *ngIf="selectedClass === 'e'" class="left-poly">
        <div class="cursor">
          <div class="energy-reading">{{ value }}</div>
          <div class="unit">{{ unit }}</div>
        </div>
      </div>
    </div>

    <div
      [ngClass]="selectedClass === 'f' ? 'big-bar' : 'small-bar'"
      class="bar"
    >

    <div
        [ngClass]="{ 'add-border': selectedClass === 'f' }"
        class="borderBox width-f"
      >
    <span
        [ngStyle]="{ color: selectedClass === 'f' ? 'black' : 'white' }"
        class="right-poly f"
        >F</span
      >
    </div>

      <div
      [ngClass]="
      selectedClass === 'f' ? 'thick-line' : 'line'
    "
        class="line"
      ></div>
      <div *ngIf="selectedClass === 'f'" class="left-poly">
        <div class="cursor">
          <div class="energy-reading">{{ value }}</div>
          <div class="unit">{{ unit }}</div>
        </div>
      </div>
    </div>

    <div
      [ngClass]="selectedClass === 'g' ? 'big-bar' : 'small-bar'"
      class="bar no-bm"
    >

    <div
        [ngClass]="{ 'add-border': selectedClass === 'g' }"
        class="borderBox width-g"
      >
    <span
        [ngStyle]="{ color: selectedClass === 'g' ? 'black' : 'white' }"
        class="right-poly g"
        >G</span
      >

    </div>

      <div
        [ngClass]="
          selectedClass === 'g' ? 'thick-line' : 'line'
        "
        class="line"
      ></div>
      <div *ngIf="selectedClass === 'g'" class="left-poly">
        <div class="cursor">
          <div class="energy-reading">{{ value }}</div>
          <div class="unit">{{ unit }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
