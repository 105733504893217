import {Theme} from "./theme.model";

export const lightTheme: Theme = {
    name: "light-theme",
    properties: {
        /* New Properties */
        "--sg-bgr-main": "#efefef",
        "--sg-bgr-primary": "#ffffff",
        "--sg-bgr-secondary": "#fafafa",
        "--sg-bgr-tertiary": "#efefef",
        "--sg-bgr-hover": "#d3d3d3",
        "--sg-font-primary": "#000000",
        "--sg-font-primary-invert": "#ffffff",
        "--sg-font-danger": "#f44336",
        "--sg-color-blue": "#4AAFDB",
        "--sg-error": "#f44336",
        "--sg-box-shadow-primary": "-1px -1px 11px 4px #CCCCCC",
        "--sg-box-shadow-webkit-primary": "-1px -1px 11px 9px rgba(204, 204, 204, 0.49)",
        /* New Properties */

        "--background-primary": "#4fb98c",
        "--background-secondary": "#4fb98c",
        "--background-tertiary": "#fff",
        "--background-quaternary": "red",
        "--background-quinary": "red",
        "--background-light": "red",
        "--background-primary-light": "#4fb98c",
        "--background-logo-color": "#fff",
        "--font-button-primary": "#000000",
        "--font-primary": "#000000",
        "--font-secondary": "#fae000",
        "--font-tertiary": "#fff",
        "--font-quaternary": "#fae000",
        "--font-quinary": "#222221",
        "--font-light": "red",
        "--error-default": "red",
        "--error-dark": "#800600",
        "--error-light": "#ffcecc",
        "--background-size": "auto 100%",
        "--logo-one-width": "170px",
        "--logo-one-height": "40px",
        "--background-logo": "url('/assets/Smart-Globe.png') left center no-repeat",
        "--label-font-color": "#000000",
    }
};
