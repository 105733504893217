import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {DocumentUploadDto} from "../../models/document/document-upload-dto";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {DocumentPresignedDto} from "../../models/document/document-presigned-dto";
import {ErrorHandlerService} from "../error-handler/error-handler.service";
import {DocumentsFiltrationModel} from "../../models/document/documents-filtration-model";
import {DatePipe} from "@angular/common";
import {DashboardDocument} from "../../models/document/dashboard-document";
import {AppService} from "../app/app.service";

@Injectable({providedIn: 'root'})
export class FileManagerService {
  private sgStoragePath = `/api/sg-storage/documents/`;
  private downloadUrl = 'download';
  private presignedUrl = 'presign';

  constructor(private http: HttpClient,
              private errorHandlerService: ErrorHandlerService,
              private appService: AppService
  ) {
  }


  uploadFile(fileUpload: DocumentUploadDto): Observable<DashboardDocument> {
    let body: FormData = new FormData();
    body.append('document', fileUpload.document);
    body.append('documentCategory', fileUpload.documentCategory);
    body.append('parentId', fileUpload.parentId);
    body.append('siteId', fileUpload.siteId);
    body.append('resourceType', fileUpload.resourceType);

    return this.http.post<any>(this.sgStoragePath + 'upload', body).pipe(
      catchError(this.errorHandlerService.handleError),
    );
  }

  getPublicDocumentFile(documentPath: string): Observable<Blob> {
    let params = new HttpParams().append('path', documentPath);
    if (!documentPath || documentPath.length == 0) return of(null)
    let url = this.sgStoragePath + this.downloadUrl;
    return this.http.get(url, {params: params, responseType: 'blob'})
  }

  getPublicSvgFile(documentPath: string): Observable<string> {
    let params = new HttpParams().append('path', documentPath);
    if (!documentPath || documentPath.length == 0) return of(null)
    let url = this.sgStoragePath + this.downloadUrl;
    return this.http.get(url, {params: params, responseType: 'text'})
  }

  getPrivateDocumentPresign(documentId: string): Observable<string> {
    const headers = {'Content-Type': 'text'};
    let params = new HttpParams().append('documentIds', documentId);
    let url = this.sgStoragePath + this.presignedUrl;
    return this.http.get<DocumentPresignedDto[]>(url, {params: params, headers: headers}).pipe(
      map((value: DocumentPresignedDto[]) => {
        if (value[0]?.presignedUrl) return value[0].presignedUrl
        throw new Error('Image not found');
      }));
  }

  getPrivateDocument(documentId: string): Observable<DocumentPresignedDto[]> {
    const headers = {'Content-Type': 'text'};
    let params = new HttpParams().append('documentIds', documentId);
    let url = this.sgStoragePath + this.presignedUrl;
    return this.http.get<DocumentPresignedDto[]>(url, {params: params, headers: headers})
  }

  getAllDocuments(filterModel: DocumentsFiltrationModel): Observable<DashboardDocument[]> {
    let params: HttpParams = this.appService.createHttpPramsFormObject(filterModel);
    return this.http.get<DashboardDocument[]>(this.sgStoragePath, {params:params}).pipe(catchError(this.errorHandlerService.handleError));
  }

  createDocument(document: DashboardDocument): Observable<DashboardDocument> {
    let body: FormData = new FormData();
    Object.entries(document).forEach(([key, value]) => {
      value && body.set(key, value);
    });
    return this.http.post<DashboardDocument>(this.sgStoragePath + '/upload', body).pipe(catchError(this.errorHandlerService.handleError));
  }

  updateDocument(document: DashboardDocument): Observable<DashboardDocument> {
    const path = this.sgStoragePath + document.id;
    let body: FormData = new FormData();
    Object.entries(document).forEach(([key, value]) => {
      value && body.set(key, value);
    });
    return this.http.put<DashboardDocument>(path, body).pipe(catchError(this.errorHandlerService.handleError));
  }

  deleteDocument(documentId: string): Observable<any> {
    let path = this.sgStoragePath + documentId;
    return this.http.delete(path).pipe(catchError(this.errorHandlerService.handleError));
  }

  getAllTags() {
    return of([]);
  }
}
