import { Pipe } from "@angular/core";

@Pipe({ name: 'capitalize' })
export class CapitalizePipe {
  transform(value: string): string {
    if (!value || value.length === 0) {
      return '';
    }

    // Find the first non-space character
    const firstNonSpaceIndex = value.search(/\S/);

    // If the string contains only spaces, return it as it is
    if (firstNonSpaceIndex === -1) {
      return value;
    }

    // Capitalize the first non-space character
    const firstLetter = value[firstNonSpaceIndex].toUpperCase();

    // Reconstruct the string with the capitalized letter
    return `${value.slice(0, firstNonSpaceIndex)}${firstLetter}${value.slice(firstNonSpaceIndex + 1)}`;
  }
}
