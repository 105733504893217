<div class="gridster-toolbar-container">

  <ng-container>
    <div class="width-100 rounded white-background space-around display-flex height-6">
      <button (click)="save()" class="btn" [disabled]="saveDisabled" >
        <mat-icon>save</mat-icon> {{'save'| translate}}
      </button>
      <button (click)="reset()" class="btn" [disabled]="resetDisabled">
        <mat-icon>autorenew</mat-icon> {{'revert'| translate}}
      </button>
      <div class="loading-shade" *ngIf="saveDisabled">
        <mat-spinner *ngIf="saveDisabled"></mat-spinner>
      </div>
    </div>
    <mat-accordion>

      <mat-expansion-panel [expanded]="true" *ngIf="!pageViewType">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'Indicators'| translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row ">
          <button class="no-border" mat-menu-item (click)="openDialog('depense',false)">
            <mat-icon>attach_money</mat-icon> {{'depense'| translate}}
          </button>
        </div>
        <div class="row">

          <button class="no-border" mat-menu-item (click)="openDialog('stacked-column',false)">
            <mat-icon>bar_chart</mat-icon> {{'stacked-column'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('column-line',false)">
            <mat-icon>show_chart</mat-icon> {{'column-line'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('text',false)">
            <mat-icon>text_fields</mat-icon> {{'text'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('plain_text',false)">
            <mat-icon>text_format</mat-icon> {{'plain_text'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('multi-line',false)">
            <mat-icon>multiline_chart</mat-icon> {{'multi-line'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('pie',false)">
            <mat-icon>pie_chart</mat-icon> {{'pie'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('sla-alarm',false)">
            <mat-icon>show_chart</mat-icon>{{'SLA alarm'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('area-chart',false)">
            <mat-icon>stacked_line_chart</mat-icon> {{'areaChart'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('main-usages',false)">
            <mat-icon>data_usage</mat-icon> {{'mainUsages'| translate}}
          </button>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="!pageViewType || pageViewType == ViewPageEnum.GMAO" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            GMAO
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('gmao-pie', false)">
            <mat-icon>pie_chart</mat-icon> {{'pie'| translate}}
          </button>
        </div>

        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('gmao-stacked-column',false)">
            <mat-icon>analytics</mat-icon> {{'stacked-column'| translate}}
          </button>
        </div>

        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('gmao-stacked-bar',false)">
            <mat-icon>line_style</mat-icon> {{'stackedBar'| translate}}
          </button>
        </div>

        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('ticket-count',false)">
            <mat-icon>looks_3
            </mat-icon>{{'ticket number'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('ticket-grouped-count',false)">
            <mat-icon>bar_chart
            </mat-icon>{{'TicketComponent.ticketGroupedNumber'| translate}}
          </button>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="!pageViewType || pageViewType == ViewPageEnum.INTERVENTIONS" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'Intervention'}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('intervention-stacked-column',false)">
            <mat-icon>line_style
            </mat-icon>{{'stackedBar'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('intervention-count',false)">
            <mat-icon>looks_3
            </mat-icon>{{'interventionNumber'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('intervention-pie',false)">
            <mat-icon>pie_chart
            </mat-icon>{{'pie'| translate}}
          </button>
        </div>

        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('intervention-column',false)">
            <mat-icon> bar_chart
            </mat-icon>{{'column'| translate}}
          </button>
        </div>

      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="!pageViewType" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'energy'| translate | capitalize}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('thermal-power',false)">
            <mat-icon>brightness_5</mat-icon> {{'thermal-power'| translate}}
          </button>
        </div>

        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('consumption-simple',false)">
            <mat-icon>trending_down</mat-icon> {{'annual consumption'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('consumption-compare-unitary',false)">
            <mat-icon>difference</mat-icon> {{'consumption compare'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('consumption-compare',false)">
            <mat-icon>compare_arrows</mat-icon> {{'consumption compare'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('label',false)">
            <mat-icon>label</mat-icon> {{'energy and emission label'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('degree-day',false)">
            <mat-icon>wb_sunny</mat-icon>{{'degree days'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('energy-price',false)">
            <mat-icon>euro</mat-icon>{{'energy price'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('gmao-floor-map', false)">
            <mat-icon>map
            </mat-icon> {{ 'floorMap' | translate }}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('energy-consumption', false)">
            <mat-icon>electric_bolt</mat-icon> {{ 'ENERGY_CONSUMPTION.ENERGY_CONSUMPTION' | translate }}
          </button>
        </div>

        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('power-calls', false)">
            <mat-icon>power</mat-icon> {{ 'ENERGY_CONSUMPTION.POWER_CALLS' | translate }}
          </button>
        </div>

      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="!pageViewType" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'Actions'| translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('switch',false)">
            <mat-icon>toggle_on</mat-icon> {{'switch'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('actions',false)">
            <mat-icon>offline_bolt</mat-icon> {{'actions'| translate}}
          </button>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="!pageViewType" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'Others'| translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('alarm',false)">
            <mat-icon>alarm</mat-icon> {{'alarm'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('schedule',false)">
            <mat-icon>insert_invitation</mat-icon> {{'schedule'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('gauge',false)">
            <mat-icon>av_timer</mat-icon> {{'gauge'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('pump',false)">
            <mat-icon>cached</mat-icon> {{'pump'| translate}}
          </button>
        </div>
        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('image-active', false)">
              <mat-icon>image</mat-icon> {{'imageActive.name'| translate}}
          </button>
        </div>

        <div class="row">
          <button class="no-border" mat-menu-item (click)="openDialog('outdoor-temperature', false)">
            <mat-icon>thermostat</mat-icon> {{ 'ENERGY_CONSUMPTION.OUT_DOOR_TEMPERATURE' | translate }}
          </button>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</div>
