import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
/**
 * @deprecated
 */
export class SnackBarManager {
  constructor(
    protected _snackBar: MatSnackBar,
    protected translateService: TranslateService
  ) {}

  /**
   * @deprecated
   * method used to open a snack bar with a translated message and action
   * @param message
   */
  openSnackBar(message: string) {
    this.translateService.get('ok').subscribe((action: string) => {
      this.translateService
        .get(message)
        .subscribe((translatedMessage: string) => {
          this._snackBar.open(translatedMessage, action, {
            duration: 4000,
            panelClass: 'yellow'
          });
        });
    });
  }
}
