import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SmallScreensMenuComponent } from './small-screens-menu.component';


@NgModule({
  declarations: [SmallScreensMenuComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatMenuModule,
    TranslateModule,
    RouterModule,
    MatButtonToggleModule
  ],
  exports: [SmallScreensMenuComponent]
})
export class SmallScreensMenuModule {}
